export const SET_ERROR = 'SET_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const VERIFICATIONCODE_SUCCESS = 'VERIFICATIONCODE_SUCCESS';
export const VERIFICATIONCODE_CLEAR = 'VERIFICATIONCODE_CLEAR';

export const VERIFICATIONCODE_FAILED = 'VERIFICATIONCODE_FAILED';
export const VERIFYCODE_SUCCESS = 'VERIFYCODE_SUCCESS';
export const VERIFYCODE_FAILED = 'VERIFYCODE_FAILED';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_FAILED = 'RESETPASSWORD_FAILED';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GENERATED_2FA = 'GENERATED_2FA';
export const GET_SETTINGS = 'GET_SETTINGS';
export const ENABLE_2FA = 'ENABLE_2FA';
export const RESET = 'RESET';
export const GENERATED_2FA_ERROR = 'GENERATED_2FA_ERROR';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';
export const ENABLE_2FA_ERROR = 'ENABLE_2FA_ERROR';
export const GET_LEAVES_STATUS = 'GET_LEAVES_STATUS';
export const GET_LEAVES_STATUS_ERROR = 'GET_LEAVES_STATUS_ERROR';
export const GET_LEAVE_TYPES = 'GET_LEAVE_TYPES';
export const GET_LEAVE_TYPES_ERROR = 'GET_LEAVE_TYPES_ERROR';
export const GET_LEAVE_BALANCE = 'GET_LEAVE_BALANCE';
export const GET_LEAVE_BALANCE_ERROR = 'GET_LEAVE_BALANCE_ERROR';
export const CLEAR_LEAVE_BALANCE = "CLEAR_LEAVE_BALANCE";
export const GET_LEAVES_LIST = 'GET_LEAVES_LIST';
export const GET_LEAVES_LIST_ERROR = 'GET_LEAVES_LIST_ERROR';
export const GET_LEAVE_DETAILS = 'GET_LEAVE_DETAILS';
export const CLEAR_LEAVE_DETAILS = 'CLEAR_LEAVE_DETAILS';
export const GET_LEAVE_DETAILS_ERROR = 'GET_LEAVE_DETAILS_ERROR';
export const SAVE_LEAVE_DETAILS = 'SAVE_LEAVE_DETAILS';
export const SAVE_LEAVE_DETAILS_ERROR = 'SAVE_LEAVE_DETAILS_ERROR';
export const WITHDRAW_LEAVE = 'WITHDRAW_LEAVE';
export const WITHDRAW_LEAVE_ERROR = 'WITHDRAW_LEAVE_ERROR';
export const GET_EXPENSE_STATUS_LIST = 'GET_EXPENSE_STATUS_LIST';
export const GET_EXPENSE_STATUS_LIST_ERROR = 'GET_EXPENSE_STATUS_LIST_ERROR';
export const GET_SUB_STATUS_LIST = 'GET_SUB_STATUS_LIST';
export const GET_SUB_STATUS_LIST_ERROR = 'GET_SUB_STATUS_LIST_ERROR';
export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_ROLE_LIST_ERROR = 'GET_ROLE_LIST_ERROR';
export const GET_ENTITLED_LEAVE_LIST = 'GET_ENTITLED_LEAVE_LIST';
export const GET_ENTITLED_LEAVE_LIST_ERROR = 'GET_ENTITLED_LEAVE_LIST_ERROR';
export const GET_USER_EXPENSE_LIST = 'GET_USER_EXPENSE_LIST';
export const GET_USER_EXPENSE_LIST_ERROR = 'GET_USER_EXPENSE_LIST_ERROR';
export const GET_USER_EXPENSE_SHEET_DETAILS = 'GET_USER_EXPENSE_SHEET_DETAILS';
export const GET_USER_EXPENSE_SHEET_DETAILS_ERROR =
  'GET_USER_EXPENSE_SHEET_DETAILS_ERROR';
export const RESET_MODIFIED_EXPENSE_STATE = "RESET_MODIFIED_EXPENSE_STATE";
export const SEARCH_USER_LIST = 'SEARCH_USER_LIST';
export const SEARCH_USER_LIST_ERROR = 'SEARCH_USER_LIST_ERROR';
export const GET_EXPENSE_MILEAGE_RATE = 'GET_EXPENSE_MILEAGE_RATE';
export const GET_EXPENSE_MILEAGE_RATE_ERROR = 'GET_EXPENSE_MILEAGE_RATE_ERROR';
export const GET_USER_EXPENSE_MILEAGE_ITEM = 'GET_USER_EXPENSE_MILEAGE_ITEM';
export const GET_USER_EXPENSE_MILEAGE_ITEM_ERROR =
  'GET_USER_EXPENSE_MILEAGE_ITEM_ERROR';
export const SAVE_USER_EXPENSE_SHEET = 'SAVE_USER_EXPENSE_SHEET';
export const SAVE_USER_EXPENSE_SHEET_ERROR = 'SAVE_USER_EXPENSE_SHEET_ERROR';
export const SUBMIT_USER_EXPENSE_SHEET = 'SUBMIT_USER_EXPENSE_SHEET';
export const SUBMIT_USER_EXPENSE_SHEET_ERROR =
  'SUBMIT_USER_EXPENSE_SHEET_ERROR';
export const SAVE_REPORT_NAME_ADND_DATE =
  'SAVE_REPORT_NAME_ADND_DATE';
export const WITHDRAW_USER_EXPENSE_SHEET = 'WITHDRAW_USER_EXPENSE_SHEET';
export const WITHDRAW_USER_EXPENSE_SHEET_ERROR =
  'WITHDRAW_USER_EXPENSE_SHEET_ERROR';
export const SAVE_USER_EXPENSE_MILEAGE_ITEM = 'SAVE_USER_EXPENSE_MILEAGE_ITEM';
export const SAVE_USER_EXPENSE_MILEAGE_ITEM_ERROR =
  'SAVE_USER_EXPENSE_MILEAGE_ITEM_ERROR';
export const GET_USER_EXPENSE_ITEM = 'GET_USER_EXPENSE_ITEM';
export const GET_USER_EXPENSE_ITEM_ERROR = 'GET_USER_EXPENSE_ITEM_ERROR';
export const SAVE_USER_EXPENSE_ITEM = 'SAVE_USER_EXPENSE_ITEM';
export const SAVE_USER_EXPENSE_ITEM_ERROR = 'SAVE_USER_EXPENSE_ITEM_ERROR';
export const GET_USER_EXPENSE_ITEM_RECEIPT_FILE =
  'GET_USER_EXPENSE_ITEM_RECEIPT_FILE';
export const GET_USER_EXPENSE_ITEM_RECEIPT_FILE_ERROR =
  'GET_USER_EXPENSE_ITEM_RECEIPT_FILE_ERROR';
export const UPLOAD_RECEIPT = 'UPLOAD_RECEIPT';
export const UPLOAD_RECEIPT_ERROR = 'UPLOAD_RECEIPT_ERROR';
export const GET_EXPENSE_TYPE_LIST = 'GET_EXPENSE_TYPE_LIST';
export const GET_EXPENSE_TYPE_LIST_ERROR = 'GET_EXPENSE_TYPE_LIST_ERROR';
export const GENERATE_EXPENSE_INVOICE = 'GENERATE_EXPENSE_INVOICE';
export const VIEW_ARTISAN_PROFILE = 'VIEW_ARTISAN_PROFILE';
export const VIEW_ARTISAN_PROFILE_ERROR = 'VIEW_ARTISAN_PROFILE_ERROR';
export const GET_HIRE_DATE = "GET_HIRE_DATE";
export const GET_HIRE_DATE_ERROR = "GET_HIRE_DATE";
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_ERROR = 'GET_COUNTRY_LIST_ERROR';
export const GET_REGION_LIST = 'GET_REGION_LIST';
export const GET_REGION_LIST_ERROR = 'GET_REGION_LIST_ERROR';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const GET_CITY_LIST_ERROR = 'GET_CITY_LIST_ERROR';
export const GET_CITY_DETAILS = 'GET_CITY_DETAILS';
export const CLEAR_CITY_DETAILS = 'CLEAR_CITY_DETAILS';
export const GET_CITY_DETAILS_ERROR = 'GET_CITY_DETAILS_ERROR';
export const GET_CURRENCY_LIST = 'GET_CURRENCY_LIST';
export const GET_CURRENCY_LIST_ERROR = 'GET_CURRENCY_LIST_ERROR';
export const GET_CURRENCY_DETAILS = 'GET_CURRENCY_DETAILS';
export const GET_CURRENCY_DETAILS_ERROR = 'GET_CURRENCY_DETAILS_ERROR';
export const SAVE_CURRENCY_DETAILS = 'SAVE_CURRENCY_DETAILS';
export const SAVE_CURRENCY_DETAILS_ERROR = 'SAVE_CURRENCY_DETAILS_ERROR';
export const GET_COMPENSATION_TYPES = 'GET_COMPENSATION_TYPES';
export const GET_COMPENSATION_TYPES_ERROR = 'GET_COMPENSATION_TYPES_ERROR';
export const GET_WORKER_TYPES = 'GET_WORKER_TYPES';
export const GET_WORKER_TYPES_ERROR = 'GET_WORKER_TYPES_ERROR';
export const GET_VISA_STATUS_LIST = 'GET_VISA_STATUS_LIST';
export const GET_VISA_STATUS_LIST_ERROR = 'GET_VISA_STATUS_LIST_ERROR';
export const GET_DEAL_TYPES = 'GET_DEAL_TYPES';
export const GET_DEAL_TYPES_ERROR = 'GET_DEAL_TYPES_ERROR';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';
export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const SAVE_USER_PROFILE_ERROR = 'SAVE_USER_PROFILE_ERROR';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';
export const SAVE_USER_PLACEMENT = 'SAVE_USER_PLACEMENT';
export const SAVE_USER_PLACEMENT_ERROR = 'SAVE_USER_PLACEMENT_ERROR'
export const SET_USER_PASSWORD = 'SET_USER_PASSWORD';
export const SET_USER_PASSWORD_ERROR = 'SET_USER_PASSWORD_ERROR';
export const USER_DISABLE_2FA = 'USER_DISABLE_2FA';
export const SAVE_USER_COMPENSATION = 'SAVE_USER_COMPENSATION';
export const SAVE_USER_COMPENSATION_ERROR = 'SAVE_USER_COMPENSATION_ERROR';
export const SAVE_USER_BENEFITS = 'SAVE_USER_BENEFITS';
export const SAVE_USER_BENEFITS_ERROR = 'SAVE_USER_BENEFITS';
export const USER_DISABLE_2FA_ERROR = 'USER_DISABLE_2FA_ERROR';
export const SET_USER_ACCOUNT_STATUS = 'SET_USER_ACCOUNT_STATUS';
export const SET_USER_ACCOUNT_STATUS_ERROR = 'SET_USER_ACCOUNT_STATUS_ERROR';
export const GET_EMPLOYMENT_STATUS_LIST = 'GET_EMPLOYMENT_STATUS_LIST';
export const GET_EMPLOYMENT_STATUS_LIST_ERROR =
  'GET_EMPLOYMENT_STATUS_LIST_ERROR';
export const GET_EMPLOYMENT_SUB_STATUS_LIST = 'GET_EMPLOYMENT_SUB_STATUS_LIST';
export const GET_EMPLOYMENT_SUB_STATUS_LIST_ERROR =
  'GET_EMPLOYMENT_SUB_STATUS_LIST_ERROR';
export const GET_SUBSCRIBER_LIST = 'GET_SUBSCRIBER_LIST';
export const GET_SUBSCRIBER_LIST_ERROR = 'GET_SUBSCRIBER_LIST_ERROR';
export const GET_SALESCONSULTANT_LIST = 'GET_SALESCONSULTANT_LIST';
export const GET_SALESCONSULTANT_LIST_ERROR = 'GET_SALESCONSULTANT_LIST_ERROR';
export const GET_HR_CONSULTANT = ' GET_HR_CONSULTANT';
export const GET_HR_CONSULTANT_ERROR = ' GET_HR_CONSULTANT_ERROR';
export const GET_BENEFITS_COVERAGE_LIST = 'GET_BENEFITS_COVERAGE_LIST';
export const GET_BENEFITS_COVERAGE_LIST_ERROR =
  'GET_BENEFITS_COVERAGE_LIST_ERROR';
export const GET_PERKS_FREQUENCY_LIST = 'GET_PERKS_FREQUENCY_LIST'
export const GET_PERKS_FREQUENCY_LIST_ERROR = 'GET_PERKS_FREQUENCY_LIST_ERROR'
export const GET_PENSION_PLAN_FREQUENCY_LIST = 'GET_PENSION_PLAN_FREQUENCY_LIST'
export const GET_PENSION_PLAN_FREQUENCY_LIST_ERROR = 'GET_PENSION_PLAN_FREQUENCY_LIST_ERROR'
export const GET_PENSION_PLAN_LIST = 'GET_PENSION_PLAN_LIST'
export const GET_PENSION_PLAN_LIST_ERROR = 'GET_PENSION_PLAN_LIST_ERROR'
export const GET_PREFERRED_EMAIL_LIST = 'GET_PREFERRED_EMAIL_LIST'
export const GET_PREFERRED_EMAIL_LIST_ERROR = 'GET_PREFERRED_EMAIL_LIST_ERROR'
export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const GET_CLIENT_LIST_ERROR = 'GET_CLIENT_LIST_ERROR';
export const GET_CLIENT_CONTACT_LIST = 'GET_CLIENT_CONTACT_LIST';
export const GET_CLIENT_CONTACT_LIST_ERROR = 'GET_CLIENT_CONTACT_LIST_ERROR';
export const GET_BENEFITS_CLASS_LIST = 'GET_BENEFITS_CLASS_LIST';
export const GET_BENEFITS_CLASS_LIST_ERROR = 'GET_BENEFITS_CLASS_LIST_ERROR';
export const GET_PRONOUN_LIST = 'GET_PRONOUN_LIST';
export const GET_PRONOUN_LIST_ERROR = 'GET_PRONOUN_LIST_ERROR';
export const GET_MARITAL_STATUS_LIST = 'GET_MARITAL_STATUS_LIST';
export const GET_MARITAL_STATUS_LIST_ERROR = 'GET_MARITAL_STATUS_LIST_ERROR';
export const SEARCH_LEAVES = 'SEARCH_LEAVES';
export const SEARCH_LEAVES_ERROR = 'SEARCH_LEAVES_ERROR';
export const GET_SUB_LEAVE_DETAILS = 'GET_SUB_LEAVE_DETAILS';
export const GET_SUB_LEAVE_DETAILS_ERROR = 'GET_SUB_LEAVE_DETAILS_ERROR';
export const GET_SUB_EXPENSE_REPORTS = 'GET_SUB_EXPENSE_REPORTS';
export const GET_SUB_EXPENSE_REPORTS_ERROR = 'GET_SUB_EXPENSE_REPORTS_ERROR';
export const SUB_WITHDRAW_LEAVE = 'SUB_WITHDRAW_LEAVE';
export const SUB_WITHDRAW_LEAVE_ERROR = 'SUB_WITHDRAW_LEAVE_ERROR';
export const SUB_SUBMIT_LEAVE_DETAILS = 'SUB_SUBMIT_LEAVE_DETAILS';
export const SUB_SUBMIT_LEAVE_DETAILS_ERROR = 'SUB_SUBMIT_LEAVE_DETAILS_ERROR';
export const GET_SUB_LEAVE_TYPES = 'GET_SUB_LEAVE_TYPES';
export const GET_SUB_LEAVE_TYPES_ERROR = 'GET_SUB_LEAVE_TYPES_ERROR';
export const GET_SUB_CLIENT_LIST = 'GET_SUB_CLIENT_LIST';
export const GET_SUB_CLIENT_LIST_ERROR = 'GET_SUB_CLIENT_LIST_ERROR';

export const GET_CLIENT_PROFILE = 'GET_CLIENT_PROFILE';
export const GET_CLIENT_PROFILE_ERROR = 'GET_CLIENT_PROFILE_ERROR';
export const SAVE_CLIENT_PROFILE = 'SAVE_CLIENT_PROFILE';
export const SAVE_CLIENT_PROFILE_ERROR = 'SAVE_CLIENT_PROFILE_ERROR';
export const GET_WORKER_FOR_CLIENT_LIST = 'GET_WORKER_FOR_CLIENT_LIST';
export const GET_WORKER_FOR_CLIENT_LIST_ERROR =
  'GET_WORKER_FOR_CLIENT_LIST_ERROR';
export const GET_CLIENT_EMPLOYMENT_STATUS_LIST = 'GET_CLIENT_EMPLOYMENT_STATUS_LIST';
export const GET_CLIENT_EMPLOYMENT_STATUS_LIST_ERROR = 'GET_CLIENT_EMPLOYMENT_STATUS_LIST_ERROR';
export const SEARCH_WORKER_FOR_CLIENT_LIST = 'SEARCH_WORKER_FOR_CLIENT_LIST';
export const SEARCH_WORKER_FOR_CLIENT_LIST_ERROR = 'SEARCH_WORKER_FOR_CLIENT_LIST_ERROR';
export const GET_CLIENT_VIEW_WORKER_PROFILE = 'GET_CLIENT_VIEW_WORKER_PROFILE';
export const CLEAR_CLIENT_VIEW_WORKER_PROFILE = 'CLEAR_CLIENT_VIEW_WORKER_PROFILE';
export const GET_CLIENT_VIEW_WORKER_PROFILE_ERROR = 'GET_CLIENT_VIEW_WORKER_PROFILE_ERROR';
export const GET_USER_JOURNEY_LIST_FOR_SUB = 'GET_USER_JOURNEY_LIST_FOR_SUB';
export const DELETE_USER_JOURNEY_FOR_SUB = 'DELETE_USER_JOURNEY_FOR_SUB';
export const DELETE_USER_JOURNEY_FOR_SUB_ERROR = 'DELETE_USER_JOURNEY_FOR_SUB_ERROR';
export const GET_USER_JOURNEY_LIST_FOR_SUB_ERROR = 'GET_USER_JOURNEY_LIST_FOR_SUB_ERROR';
export const SEARCH_WORKER_LEAVES_LIST = 'SEARCH_WORKER_LEAVES_LIST';
export const SEARCH_WORKER_LEAVES_LIST_ERROR = 'SEARCH_WORKER_LEAVES_LIST_ERROR';
export const SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT = 'SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT';
export const SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT_ERROR = 'SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT_ERROR';
export const GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT = 'GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT';
export const CLEAR_EXPENSE_SHEET_DETAILS_FOR_CLIENT = 'CLEAR_EXPENSE_SHEET_DETAILS_FOR_CLIENT';
export const GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT_ERROR = 'GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT_ERROR';
export const GET_CLIENT_VIEW_WORKER_LEAVE = 'GET_CLIENT_VIEW_WORKER_LEAVE';
export const GET_CLIENT_VIEW_WORKER_LEAVE_ERROR = 'GET_CLIENT_VIEW_WORKER_LEAVE_ERROR';
export const GET_CLIENT_EXPENSE_ITEM = 'GET_CLIENT_EXPENSE_ITEM';
export const GET_CLIENT_EXPENSE_ITEM_ERROR = 'GET_CLIENT_EXPENSE_ITEM_ERROR';
export const GET_CLIENT_MILEAGE_ITEM = 'GET_CLIENT_MILEAGE_ITEM';
export const GET_CLIENT_MILEAGE_ITEM_ERROR = 'GET_CLIENT_MILEAGE_ITEM_ERROR';
export const GET_WORKER_JOURNEY_FOR_CLIENT = 'GET_USER_JOURNEY_FOR_CLIENT';
export const GET_WORKER_JOURNEY_FOR_CLIENT_ERROR = 'GET_USER_JOURNEY_FOR_CLIENT_ERROR';
export const GET_ARTISAN_JOURNEY = 'GET_ARTISAN_JOURNEY';
export const GET_ARTISAN_JOURNEY_ERROR = 'GET_ARTISAN_JOURNEY_ERROR';

export const GET_MILEAGE_RANGE_LIST = 'GET_MILEAGE_RANGE_LIST';
export const GET_MILEAGE_RANGE_LIST_ERROR = 'GET_MILEAGE_RANGE_LIST_ERROR';
export const GET_LEAVE_TYPE_DETAILS = 'GET_LEAVE_TYPE_DETAILS';
export const GET_LEAVE_TYPE_DETAILS_ERROR = 'GET_LEAVE_TYPE_DETAILS_ERROR';
export const SAVE_LEAVE_TYPE_DETAILS = 'SAVE_LEAVE_TYPE_DETAILS';
export const SAVE_LEAVE_TYPE_DETAILS_ERROR = 'SAVE_LEAVE_TYPE_DETAILS_ERROR';
export const GET_MILEAGE_RANGE_DETAILS = 'GET_MILEAGE_RANGE_DETAILS';
export const GET_MILEAGE_RANGE_DETAILS_ERROR =
  'GET_MILEAGE_RANGE_DETAILS_ERROR';

export const SAVE_MILEAGE_RANGE_DETAILS = 'SAVE_MILEAGE_RANGE_DETAILS';
export const SAVE_MILEAGE_RANGE_DETAILS_ERROR =
  'SAVE_MILEAGE_RANGE_DETAILS_ERROR';

export const GET_SYSADMIN_SUBSCRIBER_LIST = 'GET_SYSADMIN_SUBSCRIBER_LIST';
export const GET_SYSADMIN_SUBSCRIBER_LIST_ERROR =
  'GET_SYSADMIN_SUBSCRIBER_LIST_ERROR';

export const GET_SYSADMIN_SUBSCRIBER_USER_LIST =
  'GET_SYSADMIN_SUBSCRIBER_USER_LIST';
export const GET_SYSADMIN_SUBSCRIBER_USER_LIST_ERROR =
  'GET_SYSADMIN_SUBSCRIBER_USER_LIST_ERROR';
export const GET_SYSADMIN_SUBSCRIBER_PROFILE =
  'GET_SYSADMIN_SUBSCRIBER_PROFILE';
export const GET_SYSADMIN_SUBSCRIBER_PROFILE_ERROR =
  'GET_SYSADMIN_SUBSCRIBER_PROFILE_ERROR';
export const SAVE_SYSADMIN_SUBSCRIBER_PROFILE =
  'SAVE_SYSADMIN_SUBSCRIBER_PROFILE';
export const SAVE_SYSADMIN_SUBSCRIBER_PROFILE_ERROR =
  'SAVE_SYSADMIN_SUBSCRIBER_PROFILE_ERROR';

export const SEARCH_SYSADMIN_USER_LIST =
  'SEARCH_SYSADMIN_USER_LIST';
export const SEARCH_SYSADMIN_USER_LIST_ERROR =
  'SEARCH_SYSADMIN_USER_LIST_ERROR';

export const GET_SUB_EXPENSE_ITEM = 'GET_SUB_EXPENSE_ITEM';
export const GET_SUB_EXPENSE_ITEM_ERROR = 'GET_SUB_EXPENSE_ITEM_ERROR';
export const GET_SUB_MILEAGE_ITEM = 'GET_SUB_MILEAGE_ITEM';
export const GET_SUB_MILEAGE_ITEM_ERROR = 'GET_SUB_MILEAGE_ITEM_ERROR';

export const SAVE_SUB_EXPENSE_ITEM = 'SAVE_SUB_EXPENSE_ITEM';
export const SAVE_SUB_EXPENSE_ITEM_ERROR = 'SAVE_SUB_EXPENSE_ITEM_ERROR';
export const SAVE_SUB_MILEAGE_ITEM = 'SAVE_SUB_MILEAGE_ITEM';
export const SAVE_SUB_MILEAGE_ITEM_ERROR = 'SAVE_SUB_MILEAGE_ITEM_ERROR';

export const APPROVE_SUB_EXPENSE_ITEM = 'APPROVE_SUB_EXPENSE_ITEM';
export const APPROVE_SUB_MILEAGE_ITEM = 'APPROVE_SUB_MILEAGE_ITEM';
export const APPROVE_SUB_MILEAGE_ITEM_ERROR = 'APPROVE_SUB_MILEAGE_ITEM_ERROR';

export const REJECT_SUB_EXPENSE_ITEM = 'REJECT_SUB_EXPENSE_ITEM';
export const REJECT_SUB_EXPENSE_ITEM_ERROR = 'REJECT_SUB_EXPENSE_ITEM_ERROR';
export const REJECT_SUB_MILEAGE_ITEM = 'REJECT_SUB_MILEAGE_ITEM';
export const REJECT_SUB_MILEAGE_ITEM_ERROR = 'REJECT_SUB_MILEAGE_ITEM_ERROR';

export const GET_SUB_EXPENSE_REPORT_DETAILS = 'GET_SUB_EXPENSE_REPORT_DETAILS';
export const GET_SUB_EXPENSE_REPORT_DETAILS_ERROR = 'GET_SUB_EXPENSE_REPORT_DETAILS_ERROR';

export const GET_CITY_DETAILED_LIST = 'GET_CITY_DETAILED_LIST';
export const GET_CITY_DETAILED_LIST_ERROR = 'GET_CITY_DETAILED_LIST_ERROR';
export const SAVE_CITY_DETAILS = 'SAVE_CITY_DETAILS';
export const SAVE_CITY_DETAILS_ERROR = 'SAVE_CITY_DETAILS_ERROR';

export const APPROVE_LEAVE = 'APPROVE_LEAVE';
export const APPROVE_LEAVE_ERROR = 'APPROVE_LEAVE_ERROR';

export const REJECT_LEAVE = 'REJECT_LEAVE';
export const REJECT_LEAVE_ERROR = 'REJECT_LEAVE_ERROR';

export const DISABLE_2FA = 'DISABLE_2FA';
export const DISABLE_2FA_ERROR = 'DISABLE_2FA_ERROR';

export const CLEAR_SHEET_REF_NUM = 'CLEAR_SHEET_REF_NUM';
export const CLEAR_EXPENSE_SHEET = 'CLEAR_EXPENSE_SHEET';
export const CLEAR_USER_EXPENSE_ITEM_RECEIPT_FILE = 'CLEAR_USER_EXPENSE_ITEM_RECEIPT_FILE';

export const GET_PENSION_NOTE_LIST = "GET_PENSION_NOTE_LIST";
export const GET_PENSION_NOTE_LIST_ERROR = "GET_PENSION_NOTE_LIST_ERROR";
export const GET_SUBSCRIBER_NOTE_LIST = "GET_SUBSCRIBER_NOTE_LIST";
export const GET_SUBSCRIBER_NOTE_LIST_ERROR = "GET_SUBSCRIBER_NOTE_LIST_ERROR";

export const GET_SEVERANCE_NOTE_LIST = "GET_SEVERANCE_NOTE_LIST";
export const GET_SEVERANCE_NOTE_LIST_ERROR = "GET_SEVERANCE_NOTE_LIST_ERROR";

export const GET_NOTE_LIST = "GET_NOTE_LIST";
export const GET_NOTE_LIST_ERROR = "GET_NOTE_LIST_ERROR";
export const GET_NOTE_DETAILS = "GET_NOTE_DETAILS";
export const GET_NOTE_DETAILS_ERROR = "GET_NOTE_DETAILS_ERROR";
export const SAVE_NOTE = "SAVE_NOTE";
export const SAVE_NOTE_ERROR = "SAVE_NOTE_ERROR";
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";

export const CLEAR_NOTE_LISTS = "CLEAR_NOTE_LISTS";
export const SEARCH_SUB_HOLIDAYS = 'SEARCH_SUB_HOLIDAYS';
export const SEARCH_SUB_HOLIDAYS_ERROR = 'SEARCH_SUB_HOLIDAYS_ERROR';
export const GET_HOLIDAY_DETAILS = 'GET_HOLIDAY_DETAILS';
export const GET_HOLIDAY_DETAILS_ERROR = 'GET_HOLIDAY_DETAILS_ERROR';
export const SAVE_HOLIDAY_DETAILS = 'SAVE_HOLIDAY_DETAILS';
export const SAVE_HOLIDAY_DETAILS_ERROR = 'SAVE_HOLIDAY_DETAILS_ERROR';
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';
export const DELETE_HOLIDAY_ERROR = 'DELETE_HOLIDAY_ERROR';

export const GET_EXPENSE_CONFIG_TYPE_LIST = 'GET_EXPENSE_CONFIG_TYPE_LIST';
export const GET_EXPENSE_CONFIG_TYPE_LIST_ERROR = 'GET_EXPENSE_CONFIG_TYPE_LIST_ERROR';
export const GET_ALLOWANCE_TYPE_LIST = 'GET_ALLOWANCE_TYPE_LIST';
export const GET_ALLOWANCE_TYPE_LIST_ERROR = 'GET_ALLOWANCE_TYPE_LIST_ERROR';

export const GET_ALLOWANCE_TYPE_DETAILS = 'GET_ALLOWANCE_TYPE_DETAILS';
export const GET_ALLOWANCE_TYPE_DETAILS_ERROR = 'GET_ALLOWANCE_TYPE_DETAILS_ERROR';
export const SAVE_ALLOWANCE_TYPE_DETAILS = 'SAVE_ALLOWANCE_TYPE_DETAILS';
export const SAVE_ALLOWANCE_TYPE_DETAILS_ERROR = 'SAVE_ALLOWANCE_TYPE_DETAILS_ERROR';
export const DELETE_ALLOWANCE_TYPE_DETAILS = 'DELETE_ALLOWANCE_TYPE_DETAILS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR';
export const USER_HAS_EXISTING_DATA = ' USER_HAS_EXISTING_DATA';
export const USER_HAS_EXISTING_DATA_ERROR = ' USER_HAS_EXISTING_DATA_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const DELETE_ALLOWANCE_TYPE_DETAILS_ERROR = 'DELETE_ALLOWANCE_TYPE_DETAILS_ERROR';

export const GET_EXPENSE_TYPE_DETAILS = 'GET_EXPENSE_TYPE_DETAILS';
export const GET_EXPENSE_TYPE_DETAILS_ERROR = 'GET_EXPENSE_TYPE_DETAILS_ERROR';
export const SAVE_EXPENSE_TYPE_DETAILS = 'SAVE_EXPENSE_TYPE_DETAILS';
export const SAVE_EXPENSE_TYPE_DETAILS_ERROR = 'SAVE_EXPENSE_TYPE_DETAILS_ERROR';
export const DELETE_EXPENSE_TYPE_DETAILS = 'DELETE_EXPENSE_TYPE_DETAILS';
export const DELETE_EXPENSE_TYPE_DETAILS_ERROR = 'DELETE_EXPENSE_TYPE_DETAILS_ERROR';

export const GET_SEARCH_LEAVE_POLICY_SUCCESS = 'GET_SEARCH_LEAVE_POLICY_SUCCESS'
export const GET_SEARCH_LEAVE_POLICY_ERROR = 'GET_SEARCH_LEAVE_POLICY_ERROR'
export const GET_LEAVE_POLICY_LOCATION_LIST = 'GET_LEAVE_POLICY_LOCATION_LIST';
export const GET_LEAVE_POLICY_LOCATION_LIST_ERROR = 'GET_LEAVE_POLICY_LOCATION_LIST_ERROR';

export const GET_LEAVE_POLICY_LOCATION_DETAILS = 'GET_LEAVE_POLICY_LOCATION_DETAILS';
export const GET_LEAVE_POLICY_LOCATION_DETAILS_ERROR = 'GET_LEAVE_POLICY_LOCATION_DETAILS_ERROR';


export const SAVE_LEAVE_POLICY_LOCATION_DETAILS = 'SAVE_LEAVE_POLICY_LOCATION_DETAILS';
export const SUBMIT_LEAVE_POLICY_LOCATION_DETAILS = 'SUBMIT_LEAVE_POLICY_LOCATION_DETAILS';
export const SUBMIT_LEAVE_POLICY_LOCATION_DETAILS_ERROR = 'SUBMIT_LEAVE_POLICY_LOCATION_DETAILS_ERROR';

export const GET_LEAVE_POLICY_DETAILS = 'GET_LEAVE_POLICY_DETAILS';
export const GET_LEAVE_POLICY_DETAILS_ERROR = 'GET_LEAVE_POLICY_DETAILS_ERROR';

export const GET_LEAVE_QUOTA_RESET_TYPE_LIST = 'GET_LEAVE_QUOTA_RESET_TYPE_LIST';
export const GET_LEAVE_QUOTA_RESET_TYPE_LIST_ERROR = 'GET_LEAVE_QUOTA_RESET_TYPE_LIST_ERROR';


export const GET_LEAVE_PERIOD_STRING_LIST = 'GET_LEAVE_PERIOD_STRING_LIST';
export const GET_LEAVE_PERIOD_STRING_LIST_ERROR = 'GET_LEAVE_PERIOD_STRING_LIST_ERROR';

export const GET_LEAVE_ACCRUAL_DATE_TYPE_LIST = 'GET_LEAVE_ACCRUAL_DATE_TYPE_LIST';
export const GET_LEAVE_ACCRUAL_DATE_TYPE_LIST_ERROR = 'GET_LEAVE_ACCRUAL_DATE_TYPE_LIST_ERROR';

export const GET_LEAVE_ACCRUAL_EVERY_STRING_LIST = 'GET_LEAVE_ACCRUAL_EVERY_STRING_LIST';
export const GET_LEAVE_ACCRUAL_EVERY_STRING_LIST_ERROR = 'GET_LEAVE_ACCRUAL_EVERY_STRING_LIST_ERROR';

export const GET_LEAVE_PAY_OUT_TIME_TYPE_LIST = 'GET_LEAVE_PAY_OUT_TIME_TYPE_LIST';
export const GET_LEAVE_PAY_OUT_TIME_TYPE_LIST_ERROR = 'GET_LEAVE_PAY_OUT_TIME_TYPE_LIST_ERROR';

export const GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST = 'GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST';
export const GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST_ERROR = 'GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST_ERROR';

export const DELETE_LEAVE_POLICY_LOCATION = 'DELETE_LEAVE_POLICY_LOCATION';
export const DELETE_LEAVE_POLICY_DETAILS = 'DELETE_LEAVE_POLICY_DETAILS';
export const DELETE_LEAVE_POLICY_LEVEL_DETAILS = 'DELETE_LEAVE_POLICY_LEVEL_DETAILS';
export const SAVE_LEAVE_POLICY_DETAILS = 'SAVE_LEAVE_POLICY_DETAILS';
export const SAVE_LEAVE_POLICY_LEVEL_DETAILS = 'SAVE_LEAVE_POLICY_LEVEL_DETAILS';
export const SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR = 'SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR';


export const GET_LEAVE_POLICY_NAMES_LIST = 'GET_LEAVE_POLICY_NAMES_LIST';
export const GET_LEAVE_POLICY_NAMES_LIST_ERROR = 'GET_LEAVE_POLICY_NAMES_LIST_ERROR';

export const GET_CLIENT_LEAVE_POLICY_DETAILS = 'GET_CLIENT_LEAVE_POLICY_DETAILS';
export const GET_CLIENT_LEAVE_POLICY_DETAILS_ERROR = 'GET_CLIENT_LEAVE_POLICY_DETAILS_ERROR';

export const SAVE_CLIENT_LEAVE_POLICY_DETAILS = 'SAVE_CLIENT_LEAVE_POLICY_DETAILS';
export const DELETE_CLIENT_LEAVE_POLICY_DETAILS = 'DELETE_CLIENT_LEAVE_POLICY_DETAILS';

export const CLEAR_LEAVE_POLICY_CACHES = 'CLEAR_LEAVE_POLICY_CACHES';

export const GET_CLIENT_LEAVE_POLICY_LIST_SUCCESS = 'GET_CLIENT_LEAVE_POLICY_LIST_SUCCESS';
export const GET_CLIENT_LEAVE_POLICY_LIST_ERROR = 'GET_CLIENT_LEAVE_POLICY_LIST_ERROR';

export const DELETE_CLIENT_LEAVE_POLICY_LIST_SUCCESS = 'DELETE_CLIENT_LEAVE_POLICY_LIST_SUCCESS';
export const DELETE_CLIENT_LEAVE_POLICY_LIST_ERROR = 'DELETE_CLIENT_LEAVE_POLICY_LIST_ERROR'

export const GET_CLIENT_LEAVE_POLICY_DETAIL_SUCCESS = 'GET_CLIENT_LEAVE_POLICY_DETAIL_SUCCESS';
export const GET_CLIENT_LEAVE_POLICY_DETAIL_ERROR = 'GET_CLIENT_LEAVE_POLICY_DETAIL_ERROR';

export const GET_WORKER_LEAVE_POLICY_NAMES_LIST = 'GET_WORKER_LEAVE_POLICY_NAMES_LIST';
export const GET_WORKER_LEAVE_POLICY_NAMES_LIST_ERROR = 'GET_WORKER_LEAVE_POLICY_NAMES_LIST_ERROR';

export const CLEAR_CLIENT_LEAVE_POLICY_DETAILS = 'CLEAR_CLIENT_LEAVE_POLICY_DETAILS';
export const CLEAR__HOLIDAYS_DETAILS = 'CLEAR__HOLIDAYS_DETAILS';

export const CLEAR__CLIENT_PROFILE = 'CLEAR__CLIENT_PROFILE';

export const CLEAR_CURRENCY_DETAILS = 'CLEAR_CURRENCY_DETAILS';
export const CLEAR_WORKER_LEAVE_DETAILS = 'CLEAR_WORKER_LEAVE_DETAILS';

export const GET_CALCULATED_LEAVE_INFO = 'GET_CALCULATED_LEAVE_INFO';
export const ENABLE_LEAVE_SUBMIT = 'ENABLE_LEAVE_SUBMIT';

export const GET_CALCULATED_LEAVE_INFO_ERROR = 'GET_CALCULATED_LEAVE_INFO_ERROR';

export const CLEAR_CALCULATED_LEAVE_INFO = 'CLEAR_CALCULATED_LEAVE_INFO';

export const CLEAR_CLIENT_LEAVES_LIST = 'CLEAR_CLIENT_LEAVES_LIST';

export const CLEAR_SYSADMIN_SUBSCRIBER_PROFILE = 'CLEAR_SYSADMIN_SUBSCRIBER_PROFILE';


export const GET_CLIENT_LEAVE_POLICY_NAME_LIST = 'GET_CLIENT_LEAVE_POLICY_NAME_LIST';

export const GET_CLIENT_LEAVE_POLICY_NAME_LIST_ERROR = 'GET_CLIENT_LEAVE_POLICY_NAME_LIST_ERROR';

export const GET_USER_MILEAGE_EXPENSE_YTD = 'GET_USER_MILEAGE_EXPENSE_YTD';
export const GET_USER_MILEAGE_EXPENSE_YTD_ERROR = 'GET_USER_MILEAGE_EXPENSE_YTD_ERROR';

export const CLIENT_REJECT_MILEAGE_ITEM = 'CLIENT_REJECT_MILEAGE_ITEM';
export const CLIENT_REJECT_EXPENSE_ITEM = 'CLIENT_REJECT_EXPENSE_ITEM';

export const CLIENT_APPROVE_MILEAGE_ITEM = 'CLIENT_APPROVE_MILEAGE_ITEM';
export const CLIENT_APPROVE_EXPENSE_ITEM = 'CLIENT_APPROVE_EXPENSE_ITEM';

export const CLIENT_GET_MILEAGE_ITEM = 'CLIENT_GET_MILEAGE_ITEM';
export const CLIENT_CLEAR_MILEAGE_ITEM = 'CLIENT_CLEAR_MILEAGE_ITEM';
export const CLIENT_GET_EXPENSE_ITEM = 'CLIENT_GET_EXPENSE_ITEM';

export const CLIENT_CLEAR_EXPENSE_ITEM = 'CLIENT_CLEAR_EXPENSE_ITEM';
export const GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER = 'GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER';
export const CLEAR_SUB_EXPENSE_SHEET = 'CLEAR_SUB_EXPENSE_SHEET';
export const REVIEW_SUB_EXPENSE_ITEM = 'REVIEW_SUB_EXPENSE_ITEM';
export const REVIEW_SUB_MILEAGE_ITEM = 'REVIEW_SUB_MILEAGE_ITEM';
export const UPDATE_EXPENSE_ITEM_LIST = 'UPDATE_EXPENSE_ITEM_LIST';
export const GET_BASE_CURRENCY = 'GET_BASE_CURRENCY';

export const GET_MESSAGE_CATEGORY_LIST = 'GET_MESSAGE_CATEGORY_LIST';
export const GET_MESSAGE_CATEGORY_LIST_ERROR = 'GET_MESSAGE_CATEGORY_LIST_ERROR';
export const GET_MESSAGE_CATEGORY_ROLE_LIST = 'GET_MESSAGE_CATEGORY_ROLE_LIST';
export const GET_MESSAGE_CATEGORY_ROLE_LIST_ERROR = 'GET_MESSAGE_CATEGORY_ROLE_LIST_ERROR';
export const SAVE_MESSAGE_CATEGORY_DETAILS = 'SAVE_MESSAGE_CATEGORY_DETAILS';
export const SAVE_MESSAGE_CATEGORY_DETAILS_ERROR = 'SAVE_MESSAGE_CATEGORY_DETAILS_ERROR';
export const DELETE_MESSAGE_CATEGORY_DETAILS = 'DELETE_MESSAGE_CATEGORY_DETAILS';
export const DELETE_MESSAGE_CATEGORY_DETAILS_ERROR = 'DELETE_MESSAGE_CATEGORY_DETAILS_ERROR';
export const GET_MESSAGE_CATEGORY_DETAILS = 'GET_MESSAGE_CATEGORY_DETAILS';
export const GET_MESSAGE_CATEGORY_DETAILS_ERROR = 'GET_MESSAGE_CATEGORY_DETAILS_ERROR';
export const GET_MESSAGE_STATUS_LIST = 'GET_MESSAGE_STATUS_LIST';
export const GET_HR_CONSULTANT_LIST = 'GET_HR_CONSULTANT_LIST';
export const SEARCH_CONVERSATIONS = 'SEARCH_CONVERSATIONS';
export const GET_CONVERSATION_DETAIL = 'GET_CONVERSATION_DETAIL';
export const SEARCH_CONVERSATIONS_PAGINATED = 'SEARCH_CONVERSATIONS_PAGINATED';
export const CLEAR_CONVERSATION_DETAILS = 'CLEAR_CONVERSATION_DETAILS';
export const SEARCH_USER_MESSAGES = 'SEARCH_USER_MESSAGES';
export const GET_MESSAGE_ATTACHMENT_FILE ="GET_MESSAGE_ATTACHMENT_FILE";
export const GET_MESSAGE_ATTACHMENT_FILE_ERROR ="GET_MESSAGE_ATTACHMENT_FILE_ERROR";
export const SAVE_CLIENT_NOTE = 'SAVE_CLIENT_NOTE';
export const ADD_CLIENT_NOTE = 'ADD_CLIENT_NOTE';
export const DELETE_CLIENT_NOTE = 'DELETE_CLIENT_NOTE';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_CLIENT_DASHBOARD_DATA = 'GET_CLIENT_DASHBOARD_DATA';
export const GET_SUB_DASHBOARD_DATA = 'GET_SUB_DASHBOARD_DATA';
export const GET_DASHBOARD_QUICK_FILTER_NAME_LIST = 'GET_DASHBOARD_QUICK_FILTER_NAME_LIST';
export const GET_DASHBOARD_PAY_PERIOD_LIST = 'GET_DASHBOARD_PAY_PERIOD_LIST';
export const FILTER_DASHBOARD_PAY_PERIOD_LIST = 'FILTER_DASHBOARD_PAY_PERIOD_LIST';
export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const CLEAR_USER_PERMISSION = 'CLEAR_USER_PERMISSION';
export const REDIRECT = 'REDIRECT';
export const UNREDIRECT = 'UNREDIRECT';

export const GET_NOTIFICATION_CONFIG = "GET_NOTIFICATION_CONFIG";
export const GET_NOTIFICATION_CONFIG_ERROR = "GET_NOTIFICATION_CONFIG_ERROR";
export const SAVE_NOTIFICATION_CONFIG = "SAVE_NOTIFICATION_CONFIG";
export const SAVE_NOTIFICATION_CONFIG_ERROR = "SAVE_NOTIFICATION_CONFIG_ERROR";

export const GET_ROLE_USER_LIST = 'GET_ROLE_USER_LIST';
export const GET_ROLE_USER_LIST_ERROR = 'GET_ROLE_USER_LIST_ERROR';
export const GET_ASSIGNED_MSG_LIST = 'GET_ASSIGNED_MSG_LIST';
export const GET_ASSIGNED_MSG_USER_LIST = 'GET_ASSIGNED_MSG_USER_LIST';
export const GET_ASSIGNED_MSG_LIST_ERROR = 'GET_ASSIGNED_MSG_LIST_ERROR'
export const SAVE_MSG_ASSIGNED_INFO = 'SAVE_MSG_ASSIGNED_INFO'
export const SAVE_MSG_ASSIGNED_INFO_ERROR = 'SAVE_MSG_ASSIGNED_INFO_ERROR'
export const DELETE_MSG_ASSIGNED_INFO = 'DELETE_MSG_ASSIGNED_INFO'
export const DELETE_MSG_ASSIGNED_INFO_ERROR = 'DELETE_MSG_ASSIGNED_INFO_ERROR'
export const GET_SYSADMIN_EVENTNAME_LIST = 'GET_SYSADMIN_EVENTNAME_LIST';
export const GET_SYSADMIN_EVENTNAME_LIST_ERROR =
  'GET_SYSADMIN_EVENTNAME_LIST_ERROR';
export const GET_SYSADMIN_EVENTDATA_LIST = 'GET_SYSADMIN_EVENTDATA_LIST';
export const GET_SYSADMIN_EVENTDATA_LIST_ERROR =
  'GET_SYSADMIN_EVENTDATA_LIST_ERROR';
export const GET_APP_MODULE = 'GET_APP_MODULE';
export const GET_APP_MODULE_CLEAR = 'GET_APP_MODULE_CLEAR';

export const GET_AVAILABLE_FIELD_NAME = 'GET_AVAILABLE_FIELD_NAME';
export const GET_AVAILABLE_FIELD_NAME_ERROR = 'GET_AVAILABLE_FIELD_NAME_ERROR';
export const GET_APP_MODULE_ERROR = 'GET_APP_MODULE_ERROR';
export const GET_CLIENT_LIST_REPORT = 'GET_CLIENT_LIST_REPORT';
export const GET_CLIENT_LIST_REPORT_ERROR = 'GET_CLIENT_LIST_REPORT_ERROR';
export const GET_COUNTRY_LIST_REPORT = 'GET_COUNTRY_LIST_REPORT';
export const GET_COUNTRY_LIST_REPORT_ERROR = 'GET_COUNTRY_LIST_REPORT_ERROR';
export const GET_REGION_LIST_REPORT = 'GET_REGION_LIST_REPORT';
export const GET_REGION_LIST_REPORT_ERROR = 'GET_REGION_LIST_REPORT_ERROR';
export const GET_EMPLOYMENT_STATUS_LIST_REPORT = 'GET_EMPLOYMENT_STATUS_LIST_REPORT';
export const GET_EMPLOYMENT_STATUS_LIST_REPORT_ERROR = 'GET_EMPLOYMENT_STATUS_LIST_REPORT_ERROR';
export const GET_SALES_CONSULTANT_LIST_REPORT = 'GET_SALES_CONSULTANT_LIST_REPORT';
export const GET_SALES_CONSULTANT_LIST_REPORT_ERROR = 'GET_SALES_CONSULTANT_LIST_REPORT_ERROR';
export const GET_HR_CONSULTANT_LIST_REPORT = 'GET_HR_CONSULTANT_LIST_REPORT';
export const GET_HR_CONSULTANT_LIST_REPORT_ERROR = 'GET_HR_CONSULTANT_LIST_REPORT_ERROR';
export const GET_LEAVE_TYPE_LIST_REPORT = 'GET_LEAVE_TYPE_LIST_REPORT';
export const GET_LEAVE_TYPE_LIST_REPORT_ERROR = 'GET_LEAVE_TYPE_LIST_REPORT_ERROR';
export const GET_REPORTS_NAME_LIST = ' GET_REPORTS_NAME_LIST';
export const GET_REPORTS_NAME_LIST_ERROR = 'GET_REPORTS_NAME_LIST_ERROR';
export const GET_REPORTS_PERIOD_LIST = 'GET_REPORTS_PERIOD_LIST';
export const GET_REPORTS_PERIOD_LIST_ERROR = 'GET_REPORTS_PERIOD_LIST_ERROR';
export const GET_REPORTS_STATUS_LIST = 'GET_REPORTS_STATUS_LIST';
export const GET_REPORTS_STATUS_LIST_ERROR = 'GET_REPORTS_STATUS_LIST_ERROR';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';
export const GET_WORKER_LIST_REPORT = 'GET_WORKER_LIST_REPORT';
export const GET_WORKER_LIST_REPORT_ERROR = 'GET_WORKER_LIST_REPORT_ERROR';
export const GET_WORKER_DETAILS_REPORT = 'GET_WORKER_DETAILS_REPORT';
export const GET_WORKER_DETAILS_REPORT_ERROR = 'GET_WORKER_DETAILS_REPORT_ERROR';
export const REPORT_ADVANCE_FILTERS = 'REPORT_ADVANCE_FILTERS';
export const REPORT_ADVANCE_FILTERS_ERROR = 'REPORT_ADVANCE_FILTERS_ERROR';
export const GET_DISTANCE_UNIT_NAME_LIST = 'GET_DISTANCE_UNIT_NAME_LIST';
export const GET_DISTANCE_UNIT_NAME_LIST_ERROR = 'GET_DISTANCE_UNIT_NAME_LIST_ERROR';
export const GET_BASE_DISTANCE_UNIT = 'GET_BASE_DISTANCE_UNIT';
export const GET_BASE_DISTANCE_UNIT_ERROR = 'GET_BASE_DISTANCE_UNIT_ERROR';
export const GET_BASE_DISTANCE_UNIT_NAME = 'GET_BASE_DISTANCE_UNIT_NAME';
export const GET_BASE_DISTANCE_UNIT_NAME_ERROR = 'GET_BASE_DISTANCE_UNIT_NAME_ERROR';
export const GET_DISTANCE_UNIT_DETAILS = 'GET_DISTANCE_UNIT_DETAILS';
export const GET_DISTANCE_UNIT_DETAILS_ERROR = 'GET_DISTANCE_UNIT_DETAILS_ERROR';
export const CLEAR_DISTANCE_UNIT_DETAILS = 'CLEAR_DISTANCE_UNIT_DETAILS';
export const GET_BASE_DISTANCE_UNIT_LIST = 'GET_BASE_DISTANCE_UNIT_LIST';
export const GET_BASE_DISTANCE_UNIT_LIST_ERROR = 'GET_BASE_DISTANCE_UNIT_LIST_ERROR';
export const GET_DISTANCE_UNIT_LIST = 'GET_DISTANCE_UNIT_LIST';
export const GET_DISTANCE_UNIT_LIST_ERROR = 'GET_DISTANCE_UNIT_LIST_ERROR';
export const DELETE_DISTANCE_DETAILS = 'DELETE_DISTANCE_DETAILS';
export const DELETE_DISTANCE_DETAILS_ERROR = 'DELETE_DISTANCE_DETAILS_ERROR';
export const SAVE_DISTANCE_DETAILS = 'SAVE_DISTANCE_DETAILS';
export const SAVE_DISTANCE_DETAILS_ERROR = 'SAVE_DISTANCE_DETAILS_ERROR';
export const GET_COUNTRY_NAME = 'GET_COUNTRY_NAME';
export const GET_COUNTRY_NAME_ERROR = 'GET_COUNTRY_NAME_ERROR';
export const GET_REGION_MILEAGE_LIST = 'GET_REGION_MILEAGE_LIST';
export const GET_REGION_MILEAGE_LIST_ERROR = 'GET_REGION_MILEAGE_LIST_ERROR';
export const SAVE_PAY_COMPONENT_DETAILS = 'SAVE_PAY_COMPONENT_DETAILS';
export const SAVE_PAY_COMPONENT_DETAILS_ERROR = 'SAVE_PAY_COMPONENT_DETAILS_ERROR';
export const GET_PAY_COMPONENT_DETAILS = 'GET_PAY_COMPONENT_DETAILS';
export const GET_PAY_COMPONENT_DETAILS_ERROR = 'GET_PAY_COMPONENT_DETAILS_ERROR';
export const GET_PAY_COMPONENT_LIST = 'GET_PAY_COMPONENT_LIST';
export const GET_PAY_COMPONENT_LIST_ERROR = 'GET_PAY_COMPONENT_LIST_ERROR';
export const CALCULATE_YEAR_OF_SERVICES = 'CALCULATE_YEAR_OF_SERVICES';
export const CALCULATE_YEAR_OF_SERVICES_ERROR = 'CALCULATE_YEAR_OF_SERVICES_ERROR';
export const CALCULATE_AGES = 'CALCULATE_AGES';
export const CALCULATE_AGES_ERROR = 'CALCULATE_AGES_ERROR';
export const GET_PAY_ELEMENT_LIST = 'GET_PAY_ELEMENT_LIST';
export const GET_PAY_ELEMENT_LIST_ERROR = 'GET_PAY_ELEMENT_LIST_ERROR';
export const SAVE_PAY_ELEMENT_DETAILS = 'SAVE_PAY_ELEMENT_DETAILS';
export const SAVE_PAY_ELEMENT_DETAILS_ERROR = 'SAVE_PAY_ELEMENT_DETAILS_ERROR';
export const GET_PAY_ELEMENT_DETAILS = 'GET_PAY_ELEMENT_DETAILS';
export const GET_PAY_ELEMENT_DETAILS_ERROR = 'GET_PAY_ELEMENT_DETAILS_ERROR';
export const DELETE_PAY_ELEMENT_DETAILS = 'DELETE_PAY_ELEMENT_DETAILS';
export const DELETE_PAY_ELEMENT_DETAILS_ERROR = 'DELETE_PAY_ELEMENT_DETAILS_ERROR';
export const GET_DEFAULT_VALUES = 'GET_DEFAULT_VALUES';
export const GET_DEFAULT_VALUES_ERROR = 'GET_DEFAULT_VALUES_ERROR';
export const UPLOAD_CONVERSATION_ATTACHMENT = "UPLOAD_CONVERSATION_ATTACHMENT";
export const GET_CLIENT_MARKUP_VALUES = "GET_CLIENT_MARKUP_VALUES";
export const GET_CLIENT_MARKUP_VALUES_ERROR = "GET_CLIENT_MARKUP_VALUES";
export const UPDATE_IS_SAVE_USER_PROFILE = "UPDATE_IS_SAVE_USER_PROFILE";
export const RESET_SAVE_ITEM = "RESET_SAVE_ITEM";
export const DELETE_EXPENSE_REPORT = "DELETE_EXPENSE_REPORT";
export const DELETE_EXPENSE_REPORT_ERROR = "DELETE_EXPENSE_REPORT_ERROR";
export const SEARCH_PROPOSALS = "SEARCH_PROPOSALS";
export const SEARCH_PROPOSALS_ERROR = "SEARCH_PROPOSALS_ERROR";
export const SAVE_PROPOSAL = "SAVE_PROPOSAL";
export const SAVE_PROPOSAL_ERROR = "SAVE_PROPOSAL_ERROR";
export const  GET_PROPPOSAL_OWNER_LIST = "GET_PROPPOSAL_OWNER_LIST";
export const  GET_PROPPOSAL_OWNER_LIST_ERROR = "GET_PROPPOSAL_OWNER_LIST_ERROR";
export const GET_WORKER_COMPENSATION_RATE_LIST ="GET_WORKER_COMPENSATION_RATE_LIST";
export const GET_WORKER_COMPENSATION_RATE_LIST_ERROR = "GET_WORKER_COMPENSATION_RATE_LIST_ERROR";
export const GET_PROPOSAL = "GET_PROPOSAL";
export const  GET_PROPOSAL_ERROR = "GET_PROPOSAL_ERROR";
export const CLEAR_PROPOSAL = "CLEAR_PROPOSAL";
export const DELETE_PROPOSAL = "DELETE_PROPOSAL";
export const DELETE_PROPOSAL_ERROR = "DELETE_PROPOSAL_ERROR";
export const CLEAR_CLIENT_CONTACT_LIST = "CLEAR_CLIENT_CONTACT_LIST";
export const GET_VACATION_PAY_LIST = "GET_VACATION_PAY_LIST";
export const GET_VACATION_PAY_LIST_ERROR = "GET_VACATION_PAY_LIST_ERROR";

export const SAVE_PAY_SCHEDULE = 'SAVE_PAY_SCHEDULE';
export const SAVE_PAY_SCHEDULE_ERROR = 'AVE_PAY_SCHEDULE_ERROR';
export const GENERATE_PAY_SCHEDULE = 'GENERATE_PAY_SCHEDULE';
export const GENERATE_PAY_SCHEDULE_ERROR = 'GENERATE_PAY_SCHEDULE_ERROR';
export const GET_PAY_SCHEDULE = 'GET_PAY_SCHEDULE';
export const GET_PAY_SCHEDULE_ERROR = 'GET_PAY_SCHEDULE_ERROR';
export const DELETE_PAY_SCHEDULE = 'DELETE_PAY_SCHEDULE';
export const DELETE_PAY_SCHEDULE_ERROR = 'DELETE_PAY_SCHEDULE_ERROR';
export const DELETE_PAY_PERIOD = 'DELETE_PAY_PERIOD';
export const DELETE_PAY_PERIOD_ERROR = 'DELETE_PAY_PERIOD_ERROR';
export const GET_PAY_FREQUENCY_LIST = 'GET_PAY_FREQUENCY_LIST';
export const GET_PAY_FREQUENCY_LIST_ERROR = 'GET_PAY_FREQUENCY_LIST_ERROR';
export const RESET_PAY_SCHEDULE = 'RESET_PAY_SCHEDULE';
export const SAVE_PAY_PERIOD = "SAVE_PAY_PERIOD";
export const SAVE_PAY_PERIOD_ERROR = "SAVE_PAY_PERIOD_ERROR";

// ----> Pay Schedule <----
export const GET_PAY_SCHEDULE_LIST = 'GET_PAY_SCHEDULE_LIST';
export const GET_PAY_SCHEDULE_LIST_ERROR = 'GET_PAY_SCHEDULE_LIST_ERROR';
export const GET_PAY_PERIOD_YEAR_LIST = 'GET_PAY_PERIOD_YEAR_LIST';
export const GET_PAY_PERIOD_YEAR_LIST_ERROR = 'GET_PAY_PERIOD_YEAR_LIST_ERROR'

// ----> Pay Items <----
export const GET_PAY_ITEMS_LIST = 'GET_PAY_ITEMS_LIST';
export const GET_PAY_ITEMS_LIST_ERROR = 'GET_PAY_ITEMS_LIST_ERROR';
export const DELETE_PAY_ITEM = 'DELETE_PAY_ITEM';
export const DELETE_PAY_ITEM_ERROR = 'DELETE_PAY_ITEM_ERROR';
export const CLEAR_PAY_ITEM_LIST = 'CLEAR_PAY_ITEM_LIST';
// ----> Pay Component <----
export const GET_NEW_PAY_COMPONENT_LIST = 'GET_NEW_PAY_COMPONENT_LIST';
export const GET_NEW_PAY_COMPONENT_LIST_ERROR = 'GET_NEW_PAY_COMPONENT_LIST_ERROR';
export const DELETE_NEW_PAY_COMPONENT = 'DELETE_NEW_PAY_COMPONENT';
export const DELETE_NEW_PAY_COMPONENT_ERROR = 'DELETE_NEW_PAY_COMPONENT_ERROR';
export const GET_NEW_PAY_COMPONENT_CATEGORY = 'GET_NEW_PAY_COMPONENT_CATEGORY';
export const GET_NEW_PAY_COMPONENT_CATEGORY_ERROR = 'GET_NEW_PAY_COMPONENT_CATEGORY_ERROR';
export const GET_NEW_PAY_COMPONENT_TYPE = 'GET_NEW_PAY_COMPONENT_TYPE';
export const GET_NEW_PAY_COMPONENT_TYPE_ERROR = 'GET_NEW_PAY_COMPONENT_TYPE_ERROR';
export const GET_PRO_RATA_MODES ='GET_PRO_RATA_MODES';
export const GET_PRO_RATA_MODES_ERROR ='GET_PRO_RATA_MODES_ERROR';
export const GET_FINMOD_TYPE = 'GET_FINMOD_TYPE';
export const GET_FINMOD_TYPE_ERROR = 'GET_FINMOD_TYPE_ERROR';
export const SAVE_NEW_PAY_COMPONENT = 'SAVE_NEW_PAY_COMPONENT';
export const SAVE_NEW_PAY_COMPONENT_ERROR = 'SAVE_NEW_PAY_COMPONENT_ERROR';
export const GET_PAY_COMPONENT = 'GET_PAY_COMPONENT';
export const GET_PAY_COMPONENT_ERROR = 'GET_PAY_COMPONENT_ERROR';
export const RESET_NEW_PAY_COMPONENT = 'RESET_NEW_PAY_COMPONENT';
export const GET_NEW_PAY_COMPONENT_NAME_LIST = 'GET_NEW_PAY_COMPONENT_NAME_LIST';
export const GET_NEW_PAY_COMPONENT_NAME_LIST_ERROR = 'GET_NEW_PAY_COMPONENT_NAME_LIST_ERROR';
export const GET_RATE_UNIT_OF_MEASURE_NAME_LIST = 'GET_RATE_UNIT_OF_MEASURE_NAME_LIST'
export const GET_RATE_UNIT_OF_MEASURE_NAME_LIST_ERROR = 'GET_RATE_UNIT_OF_MEASURE_NAME_LIST_ERROR'
export const SAVE_PAY_ITEM = 'SAVE_PAY_ITEM';
export const SAVE_PAY_ITEM_ERROR = 'SAVE_PAY_ERROR';
export const GET_PAY_ITEM ='GET_PAY_ITEM';
export const GET_PAY_ITEM_ERROR ='GET_PAY_ITEM_ERROR';
export const GET_RECORD_FREQUENCY_LIST = 'GET_RECORD_FREQUENCY_LIST';
export const GET_RECORD_FREQUENCY_LIST_ERROR = 'GET_RECORD_FREQUENCY_LIST_ERROR';
export const RESET_PAY_ITEM = 'RESET_PAY_ITEM';

//----------->iNVOICE<-----------
export const SAVE_INVOICE = 'SAVE_INVOICE';
export const SAVE_INVOICE_ERROR = 'SAVE_INVOICE_ERROR';
export const DELETE_INVOICE_ITEM = 'DELETE_INVOICE_ITEM';
export const SAVE_INVOICE_ITEM = 'SAVE_INVOICE_ITEM';
export const SAVE_INVOICE_ITEM_ERROR = 'SAVE_INVOICE_ITEM_ERROR';
export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_ERROR = 'GET_INVOICE_ERROR';
export const RESET_INVOICE = 'RESET_INVOICE';
export const GET_ITEM_TYPE_LIST = 'GET_ITEM_TYPE_LIST';
export const GET_ITEM_TYPE_LIST_ERROR = 'GET_ITEM_TYPE_LIST_ERROR';
export const GET_SUB_TYPE_LIST = 'GET_SUB_TYPE_LIST';
export const GET_SUB_TYPE_LIST_ERROR = 'GET_SUB_TYPE_LIST_ERROR';
export const GET_WORKER_LIST_BY_CLIENT = 'GET_WORKER_LIST_BY_CLIENT';
export const GET_WORKER_LIST_BY_CLIENT_ERROR = 'GET_WORKER_LIST_BY_CLIENT_ERROR';
export const CALCULATE_INVOICE_ITEM_DETAILS = 'CALCULATE_INVOICE_ITEM_DETAILS';
export const CALCULATE_INVOICE_ITEM_DETAILS_ERROR = 'CALCULATE_INVOICE_ITEM_DETAILS_ERROR';
export const GET_INVOICE_ITEM = 'GET_INVOICE_ITEM';
export const GET_INVOICE_ITEM_ERROR = 'GET_INVOICE_ITEM_ERROR';
export const RESET_INVOICE_ITEM = 'RESET_INVOICE_ITEM';
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_INVOICE_LIST_ERROR = 'GET_INVOICE_LIST_ERROR';
export const GET_INVOICE_STATUS_LIST = 'GET_INVOICE_STATUS_LIST';
export const GET_INVOICE_STATUS_LIST_ERROR = 'GET_INVOICE_STATUS_LIST_ERROR';
export const BULK_UPDATE_INVOICE_STATUS = 'BULK_UPDATE_INVOICE_STATUS';
export const BULK_UPDATE_INVOICE_STATUS_ERROR = 'BULK_UPDATE_INVOICE_STATUS_ERROR';
export const RESET_BULK_UPDATE_INVOICE_STATUS = 'RESET_BULK_UPDATE_INVOICE_STATUS';
export const GET_SUB_CURRENCY_LIST = 'GET_SUB_CURRENCY_LIST';
export const GET_SUB_CURRENCY_LIST_ERROR = 'GET_SUB_CURRENCY_LIST_ERROR';
export const GET_SUB_CONFIG_ERROR = 'GET_SUB_CONFIG_ERROR';
export const GET_SUB_CONFIG = 'GET_SUB_CONFIG';

/// ----> Synchronization <----
export const GETSEARCH_SYNC_HISTORY_LIST = 'GET_SYNC_HISTORY_LIST';
export const GETSEARCH_SYNC_HISTORY_LIST_ERROR = 'GET_SYNC_HISTORY_LIST_ERROR';

export const GETSEARCH_SYNC_APPROVAL_LIST = 'GETSEARCH_SYNC_APPROVAL_LIST';
export const GETSEARCH_SYNC_APPROVAL_LIST_ERROR = 'GETSEARCH_SYNC_APPROVAL_LIST_ERROR';
export const SYNC_PAY_ITEM = 'SYNC_PAY_ITEM';
export const SYNC_PAY_ITEM_ERROR = 'SYNC_PAY_ITEM_ERROR';
export const ATTACH_PAY_ITEM_HR_COMPONENT = 'SYNC_PAY_ITEM';
export const ATTACH_PAY_ITEM_HR_COMPONENT_ERROR = 'SYNC_PAY_ITEM_ERROR';
export const BULK_SYNC_ITEMS = 'BULK_SYNC_ITEMS';
export const BULK_SYNC_ITEMS_ERROR = 'BULK_SYNC_ITEMS_ERROR';

// ----->> Pay-Configuration <<-----
export const GET_PAYEE_CONFIGURATION_LIST = 'GET_PAYEE_CONFIGURATION_LIST';
export const GET_PAYEE_CONFIGURATION_LIST_ERROR = 'GET_PAYEE_CONFIGURATION_LIST_ERROR';
export const GET_PAYEE_CONFIGURATION = 'GET_PAYEE_CONFIGURATION';
export const GET_PAYEE_CONFIGURATION_ERROR = 'GET_PAYEE_CONFIGURATION_ERROR';
export const DELETE_PAYEE_CONFIGURATION = 'DELETE_PAYEE_CONFIGURATION';
export const DELETE_PAYEE_CONFIGURATION_ERROR = 'DELETE_PAYEE_CONFIGURATION_ERROR';

export const GET_PAYER_CONFIGURATION_LIST = 'GET_PAYER_CONFIGURATION_LIST';
export const GET_PAYER_CONFIGURATION_LIST_ERROR = 'GET_PAYER_CONFIGURATION_LIST_ERROR';
export const GET_PAYER_CONFIGURATION = 'GET_PAYER_CONFIGURATION';
export const GET_PAYER_CONFIGURATION_ERROR = 'GET_PAYER_CONFIGURATION_ERROR';
export const DELETE_PAYER_CONFIGURATION = 'DELETE_PAYER_CONFIGURATION';
export const DELETE_PAYER_CONFIGURATION_ERROR = 'DELETE_PAYER_CONFIGURATION_ERROR';
export const SAVE_PAYEE_CONFIGURATION = 'SAVE_PAYEE_CONFIGURATION';
export const SAVE_PAYEE_CONFIGURATION_ERROR = 'SAVE_PAYEE_CONFIGURATION_ERROR';
export const RESET_PAYEE_CONFIGURATION = 'RESET_PAYEE_CONFIGURATION';

export const SAVE_PAYER_CONFIGURATION = 'SAVE_PAYER_CONFIGURATION';
export const SAVE_PAYER_CONFIGURATION_ERROR = 'SAVE_PAYER_CONFIGURATION_ERROR';
export const RESET_PAYER_CONFIGURATION = 'RESET_PAYER_CONFIGURATION';
export const GET_PAYEE_DROPDOWN_LIST = 'GET_PAYEE_DROPDOWN_LIST';
export const GET_PAYER_DROPDOWN_LIST = 'GET_PAYER_DROPDOWN_LIST';
export const GET_PAYER_DROPDOWN_LIST_ERROR = 'GET_PAYER_DROPDOWN_LIST_ERROR';
export const GET_PAYEE_DROPDOWN_LIST_ERROR = 'GET_PAYEE_DROPDOWN_LIST_ERROR';

//------------> Payable
export const GET_PAY_RECORD_STATUS_LIST = 'ET_PAY_RECORD_STATUS_LIST';
export const GET_PAY_RECORD_STATUS_LIST_ERROR = 'ET_PAY_RECORD_STATUS_LIST_ERROR';
export const GET_PAY_RECORD = 'GET_PAY_RECORD'; 
export const GET_PAY_RECORD_ERROR = 'GET_PAY_RECORD_ERROR';
export const SAVE_PAY_RECORD = 'SAVE_PAY_RECORD';
export const SAVE_PAY_RECORD_ERROR = 'SAVE_PAY_RECORD_ERROR';
export const DOWNLOAD_PAY_RECORD = 'DOWLOAD_PAY_RECORD';
export const DOWNLOAD_PAY_RECORD_ERROR = 'DOWLOAD_PAY_RECORD_ERROR';
export const RESET_PAY_RECORD = 'RESET_PAY_RECORD';
export const CALCULATE_PAY_RECORD_ITEM_DETAILS = 'CALCULATE_PAY_RECORD_ITEM_DETAILS';
export const CALCULATE_PAY_RECORD_ITEM_DETAILS_ERROR = 'CALCULATE_PAY_RECORD_ITEM_DETAILS_ERROR';
export const GET_PAY_RECORD_ITEM = 'GET_PAY_RECORD_ITEM';
export const GET_PAY_RECORD_ITEM_ERROR = 'GET_PAY_RECORD_ITEM_ERROR';
export const RESET_PAY_RECORD_ITEM = 'RESET_PAY_RECORD_ITEM';
export const GET_PAY_RECORD_LIST = 'GET_PAY_RECORD_LIST';
export const GET_PAY_RECORD_LIST_ERROR = 'GET_PAY_RECORD_LIST_ERROR';
export const BULK_UPDATE_PAY_RECORD_STATUS = 'BULK_UPDATE_PAY_RECORD_STATUS';
export const BULK_UPDATE_PAY_RECORD_STATUS_ERROR = 'BULK_UPDATE_PAY_RECORD_STATUS_ERROR';
export const RESET_BULK_UPDATE_PAY_RECORD_STATUS ='RESET_BULK_UPDATE_PAY_RECORD_STATUS';
export const RESET_BULK_UPDATE_PAY_RECORD_STATUS_ERROR = 'RESET_BULK_UPDATE_PAY_RECORD_STATUS_ERROR'
export const GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER = 'GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER';
export const GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER_ERROR = 'GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER_ERROR';

// ----->> Payments <<-----
export const GETSEARCH_VIEW_PAYMENT_LIST = 'GETSEARCH_VIEW_PAYMENT_LIST';
export const GETSEARCH_VIEW_PAYMENT_LIST_ERROR = 'GETSEARCH_VIEW_PAYMENT_LIST_ERROR';

export const GET_PAYMENT_STATUS_LIST = 'GET_PAYMENT_STATUS_LIST';
export const GET_PAYMENT_STATUS_LIST_ERROR = 'GET_PAYMENT_STATUS_LIST_ERROR';
export const GET_PAYMENT_TYPE_LIST = 'GET_PAYMENT_TYPE_LIST';
export const GET_PAYMENT_TYPE_LIST_ERROR = 'GET_PAYMENT_STATUS_LIST_ERROR';
export const GET_PAYMENT_ALLOCATION_TYPE_LIST = 'GET_PAYMENT_ALLOCATION_TYPE_LIST';
export const GET_PAYMENT_ALLOCATION_TYPE_LIST_ERROR = 'GET_PAYMENT_ALLOCATION_TYPE_LIST_ERROR';
export const GET_PAYMENT_ALLOCATION_TYPE = 'GET_PAYMENT_ALLOCATION_TYPE';
export const GET_PAYMENT_ALLOCATION_TYPE_ERROR = 'GET_PAYMENT_ALLOCATION_TYPE_ERROR';
export const DELETE_PAYMENT_ALLOCATION = 'DELETE_PAYMENT_ALLOCATION';
export const DELETE_PAYMENT_ALLOCATION_ERROR = 'DELETE_PAYMENT_ALLOCATION_ERROR';
export const SAVE_NEW_PAYMENT = 'SAVE_NEW_PAYMENT';
export const SAVE_NEW_PAYMENT_ERROR = 'SAVE_NEW_PAYMENT_ERROR';
export const SAVE_NEW_PAYMENT_ALLOCATION = 'SAVE_NEW_PAYMENT_ALLOCATION';
export const SAVE_NEW_PAYMENT_ALLOCATION_ERROR = 'SAVE_NEW_PAYMENT_ALLOCATION_ERROR';
export const RESET_PAYMENT_VIEW ='RESET_PAYMENT_VIEW';
export const GET_PAYMENT_INFORMATION ='GET_PAYMENT_INFORMATION';
export const GET_PAYMENT_INFORMATION_ERROR ='GET_PAYMENT_INFORMATION_ERROR';
export const DELETE_PAYMENT ='DELETE_PAYMENT';
export const DELETE_PAYMENT_ERROR ='DELETE_PAYMENT_ERROR';
export const BULK_UPDATE_PAYMENT_STATUS ='BULK_UPDATE_PAYMENT_STATUS';
export const BULK_UPDATE_PAYMENT_STATUS_ERROR ='BULK_UPDATE_PAYMENT_STATUS_ERROR';
export const BULK_DELETE_PAYMENT ='BULK_DELETE_PAYMENT';
export const BULK_DELETE_PAYMENT_ERROR ='BULK_DELETE_PAYMENT_ERROR';

export const SEARCH_PAYMENT_ALLOCATIONS = 'SEARCH_PAYMENT_ALLOCATIONS';
export const SEARCH_PAYMENT_ALLOCATIONS_ERROR = 'SEARCH_PAYMENT_ALLOCATIONS_ERROR';
export const RESET_PAYMENT_ALLOCATIONS = 'RESET_PAYMENT_ALLOCATIONS';

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_ERROR = 'GET_PAYMENTS_ERROR';
export const RESET_PAYMENTS = 'RESET_PAYMENTS';
//----> Payroll Tax <----
export const GET_IMPORT_PAYROLL_TAX_LIST = 'GET_IMPORT_PAYROLL_TAX_LIST';
export const GET_IMPORT_PAYROLL_TAX_LIST_ERROR = 'GET_IMPORT_PAYROLL_TAX_LIST_ERROR';
export const BULK_DELETE_PAYROLL_TAX_DATA = 'BULK_DELETE_PAYROLL_TAX_DATA';
export const BULK_DELETE_PAYROLL_TAX_DATA_ERROR = 'BULK_DELETE_PAYROLL_TAX_DATA_ERROR';
export const GET_PAYPERIOD_LIST = 'GET_PAYPERIOD_LIST';
export const GET_PAYPERIOD_LIST_ERROR = 'GET_PAYPERIOD_LIST_ERROR';
export const GET_PAYROLL_TAX_IMPORT_TYPE = 'GET_PAYROLL_TAX_IMPORT_TYPE';
export const GET_PAYROLL_TAX_IMPORT_TYPE_ERROR = 'GET_PAYROLL_TAX_IMPORT_TYPE_ERROR';
export const GET_COMPENSATION_PAYEE_LIST = 'GET_COMPENSATION_PAYEE_LIST';
export const GET_COMPENSATION_PAYEE_LIST_ERROR = 'ET_COMPENSATION_PAYEE_LIST_ERROR';
//-------->Automation<-----
export const GET_AUTOMATION_PAY_PERIOD_LIST = 'GET_AUTOMATION_PAY_PERIOD_LIST';
export const GET_AUTOMATION_PAY_PERIOD_LIST_ERROR = 'GET_AUTOMATION_PAY_PERIOD_LIST_ERROR';
export const GET_PROCESS_LIST = 'GET_PROCESS_LIST';
export const GET_PROCESS_LIST_ERROR = 'GET_PROCESS_LIST_ERROR';

export const GENERATE_BENEFITS_REPORT = 'GENERATE_BENEFITS_REPORT';
export const GENERATE_BENEFITS_REPORT_ERROR = 'GENERATE_BENEFITS_REPORT_ERROR';

export const BULK_ACTION_EXPENSES = 'BULK_ACTION_EXPENSES';
export const BULK_ACTION_EXPENSES_ERROR = 'BULK_ACTION_EXPENSES_ERROR';
export const GET_EXPENSE_BULK_ACTION_NAME_LIST = 'GET_EXPENSE_BULK_ACTION_NAME_LIST';
export const GET_EXPENSE_BULK_ACTION_NAME_LIST_ERROR = 'GET_EXPENSE_BULK_ACTION_NAME_LIST_ERROR';


// --------> Rate Tables <-------- //
export const GET_WORKER_COMP_INSURANCE_BY_REFNUM = 'GET_WORKER_COMP_INSURANCE_BY_REFNUM';
export const GET_WORKER_COMP_INSURANCE_BY_REFNUM_ERROR = 'GET_WORKER_COMP_INSURANCE_BY_REFNUM_ERROR';
export const GET_WORKER_COMP_INSURANCE_LIST = 'GET_WORKER_COMP_INSURANCE_LIST';
export const GET_WORKER_COMP_INSURANCE_LIST_ERROR = 'GET_WORKER_COMP_INSURANCE_LIST_ERROR';
export const DELETE_WORKER_COMP_INSURANCE = 'DELETE_WORKER_COMP_INSURANCE';
export const DELETE_WORKER_COMP_INSURANCE_ERROR = 'DELETE_WORKER_COMP_INSURANCE_ERROR';
export const RESET_WORKER_COMP_INSURANCE = 'RESET_WORKER_COMP_INSURANCE';

export const GET_EMPLOYMENT_INSURANCE_BY_REFNUM = 'GET_EMPLOYMENT_INSURANCE_BY_REFNUM';
export const GET_EMPLOYMENT_INSURANCE_BY_REFNUM_ERROR = 'GET_EMPLOYMENT_INSURANCE_BY_REFNUM_ERROR';
export const GET_EMPLOYMENT_INSURANCE_LIST = 'GET_EMPLOYMENT_INSURANCE_LIST';
export const GET_EMPLOYMENT_INSURANCE_LIST_ERROR = 'GET_EMPLOYMENT_INSURANCE_LIST_ERROR';
export const DELETE_EMPLOYMENT_INSURANCE = 'DELETE_EMPLOYMENT_INSURANCE';
export const DELETE_EMPLOYMENT_INSURANCE_ERROR = 'DELETE_EMPLOYMENT_INSURANCE_ERROR';
export const RESET_EMPLOYMENT_INSURANCE = 'RESET_EMPLOYMENT_INSURANCE';

export const GET_HEALTH_TAX_BY_REFNUM = 'GET_HEALTH_TAX_BY_REFNUM';
export const GET_HEALTH_TAX_BY_REFNUM_ERROR = 'GET_HEALTH_TAX_BY_REFNUM_ERROR';
export const GET_HEALTH_TAX_LIST = 'GET_HEALTH_TAX_LIST';
export const GET_HEALTH_TAX_LIST_ERROR = 'GET_HEALTH_TAX_LIST_ERROR';
export const DELETE_HEALTH_TAX = 'DELETE_HEALTH_TAX';
export const DELETE_HEALTH_TAX_ERROR = 'DELETE_HEALTH_TAX_ERROR';
export const RESET_HEALTH_TAX = 'RESET_HEALTH_TAX';

export const GET_GOVERNMENT_PENSION_2_BY_REFNUM = 'GET_GOVERNMENT_PENSION_2_BY_REFNUM';
export const GET_GOVERNMENT_PENSION_2_BY_REFNUM_ERROR = 'GET_GOVERNMENT_PENSION_2_BY_REFNUM_ERROR';
export const GET_GOVERNMENT_PENSION_2_LIST = 'GET_GOVERNMENT_PENSION_2_LIST';
export const GET_GOVERNMENT_PENSION_2_LIST_ERROR = 'GET_GOVERNMENT_PENSION_2_LIST_ERROR';
export const DELETE_GOVERNMENT_PENSION_2 = 'DELETE_GOVERNMENT_PENSION_2';
export const DELETE_GOVERNMENT_PENSION_2_ERROR = 'DELETE_GOVERNMENT_PENSION_2_ERROR';
export const RESET_GOVERNMENT_PENSION_2 = 'RESET_GOVERNMENT_PENSION_2';

export const GET_GOVERNMENT_PENSION_BY_REFNUM = 'GET_GOVERNMENT_PENSION_BY_REFNUM';
export const GET_GOVERNMENT_PENSION_BY_REFNUM_ERROR = 'GET_GOVERNMENT_PENSION_BY_REFNUM_ERROR';
export const GET_GOVERNMENT_PENSION_LIST = 'GET_GOVERNMENT_PENSION_LIST';
export const GET_GOVERNMENT_PENSION_LIST_ERROR = 'GET_GOVERNMENT_PENSION_LIST_ERROR';
export const DELETE_GOVERNMENT_PENSION = 'DELETE_GOVERNMENT_PENSION';
export const DELETE_GOVERNMENT_PENSION_ERROR = 'DELETE_GOVERNMENT_PENSION_ERROR';
export const RESET_GOVERNMENT_PENSION = 'RESET_GOVERNMENT_PENSION';

export const GET_CLIENT_STATUS_LIST = 'GET_CLIENT_STATUS_LIST';
export const GET_CLIENT_STATUS_LIST_ERROR = 'GET_CLIENT_STATUS_LIST_ERROR';