import React, {useEffect} from 'react';
import {Card, Col, Form, ListGroup, ListGroupItem, Row} from "shards-react";
import {Link, useLocation, useParams} from "react-router-dom";
import {default as queryString} from "qs";
import {formatPhoneNumber} from "../../../utils/general";

function UserInfoCard({userId,conversation,userProfile,title,changePageMeta}) {
  let {refNum}=useParams();
  let location = useLocation()

  let userName="";
  let role = "";
  let clientName="";
  let email="";
  let phone="";
  let hrConsultant="";
  if(Object.keys(conversation).length) {
    userName = conversation.userName;
    role=conversation.uerRole;
    clientName=conversation.clientName;
    email=conversation.email;
    phone=conversation.phone;
    hrConsultant=conversation.hrConsultant;
  }else if (userProfile && Object.keys(userProfile).length){
    if (userProfile.personalInfo.firstName) {
      userName=`${userProfile.personalInfo.firstName} ${userProfile.personalInfo.lastName} ${(userProfile.personalInfo.nickName ? `(${userProfile.personalInfo.nickName})` : "")}`;
    }
    role=queryString.parse(location.search.replace("?", "")).role;
    clientName=userProfile.employmentInfo.clientName;
    
    if(role !== 'Worker')
      email=userProfile.contactInfo.emailWork
    else if(userProfile.contactInfo.preferredEmail==="Work")
      email=userProfile.contactInfo.emailWork
    else if(userProfile.contactInfo.preferredEmail==="Personal")
      email=userProfile.contactInfo.emailPersonal
    else
      email=userProfile.contactInfo.emailPersonal || userProfile.contactInfo.emailWork

    phone=userProfile.contactInfo.phoneMobile || userProfile.contactInfo.phoneHome || userProfile.contactInfo.phoneWork;
    hrConsultant=userProfile.employmentInfo.hrConsultant;
  }

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if(userName)
    changePageMeta(`${title} - ${userName}`)
  }, [userName]);


  return (
    <Card small
          className="details-card mt-3 mb-4 w-100 d-flex personalParent">
        <span className="iconBox"><i
          className="fas fa-info-circle iconStyle"/></span>
      <h4 className="m-0 section-title headerStyle">User Information</h4>
      <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
      <ListGroup flush>

        <ListGroupItem className={"p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>User Name</label>
                    <span className={"inputBoxClr   form-control"}><Link
                      to={`/manage-user-profile/${conversation.userId||userId}?role=${conversation.role}`}>{userName}</Link></span>
                  </Col>


                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>User Role</label>
                    <span
                      className={"inputBoxClr   form-control"}>{role}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Client</label>
                    <span
                      className={"inputBoxClr   form-control"}>{clientName}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Email</label>
                    <span
                      className={"inputBoxClr   form-control"}>{email}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Phone</label>
                    <span
                      className={"inputBoxClr   form-control"}>{formatPhoneNumber(phone)}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>HR Representative</label>
                    <span
                      className={"inputBoxClr   form-control"}>{hrConsultant}</span>
                  </Col>

                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>


      </ListGroup>
    </Card>
  );
}

export default UserInfoCard;
