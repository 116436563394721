import { DELETE_PAY_PERIOD, DELETE_PAY_SCHEDULE, GENERATE_PAY_SCHEDULE, GENERATE_PAY_SCHEDULE_ERROR, GET_NEW_PAY_COMPONENT_LIST, GET_NEW_PAY_COMPONENT_LIST_ERROR, GET_PAY_FREQUENCY_LIST, GET_PAY_FREQUENCY_LIST_ERROR, GET_PAY_SCHEDULE, GET_PAY_SCHEDULE_ERROR, GET_PAY_SCHEDULE_LIST, GET_PAY_SCHEDULE_LIST_ERROR, RESET_PAY_SCHEDULE, SAVE_PAY_SCHEDULE, GET_PAY_PERIOD_YEAR_LIST, GET_PAY_PERIOD_YEAR_LIST_ERROR,
    GET_PRO_RATA_MODES, 
    GET_PRO_RATA_MODES_ERROR, 
    SAVE_NEW_PAY_COMPONENT,
    SAVE_NEW_PAY_COMPONENT_ERROR,
    GET_NEW_PAY_COMPONENT_CATEGORY,
    GET_NEW_PAY_COMPONENT_TYPE,
    GET_NEW_PAY_COMPONENT_CATEGORY_ERROR,
    GET_NEW_PAY_COMPONENT_TYPE_ERROR,
    GET_PAY_COMPONENT_ERROR,
    GET_FINMOD_TYPE,
    GET_FINMOD_TYPE_ERROR,
    GET_PAY_COMPONENT,
    RESET_NEW_PAY_COMPONENT,
    GET_NEW_PAY_COMPONENT_NAME_LIST,
    GET_RATE_UNIT_OF_MEASURE_NAME_LIST_ERROR,
    GET_RATE_UNIT_OF_MEASURE_NAME_LIST,
    SAVE_PAY_ITEM_ERROR,
    SAVE_PAY_ITEM,
    GET_PAY_ITEM_ERROR,
    GET_PAY_ITEM,
    GET_RECORD_FREQUENCY_LIST,
    GET_RECORD_FREQUENCY_LIST_ERROR,
    RESET_PAY_ITEM,
    GET_IMPORT_PAYROLL_TAX_LIST_ERROR,
    GET_IMPORT_PAYROLL_TAX_LIST,
    GET_PAYPERIOD_LIST,
    GET_PAYPERIOD_LIST_ERROR, 
    GET_PAYROLL_TAX_IMPORT_TYPE_ERROR,
    GET_PAYROLL_TAX_IMPORT_TYPE,
    GET_COMPENSATION_PAYEE_LIST,
    GET_COMPENSATION_PAYEE_LIST_ERROR, 
 } from "../../actions/types"

const initialState = {
    paySchedule: {},
    payFrequencyList: [],
    payScheduleList: [],
    payPeriodYearList:[],
    newPayComponentList: [],
    PayComponentCategoryList:[],
    payComponentTypeList:[],
    proRataModeList:[],
    finmodTypeList:[],
    payComponent:{},
    newPayComponentNameList:[],
    rateUnitofMeasureNameList:[],
    payItem:{},
    recordFrequencyList:[],
    payrollTaxList: [],
    payPeriodList: [],
    payrollTaxImportType:[],
    compensationPayeeList:[]

}
export const payroll = (state = initialState, action) => {


    switch (action.type) {
        case GET_PAY_SCHEDULE:
            return {
                ...state,
                paySchedule: action.payload.data
            }
        case GET_PAY_SCHEDULE_ERROR:
            return {
                ...state,
                paySchedule: {}
            }

        case GENERATE_PAY_SCHEDULE:
            return {
                ...state,
                paySchedule: action.payload.data
            }
        case GENERATE_PAY_SCHEDULE_ERROR:
            return state;

        case SAVE_PAY_SCHEDULE:
            return {
                ...state,
                paySchedule: action.payload.data
            }
        case DELETE_PAY_SCHEDULE: case RESET_PAY_SCHEDULE:
            return {
                ...state,
                paySchedule: {}
            }
        case DELETE_PAY_PERIOD:
            return {
                ...state,
                paySchedule: { ...state.paySchedule, payPeriodList: state.paySchedule.payPeriodList.filter(payPeriod => payPeriod.refNum !== action.payload.data.refNum) }

            }
        case GET_PAY_FREQUENCY_LIST:
            return {
                ...state,
                payFrequencyList: action.payload.data
            }
        case GET_PAY_FREQUENCY_LIST_ERROR:
            return {
                ...state,
                payFrequencyList: []
            }
        // --> Pay Schedule 
        case GET_PAY_SCHEDULE_LIST: {
            return {
                ...state,
                payScheduleList: action.payload.data
            };
        }
        case GET_PAY_SCHEDULE_LIST_ERROR: {
            return {
                ...state,
                payScheduleList: []
            };
        }
        case GET_PAY_PERIOD_YEAR_LIST :{
            return{
                ...state,
                payPeriodYearList:action.payload.data
            }
        }
        case GET_PAY_PERIOD_YEAR_LIST_ERROR:{
            return{
                ...state,
                payPeriodYearList:[]
            }
        }

         // --> Pay Component
         case GET_NEW_PAY_COMPONENT_LIST: {
            return {
                ...state,
                newPayComponentList: action.payload.data
            };
        }
        case GET_NEW_PAY_COMPONENT_LIST_ERROR: {
            return {
                ...state,
                newPayComponentList: []
            };
        }
        case GET_NEW_PAY_COMPONENT_CATEGORY:{
            return {
                ...state,
                PayComponentCategoryList:action.payload.data
            }
        }
        case GET_NEW_PAY_COMPONENT_CATEGORY_ERROR:{
            return {
                ...state,
                PayComponentCategoryList:[]
            }
        }
        case GET_NEW_PAY_COMPONENT_TYPE:{
            return{
                ...state,
                payComponentTypeList:action.payload.data
            }
        }
        case GET_NEW_PAY_COMPONENT_TYPE_ERROR:{
            return{
                ...state,
                payComponentTypeList:[]
            }
        }
        case GET_PRO_RATA_MODES:{
            return {
                ...state,
                proRataModeList:action.payload.data
            }
        }
        case GET_PRO_RATA_MODES_ERROR:{
            return {
                ...state,
                proRataModeList:[]
            }
        }
        case GET_FINMOD_TYPE:{
            return{
                ...state,
                finmodTypeList:action.payload.data
            }
        }
        case GET_FINMOD_TYPE_ERROR:{
            return{
                ...state,
                finmodTypeList:[]
            }
        }
        case SAVE_NEW_PAY_COMPONENT:{
            return{
                ...state,
                payComponent:action.payload.data
            }
        }
        case SAVE_NEW_PAY_COMPONENT_ERROR:{
            return{
                ...state,
                payComponent:{}
            }
        }
        case GET_PAY_COMPONENT:{
            return{
                ...state,
                payComponent:action.payload.data
            }
        }
        case RESET_NEW_PAY_COMPONENT:
        case GET_PAY_COMPONENT_ERROR:{
            return{
                ...state,
                payComponent:{}
            }
        }
        case GET_NEW_PAY_COMPONENT_NAME_LIST:{
            return{
                ...state,
                newPayComponentNameList:action.payload.data
            }
        }
        case GET_RATE_UNIT_OF_MEASURE_NAME_LIST:{
            return{
                ...state,
                rateUnitofMeasureNameList:action.payload.data
            }
        }
        case GET_RATE_UNIT_OF_MEASURE_NAME_LIST_ERROR:{
            return{
                ...state,
                rateUnitofMeasureNameList:[]
            }
        }
        case SAVE_PAY_ITEM:{
            return{
                ...state,
                payItem:action.payload.data
            }
        }
        case RESET_PAY_ITEM :
        case SAVE_PAY_ITEM_ERROR:{
            return{
                ...state,
                payItem:{}
            }
        }
        case GET_PAY_ITEM:{
            return{
                ...state,
                payItem:action.payload.data
            }
        }
        case GET_PAY_ITEM_ERROR:{
            return{
                ...state,
                payItem:{}
            }
        }
        case GET_RECORD_FREQUENCY_LIST:{
            return{
                ...state,
                recordFrequencyList:action.payload.data
            }
        }
        case GET_RECORD_FREQUENCY_LIST_ERROR:{
            return{
                ...state,
                recordFrequencyList:[]
            }
        }
        case GET_IMPORT_PAYROLL_TAX_LIST: {
            return {
                ...state,
                payrollTaxList: action.payload.data
            }
        }
        case GET_IMPORT_PAYROLL_TAX_LIST_ERROR: {
            return {
                ...state,
                payrollTaxList: []
            }
        }

        case GET_PAYPERIOD_LIST: {
            return {
                ...state,
                payPeriodList: action.payload.data
            }
        }
        case GET_PAYPERIOD_LIST_ERROR: {
            return {
                ...state,
                payPeriodList: []
            }
        }
        case GET_PAYROLL_TAX_IMPORT_TYPE:{
            return{
                ...state,
                payrollTaxImportType:action.payload.data
            }
        }
        case GET_PAYROLL_TAX_IMPORT_TYPE_ERROR:{
            return{
                ...state,
                payrollTaxImportType:initialState.payrollTaxImportType
            }
        }
        case GET_COMPENSATION_PAYEE_LIST:
            return{
                ...state,
                compensationPayeeList:action.payload.data
            }
        case GET_COMPENSATION_PAYEE_LIST_ERROR:
            return{
                ...state,
                compensationPayeeList:initialState.compensationPayeeList
            }

        default:
            return state;
    }

}