export const LAYOUT_TYPES = {
  DEFAULT: 'DEFAULT',
  HEADER_NAVIGATION: 'HEADER_NAVIGATION',
  ICON_SIDEBAR: 'ICON_SIDEBAR'
}
export const RECEIPT_FILE_SIZE_LIMIT=2097152;
export const RECEIPT_ALL_FILE_SIZE_LIMIT=20;
export const LEAVE_POLICY_DEFAULT_END_DATE="20501231";
export const STANDARD_FORMATS={
  COMMA_SEPERATED_NUMBER:1,
  PHONE_NO:2,
};

export const SUBSCRIBER_BILLING_ADMIN = {//need to rename constant to SUBSRIBER_ROLES
  ADMIN: 'Subscriber Admin',
  BILLING: 'Subscriber Billing',
  HR:'Subscriber HR',
  USER:'Subscriber User'
}
export const EXPENSE_STATUS ={
  DRAFT:'Draft',
  APPROVED:'Approved',
  REVIEWED:'Reviewed',
  REJECTED:'Rejected',
  SUBMITTED:'Submitted',
  WITHDRAWN:'Withdrawn'
}

export const SUBSCRIBER_SYNC_TYPE ={
  PLACEMENT: 1,
  COMPENSATION: 2,
  BENEFITS_WORKER: 3,
  BENEFITS_CLIENT: 4,
  RETIREMENT_WORKER: 5,
  RETIREMENT_CLIENT: 6,
  RETAINER: 7
}

export const SUBSCRIBER_ROLES = {
  WORKER: 'Worker',
  CLIENT_USER: 'Client User',
  SYSTEM_ADMIN: 'System Admin',
  SUBSCRIBER_USER: 'Subscriber User',
  SUBSCRIBER_HR: 'Subscriber HR',
  SUBSCRIBER_BILLING: 'Subscriber Billing',
  SUBSCRIBER_ADMIN: 'Subscriber Admin',
}

export const NavgationPageLocationKey = {
  //Payable
  PayableCurrentAddEdit: 'PayableCurrentAddEdit',
  PayableCurrentItem_RefNum: 'PayableCurrentItem_RefNum',
  PayableCurrentItem_PayRecordRefNum: 'PayableCurrentItem_PayRecordRefNum',
  //Invoice
  InvoiceCurrentAddEdit: 'InvoiceCurrentAddEdit',
  InvoiceCurrentItem_RefNum: 'InvoiceCurrentItem_RefNum',
  InvoiceCurrentItem_InvoiceRefNum: 'InvoiceCurrentItem_InvoiceRefNum',
  InvoiceCurrentItem_ClientId: 'InvoiceCurrentItem_ClientId',

  //Paymets 
  PaymentCurrentAddEdit: 'PaymentCurrentAddEdit',
  PaymentCurrentPayeePayerName: 'PaymentCurrentPayeePayerName',
  PaymentCurrentPayType: 'PaymentCurrentPayType',
  PaymentCurrentPaymentRefNum: 'PaymentCurrentPaymentRefNum',
  PaymentCurrentHasAllocationList: 'PaymentCurrentHasAllocationList',
}