import React, {useEffect, useState} from 'react';
import {
  Button,
  Card, Col,
  Container, Form,
  FormCheckbox, ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  clear_user_permissions,
  get_user_permission,
  set_user_permission
} from "../../redux/actions/subscriber/subscriberUsers";
import {connect} from "react-redux";
import {default as queryString} from "qs";
import InfoTooltip from "../../components/common/InfoTooltip";

function ManagePermissions({
                             userPermissions,
                             get_user_permission,
                             set_user_permission,
                             clear_user_permissions,
                             changePageMeta, title
                           }) {

  const [state, setState] = useState({
    personalInfo: false,
    contactInfo: false,
    employmentInfo: false,
    compensationInfo: false,
    allowanceInfo: false,
    leavesInfo: false,
    benefitsInfo: false,
    HSAInfo: false,
    pensionInfo: false,
    journeyInfo: false,
    leavesModule: false,
    expenseModule: false,
    notificationExpenseFlag:false,
    notificationLeavesFlag:false,
    notificationMessageFlag:false,
    approveExpense: false,
    approveLeaves: false,
    accountSettings : false,
    roleSettings: false,
    approvalSettings: false,
    emergencyContactInfo: false,
    placementInfo: false,
    independentContractor: false,
    retainerInfo: false,
    EAPInfo: false,
    documents: false,
    integration: false,
    variablePayModule: false,

  });

  let location = useLocation();
  let {userId} = useParams();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  let history = useHistory();

  useEffect(() => {
    changePageMeta(`${title} - ${queryParams.clientName ? queryParams.clientName + " - " : ""}${queryParams.userName}`)
  }, []);

  useEffect(() => {
    get_user_permission(userId);
    return () => {
      clear_user_permissions();
    }
  }, []);

  useEffect(() => {
    if (!state.expenseModule)
      setState({...state, approveExpense: false, notificationExpenseFlag: false})
  }, [state.expenseModule]);

  useEffect(() => {
    if (!state.leavesModule)
      setState({...state, leavesInfo: false, approveLeaves: false, notificationLeavesFlag: false})
  }, [state.leavesModule]);

  

  useEffect(() => {
    if (userPermissions.length) {
      let rolePermissions = userPermissions.find(e => (e.role === queryParams.role)).permissions
      setState({
        ...state,
        personalInfo: rolePermissions.includes("Personal Information"),
        contactInfo: rolePermissions.includes("Contact Information"),
        employmentInfo: rolePermissions.includes("Employment Information"),
        compensationInfo: rolePermissions.includes("Compensation"),
        allowanceInfo: rolePermissions.includes("Allowance"),
        leavesInfo: rolePermissions.includes("Leaves"),
        benefitsInfo: rolePermissions.includes("Benefits"),
        HSAInfo: rolePermissions.includes("HSA Information"),
        pensionInfo: rolePermissions.includes("Pension Information"),
        journeyInfo: rolePermissions.includes("Journey Information"),
        leavesModule: rolePermissions.includes("Leaves Module"),
        expenseModule: rolePermissions.includes("Expense Module"),
        approveExpense: rolePermissions.includes("Approve Expense"),
        approveLeaves: rolePermissions.includes("Approve Leaves"),
        notificationExpenseFlag: rolePermissions.includes("notificationExpense"),
        notificationLeavesFlag: rolePermissions.includes("notificationLeaves"),
        notificationMessageFlag: rolePermissions.includes("notificationMessage"),
        accountSettings : rolePermissions.includes("accountSettings"),
        roleSettings: rolePermissions.includes("roleSettings"),
        approvalSettings: rolePermissions.includes("approvalSettings"),
        emergencyContactInfo: rolePermissions.includes("emergencyContactInfo"),
        placementInfo: rolePermissions.includes("placementInfo"),
        independentContractor: rolePermissions.includes("independentContractor"),
        retainerInfo: rolePermissions.includes("retainerInfo"),
        EAPInfo: rolePermissions.includes("EAPInfo"),
        documents: rolePermissions.includes("documents"),
        integration: rolePermissions.includes("integration"),
        variablePayModule: rolePermissions.includes("Variable Pay Module"),
      })
    }
  }, [userPermissions]);


  let onToggle = (e) => {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    })
  };

  let workerRole = queryParams.role === "Worker";
  let clientRole = ["Client User", "Client HR", "Client Billing", "Client Admin"].includes(queryParams.role);

  let onSubmit = (e) => {
    e.preventDefault();
    set_user_permission(userId, "role" + queryParams.role.replace(' ', ''), state.personalInfo, state.contactInfo, state.employmentInfo, state.compensationInfo, state.allowanceInfo, state.leavesInfo, state.benefitsInfo, state.HSAInfo, state.pensionInfo, state.journeyInfo, state.leavesModule, state.expenseModule, state.approveExpense, state.approveLeaves,state.notificationExpenseFlag,state.notificationLeavesFlag,state.notificationMessageFlag);
    history.goBack();
  }


  return (
    <Container fluid className="main-content-container p-4">
      <Form id="form" onSubmit={onSubmit}>
        <div className="position-relative mb-5">
          <span
            className="tableHeader">View {clientRole ? "Worker" : ""} Profile</span>
          <h4 className="m-0 section-title idStyle">Ref# {userId}</h4>
          <Card className="tableCard p-0 w-100 border-card">
            <ListGroup flush>

              <ListGroupItem className="p-3 mt-3">
                <Row>
                  <Col>
                    <Row form>
                    <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="accountSettings" name="accountSettings"
                                      checked={state.accountSettings}
                                      onChange={onToggle}
                                      toggle small disabled>Account
                          Settings</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="roleSettings" name="roleSettings"
                                      checked={state.roleSettings}
                                      onChange={onToggle}
                                      toggle small disabled>Role
                          Settings</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="approvalSettings" name="approvalSettings"
                                      checked={state.approvalSettings}
                                      onChange={onToggle}
                                      toggle small disabled>Approval
                          Settings</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2"></Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="personalInfo" name="personalInfo"
                                      checked={state.personalInfo}
                                      onChange={onToggle}
                                      toggle small>Personal
                          Information</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="contactInfo" name="contactInfo"
                                      checked={state.contactInfo}
                                      onChange={onToggle}
                                      toggle small>Contact
                          Information</FormCheckbox>
                      </Col>

                      <Col lg="4" className="form-group mb-0 p-2">
                        <FormCheckbox id="emergencyContactInfo" name="emergencyContactInfo"
                                      checked={state.emergencyContactInfo}
                                      onChange={onToggle}
                                      toggle small disabled>Emergency Contact
                          Information</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="placementInfo" name="placementInfo"
                                      checked={state.placementInfo}
                                      onChange={onToggle}
                                      toggle small disabled>Placement
                          Information</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="independentContractor" name="independentContractor"
                                      checked={state.independentContractor}
                                      onChange={onToggle}
                                      toggle small disabled>Independent Contractor</FormCheckbox>
                      </Col>
                      <Col lg="2" className="form-group mb-0 p-2"></Col>
                      <Col lg="2" className="form-group mb-0 p-2"></Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="employmentInfo"
                                      name="employmentInfo"
                                      checked={state.employmentInfo}
                                      onChange={onToggle} toggle small>Employment
                          Information</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="compensationInfo"
                                      name="compensationInfo"
                                      checked={state.compensationInfo}
                                      onChange={onToggle} toggle
                                      small>Compensation Information</FormCheckbox>
                      </Col>
                      {/*<Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="allowanceInfo" name="allowanceInfo"
                                      checked={state.allowanceInfo}
                                      onChange={onToggle}
                                      toggle small>Allowances</FormCheckbox>
  </Col>*/}

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="retainerInfo"
                                      name="retainerInfo"
                                      checked={state.retainerInfo}
                                      onChange={onToggle}
                                      toggle small disabled>Retainer Information</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2"></Col>
                      
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="benefitsInfo"
                                      name="benefitsInfo"
                                      checked={state.benefitsInfo}
                                      onChange={onToggle} toggle small>Benefits Information</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="pensionInfo"
                                      name="pensionInfo"
                                      checked={state.pensionInfo}
                                      onChange={onToggle} toggle small>Retirement Plan Information</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="HSAInfo"
                                      name="HSAInfo"
                                      checked={state.HSAInfo}
                                      onChange={onToggle} toggle small>Health
                          Spending Account Information</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="EAPInfo"
                                      name="EAPInfo"
                                      checked={state.EAPInfo}
                                      onChange={onToggle} toggle small disabled>Employee Assistance Program Information</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="leavesInfo"
                                      name="leavesInfo"
                                      checked={state.leavesInfo}
                                      disabled={!state.leavesModule}
                                      className={!state.leavesModule ? "text-light" : ""}
                                      onChange={onToggle}
                                      toggle small>Leaves</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="documents"
                                      name="documents"
                                      checked={state.documents}
                                      onChange={onToggle}
                                      toggle small disabled>Documents</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="integration"
                                      name="integration"
                                      checked={state.integration}
                                      onChange={onToggle}
                                      toggle small disabled>Integration</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="journeyInfo"
                                      name="journeyInfo"
                                      checked={state.journeyInfo}
                                      onChange={onToggle} toggle small>User
                          Journey</FormCheckbox>
                      </Col>

                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>

        <div className={`position-relative ${workerRole ? "mb-4" : "mb-5"}`}>
          <span className="tableHeader">Enable Modules</span>
          <Card className="tableCard p-0 w-100 border-card">
            <ListGroup flush>

              <ListGroupItem className="p-3 mt-3">
                <Row>
                  <Col>
                    <Row form>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="leavesModule" name="leavesModule"
                                      checked={state.leavesModule}
                                      onChange={onToggle}
                                      toggle small>Leave
                         </FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="expenseModule" name="expenseModule"
                                      checked={state.expenseModule}
                                      onChange={onToggle}
                                      toggle small>Expense
                          </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>

        

        {clientRole && <div className="position-relative mb-4">
          <span className="tableHeader">Client Permissions</span>
          <Card className="tableCard p-0 w-100 border-card">
            <ListGroup flush>

              <ListGroupItem className="p-3 mt-3">
                <Row>
                  <Col>
                    <Row form>

                    <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="approveLeaves" name="approveLeaves"
                                      checked={state.approveLeaves}
                                      disabled={!state.leavesModule}
                                      className={!state.leavesModule ? "text-light" : ""}
                                      onChange={onToggle}
                                      toggle small>Approve
                          Leaves</FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="approveExpense" name="approveExpense"
                                      checked={state.approveExpense}
                                      disabled={!state.expenseModule}
                                      className={!state.expenseModule ? "text-light" : ""}
                                      onChange={onToggle}
                                      toggle small>Approve
                          Expenses</FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>}
        <div className={`position-relative ${workerRole ? "mb-4" : "mb-5"}`}>
          <span className="tableHeader">Email Settings 
          <InfoTooltip
                            msg="Set up email notifications by module" /></span>
         
          <Card className="tableCard p-0 w-100 border-card">
            <ListGroup flush>

              <ListGroupItem className="p-3 mt-3">
                <Row>
                  <Col>
                    <Row form>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="notificationLeavesFlag" name="notificationLeavesFlag"
                                      checked={state.notificationLeavesFlag}
                                      disabled={!state.leavesModule}
                                      className={!state.leavesModule ? "text-light" : ""}
                                      onChange={onToggle}
                                      toggle small>Leave
                         </FormCheckbox>
                      </Col>

                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="notificationExpenseFlag" name="notificationExpenseFlag"
                                      checked={state.notificationExpenseFlag}
                                      disabled={!state.expenseModule}
                                      className={!state.expenseModule ? "text-light" : ""}
                                      onChange={onToggle}
                                      toggle small>Expense
                          </FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="notificationMessageFlag" name="notificationMessageFlag"
                                      checked={state.notificationMessageFlag}
                                      onChange={onToggle}
                                      toggle small>Message Center
                          </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>
      </Form>

      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

          <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                  type="submit" name="submit"
                  form="form">Submit</Button>

          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={e => history.goBack()}>Cancel</Button>

        </Col>
      </Row>

    </Container>
  );
}

const mapStateToProps = (state) => ({
  userPermissions: state.subscriberUsers.userPermissions,
});

export default connect(mapStateToProps, {
  get_user_permission,
  set_user_permission,
  clear_user_permissions
})(ManagePermissions);
