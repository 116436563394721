import api from "../../utils/api";
import {setAlert} from "./alert.js";

import {
  APPROVE_LEAVE,
  APPROVE_LEAVE_ERROR,
  CLEAR__CLIENT_PROFILE,
  CLEAR__HOLIDAYS_DETAILS,
  CLEAR_CALCULATED_LEAVE_INFO,
  CLEAR_CITY_DETAILS,
  CLEAR_CLIENT_LEAVES_LIST,
  CLEAR_CURRENCY_DETAILS,
  CLEAR_SYSADMIN_SUBSCRIBER_PROFILE,
  DELETE_ALLOWANCE_TYPE_DETAILS,
  DELETE_ALLOWANCE_TYPE_DETAILS_ERROR,
  DELETE_CLIENT_LEAVE_POLICY_LIST_ERROR,
  DELETE_CLIENT_LEAVE_POLICY_LIST_SUCCESS,
  DELETE_EXPENSE_TYPE_DETAILS,
  DELETE_EXPENSE_TYPE_DETAILS_ERROR,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  USER_HAS_EXISTING_DATA,
  USER_HAS_EXISTING_DATA_ERROR,
  DELETE_USER_JOURNEY_FOR_SUB_ERROR,
  GET_ALLOWANCE_TYPE_DETAILS,
  GET_ALLOWANCE_TYPE_DETAILS_ERROR,
  GET_ALLOWANCE_TYPE_LIST,
  GET_ALLOWANCE_TYPE_LIST_ERROR,
  GET_BASE_CURRENCY,
  GET_BENEFITS_CLASS_LIST,
  GET_BENEFITS_CLASS_LIST_ERROR,
  GET_BENEFITS_COVERAGE_LIST,
  GET_BENEFITS_COVERAGE_LIST_ERROR,
  GET_CALCULATED_LEAVE_INFO,
  GET_CALCULATED_LEAVE_INFO_ERROR,
  GET_CITY_DETAILED_LIST,
  GET_CITY_DETAILED_LIST_ERROR,
  GET_CITY_DETAILS,
  GET_CITY_DETAILS_ERROR,
  GET_CITY_LIST,
  GET_CITY_LIST_ERROR,
  GET_CLIENT_CONTACT_LIST,
  GET_CLIENT_CONTACT_LIST_ERROR,
  GET_CLIENT_LEAVE_POLICY_DETAIL_ERROR,
  GET_CLIENT_LEAVE_POLICY_DETAIL_SUCCESS,
  GET_CLIENT_LEAVE_POLICY_LIST_ERROR,
  GET_CLIENT_LEAVE_POLICY_LIST_SUCCESS,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_ERROR,
  GET_COMPENSATION_TYPES,
  GET_COMPENSATION_TYPES_ERROR,
  GET_AVAILABLE_FIELD_NAME,
  GET_AVAILABLE_FIELD_NAME_ERROR,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_ERROR,
  GET_CURRENCY_DETAILS,
  GET_CURRENCY_DETAILS_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_ERROR,
  GET_DEAL_TYPES,
  GET_DEAL_TYPES_ERROR,
  GET_EMPLOYMENT_STATUS_LIST,
  GET_EMPLOYMENT_STATUS_LIST_ERROR,
  GET_EMPLOYMENT_SUB_STATUS_LIST,
  GET_EMPLOYMENT_SUB_STATUS_LIST_ERROR,
  GET_EXPENSE_CONFIG_TYPE_LIST,
  GET_EXPENSE_CONFIG_TYPE_LIST_ERROR,
  GET_EXPENSE_TYPE_DETAILS,
  GET_EXPENSE_TYPE_DETAILS_ERROR,
  GET_HOLIDAY_DETAILS,
  GET_HOLIDAY_DETAILS_ERROR,
  GET_LEAVE_ACCRUAL_DATE_TYPE_LIST,
  GET_LEAVE_ACCRUAL_DATE_TYPE_LIST_ERROR,
  GET_LEAVE_ACCRUAL_EVERY_STRING_LIST,
  GET_LEAVE_ACCRUAL_EVERY_STRING_LIST_ERROR,
  GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST,
  GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST_ERROR,
  GET_LEAVE_PAY_OUT_TIME_TYPE_LIST,
  GET_LEAVE_PAY_OUT_TIME_TYPE_LIST_ERROR,
  GET_LEAVE_PERIOD_STRING_LIST,
  GET_LEAVE_PERIOD_STRING_LIST_ERROR,
  GET_LEAVE_QUOTA_RESET_TYPE_LIST,
  GET_LEAVE_QUOTA_RESET_TYPE_LIST_ERROR,
  GET_LEAVE_TYPE_DETAILS,
  GET_LEAVE_TYPE_DETAILS_ERROR,
  GET_MARITAL_STATUS_LIST,
  GET_MARITAL_STATUS_LIST_ERROR,
  GET_MILEAGE_RANGE_DETAILS,
  GET_MILEAGE_RANGE_DETAILS_ERROR,
  GET_MILEAGE_RANGE_LIST,
  GET_MILEAGE_RANGE_LIST_ERROR,
  GET_PENSION_PLAN_FREQUENCY_LIST,
  GET_PENSION_PLAN_FREQUENCY_LIST_ERROR,
  GET_PENSION_PLAN_LIST,
  GET_PENSION_PLAN_LIST_ERROR,
  GET_PERKS_FREQUENCY_LIST,
  GET_PERKS_FREQUENCY_LIST_ERROR,
  GET_PREFERRED_EMAIL_LIST,
  GET_PREFERRED_EMAIL_LIST_ERROR,
  GET_PRONOUN_LIST,
  GET_PRONOUN_LIST_ERROR,
  GET_REGION_LIST,
  GET_REGION_LIST_ERROR,
  GET_ROLE_LIST,
  GET_ROLE_LIST_ERROR,
  GET_SEARCH_LEAVE_POLICY_ERROR,
  GET_SEARCH_LEAVE_POLICY_SUCCESS,
  GET_SUB_CLIENT_LIST,
  GET_SUB_CLIENT_LIST_ERROR,
  GET_SUB_EXPENSE_REPORTS,
  GET_SUB_EXPENSE_REPORTS_ERROR,
  GET_SUB_LEAVE_DETAILS,
  GET_SUB_LEAVE_DETAILS_ERROR,
  GET_SUB_LEAVE_TYPES,
  GET_SUB_LEAVE_TYPES_ERROR,
  GET_SUB_STATUS_LIST,
  GET_SUB_STATUS_LIST_ERROR,
  GET_SUBSCRIBER_LIST,
  GET_SUBSCRIBER_LIST_ERROR,
  GET_SALESCONSULTANT_LIST,
  GET_SALESCONSULTANT_LIST_ERROR,
  GET_SYSADMIN_SUBSCRIBER_LIST,
  GET_SYSADMIN_SUBSCRIBER_LIST_ERROR,
  GET_SYSADMIN_SUBSCRIBER_PROFILE,
  GET_SYSADMIN_SUBSCRIBER_PROFILE_ERROR,
  GET_SYSADMIN_SUBSCRIBER_USER_LIST,
  GET_SYSADMIN_SUBSCRIBER_USER_LIST_ERROR,
  GET_USER_JOURNEY_LIST_FOR_SUB,
  GET_USER_JOURNEY_LIST_FOR_SUB_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  GET_VISA_STATUS_LIST,
  GET_VISA_STATUS_LIST_ERROR,
  GET_WORKER_FOR_CLIENT_LIST,
  GET_WORKER_FOR_CLIENT_LIST_ERROR,
  GET_WORKER_TYPES,
  GET_WORKER_TYPES_ERROR,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFICATIONCODE_SUCCESS,
  VERIFICATIONCODE_CLEAR,
  VERIFICATIONCODE_FAILED,
  VERIFYCODE_SUCCESS,
  VERIFYCODE_FAILED,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILED,
  REJECT_LEAVE,
  REJECT_LEAVE_ERROR,
  RESET,
  SAVE_CITY_DETAILS,
  SAVE_CITY_DETAILS_ERROR,
  SAVE_CLIENT_PROFILE,
  SAVE_CLIENT_PROFILE_ERROR,
  SAVE_LEAVE_TYPE_DETAILS,
  SAVE_LEAVE_TYPE_DETAILS_ERROR,
  SAVE_MILEAGE_RANGE_DETAILS,
  SAVE_MILEAGE_RANGE_DETAILS_ERROR,
  SAVE_SYSADMIN_SUBSCRIBER_PROFILE,
  SAVE_SYSADMIN_SUBSCRIBER_PROFILE_ERROR,
  SAVE_USER_PROFILE,
  SAVE_USER_PROFILE_ERROR,
  SEARCH_LEAVES,
  SEARCH_LEAVES_ERROR,
  SEARCH_SUB_HOLIDAYS,
  SEARCH_SUB_HOLIDAYS_ERROR,
  SEARCH_SYSADMIN_USER_LIST,
  SEARCH_SYSADMIN_USER_LIST_ERROR,
  SEARCH_USER_LIST,
  SEARCH_USER_LIST_ERROR,
  SET_USER_ACCOUNT_STATUS,
  SET_USER_ACCOUNT_STATUS_ERROR,
  SET_USER_PASSWORD,
  SET_USER_PASSWORD_ERROR,
  SUB_SUBMIT_LEAVE_DETAILS,
  SUB_SUBMIT_LEAVE_DETAILS_ERROR,
  SUB_WITHDRAW_LEAVE,
  SUB_WITHDRAW_LEAVE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  GET_MESSAGE_CATEGORY_LIST,
  GET_MESSAGE_CATEGORY_LIST_ERROR,
  GET_MESSAGE_CATEGORY_ROLE_LIST,
  GET_MESSAGE_CATEGORY_ROLE_LIST_ERROR,
  SAVE_MESSAGE_CATEGORY_DETAILS,
  SAVE_MESSAGE_CATEGORY_DETAILS_ERROR,
  DELETE_MESSAGE_CATEGORY_DETAILS,
  DELETE_MESSAGE_CATEGORY_DETAILS_ERROR,
  GET_MESSAGE_CATEGORY_DETAILS,
  GET_MESSAGE_CATEGORY_DETAILS_ERROR,
  GET_MESSAGE_STATUS_LIST,
  GET_HR_CONSULTANT_LIST,
  GET_HR_CONSULTANT,
  GET_HR_CONSULTANT_ERROR,
  SEARCH_CONVERSATIONS,
  GET_CONVERSATION_DETAIL,
  SEARCH_USER_MESSAGES,
  CLEAR_CONVERSATION_DETAILS,
  SEARCH_CONVERSATIONS_PAGINATED,
  REDIRECT,
  UNREDIRECT,
  GET_NOTIFICATION_CONFIG,
  GET_NOTIFICATION_CONFIG_ERROR,
  SAVE_NOTIFICATION_CONFIG,
  SAVE_NOTIFICATION_CONFIG_ERROR,

  GET_ROLE_USER_LIST,
  GET_ROLE_USER_LIST_ERROR,
  GET_ASSIGNED_MSG_LIST,
  GET_ASSIGNED_MSG_USER_LIST,
  GET_ASSIGNED_MSG_LIST_ERROR,
  SAVE_MSG_ASSIGNED_INFO,
  SAVE_MSG_ASSIGNED_INFO_ERROR,
  DELETE_MSG_ASSIGNED_INFO,
  DELETE_MSG_ASSIGNED_INFO_ERROR,
  GET_SYSADMIN_EVENTNAME_LIST,
  GET_SYSADMIN_EVENTNAME_LIST_ERROR,
  GET_SYSADMIN_EVENTDATA_LIST,
  GET_SYSADMIN_EVENTDATA_LIST_ERROR,
  DELETE_LEAVE_POLICY_LEVEL_DETAILS,
  SAVE_LEAVE_POLICY_LEVEL_DETAILS,
  SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR,
  DELETE_LEAVE_POLICY_DETAILS,
  ENABLE_LEAVE_SUBMIT,
  GET_APP_MODULE,
  GET_APP_MODULE_ERROR,
  GET_CLIENT_LIST_REPORT,
  GET_CLIENT_LIST_REPORT_ERROR,
  GET_COUNTRY_LIST_REPORT,
  GET_COUNTRY_LIST_REPORT_ERROR,
  GET_REGION_LIST_REPORT,
  GET_REGION_LIST_REPORT_ERROR,
  GET_EMPLOYMENT_STATUS_LIST_REPORT,
  GET_EMPLOYMENT_STATUS_LIST_REPORT_ERROR,
  GET_SALES_CONSULTANT_LIST_REPORT,
  GET_SALES_CONSULTANT_LIST_REPORT_ERROR,
  GET_HR_CONSULTANT_LIST_REPORT,
  GET_HR_CONSULTANT_LIST_REPORT_ERROR,
  GET_LEAVE_TYPE_LIST_REPORT,
  GET_LEAVE_TYPE_LIST_REPORT_ERROR,
  GET_REPORTS_NAME_LIST,
  GET_REPORTS_NAME_LIST_ERROR,
  GET_REPORTS_PERIOD_LIST,
  GET_REPORTS_PERIOD_LIST_ERROR,
  GET_REPORTS_STATUS_LIST,
  GET_REPORTS_STATUS_LIST_ERROR,
  GENERATE_REPORT,
  GENERATE_REPORT_ERROR,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_ERROR,
  GET_WORKER_LIST_REPORT,
  GET_WORKER_LIST_REPORT_ERROR,
  REPORT_ADVANCE_FILTERS,
  REPORT_ADVANCE_FILTERS_ERROR,
  GET_APP_MODULE_CLEAR,
  GET_DISTANCE_UNIT_NAME_LIST,

  GET_BASE_DISTANCE_UNIT,
  GET_BASE_DISTANCE_UNIT_ERROR,
  GET_BASE_DISTANCE_UNIT_NAME,
  GET_BASE_DISTANCE_UNIT_NAME_ERROR,
  GET_DISTANCE_UNIT_DETAILS,
  GET_DISTANCE_UNIT_DETAILS_ERROR,
  CLEAR_DISTANCE_UNIT_DETAILS,
  GET_BASE_DISTANCE_UNIT_LIST,
  GET_BASE_DISTANCE_UNIT_LIST_ERROR,
  GET_DISTANCE_UNIT_LIST,
  GET_DISTANCE_UNIT_LIST_ERROR,
  DELETE_DISTANCE_DETAILS,
  DELETE_DISTANCE_DETAILS_ERROR,
  SAVE_DISTANCE_DETAILS,
  SAVE_DISTANCE_DETAILS_ERROR,
  GET_COUNTRY_NAME,
  GET_COUNTRY_NAME_ERROR,
  GET_REGION_MILEAGE_LIST,
  GET_REGION_MILEAGE_LIST_ERROR,
  SAVE_PAY_COMPONENT_DETAILS,
  SAVE_PAY_COMPONENT_DETAILS_ERROR,
  GET_PAY_COMPONENT_DETAILS,
  GET_PAY_COMPONENT_DETAILS_ERROR,
  GET_PAY_COMPONENT_LIST,
  GET_PAY_COMPONENT_LIST_ERROR,
  CALCULATE_YEAR_OF_SERVICES,
  CALCULATE_YEAR_OF_SERVICES_ERROR,
  CALCULATE_AGES,
  CALCULATE_AGES_ERROR,
  GET_PAY_ELEMENT_LIST,
  GET_PAY_ELEMENT_LIST_ERROR,
  SAVE_PAY_ELEMENT_DETAILS,
  SAVE_PAY_ELEMENT_DETAILS_ERROR,
  GET_PAY_ELEMENT_DETAILS,
  GET_PAY_ELEMENT_DETAILS_ERROR,
  DELETE_PAY_ELEMENT_DETAILS,
  DELETE_PAY_ELEMENT_DETAILS_ERROR,
  GET_WORKER_DETAILS_REPORT,
  GET_WORKER_DETAILS_REPORT_ERROR,
  GET_DEFAULT_VALUES,
  GET_DEFAULT_VALUES_ERROR,
  GET_CLIENT_MARKUP_VALUES,
  GET_CLIENT_MARKUP_VALUES_ERROR,
  UPDATE_IS_SAVE_USER_PROFILE,
  UPLOAD_CONVERSATION_ATTACHMENT,
  GET_MESSAGE_ATTACHMENT_FILE,
  GET_MESSAGE_ATTACHMENT_FILE_ERROR,
  SEARCH_PROPOSALS,
  SEARCH_PROPOSALS_ERROR,
  SAVE_PROPOSAL,
  SAVE_PROPOSAL_ERROR,
  GET_PROPPOSAL_OWNER_LIST,
  GET_PROPPOSAL_OWNER_LIST_ERROR,
  GET_WORKER_COMPENSATION_RATE_LIST,
  GET_WORKER_COMPENSATION_RATE_LIST_ERROR,
  GET_PROPOSAL,
  GET_PROPOSAL_ERROR,
  CLEAR_PROPOSAL,
  DELETE_PROPOSAL,
  DELETE_PROPOSAL_ERROR,
  CLEAR_CLIENT_CONTACT_LIST,
  GET_VACATION_PAY_LIST,
  GET_VACATION_PAY_LIST_ERROR,
  GET_PAY_ITEMS_LIST,
  GET_PAY_ITEMS_LIST_ERROR,
  DELETE_PAY_ITEM,
  DELETE_PAY_ITEM_ERROR,
  GET_SUB_CONFIG_ERROR,
  GET_SUB_CONFIG,
  SYNC_PAY_ITEM,
  SYNC_PAY_ITEM_ERROR,
  ATTACH_PAY_ITEM_HR_COMPONENT,
  ATTACH_PAY_ITEM_HR_COMPONENT_ERROR,
  GENERATE_BENEFITS_REPORT,
  GENERATE_BENEFITS_REPORT_ERROR,
  CLEAR_PAY_ITEM_LIST
} from "./types";
import axios from "axios";

export const save_holiday_details = (
  holidayId,
  holidayDate,
  holidayName,
  regionList,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveHolidayDetails`,
      {
        params: {
          holidayId,
          holidayDate,
          holidayName,
          regionList: regionList.toString(),
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};
export const get_holiday_details = (
  holidayId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getHolidayDetails?holidayId=${holidayId}`
    );
    dispatch({
      type: GET_HOLIDAY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_HOLIDAY_DETAILS_ERROR,
    });
  }
};
export const delete_holiday = (
  holidayId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteHoliday?holidayId=${holidayId}`
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_HOLIDAY,
      payload: holidayId
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_HOLIDAY_ERROR,
    });
  }
};

// Login User
export const login = (username, password, tFA) => async (dispatch) => {
  api.get(`api/user/login?login=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&2fa=${tFA}`,
    {
      withCredentials: true
    })
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch(err => {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: LOGIN_FAILED,
      });
    });
};

export const clear_ForgotPasswordSteps = () => async (dispatch) => {
  dispatch({
    type: VERIFICATIONCODE_CLEAR,
  });

};
export const SendVerification = (username) => async (dispatch) => {

  try {
    const res = await api.get(`api/user/sendFPVerificationCode?email=${encodeURIComponent(username)}`);
    dispatch({
      type: VERIFICATIONCODE_SUCCESS,
      payload: "code",
    });
  } catch (err) {

    const success = err.response.data.success;
    if (!success) {
      const msg=err.response.message;
      dispatch(setAlert(err.response.message, "danger"));
    }
    dispatch({
      type: VERIFICATIONCODE_FAILED,
    });
  }
};
export const VerifyCode = (username, code) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/checkFPVerificationCode?login=${encodeURIComponent(username)}&code=${encodeURIComponent(code)}`);
    dispatch({
      type: VERIFYCODE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: VERIFYCODE_FAILED,
    });
  }
};
export const ResetPassword = (username, password, confirmPassword,code,twofa) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/setUserPassword?login=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&confirmPassword=${encodeURIComponent(confirmPassword)}&code=${code}&2fa=${twofa}`);
    dispatch({
      type: RESETPASSWORD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: RESETPASSWORD_FAILED,
    });
  }
};
// Logout
export const logout = () => ({type: LOGOUT_SUCCESS});

// reset point
export const reset = () => ({type: RESET});


export const get_sub_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getSubstatusList`);
    dispatch({
      type: GET_SUB_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_STATUS_LIST_ERROR,
    });
  }
};

export const get_client_sub_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/client/getSubstatusList`);
    dispatch({
      type: GET_SUB_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_STATUS_LIST_ERROR,
    });
  }
};
export const get_role_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getRoleList`);
    dispatch({
      type: GET_ROLE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ROLE_LIST_ERROR,
    });
  }
};

export const search_user_list = (
  userId,
  clientName,
  userName,
  email,
  substatus,
  role
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/searchUserList`,
      {
        params: {
          userId: userId,
          clientName: clientName,
          userName: userName,
          email: email,
          substatus: substatus,
          role: role
        }
      }
    );
    dispatch({
      type: SEARCH_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_USER_LIST_ERROR,
    });
  }
};


export const get_currency_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getCurrencyList`);
    dispatch({
      type: GET_CURRENCY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CURRENCY_LIST_ERROR,
    });
  }
};

export const sub_get_currency_list = (subscriberId) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getCurrencyList?subscriberId=${subscriberId}`);
    dispatch({
      type: GET_CURRENCY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CURRENCY_LIST_ERROR,
    });
  }
};

export const get_distance_unit_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/get-distance-unit-list`);
    dispatch({
      type: GET_BASE_DISTANCE_UNIT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_BASE_DISTANCE_UNIT_LIST_ERROR,
    });
  }
};

export const get_base_distance_unit = () => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/get-base-distance-unit`);
    dispatch({
      type: GET_BASE_DISTANCE_UNIT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_BASE_DISTANCE_UNIT_ERROR,
    });
  }
};
export const get_base_distance_unit_name = () => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/get-base-distance-unit`);
    dispatch({
      type: GET_BASE_DISTANCE_UNIT_NAME,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_BASE_DISTANCE_UNIT_NAME_ERROR,
    });
  }
};

export const exp_get_currency_list = (subscriberId) => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/getCurrencyList?subscriberId=${subscriberId}`);
    dispatch({
      type: GET_CURRENCY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CURRENCY_LIST_ERROR,
    });
  }
};

export const get_country_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getCountryList`);
    dispatch({
      type: GET_COUNTRY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_COUNTRY_LIST_ERROR,
    });
  }
};

export const get_region_list = (countryId) => async (dispatch) => {
  try {

    if (!countryId) {
      dispatch({
        type: GET_REGION_LIST,
        payload: {regionList: []},
      });
    } else {
      const res = await api.get(
        `api/general/getRegionList?countryId=${countryId}`
      );
      dispatch({
        type: GET_REGION_LIST,
        payload: res.data,
      });
    }


  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_REGION_LIST_ERROR,
    });
  }
};
export const get_city_list = (regionId) => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getCityList?regionId=${regionId}`);
    dispatch({
      type: GET_CITY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CITY_LIST_ERROR,
    });
  }
};
export const get_city_details = (cityId) => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getCityDetails?cityId=${cityId}`);
    dispatch({
      type: GET_CITY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CITY_DETAILS_ERROR,
    });
  }
};
export const get_currency_details = (currencyId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/general/getCurrencyDetails?currencyId=${currencyId}`
    );
    dispatch({
      type: GET_CURRENCY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CURRENCY_DETAILS_ERROR,
    });
  }
};
export const save_currency_details = (id, code, symbol, description) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/sysadmin/saveCurrencyDetails`, {
        params: {
          currencyId: id,
          code: code,
          symbol: symbol,
          description: description,

        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const sub_get_currency_details = (subscriberId, currencyId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getCurrencyDetails?subscriberId=${subscriberId}&currencyId=${currencyId}`
    );
    dispatch({
      type: GET_CURRENCY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CURRENCY_DETAILS_ERROR,
    });
  }
};
export const sub_save_currency_details = (subscriberId, id, code, symbol, description) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/subscriber/saveCurrencyDetails`, {
        params: {subscriberId, currencyId: id, code, symbol, description}
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const sub_delete_currency_details = (subscriberId, id) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteCurrencyDetails`, {
        params: {subscriberId, currencyId: id,}
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_base_currency = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/getBaseCurrency`
    );
    dispatch({
      type: GET_BASE_CURRENCY,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_distance_unit_name_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/general/get-distance-unit-name-list `
    );
    dispatch({
      type: GET_DISTANCE_UNIT_NAME_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const save_base_currency = (subscriberId, currencyId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveBaseCurrency?subscriberId=${subscriberId}&currencyId=${currencyId}`
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const save_base_distance_unit = (distanceUnitId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/save-base-distance-unit?distanceUnitId=${distanceUnitId}`
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_compensation_types = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getCompensationTypes`);
    dispatch({
      type: GET_COMPENSATION_TYPES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_COMPENSATION_TYPES_ERROR,
    });
  }
};
export const get_worker_types = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getWorkerTypes`);
    dispatch({
      type: GET_WORKER_TYPES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_TYPES_ERROR,
    });
  }
};
export const get_visa_status_types = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getVisaStatusList`);
    dispatch({
      type: GET_VISA_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_VISA_STATUS_LIST_ERROR,
    });
  }
};
export const get_deal_types_types = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getDealTypes`);
    dispatch({
      type: GET_DEAL_TYPES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_DEAL_TYPES_ERROR,
    });
  }
};
export const get_user_profile = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getUserProfile?userId=${userId}`);

    dispatch({
      type: GET_USER_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_PROFILE_ERROR,
    });
  }
};

export const get_user_default_values = () => async (dispatch)=>{
  try{
    const res = await api.get('api/subscriber/getDefaultValues')
    dispatch({
      type: GET_DEFAULT_VALUES,
      payload: res.data,
    });

  }catch(error){
    
    dispatch({
      type: GET_DEFAULT_VALUES_ERROR
    });

  }
}

export const get_client_markup_values = (clientId) => async (dispatch)=>{
  try{
    const res = await api.get(`api/subscriber/getClientMarkups?clientId=${clientId}`)
    dispatch({
      type: GET_CLIENT_MARKUP_VALUES,
      payload: res.data,
    });

  }catch(error){
    
    dispatch({
      type: GET_CLIENT_MARKUP_VALUES_ERROR
    });

  }
}

export const clear_user_profile = () => async (dispatch) => {
  dispatch({
    type: GET_USER_PROFILE_ERROR,
  });
};


export const get_user_journey_for_sub = (
  userId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getUserJourneyList?userId=${userId}`
    );
    dispatch({
      type: GET_USER_JOURNEY_LIST_FOR_SUB,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_JOURNEY_LIST_FOR_SUB_ERROR,
    });
  }
};
export const search_sub_holidays = (
  fromDate,
  toDate,
  holidayName,
  countryId,
  regionId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/searchHolidays`, {
        params: {
          from: fromDate,
          to: toDate,
          holidayName: holidayName,
          countryId: countryId,
          regionId: regionId,
        }
      }
    );
    dispatch({
      type: SEARCH_SUB_HOLIDAYS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_SUB_HOLIDAYS_ERROR,
    });
  }
};


export const delete_user_journey_for_sub = (
  userId,
  journeyId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteUserJourney?userId=${userId}&journeyId=${journeyId}`
    );

    dispatch(get_user_journey_for_sub(userId))
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_USER_JOURNEY_FOR_SUB_ERROR,
    });
  }
};

export const save_user_profile = (userProfileData) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveUserProfile`,
      {
        params: {
          userId: userProfileData.userId,
          loginEmail: userProfileData.loginEmail,
          employmentStatus: userProfileData.employmentStatus,
          subStatus: userProfileData.subStatus,
          roleWorker: userProfileData.workerFlag,
          roleClientUser: userProfileData.clientUserFlag,
          roleClientAdmin: userProfileData.clientAdminFlag,
          roleSubscriberUser: userProfileData.subscriberUserFlag,
          roleSubscriberAdmin: userProfileData.subscriberAdminFlag,
          roleSystemAdmin: userProfileData.systemAdminFlag,
          roleClientHR: userProfileData.clientHRFlag,
          roleClientBilling: userProfileData.clientBillingFlag,
          roleSubscriberHR: userProfileData.subscriberHRFlag,
          roleSubscriberBilling: userProfileData.subscriberBillingFlag,
          roleSubscriberApiIntegration: userProfileData.subscriberApiIntegrationFlag,
          pronoun: userProfileData.pronoun,
          nickName: userProfileData.nickName,
          firstName: userProfileData.firstName,
          lastName: userProfileData.lastName,
          dob: userProfileData.dob,
          maritalStatus: userProfileData.maritalStatus,
          addressLine1: userProfileData.addressLine1,
          addressLine2: userProfileData.addressLine2,
          countryId: userProfileData.countryId,
          regionId: userProfileData.regionId,
          cityId: userProfileData.cityId,
          zip: userProfileData.zip,
          phoneHome: userProfileData.phoneHome,
          phoneWork: userProfileData.phoneWork,
          phoneWorkExt: userProfileData.phoneWorkExt,
          phoneMobile: userProfileData.phoneMobile,
          emailPersonal: userProfileData.emailPersonal,
          emailWork: userProfileData.emailWork,
          preferredEmail: userProfileData.preferredEmail,
          linkedIn: userProfileData.linkedIn,
          subscriberId: userProfileData.subscriberId,
          subscriberName: userProfileData.subscriberName,
          clientId: userProfileData.clientId,
          clientName: userProfileData.clientName,
          clientContactId: userProfileData.clientContactId,
          clientContactName: userProfileData.clientContactName,
          jobTitle: userProfileData.jobTitle,
          department: userProfileData.department,
          compensation: userProfileData.compensation,
          ciPayType: userProfileData.ciPayType,
          currency: userProfileData.currency,
          allowanceLiving: userProfileData.allowanceLiving,
          allowancePhone: userProfileData.allowancePhone,
          allowanceCar: userProfileData.allowanceCar,
          allowanceTypes: userProfileData.allowanceTypes,
          hireDate: userProfileData.hireDate,
          endDate: userProfileData.endDate,
          payrollId: userProfileData.payrollId,
          benefitsCoverage: userProfileData.benefitsCoverage,
          benefitsCertificateNo: userProfileData.benefitsCertificateNo,
          benefitsClass: userProfileData.benefitsClass,
          benefitsStartDate: userProfileData.benefitsStartDate,
          benefitsEndDate: userProfileData.benefitsEndDate,
          contribution: userProfileData.contribution,
          clientContribution: userProfileData.clientContribution,
          workerType: userProfileData.workerType,
          hoursPerWeek: userProfileData.hoursPerWeek,
          daysPerWeek: userProfileData.daysPerWeek,
          agencyFee: userProfileData.agencyFee,



          visaStatus: userProfileData.visaStatus,
          hrConsultant: userProfileData.hrConsultant,
          salesConsultant: userProfileData.salesConsultant,
          subscriberNote: userProfileData.subscriberNote,
          hrDealType: userProfileData.hrDealType,
          placementId: userProfileData.placementId,
          leaveTypes: userProfileData.leaveTypes,
          HSAProvider: userProfileData.HSAProvider,
          HSAAmount: userProfileData.HSAAmount,
          HSACarryOver: userProfileData.HSACarryOver,
          HSAWaitingPeriod: userProfileData.HSAWaitingPeriod,
          planStartDate: userProfileData.planStartDate,
          pensionNote: userProfileData.pensionNote,
          talnetClientContactId: userProfileData.talnetClientContactId,
          talnetCandidateId: userProfileData.talnetCandidateId,
          finmodCandidateId: userProfileData.finmodCandidateId,
          clientCap: userProfileData.clientCap,
          clientMatchStartDate: userProfileData.clientMatchStartDate,
          clientMatch: userProfileData.clientMatch,
          myMatchStartDate: userProfileData.myMatchStartDate,
          myMatch: userProfileData.myMatch,
          vestingPeriod: userProfileData.vestingPeriod,
          planNo: userProfileData.planNo,
          perksFrequency: userProfileData.perksFrequency,
          pensionFrequency: userProfileData.pensionPlanFrequency,
          pensionPlan: userProfileData.pensionPlan,
          leavePolicies: userProfileData.leavePolicies,
          contractorsCompanyName: userProfileData.contractorsCompanyName,
          GSTOrHSTTax: userProfileData.GSTOrHSTTax,
          GSTOrHSTTaxPercentage: userProfileData.GSTOrHSTTaxPercentage,
          GSTOrHSTTaxAmount: userProfileData.GSTOrHSTTaxAmount,
          taxNumber: userProfileData.taxNumber,
          workersCompExemption: userProfileData.workersCompExemption,
          workersWCBNumber: userProfileData.workersWCBNumber,
          overtimeExempt: userProfileData.overtimeExempt,
          salutation: userProfileData.salutation,
          emergencyFirstName:userProfileData.emergencyFirstName,
          emergencyLastName:userProfileData.emergencyLastName,
          relationShip: userProfileData.relationShip,
          emergencyHomePhone: userProfileData.emergencyHomePhone,
          emergencyEmail: userProfileData.emergencyEmail,
          clientBillingContactName: userProfileData.clientBillingContactName,
          noticePeriod: userProfileData.noticePeriod,
          exchangeRateForSalary: userProfileData.exchangeRateForSalary,
          paymentMethod: userProfileData.paymentMethod,
          employeeWage: userProfileData.employeeWage,
          salesConsultantId: userProfileData.salesConsultantId,
          hrConsultantId: userProfileData.hrConsultantId,
          approverList:userProfileData.approverList,
          middleName:userProfileData.middleName,
          addressType:userProfileData.addressType,
          emergencyPhoneWork:userProfileData.emergencyPhoneWork,
          empCountryId:userProfileData.empCountryId,
          empRegionId:userProfileData.empRegionId,
          noticePeriodUnit:userProfileData.noticePeriodUnit,
          vacationPay:userProfileData.vacationPay,
          employmentType:userProfileData.employmentType,
          hrFeeType:userProfileData.hrFeeType,
          hrEffectiveDate:userProfileData.hrEffectiveDate,
          hrPlacementFee: userProfileData.hrPlacementFee,
          hrPlacementFeeCurrency:userProfileData.hrPlacementFeeCurrency,
          hrFeeFrequency:userProfileData.hrFeeFrequency,
          hrPayComponentId:userProfileData.hrPayComponentId,
          hrPlacementInvoiceFee:userProfileData.hrPlacementInvoiceFee,
          ciPayComponentId:userProfileData.ciPayComponentId,
          ciEffectiveDay:userProfileData.ciEffectiveDay,
          ciPayPeriodFrequency:userProfileData.ciPayPeriodFrequency,
          ciOTpayRate:userProfileData.ciOTpayRate,
          ciOTbillRate:userProfileData.ciOTbillRate,
          ciSalaryOrRate:userProfileData.ciSalaryOrRate,
          ciCompensationCurrency:userProfileData.ciCompensationCurrency,
          ciPaymentMethod:userProfileData.ciPaymentMethod,
          ciOvertimeExempt:userProfileData.ciOvertimeExempt,
          ciPayRate: userProfileData.ciPayRate,
          ciBillRate: userProfileData.ciBillRate,
          employerPayrollTaxes:userProfileData.employerPayrollTaxes,
          retainerApplicable: userProfileData.retainerApplicable,
          rtPayType: userProfileData.rtPayType,
          rtSalaryOrRate: userProfileData.rtSalaryOrRate,
          rtCurrency: userProfileData.rtCurrency,
          rtPayComponentId: userProfileData.rtPayComponentId,
          rtEffectiveDate: userProfileData.rtEffectiveDate,
          empEffectiveDate : userProfileData.empEffectiveDate,
          rtReason: userProfileData.rtReason,
          rtPayRate: userProfileData.rtPayRate,
          retainerWeeks: userProfileData.retainerWeeks,

          biBenefitsApplicable: userProfileData.biBenefitsApplicable,
          biBenefitsCurrency: userProfileData.biBenefitsCurrency,
          biBenefitsDeductionFrequency: userProfileData.biBenefitsDeductionFrequency,
          biEmployerPayComponentId: userProfileData.biEmployerPayComponentId,
          biEmployer: userProfileData.biEmployer,
          biEmployeePayComponentId: userProfileData.biEmployeePayComponentId,
          biWorker: userProfileData.biWorker,
          biLtdAmount: userProfileData.biLtdAmount,
          biLifeAdnDAmount: userProfileData.biLifeAdnDAmount,
          biTotalTaxableBenefitsAmount: userProfileData.biTotalTaxableBenefitsAmount,
          biHealthAmount: userProfileData.biHealthAmount,
          biBenefitsMarkUp: userProfileData.biBenefitsMarkUp,
          biBenefitsMarkupAmount: userProfileData.biBenefitsMarkupAmount,
          biTotalMarkupAmount: userProfileData.biTotalMarkupAmount,

          rpPlanAvailable: userProfileData.rpPlanAvailable,
          rpPlanProvider: userProfileData.rpPlanProvider,
          rpMatchCapAmount: userProfileData.rpMatchCapAmount,
          rpWorkerContributionEndDate: userProfileData.rpWorkerContributionEndDate,
          rpWorkerContributionAmount: userProfileData.rpWorkerContributionAmount,
          rpWorkerPayComponentId: userProfileData.rpWorkerPayComponentId,
          rpClientContributionEndDate: userProfileData.rpClientContributionEndDate,
          rpClientContributionAmount: userProfileData.rpClientContributionAmount,
          rpClientPayComponentId: userProfileData.rpClientPayComponentId,
          rpMarkup: userProfileData.rpMarkup,
          rpCurrency: userProfileData.rpCurrency,
          rpTotalMarkupAmount: userProfileData.rpTotalMarkupAmount,
          rpMarkupAmount: userProfileData.rpMarkupAmount,
          rpTotalClientContribution: userProfileData.rpTotalClientContribution,
          rpPayrollTaxes: userProfileData.rpPayrollTaxes,

          HSAApplicable: userProfileData.HSAApplicable,
          HSAEndDate: userProfileData.HSAEndDate,
          HSAContributionCurrency: userProfileData.HSAContributionCurrency,
          HSAPayComponentId: userProfileData.HSAPayComponentId,
          HSAWaitingPeriodUnit: userProfileData.HSAWaitingPeriodUnit,
          HSAMarkup: userProfileData.HSAMarkup,
          HSAMarkupAmount: userProfileData.HSAMarkupAmount,

          EAPApplicable: userProfileData.EAPApplicable,
          EAPProvider: userProfileData.EAPProvider,
          EAPStartDate: userProfileData.EAPStartDate,
          EAPEndDate: userProfileData.EAPEndDate,
          EAPContributionCurrency: userProfileData.EAPContributionCurrency,
          EAPContributionAmount: userProfileData.EAPContributionAmount,
          EAPDeductionFrequency: userProfileData.EAPDeductionFrequency,
          EAPPayComponentId: userProfileData.EAPPayComponentId,
          EAPMarkup: userProfileData.EAPMarkup,
          EAPMarkupAmount: userProfileData.EAPMarkupAmount,

          benefitsInfos: userProfileData.benefitsInfos !== null ? JSON.stringify(userProfileData.benefitsInfos) : undefined,
          pensionInfos: userProfileData.pensionInfos !== null ? JSON.stringify(userProfileData.pensionInfos) : undefined,
          hrInfos: userProfileData.placementInfos !== null ? JSON.stringify(userProfileData.placementInfos) : undefined,
          compensationInfos: userProfileData.compensationInfos !== null ? JSON.stringify(userProfileData.compensationInfos) : undefined,
          retainerInfos: userProfileData.retainerInfos !== null ? JSON.stringify(userProfileData.retainerInfos) : undefined,
          EAPInfos: userProfileData.EAPInfos !== null ? JSON.stringify(userProfileData.EAPInfos) : undefined,
          HSAInfos: userProfileData.HSAInfos !== null ? JSON.stringify(userProfileData.HSAInfos) : undefined,
          pensionApplicable : userProfileData.pensionApplicable,
          benefitsApplicable : userProfileData.benefitsApplicable,
          retainerApplicable :userProfileData.retainerApplicable,
          EAPApplicable : userProfileData.EAPApplicable,
          HSAApplicable : userProfileData.HSAApplicable,
          
          //added by nipul
          roleClientLead: userProfileData.clientLeadFlag,
          roleWorkerLead: userProfileData.workerLeadFlag,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_USER_PROFILE,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_USER_PROFILE_ERROR,
    });
    return false;
  }
};
export const update_isSave_user_profile = (status) => async (dispatch) => {
  dispatch({
    type: UPDATE_IS_SAVE_USER_PROFILE,
    payload: status,
  });
}

export const update_user_profile = (userProfileData) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/updateUserProfile`,
      {
        params: {
          userId: userProfileData.userId,
          loginEmail: userProfileData.loginEmail,
          employmentStatus: userProfileData.employmentStatus,
          subStatus: userProfileData.subStatus,
          roleWorker: userProfileData.workerFlag,
          roleClientUser: userProfileData.clientUserFlag,
          roleClientAdmin: userProfileData.clientAdminFlag,
          roleSubscriberUser: userProfileData.subscriberUserFlag,
          roleSubscriberAdmin: userProfileData.subscriberAdminFlag,
          roleSystemAdmin: userProfileData.systemAdminFlag,
          roleClientHR: userProfileData.clientHRFlag,
          roleClientBilling: userProfileData.clientBillingFlag,
          roleSubscriberHR: userProfileData.subscriberHRFlag,
          roleSubscriberBilling: userProfileData.subscriberBillingFlag,
          roleSubscriberApiIntegration: userProfileData.subscriberApiIntegrationFlag,
          pronoun: userProfileData.pronoun,
          nickName: userProfileData.nickName,
          firstName: userProfileData.firstName,
          lastName: userProfileData.lastName,
          dob: userProfileData.dob,
          maritalStatus: userProfileData.maritalStatus,
          addressLine1: userProfileData.addressLine1,
          addressLine2: userProfileData.addressLine2,
          countryId: userProfileData.countryId,
          regionId: userProfileData.regionId,
          cityId: userProfileData.cityId,
          zip: userProfileData.zip,
          phoneHome: userProfileData.phoneHome,
          phoneWork: userProfileData.phoneWork,
          phoneWorkExt: userProfileData.phoneWorkExt,
          phoneMobile: userProfileData.phoneMobile,
          emailPersonal: userProfileData.emailPersonal,
          emailWork: userProfileData.emailWork,
          preferredEmail: userProfileData.preferredEmail,
          linkedIn: userProfileData.linkedIn,
          subscriberId: userProfileData.subscriberId,
          subscriberName: userProfileData.subscriberName,
          clientId: userProfileData.clientId,
          clientName: userProfileData.clientName,
          clientContactId: userProfileData.clientContactId,
          clientContactName: userProfileData.clientContactName,
          jobTitle: userProfileData.jobTitle,
          department: userProfileData.department,
          compensation: userProfileData.compensation,
          ciPayType: userProfileData.ciPayType,
          currency: userProfileData.currency,

          allowanceLiving: userProfileData.allowanceLiving,
          allowancePhone: userProfileData.allowancePhone,
          allowanceCar: userProfileData.allowanceCar,
          allowanceTypes: userProfileData.allowanceTypes,
          hireDate: userProfileData.hireDate,
          endDate: userProfileData.endDate,
          payrollId: userProfileData.payrollId,
          benefitsCoverage: userProfileData.benefitsCoverage,
          benefitsCertificateNo: userProfileData.benefitsCertificateNo,
          benefitsClass: userProfileData.benefitsClass,
          benefitsStartDate: userProfileData.benefitsStartDate,
          benefitsEndDate: userProfileData.benefitsEndDate,
          contribution: userProfileData.contribution,
          clientContribution: userProfileData.clientContribution,
          workerType: userProfileData.workerType,
          hoursPerWeek: userProfileData.hoursPerWeek,
          daysPerWeek: userProfileData.daysPerWeek,
          hrPlacementFee: userProfileData.hrPlacementFee,
          payRate: userProfileData.payRate,
          billRate: userProfileData.billRate,

          visaStatus: userProfileData.visaStatus,
          hrConsultant: userProfileData.hrConsultant,
          salesConsultant: userProfileData.salesConsultant,
          subscriberNote: userProfileData.subscriberNote,
          hrDealType: userProfileData.hrDealType,
          placementId: userProfileData.placementId,
          leaveTypes: userProfileData.leaveTypes,
          HSAProvider: userProfileData.HSAProvider,
          HSAAmount: userProfileData.HSAAmount,
          HSACarryOver: userProfileData.HSACarryOver,
          HSAWaitingPeriod: userProfileData.HSAWaitingPeriod,
          planStartDate: userProfileData.planStartDate,
          pensionNote: userProfileData.pensionNote,
          talnetClientContactId: userProfileData.talnetClientContactId,
          talnetCandidateId: userProfileData.talnetCandidateId,
          finmodCandidateId: userProfileData.finmodCandidateId,
          clientCap: userProfileData.clientCap,
          clientMatchStartDate: userProfileData.clientMatchStartDate,
          clientMatch: userProfileData.clientMatch,
          myMatchStartDate: userProfileData.myMatchStartDate,
          myMatch: userProfileData.myMatch,
          vestingPeriod: userProfileData.vestingPeriod,
          planNo: userProfileData.planNo,
          perksFrequency: userProfileData.perksFrequency,
          pensionPlanFrequency: userProfileData.pensionPlanFrequency,
          pensionPlan: userProfileData.pensionPlan,
          leavePolicies: userProfileData.leavePolicies,
          effectiveDate: userProfileData.effectiveDate,
          empEffectiveDate : userProfileData.empEffectiveDate,
          contractorsCompanyName: userProfileData.contractorsCompanyName,
          GSTOrHSTTax: userProfileData.GSTOrHSTTax,
          GSTOrHSTTaxPercentage: userProfileData.GSTOrHSTTaxPercentage,
          GSTOrHSTTaxAmount: userProfileData.GSTOrHSTTaxAmount,
          taxNumber: userProfileData.taxNumber,
          workersCompExemption: userProfileData.workersCompExemption,
          workersWCBNumber: userProfileData.workersWCBNumber,
          overtimeExempt: userProfileData.overtimeExempt,
          salutation: userProfileData.salutation,
          emergencyFirstName:userProfileData.emergencyFirstName,
          emergencyLastName:userProfileData.emergencyLastName,
          relationShip: userProfileData.relationship,
          emergencyHomePhone: userProfileData.emergencyHomePhone,
          emergencyEmail: userProfileData.emergencyEmail,
          clientBillingContactName: userProfileData.clientBillingContactName,
          noticePeriod: userProfileData.noticePeriod,
          exchangeRateForSalary: userProfileData.exchangeRateForSalary,
          paymentMethod: userProfileData.paymentMethod,
          employeeWage: userProfileData.employeeWage,
          approverList: userProfileData.approverList,
          middleName:userProfileData.middleName,
          addressType:userProfileData.addressType,
          emergencyPhoneWork:userProfileData.emergencyPhoneWork,
          empCountryId:userProfileData.empCountryId,
          empRegionId:userProfileData.empRegionId,
          employmentType:userProfileData.employmentType,
          noticePeriodUnit:userProfileData.noticePeriodUnit,
          vacationPay:userProfileData.vacationPay,
          hrFeeType:userProfileData.hrFeeType,
          hrEffectiveDate:userProfileData.hrEffectiveDate,
          hrPlacementFeeCurrency:userProfileData.hrPlacementFeeCurrency,
          hrFeeFrequency:userProfileData.hrFeeFrequency,
          hrPayComponentId:userProfileData.hrPayComponentId,
          hrPlacementInvoiceFee:userProfileData.hrPlacementInvoiceFee,
          ciPayComponentId:userProfileData.ciPayComponentId,
          ciEffectiveDay:userProfileData.ciEffectiveDay,
          ciPayPeriodFrequency:userProfileData.ciPayPeriodFrequency,
          ciOTpayRate:userProfileData.ciOTpayRate,
          ciOTbillRate:userProfileData.ciOTbillRate,
          ciSalaryOrRate:userProfileData.ciSalaryOrRate,
          ciCompensationCurrency:userProfileData.ciCompensationCurrency,
          ciPaymentMethod:userProfileData.ciPaymentMethod,
          ciOvertimeExempt:userProfileData.ciOvertimeExempt,
          ciPayRate: userProfileData.ciPayRate,
          ciBillRate: userProfileData.ciBillRate,

          rtPayType: userProfileData.rtPayType,
          rtSalaryOrRate: userProfileData.rtSalaryOrRate,
          rtCurrency: userProfileData.rtCurrency,
          rtPayComponentId: userProfileData.rtPayComponentId,
          rtEffectiveDate: userProfileData.rtEffectiveDate,
          rtReason: userProfileData.rtReason,
          rtPayRate: userProfileData.rtPayRate,
          retainerWeeks: userProfileData.retainerWeeks,

          biBenefitsApplicable: userProfileData.biBenefitsApplicable,
          biBenefitsCurrency: userProfileData.biBenefitsCurrency,
          biBenefitsDeductionFrequency: userProfileData.biBenefitsDeductionFrequency,
          biEmployerPayComponentId: userProfileData.biEmployerPayComponentId,
          biEmployer: userProfileData.biEmployer,
          biEmployeePayComponentId: userProfileData.biEmployeePayComponentId,
          biWorker: userProfileData.biWorker,
          biLtdAmount: userProfileData.biLtdAmount,
          biLifeAdnDAmount: userProfileData.biLifeAdnDAmount,
          biTotalTaxableBenefitsAmount: userProfileData.biTotalTaxableBenefitsAmount,
          biHealthAmount: userProfileData.biHealthAmount,
          biBenefitsMarkUp: userProfileData.biBenefitsMarkUp,
          biBenefitsMarkupAmount: userProfileData.biBenefitsMarkupAmount,
          biTotalMarkupAmount: userProfileData.biTotalMarkupAmount,

          rpPlanAvailable: userProfileData.rpPlanAvailable,
          rpPlanProvider: userProfileData.rpPlanProvider,
          rpMatchCapAmount: userProfileData.rpMatchCapAmount,
          rpWorkerContributionEndDate: userProfileData.rpWorkerContributionEndDate,
          rpWorkerContributionAmount: userProfileData.rpWorkerContributionAmount,
          rpWorkerPayComponentId: userProfileData.rpWorkerPayComponentId,
          rpClientContributionEndDate: userProfileData.rpClientContributionEndDate,
          rpClientContributionAmount: userProfileData.rpClientContributionAmount,
          rpClientPayComponentId: userProfileData.rpClientPayComponentId,
          rpMarkup: userProfileData.rpMarkup,
          rpMarkupAmount: userProfileData.rpMarkupAmount,

          HSAApplicable: userProfileData.HSAApplicable,
          HSAEndDate: userProfileData.HSAEndDate,
          HSAContributionCurrency: userProfileData.HSAContributionCurrency,
          HSAPayComponentId: userProfileData.HSAPayComponentId,
          HSAWaitingPeriodUnit: userProfileData.HSAWaitingPeriodUnit,
          HSAMarkup: userProfileData.HSAMarkup,
          HSAMarkupAmount: userProfileData.HSAMarkupAmount,

          EAPApplicable: userProfileData.EAPApplicable,
          EAPProvider: userProfileData.EAPProvider,
          EAPStartDate: userProfileData.EAPStartDate,
          EAPEndDate: userProfileData.EAPEndDate,
          EAPContributionCurrency: userProfileData.EAPContributionCurrency,
          EAPContributionAmount: userProfileData.EAPContributionAmount,
          EAPDeductionFrequency: userProfileData.EAPDeductionFrequency,
          EAPPayComponentId: userProfileData.EAPPayComponentId,
          EAPMarkup: userProfileData.EAPMarkup,
          EAPMarkupAmount: userProfileData.EAPMarkupAmount,
          
          benefitsInfos: JSON.stringify(userProfileData.benefitsInfos),
          pensionInfos: JSON.stringify(userProfileData.pensionInfos),
          placementInfos:JSON.stringify(userProfileData.placementInfos),
          compensationInfos:JSON.stringify(userProfileData.compensationInfos),
          EAPInfos:JSON.stringify(userProfileData.EAPInfos),
          HSAInfos:JSON.stringify(userProfileData.HSAInfos),
          pensionApplicable : userProfileData.pensionApplicable,
          benefitsApplicable : userProfileData.benefitsApplicable,
          benefitsApplicable : userProfileData.benefitsApplicable,
          retainerApplicable :userProfileData.retainerApplicable,
          EAPApplicable : userProfileData.EAPApplicable,
          HSAApplicable : userProfileData.HSAApplicable,
          //added by nipul
          roleClientLead: userProfileData.clientLeadFlag,
          roleWorkerLead: userProfileData.workerLeadFlag,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: UPDATE_USER_PROFILE_ERROR,
    });
  }
};

export const set_user_account_status = (userId, status) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/setUserAccountStatus?userId=${userId}&status=${status}`
    );
    dispatch({
      type: SET_USER_ACCOUNT_STATUS,
      payload: {
        message: (status) ? 'Account Activated' : 'Account Deactivated',
        success: 1,
        accountStatus: status
      },
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SET_USER_ACCOUNT_STATUS_ERROR,
    });
  }
};
export const get_employment_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getEmploymentStatusList`);
    dispatch({
      type: GET_EMPLOYMENT_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EMPLOYMENT_STATUS_LIST_ERROR,
    });
  }
};
export const get_employment_sub_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getEmploymentSubstatusList`);
    dispatch({
      type: GET_EMPLOYMENT_SUB_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EMPLOYMENT_SUB_STATUS_LIST_ERROR,
    });
  }
};
export const get_subscriber_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getSubscriberList`);
    dispatch({
      type: GET_SUBSCRIBER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUBSCRIBER_LIST_ERROR,
    });
  }
};
export const get_salesconsultant = () => async (dispatch) => {

  try {
    const res = await api.get(`api/subscriber/getUserSalesConsultantList`);
    dispatch({
      type: GET_SALESCONSULTANT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SALESCONSULTANT_LIST_ERROR,
    });
  }
};
export const get_hr_Consultant = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getUserHrConsultantList`);
    dispatch({
      type: GET_HR_CONSULTANT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_HR_CONSULTANT_ERROR,
    });
  }
};
export const get_client_list = (subscriberId = "") => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getClientList`, {
      params: {
        subscriberId: subscriberId
      }
    });
    dispatch({
      type: GET_CLIENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_LIST_ERROR,
    });
  }
};
export const get_user_client_contact_list = (clientId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientContactList?clientId=${clientId}`
    );
    dispatch({
      type: GET_CLIENT_CONTACT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_CONTACT_LIST_ERROR,
    });
  }
};
export const get_client_contact_list = (subscriberId, clientId, cancelToken) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientContactList?subscriberId=${subscriberId}&clientId=${clientId}`,
      {cancelToken: cancelToken}
    );
    dispatch({
      type: GET_CLIENT_CONTACT_LIST,
      payload: res.data,
    });
  } catch (err) {
    if (axios.isCancel(err))
      return
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_CONTACT_LIST_ERROR,
    });
  }
};
export const clear_client_contact_list = () => async (dispatch) => {
  
    dispatch({
      type: CLEAR_CLIENT_CONTACT_LIST,
      payload: [],
    });
  
};
export const get_benefits_coverage_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getBenefitsCoverageList`);
    dispatch({
      type: GET_BENEFITS_COVERAGE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_BENEFITS_COVERAGE_LIST_ERROR,
    });
  }
};

export const get_perks_frequency_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getPerksFrequencyList`);
    dispatch({
      type: GET_PERKS_FREQUENCY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PERKS_FREQUENCY_LIST_ERROR,
    });
  }
};

export const get_pension_plan_frequency_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getPensionPlanFrequencyList`);
    dispatch({
      type: GET_PENSION_PLAN_FREQUENCY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PENSION_PLAN_FREQUENCY_LIST_ERROR,
    });
  }
};

export const get_pension_plan_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getRetirementPensionPlanList`);
    dispatch({
      type: GET_PENSION_PLAN_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PENSION_PLAN_LIST_ERROR,
    });
  }
};

export const get_preferred_email_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getPreferredEmailList`);
    dispatch({
      type: GET_PREFERRED_EMAIL_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PREFERRED_EMAIL_LIST_ERROR,
    });
  }
};
export const get_benefits_class_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getBenefitsClassList`);
    dispatch({
      type: GET_BENEFITS_CLASS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_BENEFITS_CLASS_LIST_ERROR,
    });
  }
};

export const get_marital_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getMaritalStatusList`);
    dispatch({
      type: GET_MARITAL_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MARITAL_STATUS_LIST_ERROR,
    });
  }
};

export const get_pronoun_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getPronounList`);
    dispatch({
      type: GET_PRONOUN_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PRONOUN_LIST_ERROR,
    });
  }
};
export const search_leaves = (
  userId,
  clientName,
  workerName,
  fromDate,
  toDate,
  status,
  leaveType,
  empStatus
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/searchLeaves`,
      {
        params: {
          userId: userId,
          clientName: clientName,
          workerName: workerName,
          fromDate: fromDate,
          toDate: toDate,
          status: status,
          leaveType: leaveType,
          empStatus : empStatus
        }
      }
    );
    dispatch({
      type: SEARCH_LEAVES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_LEAVES_ERROR,
    });
  }
};
export const get_subs_leave_details = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getLeaveDetails?refNum=${refNum}`
    );

    dispatch({
      type: GET_SUB_LEAVE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_LEAVE_DETAILS_ERROR,
    });
  }
};

export const clear_sub_leave_details = () => async (dispatch) => {
  dispatch({
    type: GET_SUB_LEAVE_DETAILS_ERROR,
  });
}

export const get_subs_expense_reports = (
  userId,
  clientName,
  workerName,
  from,
  to,
  reportStatus,
  empStatus
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getExpenseReports`,
      {
        params: {
          userId: userId,
          clientName: clientName,
          workerName: workerName,
          from: from,
          to: to,
          status: reportStatus,
          empStatus : empStatus
        }
      }
    );
    dispatch({
      type: GET_SUB_EXPENSE_REPORTS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_EXPENSE_REPORTS_ERROR,
    });
  }
};

export const sub_withdraw_leave = (refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/withdrawLeave?refNum=${refNumber}`
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SUB_WITHDRAW_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SUB_WITHDRAW_LEAVE_ERROR,
    });
  }
};
export const sub_save_leave_details = (
  userId,
  refNumber,
  leavePolicyId,
  from,
  to,
  leaveHours,
  notes,
  subscriberNote
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveLeaveDetails`,
      {
        params: {
          userId: userId,
          refNum: refNumber,
          leavePolicyId: leavePolicyId,
          fromDate: from,
          toDate: to,
          leaveHours: leaveHours,
          notes: notes,
          subscriberNotes: subscriberNote
        }
      }
    );

    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SUB_SUBMIT_LEAVE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SUB_SUBMIT_LEAVE_DETAILS_ERROR,
    });
  }
};
export const get_sub_leaves_types = () => async (dispatch) => {
  try {
    const res = await api.get("api/subscriber/getLeaveTypesList");

    dispatch({
      type: GET_SUB_LEAVE_TYPES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_LEAVE_TYPES_ERROR,
    });
  }
};
export const get_sub_client_list = (clientId, clientName, colorCode, status) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientList`,
      {
        params: {
          clientId: clientId,
          clientName: clientName,
          colorCode: colorCode,
          status: status
        }
      }
    );
    dispatch({
      type: GET_SUB_CLIENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_CLIENT_LIST_ERROR,
    });
  }
};
export const delete_client = (
  clientId,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/deleteClientProfile?clientId=${clientId}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_CLIENT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:DELETE_CLIENT_ERROR,
    });
  }
};

export const delete_user = (
  userId,
) => async (dispatch) => {
  try {
   const res = await api.get(`api/subscriber/deleteUserProfile?userId=${userId}`);

    dispatch({
      type: DELETE_USER,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:DELETE_USER_ERROR,
    });
  }
};
export const userHasExistingData = (
  userId,
) => async (dispatch) => {
  try {

    const res = await api.get(`api/subscriber/userHasExistingData?userId=${userId}`);

    dispatch({
      type: USER_HAS_EXISTING_DATA,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:USER_HAS_EXISTING_DATA_ERROR,
    });
  }
};

export const get_client_profile = (subscriberId, clientId, cancelToken) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientProfile?subscriberId=${subscriberId}&clientId=${clientId}`,
      {cancelToken: cancelToken}
    );
    dispatch({
      type: GET_CLIENT_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    if (axios.isCancel(err))
      return
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_PROFILE_ERROR,
    });
  }
};
export const save_client_profile = (
  clientId,
  clientName,
  operatingName,
  benefitsSectionId,
  businessActivity,
  status,
  colorCode,
  WSIBCode,
  WSIBIndustry,
  addressLine1,
  addressLine2,
  countryId,
  regionId,
  cityId,
  zip,
  contactNumber,
  linkedIn,
  website,
  clientNotes,
  hrConsultantId,
  salesConsultantId,
  talnetClientId,
  finmodClientId,
  HSAType,
  benefitsType,
  expenseType,
  HSAAmount,
  earningType,
  earningAmount,
  eapType,
  eapAmount,
  pensionPlanType,
  pensionPlanAmount,
  benefitsAmount,
  expenseAmount,
  companyLogoUrl,
  startDate,
  endDate,
  weekEndingDay,
  paymentMethod,
  clientExpenseTaxRate,
  glAccountNumber,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveClientProfile`,
      {
        params: {
          clientId: clientId,
          clientName: clientName,
          operatingName: operatingName,
          benefitsSectionId: benefitsSectionId,
          businessActivity: businessActivity,
          colorCode: colorCode,
          WSIBCode: WSIBCode,
          status:status,
          WSIBIndustry: WSIBIndustry,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          countryId: countryId,
          regionId: regionId,
          cityId: cityId,
          zip: zip,
          contactNumber: contactNumber,
          linkedIn: linkedIn,
          website: website,
          clientNotes: clientNotes,
          talnetClientId: talnetClientId,
          finmodClientId: finmodClientId,
          hrConsultantId: hrConsultantId,
          salesConsultantId: salesConsultantId,
          HSAType: HSAType,
          benefitsType: benefitsType,
          expenseType: expenseType,
          HSAAmount: HSAAmount,
          earningType: earningType,
          earningAmount: earningAmount,
          eapType: eapType,
          eapAmount: eapAmount,
          pensionPlanType: pensionPlanType,
          pensionPlanAmount: pensionPlanAmount,
          benefitsAmount: benefitsAmount,
          expenseAmount: expenseAmount,
          companyLogoUrl: companyLogoUrl,
          startDate: startDate,
          endDate: endDate,
          weekEndingDay: weekEndingDay,
          paymentMethod: paymentMethod,
          clientExpenseTaxRate: clientExpenseTaxRate,
          glAccount: glAccountNumber,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_CLIENT_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_CLIENT_PROFILE_ERROR,
    });
  }
};
export const get_worker_for_client_list = (subscriberId, clientId, cancelToken) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getWorkersForClientList?subscriberId=${subscriberId}&clientId=${clientId}`,
      {cancelToken: cancelToken}
    );
    dispatch({
      type: GET_WORKER_FOR_CLIENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    if (axios.isCancel(err))
      return
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_FOR_CLIENT_LIST_ERROR,
    });
  }
};

export const get_mileage_range_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/get-mileage-rate-list`);
    dispatch({
      type: GET_MILEAGE_RANGE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MILEAGE_RANGE_LIST_ERROR,
    });
  }
};
export const get_leave_type_detail = (leaveTypeId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getLeaveTypeDetails?leaveTypeId=${leaveTypeId}`
    );
    dispatch({
      type: GET_LEAVE_TYPE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_TYPE_DETAILS_ERROR,
    });
  }
};
export const clear_leave_type_detail = () => async (dispatch) => {
  dispatch({
    type: GET_LEAVE_TYPE_DETAILS_ERROR,
  });
};
export const save_leave_type_detail = (
  leaveTypeId,
  leaveTypeName,
  policyRequiredFlag,
  entitledFlag,
  entitledDays
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveLeaveTypeDetails`,
      {
        params: {
          leaveTypeId: leaveTypeId,
          leaveTypeName: leaveTypeName,
          policyRequiredFlag: policyRequiredFlag,
          entitledFlag: entitledFlag,
          entitledDays: entitledDays,
        }
      }
    );
    dispatch({
      type: SAVE_LEAVE_TYPE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_TYPE_DETAILS_ERROR,
    });
  }
};
export const get_mileage_range_detail = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/get-mileage-rate-detail?refNum=${refNum}`
    );
    dispatch({
      type: GET_MILEAGE_RANGE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MILEAGE_RANGE_DETAILS_ERROR,
    });
  }
};
export const save_mileage_range_detail = (data) => async (dispatch) => {
  try {

    const res = await api.post(`api/subscriber/save-mileage-rate-details`,data);
    // const res = await api.get(
    //   `api/subscriber/saveMileageRangeDetails?refNum=${refNum}&rate=${rate}&start=${start}&end=${end}`
    // );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_MILEAGE_RANGE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_MILEAGE_RANGE_DETAILS_ERROR,
    });
  }
};
export const get_sysadmin_subscriber_list = (
  subscriberId,
  subscriberName
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/sysadmin/getSubscriberList`, {
        params: {
          subscriberId: subscriberId,
          subscriberName: subscriberName,
        }
      }
    );
    dispatch({
      type: GET_SYSADMIN_SUBSCRIBER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SYSADMIN_SUBSCRIBER_LIST_ERROR,
    });
  }
};
export const get_sysadmin_subscriber_user_list = (subscriberId) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/sysadmin/getSubscriberUserList?subscriberId=${subscriberId}`
    );
    dispatch({
      type: GET_SYSADMIN_SUBSCRIBER_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SYSADMIN_SUBSCRIBER_USER_LIST_ERROR,
    });
  }
};

export const get_sysadmin_subscriber_profile = (subscriberId) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/sysadmin/getSubscriberProfile?subscriberId=${subscriberId}`
    );
    dispatch({
      type: GET_SYSADMIN_SUBSCRIBER_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SYSADMIN_SUBSCRIBER_PROFILE_ERROR,
    });
  }
};

export const clear_sysadmin_subscriber_profile = () => async (
  dispatch
) => {
  dispatch({
    type: CLEAR_SYSADMIN_SUBSCRIBER_PROFILE,
  });
};

export const save_sysadmin_subscriber_profile = (
  subscriberId,
  subscriberName,
  addressLine1,
  addressLine2,
  country,
  state,
  city,
  zip,
  contactNumber,
  linkedIn,
  website,
  finmodCompanyId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/sysadmin/saveSubscriberProfile`,
      {
        params: {
          subscriberId: subscriberId,
          subscriberName: subscriberName,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          country: country,
          state: state,
          city: city,
          zip: zip,
          contactNumber: contactNumber,
          linkedIn: linkedIn,
          website: website,
          finmodCompanyId: finmodCompanyId,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_SYSADMIN_SUBSCRIBER_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_SYSADMIN_SUBSCRIBER_PROFILE_ERROR,
    });
  }
};
export const search_sysadmin_user_list = (
  subscriberName,
  clientName,
  userId,
  userName,
  email,
  substatus,
  role,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/sysadmin/searchUserList`,
      {
        params: {
          userId: userId,
          subscriberName: subscriberName,
          clientName: clientName,
          userName: userName,
          email: email,
          substatus: substatus,
          role: role,
        }
      }
    );
    dispatch({
      type: SEARCH_SYSADMIN_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, 'danger'));
    }
    dispatch({
      type: SEARCH_SYSADMIN_USER_LIST_ERROR,
    });
  }
};

export const get_city_detailed_list = (regionId, countryid) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/general/getCityDetailedList?regionId=${regionId}&countryid=${countryid}`
    );
    dispatch({
      type: GET_CITY_DETAILED_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CITY_DETAILED_LIST_ERROR,
    });
  }
};
export const save_city_details = (cityId, cityName, stateProvId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/sysadmin/saveCityDetails`,
      {
        params: {
          cityId: cityId,
          cityName: cityName,
          stateProvId: stateProvId,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_CITY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_CITY_DETAILS_ERROR,
    });
  }
};
export const approve_leave = (refNumber, subscriberNote) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/approveLeave`,
      {
        params: {
          refNum: refNumber,
          subscriberNote: subscriberNote,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: APPROVE_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: APPROVE_LEAVE_ERROR,
    });
  }
};

export const reject_leave = (refNumber, subscriberNote) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/rejectLeave`,
      {
        params: {
          refNum: refNumber,
          subscriberNote: subscriberNote,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: REJECT_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: REJECT_LEAVE_ERROR,
    });
  }
};

export const set_user_password = (userId, password) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/setUserPassword?userId=${encodeURIComponent(userId)}&password=${encodeURIComponent(password)}`
    );
    dispatch({
      type: SET_USER_PASSWORD,
      payload:
        {
          message: 'Password changed',
          success: 1
        },
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SET_USER_PASSWORD_ERROR,
    });
  }
};


export const get_allowance_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getAllowanceTypeList`);
    dispatch({
      type: GET_ALLOWANCE_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ALLOWANCE_TYPE_LIST_ERROR,
    });
  }
};

export const get_expense_config_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getExpenseTypeList`);
    dispatch({
      type: GET_EXPENSE_CONFIG_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EXPENSE_CONFIG_TYPE_LIST_ERROR,
    });
  }
};

export const get_allowance_type_details = (
  allowanceTypeId,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getAllowanceTypeDetails?allowanceTypeId=${allowanceTypeId}`);
    dispatch({
      type: GET_ALLOWANCE_TYPE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ALLOWANCE_TYPE_DETAILS_ERROR,
    });
  }
};

export const clear_allowance = () => async (dispatch) => {
  dispatch({
    type: GET_ALLOWANCE_TYPE_DETAILS_ERROR,
  });
};
export const save_allowance_type_details = (
  allowanceTypeId,
  allowanceType,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/saveAllowanceTypeDetails`, {
      params: {
        allowanceTypeId,
        allowanceType
      }
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};
export const delete_allowance_type_details = (
  allowanceTypeId,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/deleteAllowanceTypeDetails?allowanceTypeId=${allowanceTypeId}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_ALLOWANCE_TYPE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_ALLOWANCE_TYPE_DETAILS_ERROR,
    });
  }
};

export const get_expense_type_details = (
  expenseTypeId,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getExpenseTypeDetails?expenseTypeId=${expenseTypeId}`);
    dispatch({
      type: GET_EXPENSE_TYPE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EXPENSE_TYPE_DETAILS_ERROR,
    });
  }
};
export const clear_expense_type = () => async (dispatch) => {
  dispatch({
    type: GET_EXPENSE_TYPE_DETAILS_ERROR,
  });
};
export const save_expense_type_details = (
  expenseTypeId,
  expenseType,
  finmodPayTypeId
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/saveExpenseTypeDetails`, {
      params: {
        expenseTypeId,
        expenseType,
        finmodPayTypeId
      }
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};
export const delete_expense_type_details = (
  expenseTypeId,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/deleteExpenseType?expenseTypeId=${expenseTypeId}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_EXPENSE_TYPE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_EXPENSE_TYPE_DETAILS_ERROR,
    });
  }
};

export const clear_mileage_range_detail = () => async (dispatch) => {
  dispatch({
    type: GET_MILEAGE_RANGE_DETAILS_ERROR,
  });
};

export const get_search_leave_policy = (
  policyId = "",
  policyName = "",
  leaveCarryover = "",
  paidTimeOff = ""
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/searchLeavePolicy`, {
      params: {
        policyId: policyId,
        policyName: policyName,
        leaveCarryover: leaveCarryover,
        paidTimeOff: paidTimeOff,
      }
    });
    dispatch({
      type: GET_SEARCH_LEAVE_POLICY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SEARCH_LEAVE_POLICY_ERROR,
    });
  }
};


export const get_leave_quota_reset_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getLeaveQuotaResetTypeList`);
    dispatch({
      type: GET_LEAVE_QUOTA_RESET_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_QUOTA_RESET_TYPE_LIST_ERROR,
    });
  }
};

export const get_leave_period_string_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getLeavePeriodStringList`);
    dispatch({
      type: GET_LEAVE_PERIOD_STRING_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_PERIOD_STRING_LIST_ERROR,
    });
  }
};


export const get_leave_accrual_date_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getLeaveAccrualDateTypeList`);
    dispatch({
      type: GET_LEAVE_ACCRUAL_DATE_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_ACCRUAL_DATE_TYPE_LIST_ERROR
    });
  }
}
export const get_client_leave_policy_list = (subscriberId, clientId, cancelToken) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/getClientLeavePolicyList?subscriberId=${subscriberId}&clientId=${clientId}`, {cancelToken: cancelToken});
    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    if (axios.isCancel(err))
      return
    const success = err.response.data.success;
    if (!success && err.response.status !== 401) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_LIST_ERROR,
    });
  }
};


export const get_leave_accrual_every_string_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getLeaveAccrualEveryStringList`);
    dispatch({
      type: GET_LEAVE_ACCRUAL_EVERY_STRING_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_ACCRUAL_EVERY_STRING_LIST_ERROR
    });
  }
};
export const delete_client_leave_policy_list = (
  clientId,
  leavePolicyId
) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/deleteClientLeavePolicyDetails?clientId=${clientId}&leavePolicyId=${leavePolicyId}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_CLIENT_LEAVE_POLICY_LIST_SUCCESS,
      payload: {leavePolicyId: leavePolicyId},
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_CLIENT_LEAVE_POLICY_LIST_ERROR,
    });
  }
};


export const get_leave_pay_out_time_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getLeavePayOutTimeTypeList`);
    dispatch({
      type: GET_LEAVE_PAY_OUT_TIME_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_PAY_OUT_TIME_TYPE_LIST_ERROR,
    });
  }
};
export const get_client_leave_policy_detail = (leavePolicyId) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/getClientLeavePolicyDetails?leavePolicyId=${leavePolicyId}`);
    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_DETAIL_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_DETAIL_ERROR,
    });
  }
};

export const get_leave_pay_out_limit_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getLeavePayOutLimitTypeList`);
    dispatch({
      type: GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST_ERROR,
    });
  }
};

export const clear_holiday_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR__HOLIDAYS_DETAILS,
  });
};
export const clear_client_profile = () => async (dispatch) => {
  dispatch({
    type: CLEAR__CLIENT_PROFILE,
  });
};
export const clear_currency_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CURRENCY_DETAILS,
  });
};
export const clear_city_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CITY_DETAILS,
  });
};

export const get_calculated_leave_info = (from, to, leaveDays, leaveHours, userId,leavePolicyId) => async (dispatch) => {
  try {
    const res = await api.get(`api/leaves/getCalculatedLeaveInfo`, {
      params: {
        from: from,
        to: to,
        leaveDays: leaveDays,
        leaveHours: leaveHours,
        userId: userId,
        leavePolicyId:leavePolicyId
      }
    });

    dispatch({
      type: GET_CALCULATED_LEAVE_INFO,
      payload: res.data,
    });
    dispatch({
      type: ENABLE_LEAVE_SUBMIT,
      payload: false,
    });
  } catch (err) {

    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CALCULATED_LEAVE_INFO_ERROR,
    });
    dispatch({
      type: ENABLE_LEAVE_SUBMIT,
      payload: true,
    });
  }
};

export const clear_calculated_leave_info = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CALCULATED_LEAVE_INFO,
  });
};


export const clear_client_leaves_list = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENT_LEAVES_LIST,
  });
};

export const get_message_category_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getMessageCategoryList`);
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST_ERROR,
    });
  }
};

export const client_get_message_category_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/client/getMessageCategoryList`);
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST_ERROR,
    });
  }
};

export const get_message_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getMessageStatusList`);
    dispatch({
      type: GET_MESSAGE_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_hr_consultant_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getHrConsultantList`);
    dispatch({
      type: GET_HR_CONSULTANT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const sub_search_conversations = (workerName, clientContact, clientName, hrConsultant, categoryName, status, from, to) => async (dispatch) => {
  try {
    let params = {};
    if (workerName)
      params.workerName = workerName;
    if (clientContact)
      params.clientContact = clientContact;
    if (clientName)
      params.clientName = clientName;
    if (categoryName)
      params.categoryName = categoryName;
    if (status)
      params.status = status;
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if (hrConsultant)
      params.hrConsultant = hrConsultant;
    const res = await api.get(`api/subscriber/searchConversation`, {
      params: params
    });
    dispatch({
      type: SEARCH_CONVERSATIONS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const sub_search_conversations_paginated = (workerName, clientContact, clientName, hrConsultant, categoryName, status,assigned, assignedTo ,from, to, empStatus, pageSize=10,pageNum=1,sort=1,fieldName='refNum') => async (dispatch) => {

  try {
    let params = {pageSize,pageNum};
    if (workerName)
      params.workerName = workerName;
    if (clientContact)
      params.clientContact = clientContact;
    if (clientName)
      params.clientName = clientName;
    if (categoryName)
      params.categoryName = categoryName;
    if (status)
      params.status = status;
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    if(assigned)
      params.assigned = assigned;
    if(assignedTo)
       params.assignedTo = assignedTo;

    params.sort = sort;
    if(fieldName)
       params.fieldName = fieldName;

    if (hrConsultant)
      params.hrConsultant = hrConsultant;
      if(empStatus)
        params.empStatus = empStatus;
    const res = await api.get(`api/subscriber/searchConversationPaged`, {
      params: params
    });
    dispatch({
      type: SEARCH_CONVERSATIONS_PAGINATED,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_search_conversations = (categoryName, status, from, to) => async (dispatch) => {
  try {
    let params = {};
    if (categoryName)
      params.categoryName = categoryName;
    if (status)
      params.status = status;
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    const res = await api.get(`api/client/searchConversation`, {
      params: params
    });
    dispatch({
      type: SEARCH_CONVERSATIONS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_message_category_role_list = (
  role
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getMessageCategoryList?role=${role}`);
    dispatch({
      type: GET_MESSAGE_CATEGORY_ROLE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_CATEGORY_ROLE_LIST_ERROR,
    });
  }
};

export const clear_message_category = () => async (dispatch) => {
  dispatch({
    type: GET_MESSAGE_CATEGORY_DETAILS_ERROR,
  });
};
export const save_message_category_details = (
  categoryId,
  categoryName,
  workerRoleFlag
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/saveMessageCategoryDetails?categoryId=${categoryId}&categoryName=${categoryName}&workerRoleFlag=${workerRoleFlag}&clientRoleFlag=${!workerRoleFlag}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_MESSAGE_CATEGORY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_MESSAGE_CATEGORY_DETAILS_ERROR,
    });
  }
};
export const delete_message_category_details = (
  id,
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/deleteMessageCategoryDetails?categoryId=${id}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_MESSAGE_CATEGORY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_MESSAGE_CATEGORY_DETAILS_ERROR,
    });
  }
};

export const get_message_category_details = (
  id
) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getMessageCategoryDetails?categoryId=${id}`);
    dispatch({
      type: GET_MESSAGE_CATEGORY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_CATEGORY_DETAILS_ERROR,
    });
  }
};


export const sub_get_conversation_detail = (refNum) => async (dispatch) => {
  try {
    let res = await api.get(`api/subscriber/getConversationDetail?refNum=${refNum}`);
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const search_user_messages = (userId, categoryName, status, keywords) => async (dispatch) => {
  try {
    let params = {userId};

    if (categoryName)
      params.categoryName = categoryName;
    if (status)
      params.status = status;
    if (keywords)
      params.keywords = keywords;
    const res = await api.get(`api/subscriber/searchUserMessages`, {
      params: params
    });
    dispatch({
      type: SEARCH_USER_MESSAGES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const upload_attachment = (file, userId, subPath) => async(dispatch)=>{
 
  try{
    let formData = new FormData();
    formData.append('file', file);
    const res = await api({
      method: "post",
      url: `api/${subPath}/uploadMessageAttachment?userId=${userId}`,
      data: formData,
      headers: {"Content-Type": "multipart/form-data"},
    });

    dispatch({
      type: UPLOAD_CONVERSATION_ATTACHMENT,
      payload: res.data.fileName,
    });

    return res.data.fileName;

  }
  catch(err){
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }

    return null;
  }
  

}

export const get_message_attachment_file = (userId, refNumber,messageId, systemFileName, subPath) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `/api/${subPath}/viewAttachmentFile/?messageId=${messageId}&systemFileName=${systemFileName}&refNum=${refNumber}&userId=${userId}`
    );
    dispatch({
      type: GET_MESSAGE_ATTACHMENT_FILE,
      payload:{[systemFileName] : res.data.file},
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_ATTACHMENT_FILE_ERROR,
    });
  }
};

export const delete_message_attachment = (userId, refNumber,messageId, systemFileName, subPath) => async (dispatch) => {
  try {
    const res = await api.get(`/api/${subPath}/deleteAttachmentFile/?messageId=${messageId}&systemFileName=${systemFileName}&refNum=${refNumber}&userId=${userId}`);
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
    dispatch(setAlert("Attachment deleted!", "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const remove_attachment = (userId, systemFileName, subPath) => async (dispatch) => {
  
  try {
    const res = await api.get(`/api/${subPath}/removeAttachmentFile/?&systemFileName=${systemFileName}&userId=${userId}`);

    return res;
  } catch (err) {
    return err.response.data;
  }
};
export const remove_all_attachments = (userId, systemFileNames, subPath) => async (dispatch) =>{
  try {
    const res = await api.get(`/api/${subPath}/removeAllAttachmentFiles/?&systemFileNames=${systemFileNames}&userId=${userId}`);

    return res;
  } catch (err) {
    return err.response.data;
  }
}
export const sub_send_message = (refNum, userId, categoryName, categoryId, status, message,userRole, attachmentFileNames ) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/saveConversationDetail`, {
      params: {
        attachmentFileNames,
        refNum,
        userId,
        categoryName,
        categoryId,
        status,
        message,
        userRole,
        
      }
    });
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
    dispatch(setAlert("Message sent!", "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const sub_set_conversation_status = (userId, refNum, status,notify=true) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/saveConversationDetail`, {
      params: {
        userId,
        refNum,
        status
      }
    });
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
    if(notify)
      dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const clear_conversation_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CONVERSATION_DETAILS
  });
};


export const client_get_conversation_detail = (refNum) => async (dispatch) => {
  try {
    let res = await api.get(`api/client/getConversationDetail?refNum=${refNum}`);
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_send_message = (refNum, categoryId, message, attachmentFileNames) => async (dispatch) => {
  try {
    let params={}
    if(refNum)
      params.refNum = refNum;
    if(categoryId)
      params.categoryId = categoryId;
    if(message)
      params.message = message;
    const res = await api.get(`api/client/saveConversationDetail`, {
      params: {
        refNum,
        categoryId,
        message,
        attachmentFileNames
      }
    });
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
    dispatch(setAlert("Message sent!", "success"))
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};
export const worker_search_conversations = (categoryName, status, from, to) => async (dispatch) => {
  try {
    let params = {};
    if (categoryName)
      params.categoryName = categoryName;
    if (status)
      params.status = status;
    if (from)
      params.from = from;
    if (to)
      params.to = to;
    const res = await api.get(`api/user/searchConversation`, {
      params: params
    });
    dispatch({
      type: SEARCH_CONVERSATIONS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_set_conversation_status = (refNum, status) => async (dispatch) => {
  try {
    const res = await api.get(`api/client/saveConversationDetail`, {
      params: {
        refNum,
        status
      }
    });
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }

  }
};

export const worker_get_message_category_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/user/getMessageCategoryList`);
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST_ERROR,
    });
  }
};


export const user_get_conversation_detail = (refNum) => async (dispatch) => {
  try {
    let res = await api.get(`api/user/getConversationDetail?refNum=${refNum}`);
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const user_send_message = (refNum, categoryId, message, attachmentFileNames) => async (dispatch) => {
  try {
    let params={}
    if(refNum)
      params.refNum = refNum;
    if(categoryId)
      params.categoryId = categoryId;
    if(message)
      params.message = message;
    const res = await api.get(`api/user/saveConversationDetail`, {
      params: {
        refNum,
        categoryId,
        message,
        attachmentFileNames
      }
    });
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
    dispatch(setAlert("Message sent!", "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const user_set_conversation_status = (refNum, status) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/saveConversationDetail`, {
      params: {
        refNum,
        status
      }
    });
    dispatch({
      type: GET_CONVERSATION_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const user_get_message_category_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/user/getMessageCategoryList`);
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_MESSAGE_CATEGORY_LIST_ERROR,
    });
  }
};

export const redirect = (to) => async (dispatch) => {
  dispatch({
    type: REDIRECT,
    payload: to,
  });
};


export const unredirect = () => async (dispatch) => {
  dispatch({
    type: UNREDIRECT,
    payload: {},
  });
};

export const get_notification_config = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNotificationConfig`
    );
    dispatch({
      type: GET_NOTIFICATION_CONFIG,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_NOTIFICATION_CONFIG_ERROR,
    });
  }
};

export const save_notification_config = (apiUrl, apiKey,email) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveNotificationConfig?apiUrl=${apiUrl}&apiKey=${apiKey}&email=${email}`
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_NOTIFICATION_CONFIG_ERROR,
    });
  }
};

export const get_user_list_role = (subscriberId, selectedRole) => async (dispatch) => {

  try {
    const res = await api.get(`api/subscriber/getUserListByRole?subscriberId=${subscriberId}&roleSubscriberUser=${selectedRole === 'SubscriberUser'}&roleSubscriberHr=${selectedRole === 'SubscriberHr'}&roleSubscriberBilling=${selectedRole === 'SubscriberBilling'}&roleSubscriberAdmin=${selectedRole === 'SubscriberAdmin'}`);
    dispatch({
      type: GET_ROLE_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ROLE_USER_LIST_ERROR,
    });
  }
};

export const get_assigned_message_info_list = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/getAssignedMessageInfoList?conversationId=${refNum}`);
    dispatch({
      type: GET_ASSIGNED_MSG_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ASSIGNED_MSG_LIST_ERROR,
    });
  }
};

export const save_assigned_message_info = (refNum, conversationId, assignRole, assigneUserId) => async (dispatch) =>
 {
  try {
    const res = await api.get(`/api/subscriber/saveAssignedMessageInfo?refNum=${refNum}&conversationId=${conversationId}&roleSubscriberUser=${assignRole === 'SubscriberUser'}&roleSubscriberHr=${assignRole === 'SubscriberHr'}&roleSubscriberBilling=${assignRole === 'SubscriberBilling'}&roleSubscriberAdmin=${assignRole === 'SubscriberAdmin'}&assignToUserId=${assigneUserId}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_MSG_ASSIGNED_INFO,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:SAVE_MSG_ASSIGNED_INFO_ERROR,
    });
  }

};

export const update_assigned_message_info = (refNum, conversationId, assignRole, assigneUserId, status) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/saveAssignedMessageInfo?refNum=${refNum}&conversationId=${conversationId}&roleSubscriberUser=${assignRole === 'SubscriberUser'}&roleSubscriberHr=${assignRole === 'SubscriberHr'}&roleSubscriberBilling=${assignRole === 'SubscriberBilling'}&roleSubscriberAdmin=${assignRole === 'SubscriberAdmin'}&assignToUserId=${assigneUserId}&status=${status}`);
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_MSG_ASSIGNED_INFO_ERROR,
    });
  }
};

export const delete_assigned_message_info = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/deleteAssignedMessageInfo?refNum=${refNum}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_MSG_ASSIGNED_INFO,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: DELETE_MSG_ASSIGNED_INFO_ERROR
      });
    }
  }
};

export const get_sysadmin_eventname_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/sysadmin/getEventNameList`
    );
    dispatch({
      type: GET_SYSADMIN_EVENTNAME_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SYSADMIN_EVENTNAME_LIST_ERROR,
    });
  }
};

export const get_sysadmin_eventdata_list = (eventName,actionBy,actionFor,from,to) => async (dispatch) => {


  try {
    const res = await api.get(
      `api/sysadmin/searchEventData?eventName=${eventName}&actionBy=${actionBy}&actionFor=${actionFor}&from-${from}&to=${to}`
    );
    dispatch({
      type: GET_SYSADMIN_EVENTDATA_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SYSADMIN_EVENTDATA_LIST_ERROR,
    });
  }
};

export const save_leave_policy_level_details = (leavePolicyId,ruleId,clientId,accrue,unit,timeUnit,afterWorkingHour,accrualOn,accrualDate,recurring) => async (dispatch) => {
  try {

    const res = await api.get(
      `api/subscriber/saveAdditionalAccrualRulesDetails`, {
        params: {
          leavePolicyId:leavePolicyId,
          rulesId:ruleId,
          clientId: clientId,
          accrue:accrue,
          unit:unit,
          timeUnit:timeUnit,
          afterWorkingFor:afterWorkingHour,
          accrualOn:accrualOn,
          accrualDate:accrualDate,
          recurring:recurring,
          deleted:false
        }
      }
    );

    if (leavePolicyId === 0) {
      dispatch(setAlert("Level has been saved successfully. Now you can configure the location.", "success", 4000));
    } else {
      dispatch(setAlert("Level has been saved successfully.", "success"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_LEVEL_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR,
    });
  }
};

export const delete_leave_policy_level_details = (
  leavePolicyId,rulesId, clientId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteAdditionalAccrualRulesDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          rulesId: rulesId,
          clientId: clientId
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_LEAVE_POLICY_LEVEL_DETAILS,
    });
  } catch (err) {

    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR,
    });
  }
};

export const get_assigned_to_msg_center_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getAssignToFilterDataForMessageCenter`);
    dispatch({
      type: GET_ASSIGNED_MSG_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_app_modules = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/get-app-modules`);
    dispatch({
      type: GET_APP_MODULE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_APP_MODULE_ERROR,
    });
  }
};
export const get_app_modules_clear = () => async (dispatch) => {
  dispatch({
    type: GET_APP_MODULE_CLEAR,
    payload: null,
  });
};
export const get_fields_report = (reportName) => async (dispatch) => {
  try {
    const res = await api.get(`api/report/get-available-field-name?reportName=${reportName}`);
    dispatch({
      type: GET_AVAILABLE_FIELD_NAME,
      payload: res.data,
    });
  } catch (err) {


    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_AVAILABLE_FIELD_NAME_ERROR,
    });
  }
};
export const get_client_list_report = (subscriberId = "") => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getClientList`, {
      params: {
        subscriberId: subscriberId
      }
    });
    dispatch({
      type: GET_CLIENT_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_LIST_REPORT_ERROR,
    });
  }
};
export const get_country_list_report = () => async (dispatch) => {
  try {

    const res = await api.get(`api/general/getCountryList`);
    dispatch({
      type: GET_COUNTRY_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_COUNTRY_LIST_REPORT_ERROR,
    });
  }
};
export const get_region_list_report = (countryId) => async (dispatch) => {
  try {

    if (!countryId) {
      dispatch({
        type: GET_REGION_LIST_REPORT,
        payload: {regionListReport: []},
      });
    } else {
      const res = await api.get(
        `api/general/getRegionList?countryId=${countryId}`
      );
      dispatch({
        type: GET_REGION_LIST_REPORT,
        payload: res.data,
      });
    }


  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_REGION_LIST_REPORT_ERROR,
    });
  }
};
export const get_employment_status_list_report = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getSubstatusList`);
    dispatch({
      type: GET_EMPLOYMENT_STATUS_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EMPLOYMENT_STATUS_LIST_REPORT_ERROR,
    });
  }
};
export const get_hr_Consultant_list_report = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getHrConsultantList`);
    dispatch({
      type: GET_HR_CONSULTANT_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_HR_CONSULTANT_LIST_REPORT_ERROR,
    });
  }
};
export const  get_sales_consultant_list_report = () => async (dispatch) => {

  try {
    const res = await api.get(`api/subscriber/getUserSalesConsultantList`);
    dispatch({
      type: GET_SALES_CONSULTANT_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SALES_CONSULTANT_LIST_REPORT_ERROR,
    });
  }
};
export const  get_leave_type_list_report = (clientId) => async (dispatch) => {

  try {

    const res = await api.get(`api/subscriber/get-client-leave-policy-List-by-client-ids?clientIdList=${clientId}`);
    dispatch({
      type: GET_LEAVE_TYPE_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_TYPE_LIST_REPORT_ERROR,
    });
  }
};
export const  get_reports_name_list = (module) => async (dispatch) => {

  try {

    const res = await api.get(`api/report/getReportsNameList?module=${module}`);
    dispatch({
      type: GET_REPORTS_NAME_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_REPORTS_NAME_LIST_ERROR,
    });
  }
};
export const  get_reports_period_list = () => async (dispatch) => {

  try {


    const res = await api.get(`api/report/getReportsPeriodList`);
    dispatch({
      type: GET_REPORTS_PERIOD_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_REPORTS_PERIOD_LIST_ERROR,
    });
  }
};
export const  get_reports_status_list = () => async (dispatch) => {

  try {


    const res = await api.get(`api/report/getReportsStatusList`);
    dispatch({
      type: GET_REPORTS_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_REPORTS_STATUS_LIST_ERROR,
    });
  }
};
export const  generate_report = (moduleName,reportName) => async (dispatch) => {

  try {


    const res = await api.get(`api/report/generateReport?moduleName=${moduleName}&reportName=${reportName}`);
    dispatch({
      type: GENERATE_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GENERATE_REPORT_ERROR,
    });
  }
};
export const  report_advance_filters = (moduleName,reportName,clientId,countryId,regionId,workerId,employmentStatus,period,from,to,hrConsultantId,salesConsultantId,leavePolicyId,status,search="") => async (dispatch) => {

  try {


    const res = await api.get(`api/report/generateReport?moduleName=${moduleName}&reportName=${reportName}&clientIdList=${clientId}&countryId=${countryId}&regionIdList=${regionId}&workerIdList=${workerId}&employmentStatusList=${employmentStatus}&period=${period}&from=${from}&to=${to}&hrConsultantIdList=${hrConsultantId}&salesConsultantIdList=${salesConsultantId}&leavePolicyIdList=${leavePolicyId}&statusList=${status}&search=${search}`);
    dispatch({
      type: REPORT_ADVANCE_FILTERS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: REPORT_ADVANCE_FILTERS_ERROR,
    });
  }
};
export const download_report = (data) => async (dispatch) => {

  try {
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

if (month.length < 2)
    month = '0' + month;
if (day.length < 2)
    day = '0' + day;

var dt =[year, month, day].join('');

var filename=dt+"_"+data.reportName.replace(/\s+/g, '_').toLowerCase()+".csv";

const res = await api.post(`api/report/download-report`,data);
if(res.status==200)
{
  const url = window.URL.createObjectURL(
    new Blob([res.data]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);


}

    //dispatch({
     // type: DOWNLOAD_REPORT,
     // payload: res.data,
    //});
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DOWNLOAD_REPORT_ERROR,
    });
  }
};
export const  get_worker_list_report = (clientId) => async (dispatch) => {

  try {


    const res = await api.get(`api/subscriber/get-workers-by-client-id-list?clientIdList=${clientId}`);
    dispatch({
      type: GET_WORKER_LIST_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_LIST_REPORT_ERROR,
    });
  }
};

export const  get_worker_details = () => async (dispatch) => {

  try {
    const res = await api.get(`api/report/worker-detail`);
    dispatch({
      type: GET_WORKER_DETAILS_REPORT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_DETAILS_REPORT_ERROR,
    });
  }
};

export const get_distance_unit_details = (distanceUnitId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/get-distance-unit-details?distanceUnitId=${distanceUnitId}`
    );
    dispatch({
      type: GET_DISTANCE_UNIT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_DISTANCE_UNIT_DETAILS_ERROR,
    });
  }
};

export const save_distance_unit_details = (distanceUnitId,unit) => async (
  dispatch
) => {
  try {

    const res = await api.get(
      `api/subscriber/save-distance-unit-details?unit=${unit}&distanceUnitId=${distanceUnitId}`
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_DISTANCE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: SAVE_DISTANCE_DETAILS_ERROR,
      });
    }
  }
};

export const clear_distance_unit_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DISTANCE_UNIT_DETAILS,
  });
};
export const delete_distance_details = (distanceUnitId) => async (dispatch) => {
  try {

    const res = await api.get(`api/subscriber/delete-distance-details?distanceUnitId=${distanceUnitId}`);
    dispatch(setAlert("Distance Unit deleted", "success"));
    dispatch({
      type: DELETE_DISTANCE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:DELETE_DISTANCE_DETAILS_ERROR
      });
    }
  }
};
export const get_country_name_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/general/getCountryList`);
    dispatch({
      type: GET_COUNTRY_NAME,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_COUNTRY_NAME_ERROR,
    });
  }
};
export const get_region_list_mileage = (countryId) => async (dispatch) => {
  try {

    if (!countryId) {
      dispatch({
        type: GET_REGION_MILEAGE_LIST,
        payload: {regionNameList: []},
      });
    } else {
      const res = await api.get(
        `api/general/getRegionList?countryId=${countryId}`
      );
      dispatch({
        type: GET_REGION_MILEAGE_LIST,
        payload: res.data,
      });
    }


  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_REGION_MILEAGE_LIST_ERROR,
    });
  }
};
export const save_pay_component = (data) => async (
  dispatch
) => {
  try {

    const res = await api.post(`api/subscriber/save-pay-component`,data);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_PAY_COMPONENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: SAVE_PAY_COMPONENT_DETAILS_ERROR,
      });
    }
  }
};

export const get_pay_component = (Num) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/get-pay-component?refNum=${Num}`
    );
    dispatch({
      type: GET_PAY_COMPONENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:  GET_PAY_COMPONENT_DETAILS_ERROR,
    });
  }
};
export const get_pay_component_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/get-pay-component-list`);
    dispatch({
      type: GET_PAY_COMPONENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAY_COMPONENT_LIST_ERROR,
    });
  }
};

export const save_pay_element = (data) => async (
  dispatch
) => {
  try {

    const res = await api.post(`api/subscriber/save-pay-element`,data);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_PAY_ELEMENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: SAVE_PAY_ELEMENT_DETAILS_ERROR,
      });
    }
  }
};

export const get_pay_element = (Num) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/get-pay-element?refNum=${Num}`
    );
    dispatch({
      type: GET_PAY_ELEMENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:  GET_PAY_ELEMENT_DETAILS_ERROR,
    });
  }
};

export const delete_pay_element = (Num) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/delete-pay-element?refNum=${Num}`);
    dispatch(setAlert("Pay element deleted", "success"));
    dispatch({
      type: DELETE_PAY_ELEMENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:DELETE_PAY_ELEMENT_DETAILS_ERROR
      });
    }
  }
};



export const get_pay_element_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/get-pay-element-list`);
    dispatch({
      type: GET_PAY_ELEMENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAY_ELEMENT_LIST_ERROR,
    });
  }
};

export const calculate_years_of_service = (Date) => async (dispatch) => {
  try {
    const res = await api.get(`api/general/calculate-years-of-service?hireDate=${Date}`);
    dispatch({
      type: CALCULATE_YEAR_OF_SERVICES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: CALCULATE_YEAR_OF_SERVICES_ERROR,
    });
  }
};
export const calculate_age = (birthDate) => async (dispatch) => {
  try {
    const res = await api.get(`api/general/calculate-age?dob=${birthDate}`);
    dispatch({
      type: CALCULATE_AGES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: CALCULATE_AGES_ERROR,
    });
  }
};

export const search_proposals = (searchKey) => async (dispatch) => {
  try {
    const res = await api.get(`/api/proposal/search-proposal?search=`);
    dispatch({
      type: SEARCH_PROPOSALS,
      payload: res.data,
    });
  } catch (err) {
    const success =  err.response && err.response.data.success;
    if (!success) {
      //dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_PROPOSALS_ERROR,
    });
  }
};

export const create_proposals = (formState) => async (dispatch) => {
  try {
    const res = await api.post(`/api/proposal/create`,JSON.stringify(formState));
    dispatch({
      type: SAVE_PROPOSAL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_PROPOSAL_ERROR,
    });
  }
};

export const get_proposal_owner_list = () => async (dispatch) => {
  try {
    const res = await api.get(`/api/proposal/owner-list`)
    dispatch({
      type: GET_PROPPOSAL_OWNER_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PROPPOSAL_OWNER_LIST_ERROR,
    });
  }
};


export const get_worker_compensation_rate_list = () => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber-config/worker-compensation-rate-list`)
    dispatch({
      type: GET_WORKER_COMPENSATION_RATE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_COMPENSATION_RATE_LIST_ERROR,
    });
  }
};

export const get_proposal = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(`/api/proposal/${refNum}`)
    dispatch({
      type: GET_PROPOSAL,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PROPOSAL_ERROR,
    });
  }
};

export const clear_proposal = () => async (dispatch) => {

  dispatch({
    type:CLEAR_PROPOSAL
  })
}

export const delete_proposal_item = (items, refNum) => async (dispatch) => {
  try {
    const res = await api.get(`/api/proposal/delete?refNum=${refNum}&itemIdList=${items}`)
    dispatch({
      type: DELETE_PROPOSAL,
      payload: items,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_PROPOSAL_ERROR,
      payload: 0
    });
  }
};

export const download_proposal = (proposalNumber, proposalName) => async (dispatch) => {

const res = await api.get(`/api/proposal/download?refNum=${proposalNumber}`);
if(res.status==200)
{
  const url = window.URL.createObjectURL(
    new Blob([res.data]),
  );

  const link = document.createElement('a');
  link.href = `/api/proposal/download?refNum=${proposalNumber}`;

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);


}
}

export const get_vacation_pay_list = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber-config/vacation-pay-list`)
    dispatch({
      type: GET_VACATION_PAY_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_VACATION_PAY_LIST_ERROR,
    });
  }
};

export const get_pay_item_list = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/getPayItemList?userId=${userId}`);
    dispatch({
      type: GET_PAY_ITEMS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAY_ITEMS_LIST_ERROR,
    });
  }
};
export const clear_pay_item_list = ()=>(dispatch)=>dispatch({type:CLEAR_PAY_ITEM_LIST})
export const delete_pay_items = (payItemRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/deletePayItem?payItemRefNum=${payItemRefNum}`);
    dispatch(setAlert("Pay item deleted", "success"));
    dispatch({
      type: DELETE_PAY_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:DELETE_PAY_ITEM_ERROR
      });
    }
  }
};

export const set_sub_config = (fieldName, fieldValue)=>async (dispatch)=>{
  try{
    const res = await api.get('api/subscriber-config/set-subconfig',
      {
        params:{
          fieldName,
          fieldValue
        }
      }
    );
    dispatch(setAlert(res.data.message,"success"));
    return true;
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    return false;
  }
}
export const get_sub_config = ()=>async (dispatch)=>{
  try{
    const res = await api.get('api/subscriber-config/get-subconfig');
    dispatch({type: GET_SUB_CONFIG,
      payload:res.data
    });
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({type: GET_SUB_CONFIG_ERROR})
  }
}


export const sync_pay_items = (userId, syncItem, syncItemRefNum) => async (dispatch) => {
  try {
    
    let data = { userId: userId, syncType: syncItem, syncItemRefNum: syncItemRefNum }
    const res = await api.get(`api/subscriber/syncItem?workerId=${userId}&syncType=${syncItem}&syncItemRefNum=${syncItemRefNum}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SYNC_PAY_ITEM,
      payload: res.data,
    });
    return res.data.data;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: SYNC_PAY_ITEM_ERROR
      });
    }
    return null;
  }
};

export const attach_Pay_Item = (workerId, syncItem, syncItemRefNum, payItemRefNum) => async (dispatch) => {
  try {
    let data = { workerId: workerId, syncType: syncItem, syncItemRefNum: syncItemRefNum, payItemRefNum: payItemRefNum }
    const res = await api.get(`api/subscriber/attachPayItemToHRPayComponent?workerId=${workerId}&syncType=${syncItem}&syncItemRefNum=${syncItemRefNum}&payItemRefNum=${payItemRefNum}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: ATTACH_PAY_ITEM_HR_COMPONENT,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: ATTACH_PAY_ITEM_HR_COMPONENT_ERROR
      });
    }
    return false;
  }
};

export const generate_benefits_report = () => async (dispatch) => {
  try{
    const res = await api.get('api/report/worker-benefits');
    dispatch({ type: GENERATE_BENEFITS_REPORT,
      payload:res.data
    });
  }
  catch(err){
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({ type: GENERATE_BENEFITS_REPORT_ERROR })
  }
}


