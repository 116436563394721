import { connect } from "react-redux"
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, FormSelect, Row } from "shards-react"
import InfoTooltip from "../../components/common/InfoTooltip"
import { get_sub_config, set_sub_config } from "../../redux/actions/user"
import { useEffect, useState } from "react"

function Settings({
    subConfigList,
    get_sub_config,
    set_sub_config,
    compensationPayeeList,
}) {


    const [formData, setFormData] = useState({
        gstHst: '',
        defaultCompensationMethod: '',
        defaultGovernmentTaxRemittance: '',
        defaultBenefitsProvider: '',
    })

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
    const payeeListOptions = (compensationPayeeList || []).map(payee => ({ value: payee.refId, label: payee.payeeName }));

    const onSubmit = (e) => {
        e.preventDefault();
        set_sub_config('gstHst', formData.gstHst)
    }

    const onSubmitCompensationPayment = (e) => {
        e.preventDefault();
        set_sub_config('defaultCompensationMethod', formData.defaultCompensationMethod)
    }

    const onSubmitGovernmentTax = (e) => {
        e.preventDefault();
        set_sub_config('defaultGovernmentTaxRemittance', formData.defaultGovernmentTaxRemittance)
    }

    const onSubmitBenefitProvider = (e) => {
        e.preventDefault();
        set_sub_config('defaultBenefitsProvider', formData.defaultBenefitsProvider)
    }

    useEffect(() => {

        const subConfig = (subConfigList || []).reduce((obj1, obj2) => {
            obj1[obj2.field] = obj2.value;
            return obj1;
        }, {});
        setFormData({ ...formData, ...subConfig })
    }, [subConfigList])

    useEffect(() => {
        get_sub_config();
    }, [])

    return (
        <div className="mt-5 position-relative">
            <span className="tableHeader">Default Settings</span>
            <Card className="tableCard p-0 border-card">
                <CardBody className="p-4">
                    <Container fluid className="file-manager__filters">
                        <Form onSubmit={onSubmit}>
                            <Row className="form-group p-2 mb-0">
                                <Col lg="4">
                                    <label for="gstHst" className="p-2">Sales Tax ID</label>
                                    <InfoTooltip infoClass="" cssClassName="text-start mw-100 mb-5" msg="<div>For Canadian Subscribers this is the GST/HST registeration number to be used for invoices.</div>"></InfoTooltip>
                                </Col>
                                <Col lg="3">
                                    <FormInput
                                        name="gstHst"
                                        id="gstHst"
                                        value={formData.gstHst}
                                        placeholder="Enter Sales Tax ID"
                                        onChange={onChange} />
                                </Col>
                                <Col lg="3">
                                    <Button>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Form onSubmit={onSubmitCompensationPayment}>
                            <Row className="form-group p-2 mb-0">
                                <Col lg="4">
                                    <label for="defaultCompensationMethod" className="p-2">Default Compensation Payment Method</label>
                                </Col>
                                <Col lg="3">
                                    <FormSelect size="sm"
                                        name='defaultCompensationMethod' value={formData.defaultCompensationMethod}
                                        className="d-inline" onChange={onChange}
                                    >
                                        <option key={0} value={""}>
                                            Select Default Payment Method
                                        </option>

                                        {payeeListOptions.map((item, idx) => (
                                            <option key={idx} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </Col>
                                <Col lg="3">
                                    <Button>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Form onSubmit={onSubmitGovernmentTax}>
                            <Row className="form-group p-2 mb-0">
                                <Col lg="4">
                                    <label for="defaultGovernmentTaxRemittance" className="p-2">Default Government Tax Remittance</label>
                                </Col>
                                <Col lg="3">
                                    <FormSelect size="sm"
                                        name='defaultGovernmentTaxRemittance' value={formData.defaultGovernmentTaxRemittance}
                                        className="d-inline" onChange={onChange}
                                    >
                                        <option key={0} value={""}>
                                            Select Default Government Tax Remittance
                                        </option>
                                        {payeeListOptions.map((item, idx) => (
                                            <option key={idx} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </Col>
                                <Col lg="3">
                                    <Button>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Form onSubmit={onSubmitBenefitProvider}>
                            <Row className="form-group p-2 mb-0">
                                <Col lg="4">
                                    <label for="defaultBenefitsProvider" className="p-2">Select Default Benefits Provider</label>
                                </Col>
                                <Col lg="3">
                                    <FormSelect size="sm"
                                        name='defaultBenefitsProvider' value={formData.defaultBenefitsProvider}
                                        className="d-inline" onChange={onChange}
                                    >
                                        <option key={0} value={""}>
                                            Select Default Benefits Provider
                                        </option>
                                        {payeeListOptions.map((item, idx) => (
                                            <option key={idx} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </Col>
                                <Col lg="3">
                                    <Button>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </CardBody>
            </Card>
        </div>
    )
}

const mapStateToProps = (state) => ({
    subConfigList: state.user.subConfigList,
    compensationPayeeList:state.payroll.compensationPayeeList,
})
export default connect(mapStateToProps, {
    get_sub_config,
    set_sub_config,
})(Settings)