import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'shards-react'
import CustomTable from '../common/CustomTable'
import { formatNumberAmount } from '../../utils/general';
import { connect } from 'react-redux';

const PayTypeComponentModal = ({ newPayComponentList,
     openModal, 
     openModalFunc,
     userName,
     setSelected,
     existingSelection,
     isSelectable,
     attachPayItem,
     payItemList,}) => {
    const [formData, setFormData] = useState({
        pageSizeOptions: [5, 10],
        pageSize: 10,
    });

    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState(payItemList);
    const [checkbox, setCheckbox] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if(gridData !== undefined ){
            setGridData(payItemList.map(x => { 
                if(x.payItemRefNum == existingSelection){
                    x.isChecked = true;
                }
                else{
                    x.isChecked = false;
                }
                return x;
            }));
        }
    }, [existingSelection, openModal, payItemList])

    const modalSizing = `
        .modal-dialog {
            max-width: 1100px!important;
            overflow:auto !important;
            max-height:900px !important;
        }
        .modal-content {
            width: 90%;
            z-index:3
        }
        .modal-title {
            width: 100% !important;
        }
        .modal-body{
        max-width: 1100px!important;
        overflow-y:auto !important;
        overflow-x: hidden !important;
        max-height:500px;
        }
    `;

    let tableColumns = [
        {
            Header: "",
            // Header: <input type='checkbox' value={"Master"} checked={checkbox} onChange={(x) => { checkingHeaderValue(x.target.checked) }} />,
            accessor: "payItemRefNum",
            maxWidth: 30,
            className: "text-center ",
            sortable: false,
            Cell: row => <>
                <span className="p-1" role="button">
                    <input type="checkbox" id={row.original.payItemRefNum} checked={row.original.isChecked}
                        value={row.original.payItemRefNum}
                        key={row.original.payItemRefNum}
                        onChange={(e) => {
                            changeStateForSelected(e.target.id, e.target.checked);
                        }}></input>
                </span>
            </>
        },
        {
            Header: "Pay Component", accessor: "payComponent", minWidth: 180, className: "text-center"
        },
        {
            Header: "Worker Rate", accessor: "payRate", className: "justify-content-end",
            Cell: row => formatNumberAmount(row.original.payRate, "en-US", 4)
        },
        {
            Header: "Rate Frequency", accessor: "rateUnitOfMeasure", className: "justify-content-start",
        },
        {
            Header: "Pay Frequency", accessor: "payFrequency", className: "justify-content-start",
        },
        {
            Header: "Invoice Frequency", accessor: "invoiceFrequency", className: "justify-content-start",
        },
        {
            Header: "Notes", accessor: "notes", className: "justify-content-left",
        }
    ];

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function checkingHeaderValue(currentValue) {
        let revisedData = gridData.map(x => {
            x.isChecked = currentValue;
            return x;
        })
        setGridData(revisedData);
        setCheckbox(!checkbox);
    }

    function changeStateForSelected(currentValue, isChecked) {
        if(isChecked){
            cleanExistingSelection();
        }

        let revisedData = gridData.map(x => {
            if (x.payItemRefNum == currentValue)
                x.isChecked = isChecked;
            return x;
        })
        setGridData(revisedData);
        setIsSelected(gridData.filter(x => x.isChecked == true).length > 0);
    }

    function cleanExistingSelection() {
        if(gridData.filter(x => x.isChecked == true).length > 0){
            gridData.filter(x => x.isChecked == true).map(y => {y.isChecked = false; return y;})
        }
    }

    const toggle = () => {
        openModalFunc();
    }

    const setSelectedValue = async () => {
        const checkedData = gridData.filter(x => x.isChecked);
        const selected = checkedData.length > 0 ? checkedData[0].payItemRefNum : 0
        const attached = await attachPayItem(selected)
        setSelected(attached);
        cleanExistingSelection();
        toggle();
    }

    return (
        <div>
            <div>
                <style>{modalSizing}</style>
            </div>
            <Modal size="lg" fade={false} backdrop={false} className="d-flex align-items-center justify-content-center w-90 h-90" open={openModal} toggle={toggle}>
                <ModalHeader className="p-2 w-100 border-none"> 
                    <span><b>Select Pay Item For {userName}</b></span>
                    <i class="fa-solid fa-xmark primary-text bg-white float-right p-1" role="button" onClick={toggle}></i> 
                </ModalHeader>
                {/* <div className="d-flex justify-content-end p-1 mx-2">{"Can we do anything here?"}<i class="fa-solid fa-xmark primary-text bg-white" role="button" onClick={toggle}></i></div> */}
                <ModalBody className="p-1 ">
                    <div className="w-100">
                        <CustomTable
                            pageSizeOptions={pageSizeOptions}
                            pageSize={pageSize}
                            onChange={onChange}
                            tableColumns={tableColumns}
                            tableData={gridData}
                            showHeader />
                    </div>
                    {/* mt-4 ml-2 pl-1 pr-1 */}
                    
                </ModalBody>
                <ModalFooter className="py-0">
                <div className="w-100 p-0">
                        <Button className="float-right ml-2 mt-0" onClick={() => toggle()}>Cancel</Button>
                        {isSelectable && <Button className="float-right ml-2 mt-0" onClick={() => setSelectedValue()} >Select</Button> }  
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}
const mapStateToProps = (state)=>({
    payItemList:state.user.payItemList
})
export default connect(mapStateToProps,{
})(PayTypeComponentModal)
