import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormInput,
  FormSelect, Row
} from "shards-react";
import ReactTable from "react-table";
import {setDateForTable, setInputDate} from "../../utils/date";
import {getStatusClass} from "../../utils/general";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  get_leaves_status
} from "../../redux/actions/worker/leaves";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import {
  get_client_leave_policy_name_list,
  search_worker_leaves_list
} from "../../redux/actions/client/clientLeaves";
import {get_client_sub_status_list} from "../../redux/actions/user";
import {default as queryString} from "qs";

function ClientLeaves({
                        get_leaves_status,
                        clientLeavePolicyNamesList,
                        search_worker_leaves_list,
                        workerLeavesList,
                        leaveStatusList,
                        subStatusList,
                        get_client_leave_policy_name_list,
                        get_client_sub_status_list
                      }) {

  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const [formData, setFormData] = useState({
    workerName: '',
    leaveStatus: '',
    leaveType: '',
    fromDate: '',
    toDate: '',
    empStatus: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  useEffect(() => {
    get_leaves_status();
    get_client_leave_policy_name_list();
    get_client_sub_status_list();
  }, []);

  useEffect(() => {
    let newState = {
      ...formData,
      workerName: queryParams.workerName || "",
      leaveStatus: queryParams.leaveStatus || "",
      leaveType: queryParams.leaveType || "",
      fromDate: queryParams.fromDate || "",
      toDate: queryParams.toDate || "",
      empStatus: queryParams.empStatus || "",
    }
    searchLeaves(
      newState.workerName,
      newState.leaveStatus,
      newState.leaveType,
      newState.fromDate,
      newState.toDate,
      newState.empStatus)
    setFormData(newState)
  }, [queryParams.workerName, queryParams.leaveStatus, queryParams.leaveType, queryParams.fromDate, queryParams.toDate, queryParams.empStatus]);

  const searchLeaves = (
    workerName=formData.workerName,
    leaveStatus=formData.leaveStatus,
    leaveType=formData.leaveType,
    fromDate=formData.fromDate,
    toDate=formData.toDate,
    empStatus=formData.empStatus) => {
    search_worker_leaves_list(workerName, leaveStatus, leaveType, setInputDate(fromDate), setInputDate(toDate), empStatus);
  }

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  let onStartDateChanged = (value) => {
    setFormData({
      ...formData,
      fromDate: value
    })
  }

  let onEndDateChanged = (value) => {
    setFormData({
      ...formData,
      toDate: value
    })
  }

  const tableData = workerLeavesList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "User ID",
      accessor: "userId",
      maxWidth: 80,
      Cell: row =>
        <Link
          to={`/client-view-worker-profile/${row.original.userId}`}>{row.original.userId}</Link>
    },
    {
      Header: "Worker Name",
      accessor: "workerName",
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Employment Status",
      accessor: "empStatus",
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row =>
        <span
          className={getStatusClass(row.original.empStatus)}>{row.original.empStatus}</span>
    },
    {
      Header: "Requested",
      accessor: "requestedDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.requestedDate)
    },
    {
      Header: "Leave Type",
      accessor: "leavePolicy",
      className: "justify-content-start text-left text-wrap text-break"
    },

    {
      Header: "From",
      accessor: "fromDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.fromDate)
    },

    {
      Header: "To",
      accessor: "toDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.toDate)
    },
    {
      Header: "Time Off",
      accessor: "timeOff",
      maxWidth: 80,
      className: "justify-content-end",
      Cell: row =>
        row.original.timeOff.toFixed(2)
    },

    {
      Header: "Entitled (once accrued)",
      accessor: "entitled",
      maxWidth: 80,
      Cell: row =>
        row.original.entitled
    },

    {
      Header: "Balance (once accrued)",
      accessor: "balance",
      className: "justify-content-end",
      maxWidth: 80,
      Cell: row =>
        row.original.balance.toFixed(2)
    },

    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },
    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      Cell: row =>
        <Link
          to={`/client-view-worker-leave/${row.original.refNum}`}>{row.original.refNum}</Link>
    },
  ];

  var leaveStatusOptions = (leaveStatusList || []).map((leaveStatus) => {
    return <option key={leaveStatus} value={leaveStatus}>{leaveStatus}</option>
  });

  var leaveTypeOptions = clientLeavePolicyNamesList.map((leavePolicy) => {
    return <option
      key={leavePolicy.policyName} value={leavePolicy.policyName}>{leavePolicy.policyName}</option>;
  });
  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus) => {
      return <option key={substatus} value={substatus}>{substatus}</option>;
    }
  );
  return (
    <Container fluid className="main-content-container p-4">

      <div 
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='workerName'
            value={formData.workerName}
            onChange={onChange}
            placeholder="Worker Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='leaveStatus' value={formData.leaveStatus}
                      onChange={onChange}>
            <option value=''>All Status</option>
            {leaveStatusOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='leaveType' value={formData.leaveType}
                      onChange={onChange}>
            <option value=''>All Types</option>
            {leaveTypeOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='empStatus' value={formData.empStatus}
                      onChange={onChange}>
       <option value=''>All Employment Status</option>
            {substatusListOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <RangeDatePicker className="justify-content-end"
                           onStartDateChanged={onStartDateChanged}
                           onEndDateChanged={onEndDateChanged} name="range"/>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                  onClick={e=>searchLeaves()}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/*Filters :: Page Size*/}
              <Col lg="2"
                   className="file-manager__filters__rows d-flex align-items-center col-12">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col lg="6"
                   className="file-manager__filters__search d-flex justify-content-center justify-content-lg-start col-12 mt-3 mb-3">
               <p
                  className="m-0 ">{`Note - This section shows Active & On Leave Users`}</p>
              </Col>
              <Col lg="4"
                   className="file-manager__filters__search d-flex justify-content-center justify-content-lg-end col-12">
                <a
                  href={`api/client/exportWorkerLeavesList?workerName=${formData.workerName}&leaveStatus=${formData.leaveStatus}&leaveType=${formData.leaveType}&fromDate=${formData.fromDate}&toDate=${formData.toDate}&empStatus=${formData.empStatus}&fileType=pdf`}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Export PDF</Button>
                </a>
                <a
                  href={`api/client/exportWorkerLeavesList?workerName=${formData.workerName}&leaveStatus=${formData.leaveStatus}&leaveType=${formData.leaveType}&fromDate=${formData.fromDate}&toDate=${formData.toDate}&empStatus=${formData.empStatus}&fileType=csv`}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Export CSV</Button>
                </a>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

ClientLeaves.protoTypes = {
  leaveStatusList: PropTypes.array.isRequired,
  get_leaves_status: PropTypes.func.isRequired,
  get_leaves_types: PropTypes.func.isRequired,
  search_worker_leaves_list: PropTypes.func.isRequired,
  leaveTypeList: PropTypes.array.isRequired,
  workerLeavesList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  leaveStatusList: state.leaves.leaveStatusList,
  clientLeavePolicyNamesList: state.clientLeaves.clientLeavePolicyNamesList,
  workerLeavesList: state.clientLeaves.workerLeavesList,
  subStatusList: state.user.subStatusList,
});


export default connect(mapStateToProps, {
  get_leaves_status,
  get_client_leave_policy_name_list,
  search_worker_leaves_list,
  get_client_sub_status_list,
})(ClientLeaves);
