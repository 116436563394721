import React, {useState} from 'react';
import {
  Button, Card, CardBody,
  CardHeader,
  Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import {Link, useLocation} from "react-router-dom";
import ReactTable from "react-table";
import {setDateForTable} from "../../../utils/date";
import PropTypes from "prop-types";
import {getStatusClass, numberToMonetary} from "../../../utils/general";

function MileageItemsCard(props) {

  let location=useLocation();
  const [state, setState] = useState({
    pageSizeOptions: [10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };


  const tableData = (props.mileageItemList) ? props.mileageItemList : []

  var {pageSize, pageSizeOptions} = state;

  // if (tableData.length < 10) {
  //   pageSize = tableData.length
  //   pageSizeOptions = [tableData.length]
  // }

  const tableColumns = [
    {
      Header: "Date",
      accessor: "tripDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.tripDate),
    },
    {
      Header: "From",
      accessor: "fromLocation",
      maxWidth: 100,
      className: "justify-content-start text-left text-wrap text-break",
    },
    {
      Header: "To",
      accessor: "toLocation",
      maxWidth: 100,
      className: "justify-content-start text-left text-wrap text-break",
    },
    {
      Header: "Trip Description",
      accessor: "tripDescription",
      className: "justify-content-start text-left text-wrap text-break",
    },

    {
      Header: "Distance Unit",
      accessor: "distanceUnit",
      className: "justify-content-start text-left text-wrap text-break",
    },

    {
      Header: "Distance Travelled",
      accessor: "distanceDriven",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => numberToMonetary(row.original.distanceDriven,2),
    },

    {
      Header: "Applied Rate",
      accessor: "appliedRate",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => parseFloat(row.original.appliedRate).toFixed(2),
    },
    {
      Header: "Amount",
      accessor: "amount",
      maxWidth: 120,
      className: "justify-content-end text-wrap text-break overflow-visible",
      Cell: row => row.original.amount.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }),
      Footer: <div className="d-flex w-100 justify-content-end text-nowrap">
        <span>Sub Total: {props.status === undefined ? '0.00' : props.status === "Submitted" ? numberToMonetary(props.submittedMileageTotal) : props.status === 'Draft' ? numberToMonetary(props.draftMileageTotal) : numberToMonetary(props.approvedMileageTotal) }</span>
      </div>
    },


    {
      Header: "Status",
      accessor: "status",
      maxWidth: 90,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <Link to={{pathname:`/${props.editItemRoute||"edit-mileage-item"}/${props.sheetRefNumber}/${row.original.refNum}`,state:location.state}} onClick={e=>props.onEditMileage(row.original.refNum)}>{row.original.refNum}</Link>
    }
  ];

  return (
    <Card className="p-0 mb-4">
      <span className="tableHeader">Add Mileage</span>
      <CardHeader className="p-0 pt-2 border-card">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col lg="6"
                 className="file-manager__filters__rows d-flex align-items-center">
              <span>Show</span>
              <FormSelect
                size="sm"
                name='pageSize'
                value={pageSize}
                onChange={onChange}
              >
                {pageSizeOptions.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size} rows
                  </option>
                ))}
              </FormSelect>
            </Col>

            <Col lg="6"
                 className="file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6">


              <Button className="mx-2 py-2" size='sm'
                      disabled={(["Approved","Reviewed","Submitted"].includes(props.status))}
                      theme="accent" onClick={props.onAddMileage}>Add Mileage</Button>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className="d-flex align-items-center text-nowrap">
              <label className="m-0">Saved YTD</label>
              <span className='px-2'>{numberToMonetary(props.savedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap">
              <label className="m-0">Submitted YTD</label>
              <span
                className='px-2'>{numberToMonetary(props.submittedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap">
              <label className="m-0">Approved YTD</label>
              <span
                className='px-2'>{numberToMonetary(props.approvedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap">
              <label className="m-0">Reviewed YTD</label>
              <span
                className='px-2'>{numberToMonetary(props.reviewedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap">
              <label className="m-0">Total YTD</label>
              <span className='px-2'>{numberToMonetary(props.totalYTD)}</span>
            </Col>
          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <div className="">
          <ReactTable
            columns={tableColumns}
            data={tableData}
            minRows={tableData.length < 10 ? tableData.length : 10}
            pageSize={Number(pageSize)}
            showPageSizeOptions={false}
            resizable={false}
            className="mileageItemsTable"
            noDataText="No results found"
          />
        </div>
      </CardBody>
    </Card>
  );
}

MileageItemsCard.propTypes = {
  mileageItemList: PropTypes.array.isRequired,
  submittedMileageTotal: PropTypes.string.isRequired,
  approvedMileageTotal: PropTypes.string.isRequired,
  sheetRefNumber: PropTypes.string.isRequired
}

MileageItemsCard.defaultProps = {
  mileageMileageList: [],
  submittedMileageTotal: '',
  approvedMileageTotal: '',
  sheetRefNumber: ''
}

export default MileageItemsCard;
