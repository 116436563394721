import api from "../../../utils/api";
import {setAlert} from "../alert";
import {
  APPROVE_SUB_EXPENSE_ITEM,
  APPROVE_SUB_MILEAGE_ITEM,
  APPROVE_SUB_MILEAGE_ITEM_ERROR,
  CLEAR_SUB_EXPENSE_SHEET,
  GENERATE_EXPENSE_INVOICE,
  GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER,
  GET_SUB_EXPENSE_ITEM,
  GET_SUB_EXPENSE_ITEM_ERROR,
  GET_SUB_EXPENSE_REPORT_DETAILS,
  GET_SUB_EXPENSE_REPORT_DETAILS_ERROR,
  GET_SUB_MILEAGE_ITEM,
  GET_SUB_MILEAGE_ITEM_ERROR,
  REJECT_SUB_EXPENSE_ITEM,
  REJECT_SUB_EXPENSE_ITEM_ERROR,
  REJECT_SUB_MILEAGE_ITEM,
  REJECT_SUB_MILEAGE_ITEM_ERROR,
  RESET_MODIFIED_EXPENSE_STATE,
  REVIEW_SUB_EXPENSE_ITEM,
  REVIEW_SUB_MILEAGE_ITEM,
  SAVE_SUB_EXPENSE_ITEM,
  SAVE_SUB_EXPENSE_ITEM_ERROR,
  SAVE_SUB_MILEAGE_ITEM,
  SAVE_SUB_MILEAGE_ITEM_ERROR,
  SAVE_USER_EXPENSE_SHEET,
  SAVE_USER_EXPENSE_SHEET_ERROR,
  UPDATE_EXPENSE_ITEM_LIST
} from "../types";
import {download_file, monetaryToNumber, numberToMonetary} from "../../../utils/general";

export const get_expense_sheet_details_for_subscriber = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getExpenseReportDetails?refNum=${refNum}`
    );
    dispatch({
      type: GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};

export const sub_get_expense_report_for_review = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getExpenseReportDetails?refNum=${refNum}`
    );
    res.data.expenseSheet.expenseItemList = res.data.expenseSheet.expenseItemList.map(item => {
      item.grossAmount = numberToMonetary(item.grossAmount)
      item.taxAmount = numberToMonetary(item.taxAmount)
      item.receiptAmount = numberToMonetary(item.receiptAmount)
      item.exchangeRate = numberToMonetary(item.exchangeRate, 5)
      item.netAmount = numberToMonetary(item.netAmount)
      return item;
    })
    dispatch({
      type: GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};
export const reject_sub_expense_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_SUB_EXPENSE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: REJECT_SUB_EXPENSE_ITEM_ERROR,
    });
  }
};
export const approve_sub_expense_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_SUB_EXPENSE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const review_sub_expense_item = (refNumber, grossAmount, taxAmount, exchangeRate, receiptAmount, netAmount, taxableFlag) => async (dispatch) => {
  dispatch({
    type: REVIEW_SUB_EXPENSE_ITEM,
    payload: {
      refNumber: parseInt(refNumber),
      grossAmount,
      taxAmount,
      exchangeRate,
      receiptAmount,
      netAmount,
      taxableFlag
    },
  });
};


export const update_expense_item_list = (expenseItemList) => async (dispatch) => {
  dispatch({
    type: UPDATE_EXPENSE_ITEM_LIST,
    payload: expenseItemList,
  });
};
export const approve_sub_mileage_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_SUB_MILEAGE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: APPROVE_SUB_MILEAGE_ITEM_ERROR,
    });
  }
};

export const review_sub_mileage_item = (refNumber, sheetRefNumber, distanceDriven, appliedRate, amount, taxable) => async (dispatch) => {
  try {
    dispatch({
      type: REVIEW_SUB_MILEAGE_ITEM,
      payload: {
        refNumber: parseInt(refNumber),
        sheetRefNumber,
        distanceDriven,
        appliedRate,
        amount,
        taxable
      },
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const reject_sub_mileage_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_SUB_MILEAGE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: REJECT_SUB_MILEAGE_ITEM_ERROR,
    });
  }
};
export const approve_sub_expense_report = (refNum, notes, rejectedExpenseItems, rejectedMileageItems) => async (dispatch) => {
  try {
    let params = {
      refNum,
      rejectedExpenseList: rejectedExpenseItems.toString(),
      rejectedMileageList: rejectedMileageItems.toString()
    };
    if (notes)
      params.notes = notes;
    const res = await api.get(
      `api/subscriber/approveExpenseReport`, {
        params: params
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const review_sub_expense_report = (refNum, notes, rejectedExpenseItems, rejectedMileageItems, expenseItemList, mileageItemList) => async (dispatch) => {
  try {
    let params = {
      refNum,
      rejectedExpenseList: rejectedExpenseItems.toString(),
      rejectedMileageList: rejectedMileageItems.toString(),
      updatedExpenseList: expenseItemList.map(item => {
        return item.refNum + "-" + monetaryToNumber(item.grossAmount) + "-" + monetaryToNumber(item.taxAmount) + "-" + monetaryToNumber(item.exchangeRate) + "-" + item.taxable
      }).toString(),
      updatedMileageList: mileageItemList.map(item => {
        return item.refNum + "-" + item.distanceDriven + "-" + item.appliedRate + "-" + item.taxable
      }).toString()
    };
    if (notes)
      params.notes = notes;

    const res = await api.get(
      `api/subscriber/reviewExpenseReport`, {
        params: params
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const reject_sub_expense_report = (refNum, notes) => async (dispatch) => {
  try {
    let params = {
      refNum
    };
    if (notes)
      params.notes = notes;
    const res = await api.get(
      `api/subscriber/rejectExpenseReport`, {
        params: params
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const clear_sub_expense_sheet = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUB_EXPENSE_SHEET,
  });
}
export const get_sub_expense_item = (refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getExpenseItem?refNum=${refNumber}`
    );
    dispatch({
      type: GET_SUB_EXPENSE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_EXPENSE_ITEM_ERROR,
    });
  }
};
export const clear_sub_expense_item = (refNumber) => async (dispatch) => {
  dispatch({
    type: GET_SUB_EXPENSE_ITEM_ERROR,
  });
};
export const save_sub_expense_item = (
  userId,
  sheetRefNum,
  refNumber,
  expenseDate,
  category,
  description,
  grossAmount,
  taxAmount,
  receiptAmount,
  exchangeRate,
  netAmount,
  receipt,
  taxableFlag,
  notes,
  currency,
  baseCurrency
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveExpenseItem`, {
        params: {
          userId: userId,
          sheetRefNum: sheetRefNum,
          refNum: refNumber,
          expenseDate: expenseDate,
          category: category,
          description: description,
          grossAmount: grossAmount,
          taxAmount: taxAmount,
          receiptAmount: receiptAmount,
          exchangeRate: exchangeRate,
          netAmount: netAmount,
          receipt: receipt,
          taxableFlag: taxableFlag,
          notes: notes,
          currency: currency,
          baseCurrency: baseCurrency,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_SUB_EXPENSE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_SUB_EXPENSE_ITEM_ERROR,
    });
  }
};
export const get_sub_mileage_item = (refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getMileageItem?refNum=${refNumber}`
    );
    dispatch({
      type: GET_SUB_MILEAGE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_MILEAGE_ITEM_ERROR,
    });
  }
};
export const clear_sub_mileage_item = (refNumber) => async (dispatch) => {
  dispatch({
    type: GET_SUB_MILEAGE_ITEM_ERROR,
  });
};
export const save_sub_mileage_item = (
  userId,
  sheetRefNum,
  refNumber,
  date,
  fromLocation,
  toLocation,
  tripDescription,
  distanceDriven,
  appliedRate,
  amount,
  taxableFlag,
  status,
  notes
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveMileageItem`,
      {
        params: {
          userId: userId,
          sheetRefNum: sheetRefNum,
          refNum: refNumber,
          date: date,
          fromLocation: fromLocation,
          toLocation: toLocation,
          tripDescription: tripDescription,
          distanceDriven: distanceDriven,
          appliedRate: appliedRate,
          amount: amount,
          taxableFlag: taxableFlag,
          status: status,
          notes: notes,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_SUB_MILEAGE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_SUB_MILEAGE_ITEM_ERROR,
    });
  }
};
export const get_sub_expense_sheet_details = (
  sheetRefNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getExpenseReportDetails?refNum=${sheetRefNum}`
    );
    dispatch({
      type: GET_SUB_EXPENSE_REPORT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUB_EXPENSE_REPORT_DETAILS_ERROR,
    });
  }
}
export const save_sub_expense_sheet_details = (
  userId,
  refNum,
  sheetDate,
  description,
  expenseItemsList,
  mileageItemsList
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveExpenseReport`, {
        params: {
          userId: userId,
          refNum: refNum,
          sheetDate: sheetDate,
          description: description,
          expenseItemsList: expenseItemsList,
          mileageItemsList: mileageItemsList,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_USER_EXPENSE_SHEET,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_USER_EXPENSE_SHEET_ERROR,
    });
  }
};

export const submit_sub_expense_sheet_details = (
  userId,
  refNum,
  sheetDate,
  description,
  expenseItemsList,
  mileageItemsList
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/submitExpenseReport`,
      {
        params: {
          userId: userId,
          refNum: refNum,
          sheetDate: sheetDate,
          description: description,
          expenseItemsList: expenseItemsList,
          mileageItemsList: mileageItemsList,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const  reset_modified_expense_state = () => (dispatch)=>{
  dispatch({
    type:RESET_MODIFIED_EXPENSE_STATE
  })
}


export const link_invoice_payrecord_to_expense = ( expenseRefNum, recordType, recordRefNum) => async (dispatch) => {
  try {
    const res = await api.get( `api/subscriber/expense-link-record`,
      {
        params: {
          expenseRefNum: expenseRefNum,
          recordType: recordType,
          recordRefNum: recordRefNum,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const generate_invoice_payrecord_payments = (sheetRefNum) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/sub-automation/expenses-generate-invoices-payrecords-payments?expenseRefNum=${sheetRefNum}`
    );
    dispatch({
      type: GENERATE_EXPENSE_INVOICE,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
}
export const download_expense_invoice = (sheetRefNum) => async(dispatch)=>{
  try{
    const res = await api.get(`api/subscriber/download-expense-invoice?sheetRefNum=${sheetRefNum}`,{ 
      responseType: 'blob' 
    });
    if(res.status==200)
      {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename = 'downloaded_payment_invoice_' + sheetRefNum + '.pdf';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      download_file(res.data, filename)
      
      }
    
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
      const text = await err.response.data.text();
      dispatch(setAlert(JSON.parse(text).message, "danger"));
    }
  }
}