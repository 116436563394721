import { GETSEARCH_SYNC_APPROVAL_LIST, GETSEARCH_SYNC_APPROVAL_LIST_ERROR, GETSEARCH_SYNC_HISTORY_LIST, GETSEARCH_SYNC_HISTORY_LIST_ERROR } from "../../actions/types";

const initialState = {
    syncHistoryList: [],
    syncApproveItems: [],
}

export const sync = (state = initialState, action) => {
    switch (action.type) {
        case GETSEARCH_SYNC_HISTORY_LIST:
            return {
                ...state,
                syncHistoryList: action.payload.data,
            };
        case GETSEARCH_SYNC_HISTORY_LIST_ERROR:
            return {
                ...state,
                syncHistoryList: [],
            };
        case GETSEARCH_SYNC_APPROVAL_LIST:
            return {
                ...state,
                syncApproveItems: action.payload.data,
            };
        case GETSEARCH_SYNC_APPROVAL_LIST_ERROR:
            return {
                ...state,
                syncApproveItems: [],
            };

        default:
            return state;
    }
}
