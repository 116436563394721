import Select from 'react-dropdown-select'
import { List, AutoSizer } from 'react-virtualized';
import styled from '@emotion/styled';
function Dropdown({
    name,
    id,
    placeholder,
    isMulti = false,
    required = false,
    disabled=false,
    selectedValues = [],
    options = [],
    onChange
}) {

    const values = options.filter(option=> selectedValues.includes(option.value));
    const Item = styled.div`
                            display: flex;
                            padding: 0 10px;
                            align-items: center;
                            cursor: pointer;
                            width: 480px;
                            height: 40px;
                            
                            &:hover {
                                background: #0071bc;
                                color:#fff
                            }
                            
                            ${({ disabled }) => disabled && 'text-decoration: line-through;'}
                            `;

    const StyledList = styled(List)`
                                    overflow: auto;
                                    height: 200px;
                                    max-height: 200px;
                                    `;
                                    
    const customDropdownRenderer = ({ methods, state, props }) => {
        const regexp = new RegExp(state.search, 'i');
        const items = props.searchable
            ? options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]))
            : options;

        return (
            <AutoSizer style={{ height: '200px' }}>
                {({ width, height }) => (
                    <StyledList
                        height={height}
                        rowCount={items.length}
                        rowHeight={40}
                        width={width - 2}
                        rowRenderer={({ index, style, key }) => (
                            <Item key={key}
                                style={style}
                                onClick={() => methods.addItem(items[index])}>
                                {items[index].label}
                            </Item>
                        )}
                    />
                )}
            </AutoSizer>
        );
    };


    return (

        <div>
            <Select
                id={id || name}
                name={id || name}
                dropdownRenderer={customDropdownRenderer}
                values={values}
                multi={isMulti}
                onChange={(value) => {
                    onChange && onChange(name, value);
                }
                }
                dropdownHandleRenderer={() => (
                    <span className='custom-select no-border ml-1'></span>
                )}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
            />
        </div>



    )
}

export default Dropdown;