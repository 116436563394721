import React, { useEffect, useState } from 'react';
import {
  FormInput, Card, Button, DatePicker,
  Col,
  Container, Form, ListGroup, ListGroupItem,
  Row,
  InputGroupAddon,
  InputGroupText, InputGroup,
  FormSelect, FormCheckbox
} from "shards-react";
import classNames from "classnames";
import InfoTooltip from "../../components/common/InfoTooltip";
import Select from 'react-select'
import InputBox from '../../components/common/InputBox';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import { setAlert } from "../../redux/actions/alert";
import { setAPIDate, setInputDate } from "../../utils/date";
import {
  clear_mileage_range_detail,
  get_mileage_range_detail,
  save_mileage_range_detail,
  get_country_name_list,
  get_region_list_mileage,
  get_base_distance_unit_name,
} from "../../redux/actions/user";

import {fixedDecimalPlaces, monetaryToNumber, numberToMonetaryRate, toNumber} from "../../utils/general";

function AddEditConfigRange({
  mileageRange,
  mileageRangeList,
  get_mileage_range_detail,
  clear_mileage_range_detail,
  save_mileage_range_detail,
  countryNameList,
  regionNameList,
  get_country_name_list,
  get_region_list_mileage,
  get_base_distance_unit_name,
  DistanceUnitName,
  IsMileageRangeSaved,
  setAlert
}) {

  let { refNumber } = useParams();
  const { className } = "";
  const [Saved, setSaved] = useState(IsMileageRangeSaved);
  const [IsReturn, setIsReturn] = useState("");
  const classes = classNames(className, "d-flex", "my-auto", "date-range", "rate-start-date");
  const history = useHistory();
  const [formData, setFormData] = useState({
    refNum: 0,
    subscriberId: 0,
    unit: '',
    rate: null,
    startRange: null,
    endRange: null,
    countryId: '1',
    regionId: 0,
    mileageRateLevelList: [],
    countryName: '',
    rateStartDate: null,
    rateEndDate: null,
    showAddLevel: 0
  });


  const [state, setState] = useState({
    items: [{ uniq: Math.random(), mileageRangeId: 0, unit: "", rate: null, startRange: 0, endRange: null, unlimited: 0 }]
  });
  useEffect(() => {
    if (
      refNumber !== null &&
      refNumber !== '' &&
      refNumber !== '0' &&
      refNumber !== 0
    ) {
      get_mileage_range_detail(refNumber);
    }
    return () => clear_mileage_range_detail();
  }, [refNumber]);
  useEffect(() => {
    get_base_distance_unit_name();
    get_country_name_list();
  }, []);



  useEffect(() => {
    if (formData.countryId) {
      get_region_list_mileage(formData.countryId);
    }
  }, [formData.countryId]);
  useEffect(() => {
    if (mileageRange && Object.keys(mileageRange).length !== 0) {
     
      setFormData({
        ...formData,
        refNum: mileageRange.refNum,
        subscriberId: mileageRange.subscriberId,
        countryId: mileageRange.countryId,
        regionId: mileageRange.regionId,
        rateStartDate: setAPIDate(mileageRange.rateStartDate),
        rateEndDate: setAPIDate(mileageRange.rateEndDate),
        distanceUnit: mileageRange.distanceUnit,
        mileageRateLevelList: mileageRange.mileageRateLevelList,
        showAddLevel: (mileageRange.mileageRateLevelList && mileageRange.mileageRateLevelList.length > 0) ? mileageRange.mileageRateLevelList[mileageRange.mileageRateLevelList.length - 1].unlimited : 0
      });
      state.items = mileageRange.mileageRateLevelList;
      state.items.map(item => {
        item.rateStartDate = setAPIDate(item.rateStartDate);
        item.rateEndDate = setAPIDate(item.rateEndDate);
        item.uniq = Math.random();
        item.rate = fixedDecimalPlaces(item.rate, 2);
        item.endRange = fixedDecimalPlaces(item.endRange, 2)
        
      });

    }
  }, [mileageRange]);
  useEffect(() => {
    if (IsMileageRangeSaved && IsMileageRangeSaved != 0) {
      if (Saved != IsMileageRangeSaved) {

        setSaved(IsMileageRangeSaved)
        if (IsReturn && IsReturn === "yes") {
          history.goBack();
        } else {
          setFormData({
            ...formData,
            refNum: 0,
            subscriberId: 0,
            countryId: 0,
            regionId: 0,
            rateStartDate: null,
            rateEndDate:null
          });
         setState({
            items: [{ uniq: Math.random(), mileageRangeId: 0, unit: "", rate: null, startRange: 0, endRange: null, unlimited: 0 }]
          });
          history.replace(`/sub-add-mileage-range/0`);

        }

      }

    }
  }, [IsMileageRangeSaved]);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeRange = (name, value) => {
    if (!(parseInt(value) > 10000000)) {
      setFormData({ ...formData, [name]: value });
    }
  };
  const onDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
   
    if (!formData.countryId || formData.countryId == "" || formData.countryId == 0) {
      setAlert("Please select Country", "danger");
    } 
    else if (!formData.rateStartDate || formData.rateStartDate == "" || formData.rateStartDate == 0) {
      setAlert("Please select Rate Start Date", "danger");
    }
    else if (state.items[state.items.length - 1].rate == null || state.items[state.items.length - 1].rate == 0 || state.items[state.items.length - 1].rate == "") {
      setAlert("Please enter rate", "danger");
    } 
    else if (state.items[state.items.length - 1].startRange == null || state.items[state.items.length - 1].startRange === "") {
      setAlert("Please enter startRange", "danger");
    }
    else if ((state.items[state.items.length - 1].endRange == null || state.items[state.items.length - 1].endRange == 0 || state.items[state.items.length - 1].endRange === "") && state.items[state.items.length - 1].unlimited == 0) {
      setAlert("Please enter endRange", "danger");
    }
    else {

      e.preventDefault();
      var count = 0
      var data = {
        refNum: refNumber,
        subscriberId: 0,
        countryId: formData.countryId,
        regionId: formData.regionId,
        rateStartDate: setInputDate(formData.rateStartDate),
        rateEndDate: setInputDate(formData.rateEndDate),
        mileageRateLevelList: state.items.map((option) => {
          count++;
          return {
            level: count,
            mileageRangeId: option.mileageRangeId,
            rate: option.rate,
            startRange: option.startRange,
            endRange: option.endRange,
            unlimited: option.unlimited
          };
        })
      };

      save_mileage_range_detail(data);
      
      if (e.target.name === "saveReturn") {
        setIsReturn("yes");
      }
      else {
        setIsReturn("no");
      }
      
    }
  };

  let addListItem = () => {
   
    if (state.items[state.items.length - 1].rate == null || state.items[state.items.length - 1].rate == 0 || state.items[state.items.length - 1].rate == "") {
      setAlert("Please enter rate", "danger");
    } else
      if (state.items[state.items.length - 1].startRange == null || state.items[state.items.length - 1].startRange === "") {
        setAlert("Please enter startRange", "danger");
      } else
        if ((state.items[state.items.length - 1].endRange == null || state.items[state.items.length - 1].endRange == 0 || state.items[state.items.length - 1].endRange === "") && state.items[state.items.length - 1].unlimited == 0) {
          setAlert("Please enter endRange", "danger");
        }
        else {
          let lastMileageRange = (state.items.length) ? (numberToMonetaryRate(state.items[state.items.length - 1].endRange)) : 0;

          const newItem = { uniq: Math.random(), mileageRangeId: 0, unit: "", rate: 0.00, startRange: parseFloat(lastMileageRange) + 0.01, endRange: 0.00, unlimited: 0 };
          setState({
            items: [...state.items, newItem]
          });
        }
  };

  let createList = () => {
    if (!state.items || state.items.length == 0) {
      const newItem = { uniq: Math.random(), mileageRangeId: 0, unit: "", rate: null, startRange: 0, endRange: null, unlimited: 0 };
      setState({
        items: [...state.items, newItem]
      });
    }
    const { items } = state;
    var count = 0;
    return items.map(item => {
      item.startRange=numberToMonetaryRate(item.startRange)
      //uncommented the lines to make level 1 deletion to initial stage
      item.rate=numberToMonetaryRate(item.rate)
      item.endRange=numberToMonetaryRate(item.endRange)
      count++;
      return (
        <div className="position-relative col-12" key={`Level${count}`}>
          <div className="tableHeader d-flex mt-3">
            <label className="text-bold m-0 mx-1" htmlFor="mileageRateLevelList">Level {count}
            </label>

          </div>
          <Card small className="mb-2 w-100 details-card mt-3 d-flex">
            <ListGroup flush className="">
              <ListGroupItem className="p-3">

                <Row >

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label htmlFor="unit">Distance Unit
                      <span aria-hidden="true" className="required">*</span>
                    </label> <InfoTooltip
                      msg="Define Measurement unit for Distance travelled" />

                    <FormInput
                      id="unit"
                      name='unit'
                      value={DistanceUnitName} disabled
                      onChange={onChange}
                      placeholder="Enter Distance Unit"
                    />

                  </Col>
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label htmlFor="rate">Rate  <span aria-hidden="true" className="required">*</span></label>
                    <InfoTooltip
                      msg="Define Mileage Rate to calculate amount based on Distance travelled" />
                    <FormInput
                      id="rate"
                      name='rate'
                      value={item.rate}
                      onChange={e => onInputChange(e, item.uniq)}
                      placeholder="0.00"
                      //type="Number"
                    />

                  </Col>
                  <Col lg="3" className="form-group p-2 mb-0">

                    <label htmlFor="startRange">Range Start  <span aria-hidden="true" className="required">*</span></label>
                    <InfoTooltip
                      msg="Define Start point of Range to apply Mileage Rate" />

                    <FormInput
                      id="startRange"
                      name='startRange'
                      value={item.startRange}
                      onChange={e => onInputChange(e, item.uniq)}
                      placeholder="0.00"
                      disabled
                      //type="Number"
                    />

                  </Col>
                  <Col lg="3" className="form-group p-2 mb-0">

                    <label htmlFor="endRange">Range End <span aria-hidden="true" className="required">*</span></label>
                    <InfoTooltip
                      msg="Define End point of Range to apply Mileage Rate" />

                    <FormInput
                      id="endRange"
                      name='endRange'
                      value={item.endRange}
                      onChange={e => onInputRangeChange(e, item.uniq)}
                      //type="Number"
                      disabled={item.unlimited == 1 || state.items[state.items.length - 1].uniq != item.uniq ? true : false}
                      placeholder="0.00"
                    />
                    <FormCheckbox checked={item.unlimited == 1 ? true : false} value={item.unlimited} disabled={item.endRange > 0 ? true : false} id="unlimited" name="unlimited" defaultChecked={item.unlimited == 1 ? true : false} onChange={e => onInputUnlimitedChange(e, item.uniq)}><span className="text-primary text-bold">Unlimited</span>
                    </FormCheckbox>

                  </Col></Row>
                <Row>
                  <Col sm="12" md="12" lg="12"
                    className="  p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

                    <Button className="mx-1 py-2 mx-md-2" size="sm"
                      theme="accent" type="button" onClick={e => deleteLevel(e, item)}

                    >Delete</Button>
                  </Col>
                </Row>

              </ListGroupItem>
            </ListGroup>
          </Card>

        </div>
      );
    });
  };

  let deleteLevel = (e, rule) => {

    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete Level?',
      visible: true,
      onConfirm: () => {
        var shouldDelete = mileageRange.mileageRateLevelList ? state.items.length === mileageRange.mileageRateLevelList.length : false;
        
        if (shouldDelete) {
          const index = state.items.findIndex(v => v.uniq === rule.uniq); //use id instead of index
          if (index > -1) {
            state.items.splice(index, 1);
          }
          // delete_leave_policy_level_details(formData.leavePolicyId, rule.id);
          var i = mileageRange.mileageRateLevelList.findIndex(v => v.uniq === rule.uniq);
          if (i > -1) {
            mileageRange.mileageRateLevelList.splice(i, 1);
          }
        } else {
          const index = state.items.findIndex(v => v.uniq === rule.uniq); //use id instead of index
          if (index > -1) {
            state.items.splice(index, 1);
          }
        }

        ///After deleting save the information
        let count = 0
        let data = {
          refNum: refNumber,
          subscriberId: 0,
          countryId: formData.countryId,
          regionId: formData.regionId,
          rateStartDate: setInputDate(formData.rateStartDate),
          rateEndDate: setInputDate(formData.rateEndDate),
          mileageRateLevelList: state.items.map((option) => {
            count++;
            return {
              level: count,
              mileageRangeId: option.mileageRangeId,
              rate: option.rate,
              startRange: option.startRange,
              endRange: option.endRange,
              unlimited: option.unlimited
            };
          })
        }; 
        save_mileage_range_detail(data);
        setIsReturn("yes");
      }
    })
  };
  let onInputChange = (e, currentId) => {
   
    const newArr = state.items.map(item => {
      if (item.uniq == currentId) {
        return {
          ...item,
          [e.target.name]: toNumber(e.target.value, 2)
        };
      } else {
        return item;
      }
    });

    setState({
      items: newArr
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  let onInputRangeChange = (e, currentId) => {
   
    const newArr = state.items.map(item => {
      if (item.uniq == currentId) {
        return {
          ...item,
          [e.target.name]: toNumber(e.target.value, 2)
        };
      } else {
        return item;
      }
    });

    setState({
      items: newArr
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  let onInputUnlimitedChange = (e, currentId) => {
   

    const newArr = state.items.map(item => {
      if (item.uniq == currentId) {
        return {
          ...item,
          [e.target.name]: e.target.checked ? 1 : 0
        };
      } else {
        return item;
      }
    });

    setState({
      items: newArr
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });


  // useEffect(() => {
  //   if ((refNumber !== "0" && formData.endRange <= formData.startRange) || (refNumber === "0" && formData.endRange <= lastMileageRange))
  //     document.getElementById("endRange").setCustomValidity('End range should be greater than start range');
  //   else
  //     document.getElementById("endRange").setCustomValidity('');
  // }, [formData.endRange, formData.startRange]);

  var regionNameListOption = regionNameList.map((option) => {
    return <option key={option.regionName + option.regionId} value={option.regionId}>{option.regionName}</option>;
  });
  var countryNameListOption = countryNameList.map((option) => {
    return <option key={option.countryName + option.countryId} value={option.countryId}>{option.countryName}</option>;
  });


  return (<>
    <ConfirmAlert confirmAlert={confirmAlert}
      setConfirmAlert={setConfirmAlert} />
    <Container fluid className="main-content-container p-4">
      <Card small className="mb-5 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form id="clientDetailsForm" >
                <Card small className="mb-5 w-100">
      
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
                  <Row className="pl-3 pr-3">
                    <Col lg="3" className="form-group p-2 mb-0">
                      <label htmlFor="startRange">Country
                        <span aria-hidden="true" className="required">*</span>
                      </label>

                      <FormSelect
                        id="countryId"
                        name='countryId'

                        //defaultValue="1"
                        onChange={onChange}
                        value={formData.countryId}>
                        <option value="">Select Country</option>
                        {countryNameListOption}

                      </FormSelect>
                    </Col>
                    <Col lg="3" className="form-group p-2 mb-0">
                      <label htmlFor="startRange">State/Province
                        <span aria-hidden="true" className="required">*</span>
                      </label>
                      <FormSelect name='regionId'
                        value={formData.regionId}
                        onChange={onChange} className='d-block'>
                        <option value=''>All</option>
                        {regionNameListOption}
                      </FormSelect>
                    </Col>
                    <Col lg="3" className="form-group p-2 mb-0">
                      <label htmlFor="rateStartDate">Rate Start Date
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="Start Date of the Mileage Rate to apply" />
                      </label>

                      <InputGroup className={classes} >
                        <DatePicker

                          id='rateStartDate'
                          autoComplete='off'
                          name='rateStartDate'
                          dateFormat='yyyy-MM-dd'
                          placeholderText='YYYY-MM-DD'
                          selected={formData.rateStartDate}
                          onChange={(date) =>
                            setFormData({
                              ...formData,
                              rateStartDate: date
                            })
                          }

                          value={formData.rateStartDate}

                        />

                        <InputGroupAddon type="append" >
                          <InputGroupText>
                            <i className="material-icons">&#xE916;</i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                    </Col>
                    <Col lg="3" className="form-group p-2 mb-0">
                      <label htmlFor="rateEndDate">Rate End Date</label>
                      <InfoTooltip
                        msg=" The End Date of the Mileage Rate is automatically created when Subscriber creates a new Mileage Rate. The End Date will be the day before the new Rate comes into effect" />
                      <DatePicker
                        id='rateEndDate'
                        autoComplete='off'
                        name='rateEndDate'
                        dateFormat='yyyy-MM-dd'

                        selected={formData.rateEndDate}
                        onChange={(date) =>
                          onDateChange("rateEndDate", date)
                        }
                        value={formData.rateEndDate} disabled
                      />



                    </Col>
                  </Row>
                  <Row style={{ overflow: "auto" }}>

                    {createList()}
                  </Row>
                  
                  <Row className="px-3">

                    <Col sm="12" md="12" lg="12"
                      className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                      <Button className="mx-1 py-2 mx-md-2" size="sm" disabled={state.items.length > 0 && state.items[state.items.length-1].unlimited > 0 ? true : false}
                        theme="accent" type="button" onClick={addListItem}
                      >Add Mileage Level</Button>


                    </Col>
                  </Row>


                  </ListGroupItem>
        </ListGroup>
      </Card>
                  <Row>
                    <Col className="form-group p-2 m-0 d-flex justify-content-md-end">
                      {refNumber == 0 &&
                        <button type="button" className="btn btn-primary " name="saveAddAnother" onClick={onSubmit}>Save and Add Another</button>
                      }
                      <button type="button" name="saveReturn"
                        className="btn btn-primary ml-2" onClick={onSubmit}>Save and Return
                      </button>
                      <button onClick={goBack}
                        className="btn btn-primary ml-2 ">Cancel
                      </button>

                    </Col>
                  </Row>




                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container></>
  );
}

AddEditConfigRange.propTypes = {
  //isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  get_mileage_range_detail: PropTypes.func.isRequired,
  save_mileage_range_detail: PropTypes.func.isRequired,
  mileageRange: PropTypes.object.isRequired,
  get_country_name_list: PropTypes.func.isRequired,
  get_region_list_mileage: PropTypes.func.isRequired,
  countryNameList: PropTypes.array.isRequired,
  regionNameList: PropTypes.array.isRequired,
  get_base_distance_unit_name: PropTypes.func.isRequired,
  DistanceUnitName: PropTypes.string,
  setAlert: PropTypes.func.isRequired,
  IsMileageRangeSaved: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => ({
  //isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  message: state.user.message,
  mileageRange: state.user.mileageRange,
  mileageRangeList: state.user.mileageRangeList,
  countryNameList: state.user.countryNameList,
  regionNameList: state.user.regionNameList,
  DistanceUnitName: state.user.DistanceUnitName,
  IsMileageRangeSaved: state.user.IsMileageRangeSaved,
});
export default connect(mapStateToProps, {
  get_mileage_range_detail,
  clear_mileage_range_detail,
  save_mileage_range_detail,
  get_country_name_list,
  get_region_list_mileage,
  get_base_distance_unit_name,
  setAlert
})(AddEditConfigRange);
