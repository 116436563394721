import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";
import {Link, useHistory} from "react-router-dom";
import ReactTable from "react-table";
import {get_sub_client_list,delete_client} from "../../redux/actions/user";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import Alerts from "../../components/common/Alerts";
import { getStatusClass } from '../../utils/general';
import { get_client_status_list } from '../../redux/actions/subscriber/subscriberUsers';

function SubscriberClients({ clientList, get_sub_client_list, delete_client,
                             IsClientdelted, get_client_status_list, clientStatusList,
                           }) {

  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,

    clientName: '',
    clientId: '',
    colorCode: '',
    status: ''
  });
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });
  const onActionChange = (e, clientId) => {
   
    setConfirmAlert({
      confirmMsg: "Are you sure you want to delete Client?",
      visible: true,
      onConfirm: () => {
        IsClientdelted=false;
        delete_client(clientId);
      }
    })
  };
  const history = useHistory()


  useEffect(() => {
    get_sub_client_list(formData.clientId, formData.clientName, formData.colorCode, formData.status);
    get_client_status_list();
  }, []);
  useEffect(() => {
    if (IsClientdelted && IsClientdelted != false) {
      get_sub_client_list(formData.clientId, formData.clientName, formData.colorCode, formData.status);
    }
  }, [IsClientdelted]);
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    get_sub_client_list(formData.clientId, formData.clientName, formData.colorCode, formData.status);
  };

  var clientStatusListOption = clientStatusList.map((option) => {
    return <option value={option} key={option}>{option}</option>;
  });

  const tableData = clientList;
  const {pageSize, pageSizeOptions} = formData;


  const tableColumns = [
    {
      Header: "ID",
      accessor: "clientId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/sub-manage-client-profile/${row.original.clientId}`}>{row.original.clientId}</Link>
    },
    {
      Header: "Name",
      accessor: "clientName",
      minWidth: 300,
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: "Status",
      accessor: "status",
      minWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },
    {
      Header: "Color Code",
      accessor: "colorCode",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <span>{row.original.colorCode}</span>,
    },
    {
      Header: "Action",
      maxWidth: 140,
      Cell: row =>
        <FormSelect className="row-action-select py-0" value=''
                    onChange={e => onActionChange(e, row.original.clientId)}>
          <option value=''>Select</option>
          <option value='Delete' className='text-danger'>Delete Client</option>
         
         
        </FormSelect>
     
    }
  ];
  return (
    <React.Fragment>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Alerts/>
    <Container fluid className="main-content-container px-4 pb-4">

      <div noGutters
           className="page-header tableSearch flex-column flex-md-row py-2 d-flex justify-content-end bg-white mt-4 ">
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='clientName'
            value={formData.clientName}
            onChange={onChange}
            placeholder="Client Name"
          />
        </div>
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='clientId'
            value={formData.clientId}
            onChange={onChange}
            placeholder="Client ID"
          />
        </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='status' value={formData.status}
              onChange={onChange}>
              <option value=''>All Status</option>
              {clientStatusListOption}
            </FormSelect>
          </div>
        <div className="d-flex align-items-center p-2">

          <FormSelect name='colorCode' value={formData.colorCode}
                      onChange={onChange}>
            <option value=''>All Colors</option>
            <option value='GREEN' className="bg-success">Green</option>
            <option value='ORANGE' className="bg-warning">Orange</option>
            <option value='RED' className="bg-danger">Red</option>
          </FormSelect>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                  onClick={onSubmit}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col lg="2"
                className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={formData.pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

                <Col lg="6" className="d-flex align-items-center">
                    <p className='m-0'>Note - This section shows only Active Clients</p>
                </Col>

              <Col lg="4"
                className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6">
                <Link to="/sub-manage-client-profile/0">
                  <Button className="mx-2 py-2 my-2" size='sm'
                          theme="accent">Add Client</Button>
                </Link>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
    </React.Fragment>
  );
}

SubscriberClients.propTypes = {
  IsClientdelted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  get_sub_client_list: PropTypes.func.isRequired,
  delete_client: PropTypes.func.isRequired,
  clientList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  clientList: state.user.clientList,
  IsClientdelted: state.user.IsClientdelted,
  delete_client: state.user.delete_client,
  clientStatusList: state.subscriberUsers.clientStatusList,
});
export default connect(mapStateToProps, { get_sub_client_list, delete_client
  , get_client_status_list
})(
  SubscriberClients
);

