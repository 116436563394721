import React from 'react';
import {
  Card,
  Col,
  FormCheckbox,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {Link} from "react-router-dom";
import {default as queryString} from "qs";
import { useLocation } from "react-router-dom";

function Roles({ userId,firstName,lastName,clientName,
                 workerFlag,
                 IsSaveWorker,
                 IsSaveClientUser,
                 IsSaveClientHR,
                 IsSaveClientBilling,
                 IsSaveClientAdmin,
                 clientUserFlag,
                 subscriberUserFlag,
                 subscriberApiIntegrationFlag,
                 clientHRFlag,
                 subscriberHRFlag,
                 clientBillingFlag,
                 subscriberBillingFlag,
                 clientAdminFlag,
                 subscriberAdminFlag,
                 systemAdminFlag,
                 isSystemAdmin,
                 onToggle,
                 //AddedBy Nipul
                 clientLeadFlag,
                 workerLeadFlag,

               }) {

              

const location = useLocation();             
let queryParams = queryString.parse(location.search.replace("?", ""))
                 
  return (

    <span id="roles">
    <Card  small
          className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fa fa-user iconStyle"/></span>
      <h4 className="m-0 section-title headerStyle">Roles</h4>
      <ListGroup flush>

        <ListGroupItem className="p-3 mt-3">
          <Row>
            <Col>
              <Row form>
                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="workerFlag" name="workerFlag"
                                checked={workerFlag} onChange={onToggle}
                                toggle small>Worker</FormCheckbox>
                                {(workerFlag && userId>0 && IsSaveWorker)&& 
                                <div className='pl-5'><Link to={`/sub-manage-permissions/`+userId+'?role=Worker&userName='+firstName+ ' ' +lastName+'&clientName='+clientName}><label className='text-primary text-decoration-underline'>Manage Permission</label></Link> </div>}
                </Col>

                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="clientUserFlag" name="clientUserFlag"
                                checked={clientUserFlag} onChange={onToggle}
                                toggle small>Client User</FormCheckbox>
                                {(clientUserFlag && userId>0 && IsSaveClientUser)&& 
                                <div className='pl-5'> <Link to={`/sub-manage-permissions/`+userId+'?role=Client User&userName='+firstName+ ' ' +lastName+'&clientName='+clientName}><label className='text-primary text-decoration-underline'>Manage Permission</label></Link></div>}
                </Col>

                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="subscriberUserFlag"
                                name="subscriberUserFlag"
                                checked={subscriberUserFlag}
                                onChange={onToggle} toggle small>Subscriber
                               User</FormCheckbox>
                             
                </Col>

                {Boolean(isSystemAdmin) && <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="subscriberApiIntegrationFlag"
                                name="subscriberApiIntegrationFlag"
                                checked={subscriberApiIntegrationFlag}
                                onChange={onToggle} toggle small>Subscriber
                    Api Integration</FormCheckbox>
                  
                </Col>}
                <Col lg="3" className="form-group offset-lg-3 mb-0 p-2">
                  <FormCheckbox id="clientHRFlag" name="clientHRFlag"
                                checked={clientHRFlag} onChange={onToggle}
                                toggle small>Client HR</FormCheckbox>
                                {(clientHRFlag && userId>0 && IsSaveClientHR)&&
                                <div className='pl-5'><Link to={`/sub-manage-permissions/`+userId+'?role=Client HR&userName='+firstName+ ' ' +lastName+'&clientName='+clientName}><label className='text-primary text-decoration-underline'>Manage Permission</label></Link></div>}
                </Col>

                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="subscriberHRFlag" name="subscriberHRFlag"
                                checked={subscriberHRFlag} onChange={onToggle}
                                toggle small>Subscriber HR</FormCheckbox>
                               
                </Col>


                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="workerLeadFlag" name="workerLeadFlag"
                    checked={workerLeadFlag} onChange={onToggle} toggle small>
                    Worker Lead
                  </FormCheckbox>
                </Col>

                <Col lg="3" className="form-group offset-lg-3 mb-0 p-2">
                  <FormCheckbox id="clientBillingFlag"
                                name="clientBillingFlag"
                                checked={clientBillingFlag}
                                onChange={onToggle} toggle small>Client
                    Billing</FormCheckbox>
                    {(clientBillingFlag && userId>0 && IsSaveClientBilling)&&
                                <div className='pl-5'><Link to={`/sub-manage-permissions/`+userId+'?role=Client Billing&userName='+firstName+ ' ' +lastName+'&clientName='+clientName}><label className='text-primary text-decoration-underline'>Manage Permission</label></Link></div>}
                </Col>

                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="subscriberBillingFlag"
                                name="subscriberBillingFlag"
                                checked={subscriberBillingFlag}
                                onChange={onToggle} toggle small>Subscriber
                    Billing</FormCheckbox>
                   
                </Col>

                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="clientLeadFlag" name="clientLeadFlag"
                    checked={clientLeadFlag} onChange={onToggle} toggle small>
                    Client Lead
                  </FormCheckbox>
                </Col>

                <Col lg="3" className="form-group offset-lg-3 mb-0 p-2">
                  <FormCheckbox id="clientAdminFlag"
                                name="clientAdminFlag"
                                checked={clientAdminFlag}
                                onChange={onToggle} toggle small>Client
                    Admin</FormCheckbox>
                    {(clientAdminFlag && userId>0 && IsSaveClientAdmin)&& 
                                <div className='pl-5'><Link to={`/sub-manage-permissions/`+userId+'?role=Client Admin&userName='+firstName+ ' ' +lastName+'&clientName='+clientName}><label className='text-primary text-decoration-underline'>Manage Permission</label></Link></div>}
                </Col>

                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="subscriberAdminFlag"
                                name="subscriberAdminFlag"
                                checked={subscriberAdminFlag}
                                onChange={onToggle} toggle small>Subscriber
                    Admin</FormCheckbox>
                   
                </Col>

                {Boolean(isSystemAdmin) &&
                <Col lg="3" className="form-group mb-0 p-2">
                  <FormCheckbox id="systemAdminFlag" name="systemAdminFlag"
                                checked={systemAdminFlag} onChange={onToggle}
                                toggle small>System Admin</FormCheckbox>
                               
                </Col>}
              </Row>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
    </span>
  )
}

export default Roles;
