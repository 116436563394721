import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { setDateForTable, setInputDate } from '../../../utils/date';
import { formatNumberAmount } from '../../../utils/general';
import { Button, Card, CardBody, CardHeader, Col, Container, FormInput, FormSelect, Row } from 'shards-react';
import RangeDatePicker from '../../../components/common/RangeDatePicker';
import { bulk_delete_payroll_tax_data, download_payroll_tax_data, get_import_payroll_tax_list, get_payperiod_list } from '../../../redux/actions/subscriber/payroll';
import ReactTable from 'react-table';
import { setAlert } from '../../../redux/actions/alert';
import ConfirmAlert from '../../../components/common/ConfirmAlert';

const PayrollTaxData = ({ get_payperiod_list, payPeriodList, get_import_payroll_tax_list
    , payrollTaxList, download_payroll_tax_data, bulk_delete_payroll_tax_data, setAlert
}) => {
    const initialData = {
        userId: '',
        client: '',
        workerName: '',
        processed: '',
        payPeriod: '',
        from: '',
        to: '',

        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        bulkAction: '',
    }
    const [formData, setFormData] = useState(initialData);
    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState([]);

    let payPeriodListOptions = payPeriodList.map(item => <option key={item.refNum} value={item.payPeriodName}>{item.payPeriodName}</option>)
    const tableRef = useRef()

    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });

    useEffect(() => {
        get_payperiod_list();
        get_import_payroll_tax_list('', '', '', '', '', 0, 0);
    }, [])

    useEffect(() => {
        setGridData(payrollTaxList.map(x => { x.checked = false; return x; }));
    }, [payrollTaxList])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    let onStartDateChanged = (value) => {
        setFormData({ ...formData, from: value })
    }

    let onEndDateChanged = (value) => {
        setFormData({ ...formData, to: value })
    }

    const isAllChecked = () => {
        if (gridData === undefined || gridData.length === 0) return false

        for (let data of gridData) {
            if (!data.checked)
                return false;
        }
        return true;
    }

    const onCheck = (refNum) => {
        let checkedTableData = gridData.map(data => {
            if (data.refNum === refNum)
                data.checked = !data.checked
            return data;
        })
        setGridData(checkedTableData);
    }

    const checkAll = (e) => {
        let checkedTableData = gridData.map(data => {
            data.checked = e.target.checked;
            return data;
        })
        setGridData(checkedTableData);
    }

    const tableColumns = [
        {
            Header: <input type="checkbox" checked={isAllChecked()} onClick={(e) => checkAll(e)} />,
            accessor: "action", sortable: false,
            maxWidth: 50, minWidth: 50,
            Cell: row => <input type="checkbox" checked={row.original.checked} key={row.original.refNum} onClick={() => onCheck(row.original.refNum)} />
        },
        {
            Header: "User ID", accessor: "userId", className: "text-center",
        },
        {
            Header: "Client", accessor: "clientName", className: "text-center",
            Cell: row => <div title={row.original.clientName}>{row.original.clientName}</div>
        },
        {
            Header: "Worker Name", accessor: "userName", className: "justify-content-start",
            Cell: row => <div title={row.original.userName}>{row.original.userName}</div>
        },
        {
            Header: "Processed", accessor: "processed", className: "text-center",
            Cell: row => (row.original.processed ? 'True' : 'False')
        },
        {
            Header: "Date", accessor: "date", className: "text-center",
            Cell: row => setDateForTable(row.original.date)
        },
        {
            Header: "PPE", accessor: "payPeriodEnding", className: "text-center",
        },
        {
            Header: "Employee Name", accessor: "employeeName", className: "text-center",
            Cell: row => <div title={row.original.employeeName}>{row.original.employeeName}</div>
        },
        {
            Header: "Employee Number", accessor: "employeeNumber", className: "text-center",
        },
        {
            Header: "CPP Employee", accessor: "cppEmployee",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.cppEmployee, 'en-US', 2)}</span>
        },
        {
            Header: "CPP Employer", accessor: "cppEmployer",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.cppEmployer, 'en-US', 2)}</span>
        },
        {
            Header: "EI Employee", accessor: "eiEmployee",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.eiEmployee, 'en-US', 2)}</span>
        },
        {
            Header: "EI Employer", accessor: "eiEmployer",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.eiEmployer, 'en-US', 2)}</span>
        },
        {
            Header: "Federal Tax", accessor: "federalTax",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.federalTax, 'en-US', 2)}</span>
        },
        {
            Header: "Prov Med", accessor: "provMed",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.provMed, 'en-US', 2)}</span>
        },
        {
            Header: "WCB", accessor: "wcb", maxWidth: 120, minWidth: 80,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.wcb, 'en-US', 2)}</span>
        },
        {
            Header: "Employer Total", accessor: "employerTotal",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.employerTotal, 'en-US', 2)}</span>
        },
    ];

    function downloadReport(e) {
        e.preventDefault();
        let selecteList = gridData.filter(data => data.checked).map(mapData => mapData.refNum);
        if (selecteList !== undefined && selecteList.length > 0) {
            //call an API
            let apiJson = { refNumList: selecteList }
            download_payroll_tax_data(apiJson);
        }
        else {
            setAlert('Please select record(s) to download report', 'danger');
        }
    }

    function deleteSelected(e) {
        e.preventDefault();
        
        let selecteList = gridData.filter(data => data.checked).map(mapData => mapData.refNum);
        if (selecteList !== undefined && selecteList.length > 0) {
            //call an API 
            let apiJson = { refNumList: selecteList }
            setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Do you want to delete selected payroll tax?',
                visible: true,
                onConfirm: async () => {
                    await bulk_delete_payroll_tax_data(apiJson);
                    resetPayrollTaxData();
                }
            })
        }
        else {
            setAlert('Please select record(s) to delete payroll tax', 'danger');
        }
    }

    function searchPayrollTaxData(e) {
        e.preventDefault();
        const fromDate = setInputDate(formData.from);
        const toDate = setInputDate(formData.to);
        get_import_payroll_tax_list(formData.userId, formData.client, formData.workerName, formData.processed, formData.payPeriod, fromDate, toDate);
    }

    function resetPayrollTaxData() {
        setFormData(initialData);
        get_import_payroll_tax_list('', '', '', '', '', 0, 0);
        get_payperiod_list();
    }

    return (
        <>
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
            <Container fluid className="main-content-container p-4">
                <div className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
                    <div className="d-flex align-items-center p-1 ml-2">
                        <FormInput name='userId' value={formData.userId} size='50px'
                            onChange={onChange} placeholder="User Id" />
                    </div>

                    <div className="d-flex align-items-center p-1">
                        <FormInput name='client' value={formData.client}
                            onChange={onChange} placeholder="Client" />
                    </div>

                    <div className="d-flex align-items-center p-1">
                        <FormInput name='workerName' value={formData.workerName}
                            onChange={onChange} placeholder="Worker Name" />
                    </div>

                    <div className="d-flex align-items-center p-1">
                        <FormSelect
                            id="processed"
                            name="processed"
                            value={formData.processed}
                            onChange={onChange}>
                            <option key={`process`} value=''>Processed</option>
                            <option key={`true`} value='True'>True</option>
                            <option key={`false`} value='False'>False</option>
                        </FormSelect>
                    </div>

                    <div className="d-flex align-items-center p-1">
                        <FormSelect
                            id="payPeriod"
                            name="payPeriod"
                            value={formData.payPeriod}
                            onChange={onChange}>
                            <option key={`status`} value=''>Select Pay Period</option>
                            {payPeriodListOptions}
                        </FormSelect>
                    </div>
                    <div className="d-flex align-items-center p-1">
                        <RangeDatePicker className="justify-content-end" name="range"
                            startDate={formData.from} endDate={formData.to}
                            onStartDateChanged={onStartDateChanged} onEndDateChanged={onEndDateChanged} />
                    </div>

                    <div className="d-flex align-items-center justify-content-end p-1">
                        <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                            onClick={searchPayrollTaxData}
                        >Search</Button>

                        <Button className="mx-2 py-2 my-2"
                            onClick={resetPayrollTaxData}
                            size='sm' theme="accent">Reset</Button>
                    </div>
                </div>

                <div className="w-100">
                    <Card className="tableCard p-0">
                        <CardHeader className="p-0">
                            <Container fluid className="file-manager__filters border-bottom">
                                <Row>
                                    {/* Filters :: Page Size */}
                                    <Col lg="12" className="file-manager__filters__rows d-flex justify-content-between">

                                        <span><span>Show</span>
                                            <FormSelect size="sm" name='pageSize'
                                                value={formData.pageSize} className="d-inline"
                                                onChange={onChange}
                                            >
                                                {pageSizeOptions.map((size, idx) => (
                                                    <option key={idx} value={size}>
                                                        {size} rows
                                                    </option>
                                                ))}
                                            </FormSelect>
                                        </span>
                                        <span>
                                            <Button className="my-2 py-2 mx-2" size='sm'
                                                onClick={downloadReport} theme="accent">Download
                                            </Button>
                                            <Button className="my-2 py-2" size='sm'
                                                onClick={deleteSelected} theme="danger">Delete
                                            </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                        </CardHeader>
                        <CardBody className="p-0">
                            <div className="">
                                <ReactTable
                                    ref={tableRef}
                                    columns={tableColumns}
                                    data={gridData}
                                    pageSize={Number(pageSize)}
                                    showPageSizeOptions={false}
                                    resizable={false}
                                    noDataText="No results found"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </>
    )
}
const mapStateToProps = (state) => ({
    payPeriodList: state.payroll.payPeriodList,
    payrollTaxList: state.payroll.payrollTaxList,
});

export default connect(mapStateToProps, {
    get_payperiod_list, get_import_payroll_tax_list
    , bulk_delete_payroll_tax_data, download_payroll_tax_data, setAlert
})
    (PayrollTaxData)
