import React, { useEffect, useRef, useState } from 'react'
import { get_payee_dropdown_list, get_payer_dropdown_list } from '../../../redux/actions/subscriber/payConfiguration';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmAlert from '../../../components/common/ConfirmAlert';
import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, FormTextarea, ListGroup, ListGroupItem, Row } from 'shards-react';
import { compareObjects, formatNumberAmount } from '../../../utils/general';
import { setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { NavgationPageLocationKey, STANDARD_FORMATS, SUBSCRIBER_BILLING_ADMIN, SUBSCRIBER_ROLES } from '../../../utils/constants';
import StandardFormInputFreeWidth from '../../../components/common/StandardFormInputFreeWidth';
import { connect } from 'react-redux';
import { get_currency_list } from '../../../redux/actions/subscriber/invoice';
import { get_base_currency } from '../../../redux/actions/user';
import Dropdown from "../../../components/common/Dropdown";
import { setAlert } from "../../../redux/actions/alert";
import { delete_payment_allocation, download_payment, get_payment_information, get_payment_type_list, reset_payment_view, save_new_payment } from '../../../redux/actions/subscriber/payments';

const NewPayment = ({ payeeDropdownList, get_payee_dropdown_list, get_base_currency
    , get_currency_list, baseCurrency, currencyList, get_payer_dropdown_list
    , payerDropdownList, get_payment_type_list
    , paymentTypeList, get_payment_information, paymentInfo
    , save_new_payment, user, download_payment, reset_payment_view
}) => {
    const initialState = {
        paymentRefNum: '0',
        payeeId: 0,
        payerId: 0,
        type: 'Incoming',
        currency: '',
        paymentDate: '',
        paymentStatus: 'Draft',
        paymentAmount: 0,
        totalUnallocatedAmount: 0,
        totalBalanceAmount: 0,
        transactionId: '',
        paymentNote: '',
        totalAllocationAmount: 0,
        paymentAllocationList: []
    }

    //const { refnum } = useParams();
    const history = useHistory();
    const backStepRef = useRef(0)
    const [formData, setFormData] = useState({ ...initialState })
    const formDataRef = useRef({});
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });

    const payeeListOptions = (payeeDropdownList || []).map(payee => ({ value: payee.refId, label: payee.payeeName }));
    const payerListOptions = (payerDropdownList || []).map(payer => ({ value: payer.refId, label: payer.payerName }));
    const currencyListOptions = (currencyList || []).map(currency => <option key={currency} value={currency}>{currency}</option>)
    const paymentTypeOptions = (paymentTypeList || []).map(type => <option key={type} value={type}>{type}</option>)
    
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangeStandardInput = (name, value) => setFormData({ ...formData, [name]: value });
    const [isSubmitting, setSubmitting] = useState(false);

    let [redirectAddNewItem, setRedirectAddNewItem] = useState(false);

    //For storing state and ids
    const location = useLocation();
    const [stateId, setStateId] = useState(0);

    useEffect(() => {
        if(stateId === 0){
            if(location.state !== undefined && location.state !== null){
                //Take from state
                setStateId(location.state?.refNum);
                get_payment_information(location.state?.refNum)
                console.log("state => ", location.state)
            }
            else if(localStorage.getItem(NavgationPageLocationKey.PaymentCurrentAddEdit) !== null){
                //Take from local storage 
                setStateId(localStorage.getItem(NavgationPageLocationKey.PaymentCurrentAddEdit));
                get_payment_information(localStorage.getItem(NavgationPageLocationKey.PaymentCurrentAddEdit))
                const newState = { refNum: localStorage.getItem(NavgationPageLocationKey.PaymentCurrentAddEdit) };
                history.push(location.pathname, newState);
                localStorage.removeItem(NavgationPageLocationKey.PaymentCurrentAddEdit);
                console.log("local storage => ")
            }
        }
        
        // if (refnum !== '0' && refnum !== undefined) {
        //     get_payment_information(refnum)
        // }
        get_currency_list();
        get_base_currency();
        get_payee_dropdown_list();
        get_payer_dropdown_list();
        get_payment_type_list();

        reset_payment_view();
    }, [])

    // useEffect(() => {
    //     backStepRef.current = backStepRef.current - 1;
    //     if (Number(refnum) === 0) {
    //         setFormData({ ...initialState, currency: baseCurrency.currencyCode, paymentRefNum: refnum })
    //     }
    // }, [refnum])

    useEffect(() => {
        if(paymentInfo !== undefined){
            setFormData({ ...formData, ...getFormData(paymentInfo) })

            formDataRef.current = { ...getFormData(paymentInfo) }
            if (paymentInfo.paymentRefNum) {
                if (redirectAddNewItem) {
                    history.push({
                        pathname: `/add-edit-payment-allocation-item`,
                        state: { 
                            payerOrPayee: paymentInfo.type === 'Incoming' ? paymentInfo.payerName : paymentInfo.payeeName,
                            paymentType: paymentInfo.type,
                            refNum: paymentInfo.paymentRefNum,
                            formType: formData.paymentAllocationList.length > 0 ? 1 : 0,
                        }
                    });
                    // history.replace(`/add-edit-payment-allocation-item/${paymentInfo.type === 'Incoming' 
                    //     ? paymentInfo.payerName : paymentInfo.payeeName}/${paymentInfo.type}/${paymentInfo.paymentRefNum}/${formData.paymentAllocationList.length > 0 ? 1 : 0}`);
                }
            }
        }
    }, [paymentInfo, redirectAddNewItem])

    useEffect(() => {
        let id = fetchLoginUserDetails();
        if(formData.type === 'Incoming' && formData.paymentRefNum === '0')
            setFormData({ ...formData, payeeId: id, payerId: 0, currency: baseCurrency.currencyCode })
        else if(formData.type === 'Outgoing' && formData.paymentRefNum === '0')
            setFormData({ ...formData, payerId: id, payeeId: 0, currency: baseCurrency.currencyCode })
    }, [formData.type, baseCurrency])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;

        setSubmitting(true);
        let formDataToSubmit = { ...formData }
        formDataToSubmit.paymentDate = setInputDate(formDataToSubmit.paymentDate);
        let isChangedFormData = !compareObjects(formData, formDataRef.current);

        if (e.nativeEvent.submitter.name === 'addPaymentItem') {
            if (formData.paymentRefNum === '0' || isChangedFormData) {
                // Delete payment allocation for delete
                delete formDataToSubmit.paymentAllocationList;
                delete formDataToSubmit.totalAllocationAmount;
                if(formDataToSubmit.paymentRefNum === '0')
                    formDataToSubmit.paymentStatus = "Pending";
                
                let saved = await save_new_payment(JSON.stringify(formDataToSubmit))
                if (!saved) {
                    setSubmitting(false);
                    return;
                }
            }
            setRedirectAddNewItem(true)
        }
        else {
            if(formDataToSubmit.paymentRefNum === '0')
                formDataToSubmit.paymentStatus = "Pending";
            let saved = await save_new_payment(JSON.stringify(formDataToSubmit))
            setSubmitting(false);
        }
    }

    function getFormData(paymentItem) {
        return {
            //To Do
            paymentRefNum: paymentItem.paymentRefNum || formData.paymentRefNum,
            payerId: paymentItem.payerId || formData.payerId,
            payeeId: paymentItem.payeeId || formData.payeeId,
            type: paymentItem.type || formData.type,
            currency: paymentItem.currency || formData.currency,
            paymentDate: paymentItem.paymentDate ? setAPIDate(paymentItem.paymentDate) : setAPIDate(formData.paymentDate),
            paymentStatus: paymentItem.paymentStatus || formData.paymentStatus,
            paymentAmount: paymentItem.paymentAmount || formData.paymentAmount,
            transactionId: paymentItem.transactionId || formData.transactionId,
            paymentNote: paymentItem.paymentNote || formData.paymentNote,
            totalUnallocatedAmount: paymentItem.totalUnallocatedAmount || formData.totalUnallocatedAmount,
            totalBalanceAmount: paymentItem.totalBalanceAmount || formData.totalBalanceAmount,
            totalAllocationAmount: paymentItem.totalAllocationAmount || formData.totalAllocationAmount,
            paymentAllocationList: (paymentItem.paymentAllocationList && paymentItem.paymentAllocationList.length > 0) ? paymentItem.paymentAllocationList : formData.paymentAllocationList
        }
    }

    /// Fetch user selected role and generating the ID based on it
    function fetchLoginUserDetails() {
        let payeePayerPrefix = '';
        if(user !== undefined && user.userRoles !== undefined && user.userRoles.length > 0) {
            let selectedRoleDomain = user.userRoles.filter(x => x.role === user.selectedrole)[0]?.domainId;
            if(user.selectedrole === SUBSCRIBER_ROLES.SUBSCRIBER_ADMIN)
                payeePayerPrefix = "S-";
            else if(user.selectedrole === SUBSCRIBER_ROLES.WORKER)
                payeePayerPrefix = "W-";
            else 
                payeePayerPrefix = "PE-";
            payeePayerPrefix = payeePayerPrefix + selectedRoleDomain;
        }
        return payeePayerPrefix
    }

    // Added by nipul for parameter less flow
    function navigateToPayable(e, refId){
        e.preventDefault(); 
        history.push({
            pathname: `/payable`,
            state: { refNum: refId }
        });
        return false;
    }

    function onContextMenuPayableChange(e, refId){
        if(e.type === 'contextmenu'){
            localStorage.setItem(NavgationPageLocationKey.PayableCurrentAddEdit, refId);
        }
    }

    function navigateToInvoice(e, refId){
        e.preventDefault(); 
        history.push({
            pathname: `/new-invoice`,
            state: { refNum: refId }
        });
        return false;
    }

    function onContextMenuInvoiceChange(e, refId){
        if(e.type === 'contextmenu'){
            localStorage.setItem(NavgationPageLocationKey.InvoiceCurrentAddEdit, refId);
        }
    }

    return (<>
        <ConfirmAlert confirmAlert={confirmAlert}
            setConfirmAlert={setConfirmAlert}
        />
        <Container fluid className="main-content-container p-4">
            <Card small className="mb-4 w-100 ">
                <ListGroup flush>
                    <Form onSubmit={onSubmit}>
                        <ListGroupItem className="border-card p-3 w-100">
                            <Row className="mb-2 h-100">
                                <Col lg="6" className="form-group m-0 p-2">
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="type" className="">Type</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                { formData.paymentRefNum !== undefined && formData.paymentRefNum > 0 ? <>
                                                    <FormInput id="type" name="type"
                                                        value={formData.type} readonly='true' />
                                                </> : <>
                                                    <FormSelect id="type" name="type" required={true}
                                                        value={formData.type} onChange={onChange}>
                                                        <option key='type' value=''>Select Type</option>
                                                        {paymentTypeOptions}
                                                    </FormSelect>
                                                </> }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="payee" className="">Payee</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    { (formData.paymentRefNum !== undefined && formData.paymentRefNum > 0) && formData.type === "Incoming" ? <>
                                                        <FormInput id="payeeId" name="payeeId"
                                                            value={payeeListOptions.filter(x => x.value === formData.payeeId)[0]?.label} readonly='true' />
                                                    </> : <>
                                                        <Dropdown name='payeeId' id='payee'
                                                            required={true} options={payeeListOptions}
                                                            placeholder='Enter Payee' selectedValues={[formData.payeeId]}
                                                            onChange={(name, value) => {
                                                                setFormData({ ...formData, [name]: value.length > 0 ? value[0].value : 0 })
                                                            }}
                                                        />
                                                    </> }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="payer" className="">Payer</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    { (formData.paymentRefNum !== undefined && formData.paymentRefNum > 0) && formData.type === 'Outgoing' ? <>
                                                        <FormInput id="payerId" name="payerId"
                                                            value={payerListOptions.filter(x => x.value === formData.payerId)[0]?.label} readonly='true' />
                                                    </> : <>
                                                        <Dropdown name='payerId' id='payer'
                                                            required={true} options={payerListOptions}
                                                            placeholder='Enter Payer' selectedValues={[formData.payerId]}
                                                            onChange={(name, value) => {
                                                                setFormData({ ...formData, [name]: value.length > 0 ? value[0].value : 0 })
                                                            }}
                                                        />
                                                    </> }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="currency" className="">Currency</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <FormSelect
                                                        id="currency"
                                                        name="currency"
                                                        value={formData.currency}
                                                        onChange={onChange}
                                                        required={true}
                                                    >
                                                        <option key='currency' value=''>Select Currency</option>
                                                        {currencyListOptions}
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" className="form-group m-0 p-2">
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="paymentDate" className="">Date</label>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto">
                                                    <DatePicker
                                                        id='paymentDate'
                                                        autoComplete='off'
                                                        name='paymentDate'
                                                        dateFormat='yyyy-MM-dd'
                                                        value={formData.paymentDate}
                                                        selected={formData.paymentDate}
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, 'paymentDate': date })
                                                        }
                                                        placeholderText="YYYY-MM-DD"
                                                    />
                                                </Col>
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <span> <label htmlFor="paymentRefNum" className="">Ref #</label></span>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto">
                                                    <label htmlFor="paymentRefNum" className="text-left" >{Number(formData.paymentRefNum) === 0 ? `New` : <span className="primary-text">{`PMT-${formData.paymentRefNum}`}</span>}</label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <span> <label htmlFor="paymentAmount" className="">Payment Amount</label></span>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto">
                                                    <span>
                                                        <StandardFormInputFreeWidth
                                                            id="paymentAmount"
                                                            name='paymentAmount'
                                                            standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                            dataMaxFracDigits="2"
                                                            type="text"
                                                            onChangeStandardInput={onChangeStandardInput}
                                                            value={formData.paymentAmount}
                                                            placeholder="0.00"
                                                            align='right'
                                                            labelClasses=""
                                                        />
                                                    </span>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <span> <label htmlFor="paymentStatus" className="">Status</label></span>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto">
                                                    <div className="pr-2 mr-1">
                                                        { formData.paymentStatus }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row>
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <span> <label htmlFor="totalUnallocatedAmount" className="">Unallocated</label></span>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto">
                                                    <div className="text-right pr-2 mr-1">
                                                        {formatNumberAmount(formData.totalUnallocatedAmount, 'en-US', 2)}
                                                    </div>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <span> <label htmlFor="transactionId" className="">Transaction Id</label></span>
                                                </Col>
                                                <Col lg="3" className="form-group my-auto">
                                                    <FormInput
                                                        id="transactionId"
                                                        name="transactionId"
                                                        value={formData.transactionId}
                                                        onChange={onChange}
                                                        placeholder="Transaction Id"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row>
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <span> <label htmlFor="paymentNote" className="">Payment Note</label></span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <FormTextarea id="paymentNote" className="notes-textarea border-1 bg-white" rows="2"
                                                        onChange={onChange} name="paymentNote"
                                                        value={formData.paymentNote} placeholder="Enter Payment Note" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col sm lg="12 d-flex flex-row justify-content-between">
                                    <span className="ml-2 pl-1 pt-1 primary-text font-weight-bold">Allocation Details</span>
                                    <Button name="addPaymentItem">
                                        { formData.paymentAllocationList !== undefined && formData.paymentAllocationList.length > 0 
                                        ? "Edit Allocation" : "Add Allocation"} </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm lg="12 m-0 p-0">
                                    <table className="table ">
                                        <thead>
                                            <th className="text-center p-1" >{ formData.type === 'Incoming' ? "Invoice" : "Payable" } #</th>
                                            <th className="text-center p-1" >Date</th>
                                            <th className="text-center p-1" >
                                                { formData.type === '' ? "-" : formData.type === 'Incoming' ? "Payer" : "Payee" }
                                            </th>
                                            <th className="text-center p-1" >Status</th>
                                            <th className="text-center p-1" >Currency</th>
                                            <th className="text-center p-1" >Amount</th>
                                            <th className="text-center p-1 pr-2 border-right">Balance</th>
                                            <th className="text-center p-1 pr-2">FX Rate { formData.currency !== '' ? '(' + formData.currency + ')' : '' }</th>
                                            <th className="text-center p-1" >Allocation { formData.currency !== '' ? '(' + formData.currency + ')' : '' }</th>
                                            <th className="text-center p-1" >Balance { formData.currency !== '' ? '(' + formData.currency + ')' : '' }</th>
                                        </thead>
                                        {(formData.paymentAllocationList || []).map((item, i) => <tr id={i}>
                                            
                                            <td className="p-1 text-center table-bordered">
                                                { formData.type === 'Incoming' ? 
                                                    <a href="/new-invoice" onClick={(e) => {navigateToInvoice(e, item.referenceId)}}
                                                        onContextMenu={(e) => onContextMenuInvoiceChange(e, item.referenceId)}>
                                                        {`INV-${item.referenceId}`}
                                                    </a>
                                                    : <a href="/payable" onClick={(e) => {navigateToPayable(e, item.referenceId)}}
                                                        onContextMenu={(e) => onContextMenuPayableChange(e, item.referenceId)}>
                                                        {`PAY-${item.referenceId}`}
                                                    </a>
                                                }
                                            </td>
                                            <td className="p-1 text-center table-bordered">{setDateForTable(item.referenceDate)}</td>
                                            <td className="p-1 text-center table-bordered">{item.payerOrPayee}</td>
                                            <td className="p-1 text-center table-bordered">{item.referenceStatus}</td>
                                            <td className="p-1 text-center table-bordered">{item.referenceCurrency}</td>
                                            <td align="right" className="p-1 table-bordered">{formatNumberAmount(item.referenceAmount, 'en-US', 2)}</td>
                                            <td align="right" className="p-1 table-bordered">{formatNumberAmount(item.referenceBalanceAmount, 'en-US', 2)}</td>
                                            <td align="right" className="p-1 table-bordered">{formatNumberAmount(item.fxRate, 'en-US', 5)}</td>
                                            <td align="right" className="p-1 table-bordered">{formatNumberAmount(item.allocationAmount, 'en-US', 2)}</td>
                                            <td align="right" className="p-1 table-bordered">{formatNumberAmount(item.balanceAmount, 'en-US', 2)}</td>
                                        </tr>)}

                                        {(formData.paymentAllocationList || []).length === 0 && <td colSpan="8" className="p-4"></td>}
                                        <tr>
                                            <td colSpan="8"></td>
                                            <td align="right" className="font-weight-bold p-1 pr-1">{formatNumberAmount(formData.totalAllocationAmount, 'en-US', 2)}</td>
                                            <td colSpan="1" align="right" className="font-weight-bold p-1 pr-1">{formatNumberAmount(formData.totalBalanceAmount, 'en-US', 2)}</td>
                                        </tr>

                                         <tr><td colSpan="12"></td></tr>

                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" className="d-flex flex-row justify-content-end">
                                    <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent" name="save">
                                        Save
                                    </Button>
                                    <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent" name="download"
                                        onClick={() => {
                                            if (formData.paymentRefNum === '0') {
                                                setAlert('Payment is not available for ref number 0', 'danger')
                                                return;
                                            }
                                            download_payment(formData.paymentRefNum)
                                        }}
                                    >Download Excel</Button>

                                    <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent" name="cancel"
                                        onClick={() => history.replace(`/view-payments`)}
                                    >Cancel</Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </Form>
                </ListGroup>
            </Card>
        </Container>
    </>
    )
}

const mapStateToProps = (state) => (
    {
        payeeDropdownList: state.payConfiguration.payeeDropdownList,
        payerDropdownList: state.payConfiguration.payerDropdownList,
        currencyList: state.invoice.currencyList,
        baseCurrency: state.user.baseCurrency,
        paymentTypeList: state.payment.paymentTypeList,
        paymentInfo: state.payment.paymentInfo,
        user: state.user.user,
    }
)
export default connect(mapStateToProps, {
    get_payee_dropdown_list,
    get_currency_list,
    get_base_currency,
    get_payer_dropdown_list,
    get_payment_type_list,
    get_payment_information,
    delete_payment_allocation,
    save_new_payment,
    download_payment,
    reset_payment_view,
})(NewPayment)

