import { BULK_DELETE_PAYMENT, BULK_DELETE_PAYMENT_ERROR, BULK_UPDATE_PAYMENT_STATUS, BULK_UPDATE_PAYMENT_STATUS_ERROR, GET_PAYMENT_ALLOCATION_TYPE, GET_PAYMENT_ALLOCATION_TYPE_ERROR, GET_PAYMENT_INFORMATION, GET_PAYMENT_INFORMATION_ERROR, GET_PAYMENT_STATUS_LIST, GET_PAYMENT_STATUS_LIST_ERROR, GET_PAYMENT_TYPE_LIST, GET_PAYMENT_TYPE_LIST_ERROR, GETSEARCH_VIEW_PAYMENT_LIST, GETSEARCH_VIEW_PAYMENT_LIST_ERROR, RESET_PAYMENT_VIEW, SAVE_NEW_PAYMENT, SAVE_NEW_PAYMENT_ERROR, RESET_PAYMENT_ALLOCATIONS, SEARCH_PAYMENT_ALLOCATIONS, SEARCH_PAYMENT_ALLOCATIONS_ERROR, GET_PAYMENTS, GET_PAYMENTS_ERROR, RESET_PAYMENTS } from "../../actions/types";

const initialState = {
    paymentHistoryList: [],
    paymentDetails: {},
    paymentStatusList: [],
    paymentTypeList: [],
    paymentAllocation: {},
    paymentInfo: {},
    bulkUpdate: false,
    bulkDelete: false,
    paymentAllocations:{},
    payments:[],
    invoicePaymentList: [],
}

export const payment = (state = initialState, action) => {
    switch (action.type) {
        case GETSEARCH_VIEW_PAYMENT_LIST:
            return {
                ...state,
                paymentHistoryList: action.payload.data,
            };
        case GETSEARCH_VIEW_PAYMENT_LIST_ERROR:
            return {
                ...state,
                paymentHistoryList: [],
            };
        case GET_PAYMENT_STATUS_LIST:
            return {
                ...state,
                paymentStatusList: action.payload.data
            }
        case GET_PAYMENT_STATUS_LIST_ERROR:
            return {
                ...state,
                paymentStatusList: [],
            }

        case GET_PAYMENT_TYPE_LIST:
            return {
                ...state,
                paymentTypeList: action.payload.data
            }
        case GET_PAYMENT_TYPE_LIST_ERROR:
            return {
                ...state,
                paymentTypeList: [],
            }
        case GET_PAYMENT_ALLOCATION_TYPE:
            return {
                ...state,
                paymentAllocation: action.payload.data
            }
        case GET_PAYMENT_ALLOCATION_TYPE_ERROR:
            return {
                ...state,
                paymentAllocation: {}
            }
        case SAVE_NEW_PAYMENT: {
            return {
                ...state,
                paymentInfo: action.payload.data
            }
        }
        case SAVE_NEW_PAYMENT_ERROR: {
            return {
                ...state,
                paymentInfo: initialState.payRecord
            }
        }
        case GET_PAYMENT_INFORMATION:
            return {
                ...state,
                paymentInfo: action.payload.data
            }
        case GET_PAYMENT_INFORMATION_ERROR:
            return {
                ...state,
                paymentInfo: {}
            }
            case BULK_UPDATE_PAYMENT_STATUS:
                return{
                    ...state,
                    bulkUpdate: true
                }
            
            case BULK_UPDATE_PAYMENT_STATUS_ERROR:
                return{
                    ...state,
                    bulkUpdate: initialState.bulkUpdate
                }
            case BULK_DELETE_PAYMENT:
                return{
                    ...state,
                    bulkDelete: true
                }
            
            case BULK_DELETE_PAYMENT_ERROR:
                return{
                    ...state,
                    bulkDelete: initialState.bulkDelete
                }
    
            case RESET_PAYMENT_VIEW:
                return {
                    ...state,
                    paymentInfo: initialState.paymentInfo
                }
        case SEARCH_PAYMENT_ALLOCATIONS:
            return{
                ...state,
                paymentAllocations:action.payload.data
            }
        case RESET_PAYMENT_ALLOCATIONS:
        case SEARCH_PAYMENT_ALLOCATIONS_ERROR:
            return{
                ...state,
                paymentAllocations:initialState.paymentAllocations
            }
        case GET_PAYMENTS:
            return{
                ...state,
                payments:action.payload.data
            }
        case RESET_PAYMENTS:
        case GET_PAYMENTS_ERROR:
            return{
                ...state,
                payments:initialState.payments
            }
            
        default:
            return state;
    }
}
