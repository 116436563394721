import api from "../../../utils/api";
import { download_file } from "../../../utils/general";
import { setAlert } from "../alert";
import { DELETE_NEW_PAY_COMPONENT, DELETE_NEW_PAY_COMPONENT_ERROR, DELETE_PAY_PERIOD, DELETE_PAY_PERIOD_ERROR, DELETE_PAY_SCHEDULE, DELETE_PAY_SCHEDULE_ERROR, GENERATE_PAY_SCHEDULE, GENERATE_PAY_SCHEDULE_ERROR, GET_NEW_PAY_COMPONENT_LIST, GET_PAY_FREQUENCY_LIST, GET_PAY_FREQUENCY_LIST_ERROR, GET_PAY_PERIOD_YEAR_LIST, GET_PAY_PERIOD_YEAR_LIST_ERROR, GET_PAY_SCHEDULE, GET_PAY_SCHEDULE_ERROR, GET_PAY_SCHEDULE_LIST, RESET_PAY_SCHEDULE, SAVE_PAY_PERIOD, SAVE_PAY_PERIOD_ERROR, SAVE_PAY_SCHEDULE, SAVE_PAY_SCHEDULE_ERROR,
  GET_NEW_PAY_COMPONENT_CATEGORY, 
  GET_NEW_PAY_COMPONENT_CATEGORY_ERROR,
  GET_NEW_PAY_COMPONENT_TYPE, 
  GET_NEW_PAY_COMPONENT_TYPE_ERROR,
  GET_PRO_RATA_MODES,
  GET_PRO_RATA_MODES_ERROR,
  GET_FINMOD_TYPE,
  GET_FINMOD_TYPE_ERROR,
  SAVE_NEW_PAY_COMPONENT,
  SAVE_NEW_PAY_COMPONENT_ERROR,
  GET_PAY_COMPONENT,
  GET_PAY_COMPONENT_ERROR,
  RESET_NEW_PAY_COMPONENT,
  GET_NEW_PAY_COMPONENT_NAME_LIST,
  GET_NEW_PAY_COMPONENT_NAME_LIST_ERROR,
  GET_RATE_UNIT_OF_MEASURE_NAME_LIST,
  GET_RATE_UNIT_OF_MEASURE_NAME_LIST_ERROR,
  SAVE_PAY_ITEM,
  SAVE_PAY_ITEM_ERROR,
  GET_PAY_ITEM,
  GET_PAY_ITEM_ERROR,
  DELETE_PAY_ITEM,
  DELETE_PAY_ITEM_ERROR,
  GET_RECORD_FREQUENCY_LIST,
  GET_RECORD_FREQUENCY_LIST_ERROR,
  RESET_PAY_ITEM,
  GET_IMPORT_PAYROLL_TAX_LIST,
  GET_PAYROLL_TAX_IMPORT_TYPE,
  GET_PAYROLL_TAX_IMPORT_TYPE_ERROR,
  GET_IMPORT_PAYROLL_TAX_LIST_ERROR,
  BULK_DELETE_PAYROLL_TAX_DATA,
  BULK_DELETE_PAYROLL_TAX_DATA_ERROR,
  GET_PAYPERIOD_LIST,
  GET_PAYPERIOD_LIST_ERROR,
  GET_COMPENSATION_PAYEE_LIST,
  GET_COMPENSATION_PAYEE_LIST_ERROR,

 } from "../types";

export const generate_pay_schedule = (
 { refNum,
  payScheduleName,
  payFrequency,
  initialPayPeriodNumber,
  startDate,
  endDate,
  taxYearEndMonth,
  taxYearEndDay,
  paymentInterval,
  defaultPaySchedule}
  ) => async (dispatch) => {
    try {
      const res = await api.get(
        `api/subscriber/generatePaySchedule?`,
        {
          params: {
            refNum:refNum,
            payScheduleName:payScheduleName,
            payFrequency:payFrequency,
            initialPayPeriodNumber:initialPayPeriodNumber,
            startDate:startDate,
            endDate:endDate,
            taxYearEndMonth:taxYearEndMonth,
            taxYearEndDay:taxYearEndDay,
            paymentInterval:paymentInterval,
            isDefault:defaultPaySchedule
          }
        }
      );
      dispatch(setAlert(res.data.message, "success"));
      dispatch({
        type: GENERATE_PAY_SCHEDULE,
        payload: res.data,
      });
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: GENERATE_PAY_SCHEDULE_ERROR
      });
    }
  };
export const save_pay_schedule = (
    paySchedule
  ) => async (dispatch) => {
    try {
      const res = await api.post(
        `/api/subscriber/savePaySchedule`,paySchedule
      );
      dispatch(setAlert(res.data.message, "success"));
      dispatch({
        type: SAVE_PAY_SCHEDULE,
        payload: res.data,
      });
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: SAVE_PAY_SCHEDULE_ERROR
      });
    }
  };

  export const get_pay_schedule = (
    payScheduleRefNum,
  ) => async (dispatch) => {
    try {
      const res = await api.get(
        `api/subscriber/getPaySchedule?payScheduleRefNum=${payScheduleRefNum}`
      );
      dispatch({
        type: GET_PAY_SCHEDULE,
        payload: res.data,
      });
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: GET_PAY_SCHEDULE_ERROR,
      });
    }
  };

  export const delete_pay_schedule = (
    payScheduleRefNum,
  ) => async (dispatch) => {
    try {
      const res = await api.get(
        `api/subscriber/deletePaySchedule?payScheduleRefNum=${payScheduleRefNum}`
      );
      dispatch({
        type: DELETE_PAY_SCHEDULE,
        payload: res.data,
      });
      dispatch(setAlert(res.data.message, "success"));
      return true;
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: DELETE_PAY_SCHEDULE_ERROR,
      });
      return false;
    }
  };

  export const delete_pay_period = (
    payPeriodRefNum,
    payScheduleRefNum,
  ) => async (dispatch) => {
    try {
      const res = await api.get(
        `api/subscriber/deletePayPeriod?payScheduleRefNum=${payScheduleRefNum}&payPeriodRefNum=${payPeriodRefNum}`
      );
      dispatch({
        type: DELETE_PAY_PERIOD,
        payload: res.data,
      });
      dispatch(setAlert(res.data.message, "success"));
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: DELETE_PAY_PERIOD_ERROR,
      });
    }
  };

  export const get_pay_frequency_list = () => async (dispatch) => {
    try {
      const res = await api.get(
        `api/subscriber/pay-frequency-list`
      );
      dispatch({
        type: GET_PAY_FREQUENCY_LIST,
        payload: res.data,
      });
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: GET_PAY_FREQUENCY_LIST_ERROR,
      });
    }
  };
  export const reset_pay_schedule = () => async (dispatch) =>{
    dispatch({
      type: RESET_PAY_SCHEDULE,
    });
  }

export const get_pay_schedule_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getPayScheduleList`);

    dispatch({
      type: GET_PAY_SCHEDULE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};
  export const save_pay_period = ({
          payScheduleRefNum,
          payPeriodRefNum,
          name,
          startDate,
          endDate,
          paymentDate
        }
    
  ) => async (dispatch) => {
    try {
      const res = await api.get(
        `api/subscriber/savePayPeriod`,{
          params:{
            payScheduleRefNum,
            payPeriodRefNum,
            name,
            startDate,
            endDate,
            paymentDate
          }
        }
      );
      dispatch({
        type: SAVE_PAY_PERIOD,
        payload: res.data,
      });
      dispatch(setAlert(res.data.message, "success"));
      return true;
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: SAVE_PAY_PERIOD_ERROR,
      });
      return false;
    }
  };
  export const get_pay_period_year_list = () => async (dispatch) => {
    try {
      const res = await api.get(`api/subscriber/getPayScheduleYearsList`);
  
      dispatch({
        type: GET_PAY_PERIOD_YEAR_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_PAY_PERIOD_YEAR_LIST_ERROR
      });
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
    }
  };

export const get_new_pay_component_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/getNewPayComponentList`);

    dispatch({
      type: GET_NEW_PAY_COMPONENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const delete_new_pay_component = ( payComponentRefNum ) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/deleteNewPayComponent?payComponentRefNum=${payComponentRefNum}`);
    dispatch({
      type: DELETE_NEW_PAY_COMPONENT,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success"));
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_NEW_PAY_COMPONENT_ERROR,
    });
    return false;
  }
};
export const get_new_pay_component_category = () => async (dispatch) => {
  try {
    const res = await api.get(
      `/api/subscriber/getNewPayComponentCategory`
    );
    dispatch({
      type: GET_NEW_PAY_COMPONENT_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_NEW_PAY_COMPONENT_CATEGORY_ERROR,
    });
  }
};
export const get_new_pay_component_type = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNewPayComponentType`
    );
    dispatch({
      type: GET_NEW_PAY_COMPONENT_TYPE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_NEW_PAY_COMPONENT_TYPE_ERROR,
    });
  }
};
export const get_pro_rata_modes = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getProRataModes`
    );
    dispatch({
      type: GET_PRO_RATA_MODES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PRO_RATA_MODES_ERROR,
    });
  }
};
export const get_finmod_type = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getFinmodType`
    );
    dispatch({
      type: GET_FINMOD_TYPE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_FINMOD_TYPE_ERROR,
    });
  }
};
export const save_new_pay_component = (
  newPayComponent
) => async (dispatch) => {
  try {
    const res = await api.post(
      `api/subscriber/saveNewPayComponent`,newPayComponent
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_NEW_PAY_COMPONENT,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_NEW_PAY_COMPONENT_ERROR
    });
    return false;
  }
};
  export const get_pay_component = (
    payComponentRefNum,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNewPayComponent?payComponentRefNum=${payComponentRefNum}`
    );
    dispatch({
      type: GET_PAY_COMPONENT,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAY_COMPONENT_ERROR,
    });
  }

};
export const reset_new_pay_component = () => (dispatch)=> dispatch({type:RESET_NEW_PAY_COMPONENT})
export const get_new_payComponent_name_list = () => async (dispatch)=>{
  try{
    const res = await api.get('api/subscriber/getNewPayComponentNameList');
    dispatch({
      type:GET_NEW_PAY_COMPONENT_NAME_LIST,
      payload:res.data
    })
  }catch(err){

    const success = err.response.data.message;
    if(!success){
      dispatch(setAlert(err.response.data.message))
    }

    dispatch({type:GET_NEW_PAY_COMPONENT_NAME_LIST_ERROR})
  }
}
export const get_rate_unit_of_measure_name_list = () => async (dispatch)=>{
  try{
    const res = await api.get('api/subscriber/getRateUnitOfMeasureNameList');
    dispatch({
      type:GET_RATE_UNIT_OF_MEASURE_NAME_LIST,
      payload:res.data
    })
  }catch(err){

    const success = err.response.data.message;
    if(!success){
      dispatch(setAlert(err.response.data.message))
    }

    dispatch({type:GET_RATE_UNIT_OF_MEASURE_NAME_LIST_ERROR})
  }
}
export const save_pay_item = (
  payItem
) => async (dispatch) => {
  try {
    const res = await api.post(
      `api/subscriber/savePayItem`,payItem
    );
    dispatch({
      type: SAVE_PAY_ITEM,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success"));
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_PAY_ITEM_ERROR,
    });
    return false;
  }
};
export const get_pay_item = (payItemRefNum) => async (dispatch)=>{
  try{
    const res = await api.get(`api/subscriber/getPayItem?payItemRefNum=${payItemRefNum}`);
    dispatch({
      type:GET_PAY_ITEM,
      payload:res.data
    })
  }catch(err){

    const success = err.response.data.message;
    if(!success){
      dispatch(setAlert(err.response.data.message))
    }

    dispatch({type:GET_PAY_ITEM_ERROR})
  }
}
export const delete_pay_items = (payItemRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`/api/subscriber/deletePayItem?payItemRefNum=${payItemRefNum}`);
    dispatch(setAlert("Pay item deleted", "success"));
    dispatch({
      type: DELETE_PAY_ITEM,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:DELETE_PAY_ITEM_ERROR
      });
    }

    return false;
  }
};
export const get_record_frequency_list = (payItemRefNum) => async (dispatch) => {
  try {
    const res = await api.get(` /api/subscriber/record-frequency-list`);
    dispatch({
      type: GET_RECORD_FREQUENCY_LIST,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:GET_RECORD_FREQUENCY_LIST_ERROR
      });
    }

    return false;
  }
};
export const reset_pay_item = ()=> async (dispatch)=>  dispatch({type:RESET_PAY_ITEM})
export const save_import_data = ({file, importDuplicates, payPeriod, startDate, importType})=> async(dispatch)=>{
  
    try{
      let fd = new FormData();
    fd.append('file', file);
     const res = await api({
        method: "post",
        url: `api/sub-data/save-import-data?importDuplicates=${importDuplicates}&payPeriod=${payPeriod}&startDate=${startDate}&importType=${importType}`,
        data: fd,
        headers: {"Content-Type": "multipart/form-data"},
      });
      dispatch(setAlert(res.data.message,"success"));
      return true;
    }catch(err){
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      return false;
    }
}
export const get_payroll_taximort_type = () => async (dispatch)=>{
  try{
    const res = await api.get('api/sub-data/payrolltax-import-type-list');
    dispatch({type:GET_PAYROLL_TAX_IMPORT_TYPE,
      payload:res.data
    });
  }catch(err){
    const success = err.response.data.success;
    dispatch({type:GET_PAYROLL_TAX_IMPORT_TYPE_ERROR
    });
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
} 

export const get_import_payroll_tax_list = (
  userID, client, workerName, processed, payPeriod, startDate, endDate
) => async (dispatch) => {
  try {
    const res = await api.get(`/api/sub-data/get-payrolltax-data-list`,
      {
        params: {
          userId: userID,
          client: client,
          workerName: workerName,
          processed: processed,
          payPeriod: payPeriod,
          startDate: startDate,
          endDate: endDate,
        }
      }
    );
    dispatch({
      type: GET_IMPORT_PAYROLL_TAX_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_IMPORT_PAYROLL_TAX_LIST_ERROR,
    });
  }
};

export const bulk_delete_payroll_tax_data = (bulkData) => async (dispatch) => {
  try {
    const res = await api.post(`/api/sub-data/delete-payrolltax-data`, bulkData)
    dispatch({
      type: BULK_DELETE_PAYROLL_TAX_DATA,
      payload: true
    })
    dispatch(setAlert(res.data.message, 'success'))
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, 'danger'))
    }
    dispatch({
      type: BULK_DELETE_PAYROLL_TAX_DATA_ERROR
    })
    return false;
  }
}

export const download_payroll_tax_data = (refNums) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-data/download-payrolltax-data`, refNums, { responseType: 'blob' });
 
    const contentDisposition = res.headers.get('Content-Disposition');
    let filename = 'downloaded_payroll_tax_' + refNums.refNumList.toString().split(',').join('_') + '.csv';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
        console.log('fileName ', filename)
      }
    }
    download_file(res.data, filename, 'application/octet-stream')
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
}

export const get_payperiod_list = () => async (dispatch) => {
  try {
    const res = await api.get(`/api/sub-automation/get-payperiod-list`);
    dispatch({
      type: GET_PAYPERIOD_LIST,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:GET_PAYPERIOD_LIST_ERROR
      });
    }

    return false;
  }
};
export const get_compensation_payee_list = ()=> async (dispatch)=>{
  try{
    const res = await api.get('api/subscriber/get-compensation-payee-list');
    dispatch({
      type:GET_COMPENSATION_PAYEE_LIST,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:GET_COMPENSATION_PAYEE_LIST_ERROR
      });
    }
  }
}

export const get_compensation_payee_list_byworker = (workerId) => async (dispatch)=>{
  try{
    const res = await api.get(`api/subscriber/get-compensation-payee-list?workerId=${workerId}`);
    dispatch({
      type:GET_COMPENSATION_PAYEE_LIST,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type:GET_COMPENSATION_PAYEE_LIST_ERROR
      });
    }
  }
}
