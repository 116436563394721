import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useLocation} from "react-router-dom";
import {setDateForTableInHrMinSec} from "../../utils/date";
import {default as queryString} from "qs";

function SeveranceNotes({
                         
                           formData,
                            severanceNoteList,
                            formDataRef,
                           sectionformDataRef
                         }) {

  const history = useHistory();
  const location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))


  var severanceNoteContent = severanceNoteList.map((note) => {
    return setDateForTableInHrMinSec(note.date) + ' by ' + note.userName + ' : ' + note.noteText;
  });

  severanceNoteContent = severanceNoteContent.join("\r\n");

  return (
    <Card id='severanceNotes' small className="mb-5 w-100">
        <span className="iconBox"><i className="fas fa-clipboard iconStyle"/></span>
        <label htmlFor="severanceNoteContent" className="m-0"><h4 className="m-0 section-title headerStyle">Severance Notes</h4></label>
      <CardHeader
        className="border-bottom d-flex align-items-center justify-content-end pt-0 mt-3">
        <Button size='sm'
                theme="accent" onClick={() => { formData.userId &&
          history.push({
            pathname: '/sub-add-edit-notes',
            state: {
              noteType: 3,
              noteTypeText: 'Severance Notes',
              userId: formData.userId,
              userName: formData.firstName + ' ' + formData.lastName,
              role:queryParams.role,
              formData,
              formDataRef, 
              sectionformDataRef
            },
          });
        }}>Add/Edit Notes</Button>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-3 pt-0">
          <Row>
            <Col className="pr-0">
              <Form>
                <Row form className="mr-0">
                  <FormTextarea id="severanceNoteContent" className="notes-textarea border-0 bg-white" readOnly rows="10" value={severanceNoteContent}/>
                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

export default SeveranceNotes;
