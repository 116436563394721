import api from "../../../utils/api";
import { download_file } from "../../../utils/general";
import { setAlert } from "../alert";
import {BULK_UPDATE_PAY_RECORD_STATUS, BULK_UPDATE_PAY_RECORD_STATUS_ERROR, CALCULATE_PAY_RECORD_ITEM_DETAILS, CALCULATE_PAY_RECORD_ITEM_DETAILS_ERROR, GET_PAY_RECORD, GET_PAY_RECORD_ERROR, GET_PAY_RECORD_ITEM, GET_PAY_RECORD_ITEM_ERROR, GET_PAY_RECORD_LIST, GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER, GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER_ERROR, GET_PAY_RECORD_LIST_ERROR, GET_PAY_RECORD_STATUS_LIST, GET_PAY_RECORD_STATUS_LIST_ERROR, RESET_BULK_UPDATE_PAY_RECORD_STATUS, RESET_PAY_RECORD, RESET_PAY_RECORD_ITEM, SAVE_PAY_RECORD, SAVE_PAY_RECORD_ERROR } from "../types";

export const get_pay_record_status_list = () => async (dispatch) => {
    try {
        const res = await api.get('api/sub-payrecord/get-payrecord-status-list');
        dispatch({
            type: GET_PAY_RECORD_STATUS_LIST,
            payload: res.data
        })

    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAY_RECORD_STATUS_LIST_ERROR,
        });
    }
}
export const get_pay_record = (payRecordRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/sub-payrecord/get-payrecord?payRecordRefNum=${payRecordRefNum}`);
        dispatch({
            type: GET_PAY_RECORD,
            payload: res.data
        })

    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAY_RECORD_ERROR,
        });
    }
}
export const delete_pay_record_item = (payRecordRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/sub-payrecord/delete-payrecord-item?payRecordItemRefNum=${payRecordRefNum}`);
        dispatch(setAlert(res.data.message, "success"))
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }

        return false;
    }
}
export const save_pay_record = (payRecord) => async (dispatch) => {
    try {
        const res = await api.post('api/sub-payrecord/save-payrecord', payRecord);
        dispatch(setAlert(res.data.message, 'success'))
        dispatch({
            type: SAVE_PAY_RECORD,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: SAVE_PAY_RECORD_ERROR })

        return false;
    }
}

export const download_pay_record = (payRecordRefNum) => async (dispatch) => {
    try {
       const res = await api.get(`api/sub-payrecord/download-payrecord?payRecordRefNum=${payRecordRefNum}`,{ 
            responseType: 'blob' 
          })
        try{
            const contentDisposition = res.headers.get('Content-Disposition');
            let filename = 'downloaded_file.pdf';
            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
                if (filenameMatch.length === 2) {
                    filename = filenameMatch[1];
                }
            }
            download_file(res.data, filename)
        }catch(err){
            dispatch(setAlert('Could not download the file', 'danger'));
        }  
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
    }
}
export const reset_pay_record = ()=> (dispatch)=>dispatch({type:RESET_PAY_RECORD})
export const save_payrecord_item = (payRecordItem) => async (dispatch)=>{

    try{
        const res = await api.post('api/sub-payrecord/save-payrecord-item',payRecordItem)
        dispatch(setAlert(res.data.message,'success'));
        return true;
    }catch(err){
        const success = err.response.data.success
        if(!success){
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}
export const calculate_pay_record_item_details = ({itemTypeId, subTypeId, workerId, date,payRecordItemPayScheduleId, payRecordItemPayPeriodId})=> async (dispatch)=>{
    try{
      const res = await api.get(`api/sub-payrecord/calculate-payrecord-item-details`,{
        params:{
          itemTypeId:itemTypeId,
          subTypeId:subTypeId, 
          workerId:workerId, 
          date:date, 
          payRecordItemPayScheduleId:payRecordItemPayScheduleId,
          payRecordItemPayPeriodId:payRecordItemPayPeriodId
        }
      });
      dispatch({
        type:CALCULATE_PAY_RECORD_ITEM_DETAILS,
        payload:res.data
      })
    }catch(err){
      const success = err.response.data.success;
      if(!success){
        dispatch(setAlert(err.response.data.message,'danger'))
      }
      dispatch({
        type:CALCULATE_PAY_RECORD_ITEM_DETAILS_ERROR
      })
    }
  }
  export const get_pay_record_item = (payRecordItemRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/sub-payrecord/get-payrecord-item?payRecordItemRefNum=${payRecordItemRefNum}`);

        dispatch({
            type: GET_PAY_RECORD_ITEM,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_PAY_RECORD_ITEM_ERROR })

        return false;
    }
}
export const reset_pay_record_item = ()=> (dispatch)=>dispatch({type:RESET_PAY_RECORD_ITEM});
export const get_pay_record_list = ({
    from,
    to,
    payee,
    subPayee,
    payRecordRefNum,
    status,
    currency,})=> async (dispatch)=>{
    try{const res = await api.get('api/sub-payrecord/get-payrecord-list',{
        params:{
            payRecordRefNum,
            payee,
            subPayee,
            status,
            currency,
            startDate:from,
            endDate:to
        }
    });

    dispatch({type:GET_PAY_RECORD_LIST,
        payload:res.data
    })}
    catch(err){
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_PAY_RECORD_LIST_ERROR })
    }
}
export const bulk_update_pay_record_status = (bulkData)=> async (dispatch)=>{
    try{
      const res = await api.post(`api/sub-payrecord/bulk-status-update`, bulkData)
      dispatch({
        type:BULK_UPDATE_PAY_RECORD_STATUS,
        payload:true
      })
      dispatch(setAlert(res.data.message,'success'))
      return true;
    }catch(err){
      const success = err.response.data.success;
      if(!success){
        dispatch(setAlert(err.response.data.message,'danger'))
      }
      dispatch({
        type:BULK_UPDATE_PAY_RECORD_STATUS_ERROR
      })
      return false;
    }
  }
export const reset_bulk_update_pay_record = ()=> (dispatch)=> dispatch({type:RESET_BULK_UPDATE_PAY_RECORD_STATUS})
export const set_bulk_update_pay_record = ()=> (dispatch)=> dispatch({type:BULK_UPDATE_PAY_RECORD_STATUS})

export const get_pay_record_list_by_invoice_num = (invoiceRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/sub-invoice/get-payrecord-list?invoiceRefNum=${invoiceRefNum}`);

        dispatch({
            type: GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER_ERROR })

        return false;
    }
}