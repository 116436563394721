import { Button, Card, Col, Container, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import InputBox from "../../../components/common/InputBox";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { fixedDecimalPlaces, toNumber } from "../../../utils/general";
import { connect } from "react-redux";
import {
    get_finmod_type,
    get_new_pay_component_category,
    get_new_pay_component_type,
    get_pro_rata_modes,
    save_new_pay_component,
    get_pay_component,
    delete_new_pay_component,
    reset_new_pay_component
} from "../../../redux/actions/subscriber/payroll";
import ConfirmAlert from "../../../components/common/ConfirmAlert";
import ActionBar from "../../../components/common/ActionBar";

function PayComponent({
    PayComponentCategoryList,
    payComponentTypeList,
    proRataModeList,
    finmodTypeList,
    payComponent,
    get_new_pay_component_category,
    get_new_pay_component_type,
    get_pro_rata_modes,
    get_finmod_type,
    save_new_pay_component,
    get_pay_component,
    delete_new_pay_component,
    reset_new_pay_component
}) {

    const { refNum } = useParams();
    const [formData, setFormData] = useState({
        payComponentRefNum: refNum,
        payComponentName: '',
        payComponentCategory: '',
        payComponentCategoryLabel: '',
        payComponentType: '',
        glAccountNumber: '',
        proRataBasis: '',
        payrollLabel: '',
        descriptionOverride: '',
        finmodType: '',
        finmodMemoDescription: '',
        paymentMethodItemName: '',
        accountingSystemItemName: '',
        includeInDealMarginReport: 'No',
        finmodIntegrationId: '',
        taxRate:''
    })
    const [formSubmitting, setForSubmitting] = useState(false);
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });
    const history = useHistory();
    

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        setFormData({ ...formData, [e.target.name]: toNumber(e.target.value, decimalPlaces) })
    };

    const onCheck = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked ? 'Yes' : 'No' })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if(formSubmitting) return;
        
        let formDataToSubmit = { ...formData };
        setForSubmitting(true);
        let saved = await save_new_pay_component(JSON.stringify(formDataToSubmit));
        
        if (saved) history.goBack();

        setForSubmitting(false)
    }
    const onWheel = (e) => {
        e.target.blur()
        e.stopPropagation();
    }

    const onDelete = () => {
        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the New Pay Component?',
            visible: true,
            onConfirm: async () => {

                let deleted = await delete_new_pay_component(formData.payComponentRefNum)
                if (deleted) history.goBack();

            }
        })


    }
    let PayComponentCategoryListOptions = PayComponentCategoryList.map(category => <option key={category} value={category}>{category}</option>)
    let payComponentTypeListOptions = payComponentTypeList.map(type => <option key={type} value={type}>{type}</option>);
    let proRataModeListOptions = proRataModeList.map(proRata => <option key={proRata} value={proRata}>{proRata}</option>);
    let finmodTypeListOption = finmodTypeList.map(type => <option key={type} value={type}>{type}</option>)

    const getFormData = (payComponent) => {
        return {
            payComponentRefNum: payComponent.payComponentRefNum || formData.payComponentRefNum,
            payComponentName: payComponent.payComponentName || formData.payComponentName,
            payComponentCategory: payComponent.payComponentCategory || formData.payComponentCategory,
            payComponentCategoryLabel: payComponent.payComponentCategoryLabel || formData.payComponentCategoryLabel,
            payComponentType: payComponent.payComponentType || formData.payComponentType,
            glAccountNumber: payComponent.glAccountNumber || formData.glAccountNumber,
            proRataBasis: payComponent.proRataBasis || formData.proRataBasis,
            payrollLabel: payComponent.payrollLabel || formData.payrollLabel,
            descriptionOverride: payComponent.descriptionOverride || formData.descriptionOverride,
            finmodType: payComponent.finmodType || formData.finmodType,
            finmodMemoDescription: payComponent.finmodMemoDescription || formData.finmodMemoDescription,
            paymentMethodItemName: payComponent.paymentMethodItemName || formData.paymentMethodItemName,
            accountingSystemItemName: payComponent.accountingSystemItemName || formData.accountingSystemItemName,
            includeInDealMarginReport: payComponent.includeInDealMarginReport || formData.includeInDealMarginReport,
            finmodIntegrationId: payComponent.finmodIntegrationId || formData.finmodIntegrationId,
            taxRate:payComponent.taxRate && fixedDecimalPlaces(payComponent.taxRate, 2) || formData.taxRate
        }
    }
    useEffect(() => {
        setFormData({ ...getFormData(payComponent) })
    }, [payComponent])
    useEffect(() => {
        if (refNum !== '0') {
            get_pay_component(refNum);
        }
        get_new_pay_component_category();
        get_new_pay_component_type();
        get_pro_rata_modes();
        get_finmod_type();

        return reset_new_pay_component;
    }, [])
    return (
        <>
            <ConfirmAlert confirmAlert={confirmAlert}
                setConfirmAlert={setConfirmAlert}
            />
            <Container fluid className="main-content-container p-0">
                <Form onSubmit={onSubmit}>
                    <ActionBar id="newPayComponent" className="mb-4">
                        <Button className="my-2 py-2 mx-1" size="sm"
                            theme="accent" name="save"
                        >Save</Button>
                        <Button className="my-2 py-2 mx-1" size="sm"
                            theme="danger" name="delete"
                            onClick={onDelete}
                        >Delete</Button>
                        <Button onClick={e => history.goBack()}
                            className="my-2 py-2 mx-1" size="sm"
                            theme="accent">Cancel</Button>
                    </ActionBar>
                    <div className="px-3 ">
                        <Card small className="mb-4 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.payComponentRefNum}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">
                                    <Row>
                                        <InputBox
                                            id="payComponentName"
                                            name="payComponentName"
                                            label="Name"
                                            required={true}
                                            value={formData.payComponentName}
                                            onChange={onChange}
                                            placeholder="Enter Pay Component Name" />

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="payComponentCategory">Category</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect
                                                required={true}
                                                value={formData.payComponentCategory}
                                                id="payComponentCategory"
                                                name='payComponentCategory'
                                                onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select Category
                                                </option>
                                                {PayComponentCategoryListOptions}
                                            </FormSelect>
                                        </Col>

                                        <InputBox
                                            id="payComponentCategoryLabel"
                                            name="payComponentCategoryLabel"
                                            label="Category Label"
                                            required={true}
                                            value={formData.payComponentCategoryLabel}
                                            onChange={onChange}
                                            placeholder="Enter Category Label" />
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="payComponentType">Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect
                                                required={true}
                                                value={formData.payComponentType}
                                                id="payComponentType"
                                                name='payComponentType'
                                                onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select Type
                                                </option>
                                                {payComponentTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                        <InputBox
                                            id="glAccountNumber"
                                            name="glAccountNumber"
                                            label="GL Account #"
                                            required={true}
                                            value={formData.glAccountNumber}
                                            onChange={onChange}
                                            placeholder="Enter GL Account #" />

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="proRataBasis">Pro Rate Basis</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect
                                                required={true}
                                                value={formData.proRataBasis}
                                                id="proRataBasis"
                                                name='proRataBasis'
                                                onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select Pro Rate Basis
                                                </option>
                                                {proRataModeListOptions}
                                            </FormSelect>
                                        </Col>
                                        <InputBox
                                            id="taxRate"
                                            name="taxRate"
                                            label="Tax Rate (%)"
                                            value={formData.taxRate}
                                            onChange={(e)=>onMonetaryAmountChanged(e,2)}
                                            placeholder="0.00" />
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <InputBox
                                            id="payrollLabel"
                                            name="payrollLabel"
                                            label="Payroll Label"
                                            value={formData.payrollLabel}
                                            onChange={onChange}
                                            placeholder="Enter Payroll Label" />
                                        <InputBox
                                            id="descriptionOverride"
                                            name="descriptionOverride"
                                            label="Description Override"
                                            value={formData.descriptionOverride}
                                            onChange={onChange}
                                            placeholder="Enter Description Override" />
                                        <Col lg="6" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="finmodType">Finmod Type</label>
                                            <FormSelect
                                                value={formData.finmodType}
                                                id="finmodType"
                                                name='finmodType'
                                                onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select Finmod Type
                                                </option>
                                                {finmodTypeListOption}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="9" className="form-group m-0 p-2"></Col>
                                        <Col lg="9" className="form-group m-0 p-2">
                                            <label htmlFor="finmodMemoDescription">Finmod Memo Description</label>
                                            <FormInput
                                                id="finmodMemoDescription"
                                                name="finmodMemoDescription"
                                                value={formData.finmodMemoDescription}
                                                onChange={onChange}
                                                placeholder="Enter Memo Description" />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <InputBox
                                            id="paymentMethodItemName"
                                            name="paymentMethodItemName"
                                            label="Payment Method Item Name"
                                            value={formData.paymentMethodItemName}
                                            onChange={onChange}
                                            placeholder="Enter Payment Method Item Name" />
                                        <InputBox
                                            id="accountingSystemItemName"
                                            name="accountingSystemItemName"
                                            label="Accounting System Item Name"
                                            value={formData.accountingSystemItemName}
                                            onChange={onChange}
                                            placeholder="Enter Accounting System Item Name" />
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <div className=" d-flex flex-row justify-content-center mt-1 pt-4">
                                                <div lg="9" className="form-group m-0 p-2 text-right">
                                                    <label htmlFor="includeInDealMarginReport">Include in Deal Margin Report</label>
                                                </div>
                                                <div lg="3" className="form-group m-0 p-2">

                                                    <FormCheckbox
                                                        id="includeInDealMarginReport"
                                                        name="includeInDealMarginReport"
                                                        checked={formData.includeInDealMarginReport === 'Yes'}
                                                        value={formData.includeInDealMarginReport}
                                                        onChange={onCheck} />

                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="includeInDealMarginReport">FINMOD Integration ID</label>
                                            <FormInput
                                                id="finmodIntegrationId"
                                                name="finmodIntegrationId"
                                                value={formData.finmodIntegrationId}
                                                onChange={(e) => {
                                                    let number = fixedDecimalPlaces(e.target.value, 0);
                                                    setFormData({ ...formData, [e.target.name]: number.replace('.', '') })
                                                }}
                                                type="number"
                                                step={0}
                                                min={0}
                                                onWheel={onWheel}
                                                placeholder="Enter FINMOD Integration ID" />
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </div>
                </Form>
            </Container>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        PayComponentCategoryList: state.payroll.PayComponentCategoryList,
        payComponentTypeList: state.payroll.payComponentTypeList,
        proRataModeList: state.payroll.proRataModeList,
        finmodTypeList: state.payroll.finmodTypeList,
        payComponent: state.payroll.payComponent
    }
}

export default connect(mapStateToProps, {
    get_new_pay_component_category,
    get_new_pay_component_type,
    get_pro_rata_modes,
    get_finmod_type,
    save_new_pay_component,
    get_pay_component,
    delete_new_pay_component,
    reset_new_pay_component
})(PayComponent)