import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import StandardFormInputFreeWidth from "../../../components/common/StandardFormInputFreeWidth";
import { NavgationPageLocationKey, STANDARD_FORMATS } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { calculate_invoice_item_details, get_invoice_item, get_item_type_list, 
     get_worker_list_by_client, 
     reset_invoice_item, 
     save_invoice_item } from "../../../redux/actions/subscriber/invoice";
import { setAPIDate, setInputDate } from "../../../utils/date";
import { get_new_payComponent_name_list, get_pay_schedule, get_pay_schedule_list, reset_pay_schedule } from "../../../redux/actions/subscriber/payroll";
import { useRef } from "react";
import PayRecordPopUp from "./PayablePopUp";
import { CheckValueCorrectionForMiniCalcField, fixedDecimalPlaces, IsValidProcessNumber, prevent_enter_submit } from "../../../utils/general";
import { evaluate, string } from "mathjs";
import { setAlert } from "../../../redux/actions/alert";

function InvoiceItem({
    itemTypeList,
    invoiceItem,
    workerList,
    newPayComponentNameList,
    payScheduleList,
    paySchedule,
    calculatedInvoiceItemDetails,
    get_item_type_list,
    get_worker_list_by_client,
    save_invoice_item,
    calculate_invoice_item_details,
    get_new_payComponent_name_list,
    get_invoice_item,
    get_pay_schedule_list,
    get_pay_schedule,
    reset_pay_schedule,
    reset_invoice_item,
    setAlert,
}) {

    const history = useHistory();
    //const {refNum, invoiceRefNum, clientId} = useParams();
    //For storing state and ids
    const location = useLocation();
    const [refNum, setrefNum] = useState(0);
    const [invoiceRefNum, setInvoiceRefNum] = useState(0);
    const [clientId, setClientId] = useState(0);
    const [formData, setFormData] = useState(
    {
        invoiceRefNum: invoiceRefNum,
        refNum: refNum,
        itemTypeId: '',
        subTypeId: '0',
        description: '',
        workerId: '0',
        weekendingDate: '',
        invoiceItemDate: '',
        payPeriodRefNum: '0',
        hrsQty: '',
        invoiceRate: '',
        payRate: '',
        invoiceItemRate: '',
        gross: '',
        tax: '',
        total: '',
        payRecordRefNum: '0',
        exportLabel:'',
        payScheduleRefNum:'0'
    })

    const [rawData, setRawData] = useState({
        gross: '',
        hrsQty: '',
        invoiceRate: '',
        total: '',
        isHrsQtyEvalDisable: true,
        isInvoiceRateEvalDisable: true,
    })

    const [openModal, setOpenModal] = useState(false);
    const toggle = () => {
        setOpenModal(!openModal);

    }
    const isFirstRender = useRef(refNum !== '0');
    const setSelectedPayRecordRefNum = (refNum)=>{
        setFormData({...formData, payRecordRefNum:refNum})
    }
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onChangeStandardInput = (name, value)=>setFormData({...formData, [name]:value});

    const onSubmit = async(e) => {
        e.preventDefault()
        
        let formDataToSubmit = {...formData}
        formDataToSubmit.weekendingDate = setInputDate(formDataToSubmit.weekendingDate);
        formDataToSubmit.invoiceItemDate = setInputDate(formDataToSubmit.invoiceItemDate);

        formDataToSubmit.refNum = refNum;
        formDataToSubmit.invoiceRefNum = invoiceRefNum;
        //Check formula exists for hrsQty & invoiceRate
        if(checkArthimaticOperators('hrsQty', rawData.hrsQty) || checkArthimaticOperators('invoiceRate', rawData.invoiceRate)){
            try{
                //Do all calculation if any one field have any formula
                let calHours = String(fixedDecimalPlaces(evaluate(string(rawData.hrsQty)), 2));
                let calRate = String(fixedDecimalPlaces(evaluate(string(rawData.invoiceRate)), 2));
                if(calHours === "Infinity" || calRate === "Infinity"){
                    setAlert("Cannot divide value by 0", 'danger');
                    return false;
                }
                
                let calCulatedGross = fixedDecimalPlaces(Number(calHours) * Number(calRate), 2);
                let calCulatedTotal = fixedDecimalPlaces(Number(calCulatedGross) + Number(formData.tax), 2);
                formDataToSubmit.hrsQty = calHours;
                formDataToSubmit.invoiceRate = calRate;
                formDataToSubmit.gross = calCulatedGross;
                formDataToSubmit.total = calCulatedTotal;
            }
            catch(ex){
                setAlert("Please provide proper value to calculate", 'danger');
                return false;
            }
        }
        else {
            //Check validation for values
            if(CheckValueCorrectionForMiniCalcField(rawData.invoiceRate)){
                setAlert("Please provide proper value into invoice Rate", 'danger');
                return false;
            }
            else if(CheckValueCorrectionForMiniCalcField(rawData.hrsQty)){
                setAlert("Please provide proper value into Hrs/Qty", 'danger');
                return false;
            }
        }

       let saved = await save_invoice_item(JSON.stringify(formDataToSubmit));
       if(saved)
        history.push({
            pathname: `/new-invoice`,
            state: { refNum: invoiceRefNum }
        });
        //history.replace(`/new-invoice/${invoiceRefNum}`);

    }
    const workerListOptins = workerList.map(worker => <option key={worker.name + worker.userId} value={worker.userId}>{worker.name}</option>)
    const subTypeListOptions = newPayComponentNameList.map(newPayComponent => <option key={newPayComponent.payComponentName + newPayComponent.payComponentId} value={newPayComponent.payComponentId}>{newPayComponent.payComponentName}</option>)
    const itemTypeListOptions = itemTypeList.map(itemType => <option key={itemType.categoryLabel + itemType.payComponentRefNum} value={itemType.payComponentRefNum}>{itemType.categoryLabel}</option>)
    const payScheduleListOptions = payScheduleList.map(paySchedule => <option key={paySchedule.payScheduleName + paySchedule.payScheduleRefNum} value={paySchedule.payScheduleRefNum}>{paySchedule.payScheduleName}</option>)
    const payPeriodListOptions = paySchedule.payPeriodList && paySchedule.payPeriodList.map(payPeriod => <option key={payPeriod.payPeriodName + payPeriod.refNum} value={payPeriod.refNum}>{payPeriod.payPeriodName}</option>)
    
    const calculateInvoiceDetails = ()=>{
        let foundPayPeriod = (paySchedule.payPeriodList || []).filter(payPeriod => Number(payPeriod.payScheduleRefNum) === Number(formData.payScheduleRefNum) && Number(payPeriod.refNum) === Number(formData.payPeriodRefNum))
        
        if(foundPayPeriod.length === 0)
        { 
             setFormData({...formData, payPeriodRefNum : '0'})

        }
        if(foundPayPeriod.length === 0 && (formData.invoiceItemDate === '' || formData.invoiceItemDate === null)){
            return;
        }
        
        if(formData.workerId !== '0' && formData.subTypeId !== '0' && formData.itemTypeId !== '' && (formData.invoiceItemDate !== '' || (formData.payScheduleRefNum !== '0' && formData.payPeriodRefNum !== '0'))){
            // Not needed as below change is restriction first call to API - Change by nipul
            // if (isFirstRender.current) {
            //     console.log("First Render check Chnaged=")
            //     isFirstRender.current = false;
            //     return;
            // }
            calculate_invoice_item_details({itemTypeId:formData.itemTypeId, subTypeId:formData.subTypeId, workerId:formData.workerId, date:setInputDate(formData.invoiceItemDate), invoiceItemPayScheduleId:formData.payScheduleRefNum, invoiceItemPayPeriodId:formData.payPeriodRefNum})
        }
    }
    useEffect(()=>{
        
        calculateInvoiceDetails();
    },[formData.subTypeId, formData.itemTypeId, formData.invoiceItemDate, formData.payPeriodRefNum, formData.workerId, formData.payScheduleRefNum])
    
    useEffect(()=>{
        if(Number(formData.payScheduleRefNum) !== 0){
            get_pay_schedule(formData.payScheduleRefNum)
        }
    },[formData.payScheduleRefNum])

    const getFormData = (invoiceItem)=>{
        return{
            invoiceRefNum: invoiceItem.invoiceRefNum || formData.invoiceRefNum,
            refNum: invoiceItem.refNum || formData.refNum,
            itemTypeId: invoiceItem.itemTypeId !== undefined ? invoiceItem.itemTypeId : formData.itemTypeId,
            subTypeId: invoiceItem.subTypeId || formData.subTypeId,
            description: invoiceItem.description || formData.description,
            workerId: invoiceItem.workerId || formData.workerId,
            weekendingDate: invoiceItem.weekendingDate ? setAPIDate(invoiceItem.weekendingDate) : formData.weekendingDate,
            invoiceItemDate: invoiceItem.invoiceItemDate ? setAPIDate(invoiceItem.invoiceItemDate) : formData.invoiceItemDate,
            payPeriodRefNum: invoiceItem.payPeriodRefNum || formData.payPeriodRefNum,
            hrsQty: invoiceItem.hrsQty || formData.hrsQty,
            invoiceRate: invoiceItem.invoiceRate || formData.invoiceRate,
            payRate: invoiceItem.payRate || formData.payRate,
            invoiceItemRate: invoiceItem.invoiceItemRate || formData.invoiceItemRate,
            //gross: invoiceItem.gross || formData.gross,
            gross: fixedDecimalPlaces(invoiceItem.gross) || fixedDecimalPlaces(formData.gross),
            tax: invoiceItem.tax || formData.tax,
            total: invoiceItem.total || formData.total,
            payRecordRefNum: invoiceItem.payRecordRefNum || formData.payRecordRefNum,
            exportLabel:invoiceItem.exportLabel || formData.exportLabel,
            payScheduleRefNum:invoiceItem.payScheduleRefNum || formData.payScheduleRefNum
        }
    }
    useEffect(()=>{

        setFormData({...formData, 
            description: calculatedInvoiceItemDetails.description, 
            weekendingDate:setAPIDate(calculatedInvoiceItemDetails.weekEnding),
            hrsQty:calculatedInvoiceItemDetails.hrsQty,
            invoiceRate:calculatedInvoiceItemDetails.invoiceRate,
            payRate:calculatedInvoiceItemDetails.payRate,
            gross:calculatedInvoiceItemDetails.gross,
            tax:calculatedInvoiceItemDetails.tax,
            total:calculatedInvoiceItemDetails.total,
            exportLabel:calculatedInvoiceItemDetails.exportLabel,
            //Added by nipul
            invoiceItemRate: calculatedInvoiceItemDetails.invoiceRate,
        })
        //API Data
        setRawData({ ...rawData
            , total: fixedDecimalPlaces(calculatedInvoiceItemDetails['total'])
            , gross: fixedDecimalPlaces(calculatedInvoiceItemDetails['gross'])
            , invoiceRate: fixedDecimalPlaces(calculatedInvoiceItemDetails['invoiceRate'])
            , hrsQty: fixedDecimalPlaces(calculatedInvoiceItemDetails['hrsQty'])
        });
    },[calculatedInvoiceItemDetails])

    useEffect(()=>{
        setFormData({...formData, invoiceRate: formData.invoiceItemRate})
    },[formData.invoiceItemRate])

    useEffect(()=>{
        setFormData({...formData, ...getFormData(invoiceItem)})
        
        //API Data
        setRawData({ ...rawData
            , total: fixedDecimalPlaces(invoiceItem['total'])
            , gross: fixedDecimalPlaces(invoiceItem['gross'])
            , invoiceRate: fixedDecimalPlaces(invoiceItem['invoiceRate'])
            , hrsQty: fixedDecimalPlaces(invoiceItem['hrsQty'])
        });
        
    },[invoiceItem])

    useEffect(()=>{
        
        if(formData.payScheduleRefNum === '0'){
            let defaultPaySchedule = payScheduleList.filter(paySchedule => paySchedule.defaultPaySchedule);
            if(defaultPaySchedule && defaultPaySchedule.length > 0){
                setFormData({...formData, payScheduleRefNum:defaultPaySchedule[0].payScheduleRefNum})
            }
        }
        
    },[payScheduleList])
    useEffect(()=>{
        if(refNum === 0){
            
            if(location.state !== undefined){
                //Take from state
                setrefNum(location.state?.refNum);
                setInvoiceRefNum(location.state?.invoiceRefNum);
                setClientId(location.state?.clientId);
                //Need to fix below stuff
                setFormData({ ...formData, refNum: location.state?.refNum
                    , invoiceRefNum: location.state?.invoiceRefNum
                    , clientId: location.state?.clientId
                })

                if(Number(location.state?.refNum) !== 0){
                    get_invoice_item(location.state?.refNum)
                }
                
                if(Number(location.state?.clientId) !== 0){
                    get_worker_list_by_client(location.state?.clientId);
                }
            }
            else if(localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_RefNum) !== null
                && localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_ClientId) !== null
                && localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_InvoiceRefNum) !== null){
                //Take from local storage 
                setrefNum(localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_RefNum));
                setClientId(localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_ClientId));
                setInvoiceRefNum(localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_InvoiceRefNum));
                get_invoice_item(localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_InvoiceRefNum));
                get_worker_list_by_client(NavgationPageLocationKey.InvoiceCurrentItem_ClientId);
                //Set state for same page
                const newState = { refNum: localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_RefNum)
                    , invoiceRefNum: localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_InvoiceRefNum) 
                    , clientId: localStorage.getItem(NavgationPageLocationKey.InvoiceCurrentItem_ClientId) 
                }
                history.push(location.pathname, newState);
                localStorage.removeItem(NavgationPageLocationKey.InvoiceCurrentItem_RefNum);
                localStorage.removeItem(NavgationPageLocationKey.InvoiceCurrentItem_InvoiceRefNum);
                localStorage.removeItem(NavgationPageLocationKey.InvoiceCurrentItem_ClientId);
            }
        }
        // if(refNum !== '0'){
        //     get_invoice_item(refNum)
        // }
        get_item_type_list();
        //get_worker_list_by_client(clientId);
        get_new_payComponent_name_list();
        get_pay_schedule_list();
        return ()=>{
            reset_invoice_item();
            reset_pay_schedule();
        } 
    },[])

    // Added by nipul for parameter less flow
    function navigateToInvoice(e, refId){
        e.preventDefault(); 
        history.push({
            pathname: `/new-invoice`,
            state: { refNum: refId }
        });
        return false;
    }

    function onContextMenuInvoiceChange(e, refId){
        if(e.type === 'contextmenu'){
            localStorage.setItem(NavgationPageLocationKey.InvoiceCurrentAddEdit, refId);
        }
    }

    function navigateToPayable(e, refId){
        e.preventDefault();
        history.push({
          pathname: `/payable`,
          state: { refNum: refId }
        });
        return false;
    }

    function onContextMenuPayableChange(e, refId){
        if(e.type === 'contextmenu'){
            localStorage.setItem(NavgationPageLocationKey.PayableCurrentAddEdit, refId);
        }
    }

    //For evaluation auto calculation
    const onChangeRawData = (e) => { 
        const regex = /^[0-9+\-*/().\b]*$/;
        let doesHaveOperator = checkArthimaticOperators(e.target.name, e.target.value);
        if (!regex.test(e.target.value) ) {
            e.preventDefault();
        }
        else if(!doesHaveOperator && e.target.value.indexOf('.') > 0 && !IsValidProcessNumber(e.target.value)){
            e.preventDefault();
        }
        else {
            CheckFormulaAndUpdate(e.target.name, e.target.value, doesHaveOperator);
        }
    }

    const CheckFormulaAndUpdate = (prop, val, doesHaveOperator) => {
        // Revised changes to accomodate manual values for gross
        switch(prop){
            case 'hrsQty' :
                if(val !== '' && doesHaveOperator){
                    setRawData({...rawData, isHrsQtyEvalDisable: false, [prop]: val });
                    return;
                }
                else {
                    let calCulatedGross = fixedDecimalPlaces(Number(val) * Number(formData.invoiceRate), 2);
                    setFormData({ ...formData, gross: calCulatedGross, ['hrsQty']: val })
                    setRawData({ ...rawData, gross: calCulatedGross, [prop]: val, isHrsQtyEvalDisable: true })
                }
            break;
            case 'invoiceRate':
                if(val !== '' && doesHaveOperator){
                    setRawData({...rawData, isInvoiceRateEvalDisable: false, [prop]: val });
                    return;
                }
                else {
                    let calCulatedGross = fixedDecimalPlaces(Number(formData.hrsQty) * Number(val), 2);
                    setFormData({ ...formData, gross: calCulatedGross, ['invoiceRate']: val })
                    setRawData({ ...rawData, gross: calCulatedGross, [prop]: val, isInvoiceRateEvalDisable: true })
                }
            break;
            default:
                setRawData({ ...rawData, [prop]: val }); 
                setFormData({ ...formData, [prop]: val });
            break;
        }
    }
    
    const checkArthimaticOperators = (name, val) => {
        const arthimaticRegex = /[+\-*/%]/;
        //if no operation then change main form value directly
        if(arthimaticRegex.test(val)){
            setFormData({ ...formData, [name]: val });
            return true;
        }
        return false;
    }

    const handleKeyDown = (event) => {
        let propName = event.nativeEvent.target.name;
        if (event.key === 'Enter' && checkArthimaticOperators(event.nativeEvent.target.name, event.nativeEvent.target.value)) {
            onChangeCalculationField(propName);
        }
    };

    const onChangeCalculationField = (prop) => {
        try{
            let evalValue = String(fixedDecimalPlaces(evaluate(string(rawData[prop])), 2));
            if(evalValue !== "Infinity")
                CheckFormulaAndUpdate(prop, evalValue, false);
            else
                setAlert("Cannot divide value by 0", 'danger');
        }
        catch(ex){
            setAlert("Please provide proper value to calculate", 'danger');
        }
    }   

    useEffect(() => {
        let calCulatedTotal = fixedDecimalPlaces(Number(formData.gross) + Number(formData.tax), 2);
        setFormData({ ...formData, total: calCulatedTotal })
        setRawData({ ...rawData, total: calCulatedTotal })
    },[formData.tax])

    useEffect(() => {
        let calCulatedTotal = fixedDecimalPlaces(Number(rawData.gross) + Number(formData.tax), 2);
        setFormData({ ...formData, total: calCulatedTotal });
    }, [rawData.gross]) 

    return (
        <Container fluid className="main-content-container p-4">
            <PayRecordPopUp
            toggle={toggle}
            openModal={openModal}
            selectedRefs={formData.payRecordRefNum}
            setSelectedRef={setSelectedPayRecordRefNum}
            {...formData}
            workerList={workerList}
            />
            {/*  */}
            <Form onSubmit={onSubmit} onKeyDown={prevent_enter_submit}>
                <Card small className="mb-4 w-100 ">
                    <ListGroup flush>
                        <ListGroupItem className="border-card p-3">
                            <Row className="">
                                <Col lg="6" className="form-group m-0 px-5">
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="description" className="">Description</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormInput
                                                value={formData.description}
                                                id="description"
                                                name='description'
                                                onChange={onChange}
                                                placeholder='Enter Description'
                                                className=''>

                                            </FormInput>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="exportLabel" className="">Export Label</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormInput
                                                value={formData.exportLabel}
                                                id="exportLabel"
                                                name='exportLabel'
                                                onChange={onChange}
                                                placeholder='Enter Export Label'>

                                            </FormInput>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="weekendingDate" className="">Week Ending</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <DatePicker
                                                id='weekendingDate'
                                                autoComplete='off'
                                                name='weekendingDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.weekendingDate}
                                                selected={formData.weekendingDate}
                                                onChange={(date) =>
                                                    setFormData({ ...formData, 'weekendingDate': date })
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="hrsQty" className="">Hrs/Qty</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">

                                            <FormInput name='hrsQty' className="align-input-amount-right"
                                                value={rawData.hrsQty} 
                                                align='right' 
                                                onKeyDown={handleKeyDown}
                                                onChange={onChangeRawData}
                                                placeholder="0.00" />
                                            <Button className="ml-2" size="sm" disabled={rawData.isHrsQtyEvalDisable}
                                                onClick={() => onChangeCalculationField('hrsQty')}>
                                                <i class="fa-solid fa-calculator"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceRate" className="">Invoice Rate</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">

                                            <FormInput name='invoiceRate' className="align-input-amount-right"
                                                value={rawData.invoiceRate} 
                                                align='right' 
                                                onKeyDown={handleKeyDown}
                                                onChange={onChangeRawData}
                                                placeholder="0.00" />
                                            <Button className="ml-2" size="sm" disabled={rawData.isInvoiceRateEvalDisable}
                                                onClick={() => onChangeCalculationField('invoiceRate')}>
                                                <i class="fa-solid fa-calculator"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="gross" className="">Gross</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">

                                            <FormInput name='gross' className="align-input-amount-right"
                                                value={rawData.gross} 
                                                align='right' 
                                                onChange={onChangeRawData}
                                                placeholder="0.00" />

                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="tax" className="">Tax</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="tax"
                                                name='tax'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.tax}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="total" className="">Total</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="total"
                                                name='total'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.total}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" className="form-group m-0 px-5">
                                <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="itemTypeId" className="">Item Type</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.itemTypeId}
                                                id="itemTypeId"
                                                name='itemTypeId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Item Type
                                                </option>
                                                {itemTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="subTypeId" className="">Sub Type</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.subTypeId}
                                                id="subTypeId"
                                                name='subTypeId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Sub Type
                                                </option>
                                                {subTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="workerId" className="">Worker</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.workerId}
                                                id="workerId"
                                                name='workerId'
                                                onChange={onChange}
                                                className='' >
                                                <option value=''>Select Worker
                                                </option>
                                                {workerListOptins}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="payScheduleRefNum" className="">Pay Schedule</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0">
                                        <FormSelect
                                                value={formData.payScheduleRefNum}
                                                id="payScheduleRefNum"
                                                name='payScheduleRefNum'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Pay Schedule
                                                </option>
                                                {payScheduleListOptions}
                                            </FormSelect> 
                                        </Col>
                                        <Col lg="3" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                            <label htmlFor="payPeriodRefNum" className="p-0">Pay Period</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0">
                                            <FormSelect
                                                value={formData.payPeriodRefNum}
                                                id="payPeriodRefNum"
                                                name='payPeriodRefNum'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Pay Period
                                                </option>
                                                {payPeriodListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceItemDate" className="">Date</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0">
                                            <DatePicker
                                                id='invoiceItemDate'
                                                autoComplete='off'
                                                name='invoiceItemDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.invoiceItemDate}
                                                selected={formData.invoiceItemDate}
                                                onChange={(date) => 
                                                    setFormData({ ...formData, 'invoiceItemDate': date })
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="6" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceItemRate" className="">Invoice Rate</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0 d-flex">
                                            <StandardFormInputFreeWidth
                                                id="invoiceItemRate"
                                                name='invoiceItemRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.invoiceItemRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                            <label htmlFor="payRate" className="p-0">Pay Rate</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0 d-flex">
                                            <StandardFormInputFreeWidth
                                                id="payRate"
                                                name='payRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.payRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4 pt-2">
                                        <Col lg="12" className="form-group my-auto pt-2 "></Col>
                                    </Row>
                                    
                                    
                                    <Row className="mb-4">
                                        <Col lg="4" className="form-group my-auto pt-3 ">
                                            <label htmlFor="clientId" className="">Payable Reference</label>

                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0 d-flex flex-row justify-content-center">
                                            <span className="pt-2">
                                                { Number(formData.payRecordRefNum) === 0 ?
                                                    <label className="primary-text"></label>
                                                    : <a href="/payable" onClick={(e) => {navigateToPayable(e, formData.payRecordRefNum)}}
                                                       onContextMenu={(e) => onContextMenuPayableChange(e, formData.payRecordRefNum)}>
                                                        {`PAY-${formData.payRecordRefNum}`}
                                                    </a>
                                                    //: <Link to={`/payable/${formData.payRecordRefNum}`}>{`PAY-${formData.payRecordRefNum}`}</Link>
                                                }
                                            </span>
                                        </Col>

                                        <Col lg="1" className="form-group my-auto  pt-2 pr-0 px-0 d-flex flex-row justify-content-end">
                                            
                                        </Col>
                                        <Col lg="4" className="form-group my-auto pl-0 dr pt-2 pr-2 px-0 d-flex flex-row justify-content-end">
                                            <Button  className=" py-2 mx-md-2" onClick={toggle}>Find Payable</Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row className="mb-4 px-4">
                                <Col lg="12" className="form-group my-auto pt-2 d-flex justify-content-end">
                                    <Button className=" py-2 mx-md-2">Save</Button>
                                    <Button className=" py-2 mx-md-2" onClick={(e) => {navigateToInvoice(e, invoiceRefNum)}}
                                        onContextMenu={(e) => {onContextMenuInvoiceChange(e, invoiceRefNum)}}
                                    >
                                        Cancel
                                    </Button>
                                    {/* <Button className=" py-2 mx-md-2" onClick={()=> history.replace(`/new-invoice/${invoiceRefNum}`)}>Cancel</Button> */}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Form>
        </Container>
    )
}

const mapStateToProps = (state)=>({
    currencyList:state.invoice.currencyList,
    invoiceItem:state.invoice.invoiceItem,
    itemTypeList:state.invoice.itemTypeList,
    subTypeList:state.invoice.subTypeList,
    workerList:state.invoice.workerList,
    newPayComponentNameList:state.payroll.newPayComponentNameList,
    payScheduleList:state.payroll.payScheduleList,
    paySchedule:state.payroll.paySchedule,
    calculatedInvoiceItemDetails:state.invoice.calculatedInvoiceItemDetails
})

export default connect(mapStateToProps,
     {
        get_item_type_list,
        get_worker_list_by_client,
        save_invoice_item,
        calculate_invoice_item_details,
        get_new_payComponent_name_list,
        get_invoice_item,
        get_pay_schedule_list,
        get_pay_schedule,
        reset_pay_schedule,
        reset_invoice_item,
        setAlert,
    }
    )(InvoiceItem)