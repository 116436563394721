import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormInput,
  FormSelect, Row
} from "shards-react";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import ReactTable from "react-table";
import {setDateForTable, setInputDate} from "../../utils/date";
import {getStatusClass, numberToMonetary} from "../../utils/general";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {get_expense_status_list} from "../../redux/actions/worker/expenses";
import {
  clear_expenses_sheet_details_for_client,
  search_expense_reports_for_client
} from "../../redux/actions/client/clientExpenses";
import {default as queryString} from "qs";
import {get_client_sub_status_list} from "../../redux/actions/user";

function ClientExpenses({
                          get_expense_status_list,
                          expenseStatusList,
                          subStatusList,
                          search_expense_reports_for_client,
                          clear_expenses_sheet_details_for_client,
                          expenseReportsList,
                          get_client_sub_status_list
                        }) {
  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  const [formData, setFormData] = useState({
    workerName: '',
    expenseStatus: '',
    fromDate: '',
    toDate: '',
    empStatus:'',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  useEffect(() => {
    get_expense_status_list();
    get_client_sub_status_list();
  }, []);

  useEffect(() => {
    let newState = {
      ...formData,
      workerName: queryParams.workerName || "",
      expenseStatus: queryParams.expenseStatus || "",
      fromDate: queryParams.fromDate || "",
      toDate: queryParams.toDate || "",
      empStatus: queryParams.empStatus || ""
    }
    searchExpenses(newState.workerName,
      newState.expenseStatus,
      newState.fromDate,
      newState.toDate, newState.empStatus)
    setFormData(newState)
  }, [queryParams.workerName,
    queryParams.expenseStatus,
    queryParams.fromDate,
    queryParams.toDate,
    queryParams.empStatus]);

  const searchExpenses = (workerName = formData.workerName,
                          expenseStatus = formData.expenseStatus,
                          fromDate = formData.fromDate,
                          toDate = formData.toDate,
                          empStatus = formData.empStatus) => {
    search_expense_reports_for_client(workerName, expenseStatus, setInputDate(fromDate), setInputDate(toDate), empStatus);
  }

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  let onStartDateChanged = (value) => {
    setFormData({
      ...formData,
      fromDate: value
    })
  }

  let onEndDateChanged = (value) => {
    setFormData({
      ...formData,
      toDate: value
    })
  }

  const tableData = expenseReportsList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "User ID",
      accessor: "userId",
      maxWidth: 80,
      Cell: row =>
        <Link
          to={`/client-view-worker-profile/${row.original.userId}`}>{row.original.userId}</Link>
    },
    {
      Header: "Worker Name",
      accessor: "workerName",
      maxWidth: 210,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Employment Status",
      accessor: "empStatus",
      maxWidth: 150,
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row =>
        <span
          className={getStatusClass(row.original.empStatus)}>{row.original.empStatus}</span>
    },
    {
      Header: "Date",
      accessor: "date",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.date)
    },
    {
      Header: "Report Name",
      accessor: "reportName",
      className: "justify-content-center text-center text-wrap text-break"
    },
    {
      Header: "Submitted Total",
      accessor: "submittedTotal",
      maxWidth: 130,
      className: "justify-content-end text-wrap text-break",
      Cell: row =>
        numberToMonetary(row.original.submittedTotal)
    },

    {
      Header: "Approved Total",
      accessor: "approvedTotal",
      maxWidth: 130,
      className: "justify-content-end text-wrap text-break",
      Cell: row =>
        numberToMonetary(row.original.approvedTotal)
    },

    {
      Header: "Rejected Total",
      accessor: "rejectedTotal",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => numberToMonetary(
        row.original.status === 'Rejected' ? row.original.submittedTotal :
        row.original.approvedTotal != 0 ? Number(row.original.submittedTotal) - Number(row.original.approvedTotal) : 0
      )
    },

    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },
    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      Cell: row =>
        <Link to={`/client-view-expense-sheet/${row.original.refNum}`}
              onClick={clear_expenses_sheet_details_for_client}>{row.original.refNum}</Link>
    },
  ];

  var expenseStatusListOptions = expenseStatusList.map((expenseStatusVal) => {
    return <option key={expenseStatusVal} value={expenseStatusVal}>{expenseStatusVal}</option>;
  });
  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus) => {
      return <option key={substatus} value={substatus}>{substatus}</option>;
    }
  );

  return (
    <Container fluid className="main-content-container p-4">
      <div 
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='workerName'
            value={formData.workerName}
            onChange={onChange}
            placeholder="Worker Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='expenseStatus' value={formData.expenseStatus}
                      onChange={onChange}>
            <option value=''>All Status</option>
            {expenseStatusListOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='empStatus' value={formData.empStatus}
                      onChange={onChange}>
            <option value=''>All Employment Status</option>
            {substatusListOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <RangeDatePicker className="justify-content-end"
                           onStartDateChanged={onStartDateChanged}
                           onEndDateChanged={onEndDateChanged} name="range"/>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                  onClick={e => searchExpenses()}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/*Filters :: Page Size*/}
              <Col lg="2"
                   className="file-manager__filters__rows d-flex align-items-center col-12">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>
              <Col lg="6" className="d-flex align-items-center">
                    <p className='m-0'>Note - This section shows Active & On Leave Users</p>
                </Col>
              <Col lg="4"
                   className="file-manager__filters__search d-flex justify-content-center justify-content-lg-end col-12">
                <a
                  href={`api/client/exportWorkerExpenseReports?workerName=${formData.workerName}&expenseStatus=${formData.expenseStatus}&fromDate=${formData.fromDate}&toDate=${formData.toDate}&empStatus=${formData.empStatus}&type=pdf`}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Export PDF</Button>
                </a>
                <a
                  href={`api/client/exportWorkerExpenseReports?workerName=${formData.workerName}&expenseStatus=${formData.expenseStatus}&fromDate=${formData.fromDate}&toDate=${formData.toDate}&empStatus=${formData.empStatus}&type=csv`}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Export CSV</Button>
                </a>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

ClientExpenses.propTypes = {
  expenseStatusList: PropTypes.array.isRequired,
  get_expense_status_list: PropTypes.func.isRequired,
  search_expense_reports_for_client: PropTypes.func.isRequired,
  expenseReportsList: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => ({
  expenseStatusList: state.expenses.expenseStatusList,
  expenseReportsList: state.clientExpenses.clientExpenseReportList,
  subStatusList: state.user.subStatusList,
});

export default connect(mapStateToProps, {
  get_expense_status_list,
  search_expense_reports_for_client,
  clear_expenses_sheet_details_for_client,
  get_client_sub_status_list,
})(ClientExpenses);
