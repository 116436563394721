import React, { useEffect, useState } from 'react';
import { Col, Nav } from "shards-react";

function TabBar({userProfile, formData, role, currentSection, setAlert, jumpTo, setContainerColorClass }) {
  const [componentState, setComponentState] = useState({
    activeSection: '',
  })

  useEffect(() => {
    //if (!componentState.activeSection)
    // setComponentState({
    //   ...componentState,
    //   activeSection: ''
    // })
    if (currentSection) {
      setComponentState({
        ...componentState,
        currentSection: ''
      })
    }

    if(jumpTo){
      setTimeout(()=>jumpInto(jumpTo), 400)
    }
    displaySections();
  }, [componentState.activeSection, userProfile]);


  const scrollInto = (id) => {
    setComponentState({ ...componentState, activeSection: id })
  }

  // const isSectionVisible = (rect) => {
  //   return (rect.top >= 0 &&
  //     rect.left >= 0 &&
  //     rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) - 100) &&
  //     rect.right <= (window.innerWidth || document.documentElement.clientWidth))
  // }

  // const setActiveSection = () => {
  //   for (var i = 0; i < sections.length; i++) {
  //     let section = document.querySelector(`#${sections[i].id} .section-title`);
  //     if (section) {
  //       let rect = document.querySelector(`#${sections[i].id} .section-title`).getBoundingClientRect()
  //       if (isSectionVisible(rect)) {
  //         if (componentState.activeSection !== sections[i].id) {
  //           setComponentState({ ...componentState, activeSection: sections[i].id })
  //         }
  //         break
  //       }
  //     }
  //   }
  // }

  const displaySections = () => {
    sections.forEach(section => {
      let element = document.getElementById(section.id)
      if (componentState.activeSection !== section.id) {
        if (element) {
          document.getElementById(section.id).classList.remove("show");
          document.getElementById(section.id).classList.add("hidden");
        }
      }
      else {
        if (element) {
          document.getElementById(section.id).classList.remove("hidden");
          document.getElementById(section.id).classList.add("show");
        }
      }
      let updatedSections = [...sections]

      if (!formData.workerFlag && !formData.workerLeadFlag) {
        updatedSections = [...updatedSections.filter(section => section.id !== 'placement' 
            && section.id !== 'benefits' && section.id !== 'retainer' && section.id !== 'notes' 
            && section.id !== 'userJourney' && section.id !== 'leaves' && section.id !== 'payItem'
        )]
      }
      else {

        if (updatedSections.filter(section => section.id == 'placement').length === 0) {

          updatedSections.splice(2, 0, {
            id: "placement",
            title: "Placement",
          });

        }
        
        if (updatedSections.filter(section => section.id == 'benefits').length === 0) {
          updatedSections.splice(4, 0, {
            id: "benefits",
            title: "Benefits",
          });

        }

        if (updatedSections.filter(section => section.id == 'retainer').length === 0) {
          updatedSections.splice(5, 0, {
            id: "retainer",
            title: "Retainer",
          });

        }

        if (updatedSections.filter(section => section.id == 'leaves').length === 0) {
          let position = formData.clientUserFlag || formData.clientLeadFlag ? updatedSections.length - 2 : updatedSections.length - 1;
          updatedSections.splice(position, 0, {
            id: "leaves",
            title: "Leaves",
          });

        }

        if (updatedSections.filter(section => section.id == 'notes').length === 0) {
          updatedSections.splice(updatedSections.length, 0, {
            id: "notes",
            title: "Notes",
          });

        }

        if (updatedSections.filter(section => section.id == 'userJourney').length === 0) {
          updatedSections.splice(updatedSections.length + 1, 0, {
            id: "userJourney",
            title: "User Journey",
          });

        }

        if (updatedSections.filter(section => section.id == 'payItem').length === 0 && formData.userId !== 0) {
          updatedSections.splice(updatedSections.length + 1, 0, {
            id: "payItem",
            title: "Pay Item",
          });
        }

      }
      if (formData.workerFlag || formData.clientUserFlag || formData.clientBillingFlag || formData.clientHRFlag || formData.clientAdminFlag
        || formData.workerLeadFlag || formData.clientLeadFlag
      ) {

        let position = updatedSections.length -2;

         if((!formData.workerFlag && !formData.workerLeadFlag && formData.clientUserFlag) 
          || (!formData.workerFlag && !formData.workerLeadFlag && formData.clientBillingFlag)
          || (!formData.workerFlag && !formData.workerLeadFlag && formData.clientHRFlag)
          || (!formData.workerFlag && !formData.workerLeadFlag && formData.clientLeadFlag)
          || (!formData.workerFlag && !formData.workerLeadFlag && formData.clientAdminFlag) ){
          position = updatedSections.length;
        }


        if (updatedSections.filter(section => section.id == 'integration').length === 0) {

          updatedSections.splice(position, 0, {
            id: "integration",
            title: "Integration",
          });
        }
      }
      else {
        updatedSections = [...updatedSections.filter(section => section.id !== 'integration')]

      }
      setSections([...updatedSections]);
    })
  }
  const checkRoles = () => {

    let roleSelected = false;
    if (formData.workerFlag || formData.clientHRFlag || formData.subscriberHRFlag
      || formData.clientBillingFlag || formData.subscriberBillingFlag || formData.clientAdminFlag || formData.clientAdminFlag || formData.subscriberAdminFlag
      || formData.systemAdminFlag || formData.clientUserFlag || formData.subscriberUserFlag || formData.subscriberApiIntegrationFlag
      || formData.workerLeadFlag || formData.clientLeadFlag
    ) {

      roleSelected = true;
    }

    return roleSelected;
  }

  const jumpInto = (subSection)=>{
   
    let el = document.getElementById(subSection);
    if(el !== null){
      el.scrollIntoView();
    }
  }
  const displaySection = (section) => {

    if (componentState.activeSection === 'settings' && !checkRoles()) {

      setAlert("Please Select Role.", "danger");
      jumpInto('roles')
      return;

    }
    if(section === 'documents'){
      setContainerColorClass('bg-white')
    }
    else{
      setContainerColorClass('')
    }
    setComponentState({ ...componentState, activeSection: section });
  }

  useEffect(() => {
    if(formData.activeSection !== ''){
      setComponentState({...componentState, activeSection : formData.activeSection});
      formData.activeSection = '';
    }
    displaySections();
  }, [formData])
  useEffect(() => {


    setComponentState({ ...componentState, activeSection: 'settings' })

    // window.onscroll = () => {
    //   setActiveSection()
    // }
    if (currentSection) {
      scrollInto(currentSection)
    }
    return () => {
      window.onscroll = null
    }
  }, []);

  const [sections, setSections] = useState([
    {
      id: "settings",
      title: "Settings",
    },

    {
      id: "personal",
      title: "Personal",
    },
    {
      id: "placement",
      title: "Placement",
    },
    {
      id: "employment",
      title: "Employment",
    },

    {
      id: "benefits",
      title: "Benefits",
    },

    {
      id: "retainer",
      title: "Retainer",
    },

    {
      id: "leaves",
      title: "Leaves",
    },

    {
      id: "documents",
      title: "Documents",
    },

    {
      id: "integration",
      title: "Integration",
    },

    {
      id: "notes",
      title: "Notes",
    },
    {
      id: "userJourney",
      title: "User Journey",
    },
    {
      id: "payItem",
      title: "Pay Item",
    },
    
  ])
  // if (role && role.role !== "Client User" && role.role !== "Client Billing" && role.role !== "Subscriber User") {
  //   sections.push({
  //     id: "emergencyContact",
  //     title: "Emergency Contact"
  //   });
  // }

  // if (role && role.role !== "Client User") {
  //   sections.push({
  //     id: "independentContractor",
  //     title: "Independent Contractor"
  //   });
  // }

  const sectionLinks = sections.map(section => {
    return <a
      className={'nav-link ' + (componentState.activeSection === section.id ? 'active font-weight-bold mr-3' : 'mr-3')}
      onClick={() => displaySection(section.id)}>{section.title}</a>
  })

  return (
    <Col sm={12} md={8} lg={9} xl={10}
      className='wrapper d-flex align-items-end order-md-0'>
      <Nav tag="nav" className="flex-nowrap overflow-auto tabBar">
        {sectionLinks}
      </Nav>
    </Col>
  );
}
export default TabBar;
