import api from "../../../utils/api";
import { setAlert } from "../alert";
import { BULK_SYNC_ITEMS, BULK_SYNC_ITEMS_ERROR, GETSEARCH_SYNC_APPROVAL_LIST, GETSEARCH_SYNC_APPROVAL_LIST_ERROR, GETSEARCH_SYNC_HISTORY_LIST, GETSEARCH_SYNC_HISTORY_LIST_ERROR } from "../types";

export const get_sync_history_list = (
    changeType, client, worker, paycomponent,
    modifiedBy, approvedBy, startDate, endDate
) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber/getSyncHistoryList`,
            {
                params: {
                    changeType: changeType,
                    clientName: client,
                    workerName: worker,
                    payComponent: paycomponent,
                    modifiedBy: modifiedBy,
                    approvedBy: approvedBy,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        );
        dispatch({
            type: GETSEARCH_SYNC_HISTORY_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GETSEARCH_SYNC_HISTORY_LIST_ERROR,
        });
    }
};

export const get_sync_approval_list = (
    changeType, client, worker, paycomponent,
    modifiedBy, startDate, endDate
) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber/getSyncApprovalList`,
            {
                params: {
                    changeType: changeType,
                    clientName: client,
                    workerName: worker,
                    payComponent: paycomponent,
                    modifiedBy: modifiedBy,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        );
        dispatch({
            type: GETSEARCH_SYNC_APPROVAL_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GETSEARCH_SYNC_APPROVAL_LIST_ERROR,
        });
    }
};

export const bulk_sync_items = (syncItems) => async (dispatch) => {
    try {
        const res = await api.post(`/api/subscriber/bulkSyncItems`, syncItems);
        dispatch({
            type: BULK_SYNC_ITEMS,
            payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: BULK_SYNC_ITEMS_ERROR,
        });
        return false;
    }
}