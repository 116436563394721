import React, { useEffect } from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from 'shards-react';
import { get_country_list, get_region_list } from '../../../redux/actions/user';
import { STANDARD_FORMATS } from '../../../utils/constants';
import StandardFormInputFreeWidth from '../../../components/common/StandardFormInputFreeWidth';
import { get_worker_comp_insurance_by_refnum, reset_worker_comp_view, save_worker_comp_insurance } from '../../../redux/actions/subscriber/rateTable';
import { setAPIDate, setInputDate } from '../../../utils/date';
import { setAlert } from '../../../redux/actions/alert';

const WorkerCompInsurance = ({
  workerCompInsuranceDetails, countryList, regionList, get_country_list, get_region_list
  , get_worker_comp_insurance_by_refnum, save_worker_comp_insurance, reset_worker_comp_view
  , setAlert
}) => {
  const { workerRefNum } = useParams();
  const history = useHistory();
  const initialState = {
    refNum: 0,
    countryId: '',
    regionId: '',
    year: '',
    effectiveDate: '',
    endDate: '',
    wsibRate: '',
    industry: '',
  }

  const [formData, setFormData] = useState(initialState);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  var regionListOption = regionList.map((option) => {
    return <option value={option.stateProvId} key={option.stateProvName}>{option.stateProvName}</option>;
  });
  var countryListOption = countryList.map((option) => {
    return <option value={option.countryId} key={option.countryName}>{option.countryName}</option>;
  });

  useEffect(() => {
    if (formData.countryId) {
      get_region_list(formData.countryId);
    }
  }, [formData.countryId]);

  useEffect(() => {
    resetForm();
    get_country_list();
    if (workerRefNum > 0) {
      //Fetch the details by Id
      get_worker_comp_insurance_by_refnum(workerRefNum);
    }
    return () => reset_worker_comp_view();
  }, [])

  useEffect(() => {
    if (workerCompInsuranceDetails && Object.keys(workerCompInsuranceDetails).length !== 0) {
      setFormData({ ...getFormData(workerCompInsuranceDetails) })
    }
  }, [workerCompInsuranceDetails])

  const getFormData = (obj) => {
    return {
      refNum: obj.refNum || 0,
      countryId: obj.countryId || '',
      regionId: obj.regionId || '',
      year: obj.year || '',
      effectiveDate: setAPIDate(obj.effectiveDate),
      endDate: setAPIDate(obj.endDate),
      wsibRate: obj.wsibRate || '',
      industry: obj.industry || '',
    }
  }

  function resetForm() {
    setFormData(initialState);
  }

  function resetAndBack() {
    history.replace(`/worker-comp-insurance`);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (e.nativeEvent.submitter.name === 'save') {
      //call an api to save
      let formDataToSubmit = { ...formData }
      formDataToSubmit.effectiveDate = setInputDate(formDataToSubmit.effectiveDate);
      formDataToSubmit.endDate = setInputDate(formDataToSubmit.endDate);
      let saved = await save_worker_comp_insurance(JSON.stringify(formDataToSubmit));
      if (saved) {
        history.replace(`/worker-comp-insurance`);
      }
    }
    else {
      /// -> Copy and create new entry
      setAlert("Copied! Please save new entry.", "success")
      setFormData({ ...formData, refNum: 0 })
      history.push('/worker-comp-insurance-add-edit/0');
    }
  }

  const onChangeStandardInput = (name, value) => setFormData({ ...formData, [name]: value });

  return (
    <>
      <Row className="w-100 mb-1 ml-n1" >
        <Col lg="12" className="mt-1 p-0">
          <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
            <Row className="m-1">

              <Col lg="12" className="d-flex flex-row justify-content-end py-2">
                <Button className="mx-1 py-2 mx-md-2 btn btn-accent btn-sm" size="sm" theme="accent" name="copyCreateNewEntry" type="submit" form="frmWorkerCompInsurance">
                  Copy & Create New Entry
                </Button>
                <Button className="mx-1 py-2 mx-md-2 btn btn-accent btn-sm" size="sm" theme="accent" type="submit" name="save" form="frmWorkerCompInsurance">
                  Save
                </Button>
                <Button className="mx-1 py-2 mx-md-2 btn btn-accent btn-sm" size="sm" theme="accent" onClick={() => { resetAndBack() }}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Container fluid className="main-content-container p-4">
        <div className="w-100">
          <Form id="frmWorkerCompInsurance" onSubmit={onSubmit}>
            <Card small className="mb-5 w-100 p-1">
              <h4 className="m-0 section-title idStyle">Ref# {workerRefNum}</h4>
              <ListGroup flush>
                <ListGroupItem className="border-card">
                  <Row form>
                    <Col lg="6" className="form-group p-2">
                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto pt-2">
                          <label htmlFor="countryId" className="">Country</label>
                          <span aria-hidden="true" className="required "> *</span>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <FormSelect required={true} value={formData.countryId}
                            id="countryId" name='countryId' onChange={onChange}>
                            <option value=''>Select Country
                            </option>
                            {countryListOption}
                          </FormSelect>
                        </Col>
                      </Row>
                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto pt-2">
                          <label htmlFor="regionId" className="">State/Province</label>
                          <span aria-hidden="true" className="required "> *</span>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <FormSelect required={true} value={formData.regionId}
                            id="regionId" name='regionId' onChange={onChange}>
                            <option value=''>Select State/Province </option>
                            {regionListOption}
                          </FormSelect>
                        </Col>
                      </Row>

                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto pt-2">
                          <label htmlFor="year" className="">Year</label>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <FormInput value={formData.year} id="year" name='year' onChange={onChange}
                            placeholder='Enter Year'>
                          </FormInput>
                        </Col>
                      </Row>
                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto">
                          <label htmlFor="effectiveDate" className="">Effective Date</label>
                          <span aria-hidden="true" className="required "> *</span>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <DatePicker id='effectiveDate' autoComplete='off' name='effectiveDate' required
                            dateFormat='yyyy-MM-dd' placeholderText="YYYY-MM-DD"
                            value={formData.effectiveDate} selected={formData.effectiveDate}
                            onChange={(date) =>
                              setFormData({ ...formData, effectiveDate: date })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="6" className="form-group p-2">
                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto pt-2">
                          <label htmlFor="wsibRate" className="">WSIB Rate (%)</label>
                          <span aria-hidden="true" className="required "> *</span>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <StandardFormInputFreeWidth
                            id="wsibRate" name='wsibRate'
                            standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                            dataMaxFracDigits="4" type="text"
                            required={true} value={formData.wsibRate}
                            onChangeStandardInput={onChangeStandardInput}
                            placeholder="0.0000" align='left'
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto pt-2">
                          <label htmlFor="industry" className="">Industry</label>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <FormInput value={formData.industry} id="industry" name='industry' onChange={onChange}
                            placeholder='Enter industry' />
                        </Col>
                      </Row>

                      <Row className="ml-2 form-group pt-2">
                        <Col lg="4" className="form-group my-auto pt-2">
                          <label htmlFor="endDate" className="">End Date</label>
                        </Col>
                        <Col lg="8" className="form-group my-auto">
                          <DatePicker id='endDate' autoComplete='off' name='endDate'
                            dateFormat='yyyy-MM-dd' placeholderText="YYYY-MM-DD"
                            value={formData.endDate} selected={formData.endDate}
                            onChange={(date) =>
                              setFormData({ ...formData, endDate: date })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Form>
        </div>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => ({
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  workerCompInsuranceDetails: state.rateTable.workerCompInsuranceDetails,
});

export default connect(mapStateToProps, {
  get_country_list, get_region_list, reset_worker_comp_view
  , save_worker_comp_insurance, get_worker_comp_insurance_by_refnum
  , setAlert
})
(WorkerCompInsurance)
