import { GET_EMPLOYMENT_INSURANCE_BY_REFNUM, GET_EMPLOYMENT_INSURANCE_BY_REFNUM_ERROR, GET_EMPLOYMENT_INSURANCE_LIST, GET_EMPLOYMENT_INSURANCE_LIST_ERROR, GET_GOVERNMENT_PENSION_2_BY_REFNUM, GET_GOVERNMENT_PENSION_2_BY_REFNUM_ERROR, GET_GOVERNMENT_PENSION_2_LIST, GET_GOVERNMENT_PENSION_2_LIST_ERROR, GET_GOVERNMENT_PENSION_BY_REFNUM, GET_GOVERNMENT_PENSION_BY_REFNUM_ERROR, GET_GOVERNMENT_PENSION_LIST, GET_GOVERNMENT_PENSION_LIST_ERROR, GET_HEALTH_TAX_BY_REFNUM, GET_HEALTH_TAX_BY_REFNUM_ERROR, GET_HEALTH_TAX_LIST, GET_HEALTH_TAX_LIST_ERROR, GET_WORKER_COMP_INSURANCE_BY_REFNUM, GET_WORKER_COMP_INSURANCE_BY_REFNUM_ERROR, GET_WORKER_COMP_INSURANCE_LIST, GET_WORKER_COMP_INSURANCE_LIST_ERROR, RESET_EMPLOYMENT_INSURANCE, RESET_GOVERNMENT_PENSION, RESET_GOVERNMENT_PENSION_2, RESET_HEALTH_TAX, RESET_WORKER_COMP_INSURANCE } from "../../actions/types";

const initialState = {
    workerCompInsuranceDetails: {},
    workerCompInsuranceList: [],
    healthTaxRateDetails: {},
    healthTaxList: [],
    governmentPensionDetails: {},
    governmentPensionList: [],
    employementInsuranceDetails: {},
    employmentInsuranceList: [],
    governmentPension2Details: {},
    governmentPension2List: [],
}

export const rateTable = (state = initialState, action) => {
    switch (action.type) {
        case GET_WORKER_COMP_INSURANCE_BY_REFNUM:
            return {
                ...state,
                workerCompInsuranceDetails: action.payload.data,
            };
        case GET_WORKER_COMP_INSURANCE_BY_REFNUM_ERROR:
            return {
                ...state,
                workerCompInsuranceDetails: {},
            }
        case GET_WORKER_COMP_INSURANCE_LIST:
            return {
                ...state,
                workerCompInsuranceList: action.payload.data,
            }
        case GET_WORKER_COMP_INSURANCE_LIST_ERROR:
            return {
                ...state,
                workerCompInsuranceList: []
            }
        case RESET_WORKER_COMP_INSURANCE:
            return {
                ...state,
                workerCompInsuranceDetails: {}
            }

        case GET_HEALTH_TAX_BY_REFNUM:
            return {
                ...state,
                healthTaxRateDetails: action.payload.data,
            };
        case GET_HEALTH_TAX_BY_REFNUM_ERROR:
            return {
                ...state,
                healthTaxRateDetails: {},
            }
        case GET_HEALTH_TAX_LIST:
            return {
                ...state,
                healthTaxList: action.payload.data,
            }
        case GET_HEALTH_TAX_LIST_ERROR:
            return {
                ...state,
                healthTaxList: []
            }
        case RESET_HEALTH_TAX:
            return {
                ...state,
                healthTaxRateDetails: {}
            }

        case GET_GOVERNMENT_PENSION_BY_REFNUM:
            return {
                ...state,
                governmentPensionDetails: action.payload.data,
            }
        case GET_GOVERNMENT_PENSION_BY_REFNUM_ERROR:
            return {
                ...state,
                governmentPensionDetails: {}
            }
        case GET_GOVERNMENT_PENSION_LIST:
            return {
                ...state,
                governmentPensionList: action.payload.data,
            }
        case GET_GOVERNMENT_PENSION_LIST_ERROR:
            return {
                ...state,
                governmentPensionList: []
            }
        case RESET_GOVERNMENT_PENSION:
            return {
                ...state,
                governmentPensionDetails: {}
            }

        case GET_EMPLOYMENT_INSURANCE_BY_REFNUM:
            return {
                ...state,
                employementInsuranceDetails: action.payload.data,
            };
        case GET_EMPLOYMENT_INSURANCE_BY_REFNUM_ERROR:
            return {
                ...state,
                employementInsuranceDetails: {},
            };
        case GET_EMPLOYMENT_INSURANCE_LIST:
            return {
                ...state,
                employmentInsuranceList: action.payload.data,
            }
        case GET_EMPLOYMENT_INSURANCE_LIST_ERROR:
            return {
                ...state,
                employmentInsuranceList: []
            }
        case RESET_EMPLOYMENT_INSURANCE:
            return {
                ...state,
                employementInsuranceDetails: {}
            }

        case GET_GOVERNMENT_PENSION_2_BY_REFNUM:
            return {
                ...state,
                governmentPension2Details: action.payload.data,
            };
        case GET_GOVERNMENT_PENSION_2_BY_REFNUM_ERROR:
            return {
                ...state,
                governmentPension2Details: {},
            };
        case GET_GOVERNMENT_PENSION_2_LIST:
            return {
                ...state,
                governmentPension2List: action.payload.data,
            }
        case GET_GOVERNMENT_PENSION_2_LIST_ERROR:
            return {
                ...state,
                governmentPension2List: []
            }
        case RESET_GOVERNMENT_PENSION_2:
            return {
                ...state,
                governmentPension2Details: {}
            }

        default:
            return state;
    }
}