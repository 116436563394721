import { Modal, ModalBody, ModalFooter, ModalHeader } from "shards-react";

export default function CustomModal({
    onShowModal,
    toggle,
    openModal,
    headerTitle,
    headerComponent,
    footerComponent,
    showCloseIcon = true,
    showHeader=true,
    footerClasses = "d-flex flex-row justify-content-center",
    className="custom-modal",
    size="lg",
    children,
    setRightHeaderStyle = false,
    rightHeaderWidth = ''
}) {

    return (<div className={`${className} `}>
        <Modal size={size} fade={false} backdrop={false} className="d-flex align-items-between justify-content-between w-100" showModal={onShowModal} open={openModal} toggle={toggle}>
            {showHeader && <ModalHeader className="p-2 w-100 border-none">
                <div className="d-flex flex-row justify-content-between">
                    <span>{headerTitle}</span>
                    <span className="d-flex flex-row justify-content-between" style={ setRightHeaderStyle ? { width: rightHeaderWidth } : {}} >
                        {headerComponent}
                        {showCloseIcon && <i class="fa-solid fa-xmark primary-text bg-white float-right p-1" role="button" onClick={toggle}></i>}
                    </span>
                </div>
            </ModalHeader>}
            <ModalBody>
                {children}
            </ModalBody>
            {footerComponent && <ModalFooter className={`py-0 ${footerClasses}`}>
                {footerComponent}
            </ModalFooter>}
        </Modal>
    </div>
    )
}