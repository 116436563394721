import React, { useEffect, useState } from 'react'
import { Button, Container, FormInput, FormSelect } from 'shards-react';
import { connect } from 'react-redux';
import CustomTable from '../../../components/common/CustomTable';
import { get_sync_history_list } from '../../../redux/actions/subscriber/sync';
import RangeDatePicker from '../../../components/common/RangeDatePicker';
import { setDateForTable, setInputDate, utcTimestampToLocalTimeStringWithSeconds } from '../../../utils/date';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const get_sub_sections = (sectionName)=>{
    switch(sectionName){
        case 'new-Retainer':
        case 'update-Retainer':
            return'section=retainer&subsection=RetainerInfo'
        case 'new-BenefitsClient':
        case 'new-BenefitsWorker':
        case 'update-BenefitsClient':
        case 'update-BenefitsWorker':
            return'section=benefits'
        case 'new-Placement':
        case 'update-Placement':
            return'section=placement&subSection=placementData'
        case 'update-Compensation':
        case 'new-Compensation':
            return'section=employment&subSection=CompensationInfo'
        case 'new-RetirementClient':
        case 'new-RetirementWorker':
        case 'update-RetirementClient':
        case 'update-RetirementWorker':
            return'section=benefits&subSection=pension'
        default:
            return ''
    }
}
const SyncHistory = ({ syncHistoryList, get_sync_history_list }) => {

    let initialData = {
        changeType: '',
        clientName: '',
        workerName: '',
        payComponent: '',
        approvedByName: '',
        updatedByName: '',
        from: '',
        to: '',
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
    }
    const [formData, setFormData] = useState(initialData);

    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState(syncHistoryList);

    const tableColumns = [
        {
            Header: "Modified Date", accessor: "modifiedDate", className: "text-center",
            Cell:row=>Number(row.original.updatedAt) !== 0 ? utcTimestampToLocalTimeStringWithSeconds(row.original.updatedAt) : ''
        },
        {
            Header: "Change Type", accessor: "changeType", className: "text-center",
            Cell: row => !row.original.changeType.includes('delete') ? <Link to={`/manage-user-profile/${row.original.workerId}?role=Worker&${get_sub_sections(row.original.changeType)}`}><div title={row.original.changeType}>{row.original.changeType}</div></Link> : <div title={row.original.changeType}>{row.original.changeType}</div>        },
        {
            Header: "Client", accessor: "clientName", className: "text-center"
            , Cell: row => <div title={row.original.clientName}>{row.original.clientName}</div>
        },
        {
            Header: "Worker", accessor: "workerName", className: "text-center"
            , Cell: row => <div title={row.original.workerName}>{row.original.workerName}</div>
        },
        {
            Header: "Pay Component", accessor: "payComponent", className: "text-center"
            , Cell: row => <div title={row.original.payComponent}>{row.original.payComponent}</div>
        },
        {
            Header: "Effective Date", accessor: "effectiveDate", className: "text-center",
            Cell:row=>setDateForTable(row.original.effectiveDate)
        },
        {
            Header: "Modified By", accessor: "updatedByName", className: "text-center"
            , Cell: row => <div title={row.original.updatedByName}>{row.original.updatedByName}</div>
        },
        {
            Header: "Approved By", accessor: "approvedByName", className: "text-center"
            , Cell: row => <div title={row.original.approvedByName}>{row.original.approvedByName}</div>
        },
    ];

    useEffect(() => {
        get_sync_history_list('', '', '', '', '', '', 0, 0);
    }, [])

    useEffect(() => {
        setGridData(syncHistoryList);
    }, [syncHistoryList])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    let onStartDateChanged = (value) => {
        setFormData({ ...formData, from: value })
    }

    let onEndDateChanged = (value) => {
        setFormData({ ...formData, to: value })
    }

    function searchHistory(e) {
        e.preventDefault();
        const fromDate = setInputDate(formData.from);
        const toDate = setInputDate(formData.to);
        get_sync_history_list(formData.changeType, formData.clientName, formData.workerName, formData.payComponent, formData.updatedByName, formData.approvedByName, fromDate, toDate);
    }

    function resetHistory() {
        setFormData(initialData);
        get_sync_history_list('', '', '', '', '', '', 0, 0);
    }

    return (
        <Container fluid className="main-content-container p-4">
            <div noGutters
                className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">

                <div className="d-flex align-items-center p-2 ml-2">
                    <FormInput name='changeType' value={formData.changeType}
                        onChange={onChange} placeholder="Change Type" />
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormInput name='clientName' value={formData.clientName}
                        onChange={onChange} placeholder="Client" />
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormInput name='workerName' value={formData.workerName}
                        onChange={onChange} placeholder="Worker" />
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormInput name='payComponent' value={formData.payComponent}
                        onChange={onChange} placeholder="Pay Component" />
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormInput name='approvedByName' value={formData.approvedByName}
                        onChange={onChange} placeholder="Approved By" />
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormInput name='updatedByName' value={formData.updatedByName}
                        onChange={onChange} placeholder="Modified By" />
                </div>
                <div className="d-flex align-items-center p-2">
                    <RangeDatePicker className="justify-content-end" name="range"
                    startDate={formData.from}
                    endDate={formData.to}
                        onStartDateChanged={onStartDateChanged} onEndDateChanged={onEndDateChanged} />
                </div>

                <div className="d-flex align-items-center justify-content-end p-2">
                    <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                        onClick={searchHistory}>Search</Button>

                    <Button onClick={resetHistory} className="mx-2 py-2 my-2"
                        size='sm' theme="accent">Reset</Button>
                </div>
            </div>
            <div className="w-100">
                <CustomTable
                    pageSizeOptions={pageSizeOptions}
                    pageSize={pageSize}
                    onChange={onChange}
                    tableColumns={tableColumns}
                    tableData={gridData}
                    showHeader />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    syncHistoryList: state.sync?.syncHistoryList
});

export default connect(mapStateToProps, { get_sync_history_list })
    (SyncHistory)
