import React from "react";
import PropTypes from "prop-types";
import {NavLink as RouteNavLink} from "react-router-dom";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "shards-react";
import classNames from "classnames";
import { withRouter } from 'react-router'
import {Dispatcher, Constants} from "../../../flux";
import SideBarSubNavItem from "./SideBarSubNavItem";

class SidebarNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleSubMenuDropDown = this.toggleSubMenuDropDown.bind(this);
  }

  toggleDropdown(item) {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR_DROPDOWN,
      payload: item
    });
  }

  toggleSubMenuDropDown(item) {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_PAYROLL_DROPDOWN,
      payload: item
    });
  }

  render() {
    const {item} = this.props;
    const hasSubItems = item.items && item.items.length;

    const location=this.props.location

    //const isActive=item.activeRoutes?item.activeRoutes.some(route =>location.pathname.startsWith(route)):false;
    const isActive=item.activeRoutes?item.activeRoutes.some(route =>location.pathname === route):false;
    let navLinkClassNames=[]
    if(hasSubItems){
      navLinkClassNames.push("dropdown-toggle")
      const activeRoutes = item.items.find((subItem) => {
         return subItem.activeRoutes.some(route=> location.pathname.startsWith(route))
      })
      if(activeRoutes){
        navLinkClassNames.push('active')
      }
    }
    if(isActive)navLinkClassNames.push("active")
    return (
      <NavItem style={{position: "relative"}} >
        <NavLink
          className={classNames(navLinkClassNames)}
          tag={hasSubItems ? "a" : RouteNavLink}
          to={hasSubItems ? "#" : item.to}
          onClick={() => this.toggleDropdown(item)}
        >
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{__html: item.htmlBefore}}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{__html: item.htmlAfter}}
            />
          )}
        </NavLink>
        {hasSubItems && (
          
          <Collapse tag={DropdownMenu} small open={item.open} style={{top: 0}} >
            {item.items.map((subItem, idx) => {
              //const isSubItemActive=subItem.activeRoutes !== undefined ? subItem.activeRoutes.some(route =>location.pathname.startsWith(route)) : false;
              const isSubItemActive=subItem.activeRoutes !== undefined ? subItem.activeRoutes.some(route =>location.pathname === route) : false;

              //Added by nipul
              const subMenu = subItem.items && subItem.items.length;
              if(subMenu){
                return <SideBarSubNavItem key={idx} item={subItem}  />

              }
              else {
                return (
                  <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to} className={(isSubItemActive?"active":"")}>
                    {subItem.htmlBefore && (
                      <div
                        className="d-inline-block subItem-icon-wrapper ml-2 mr-1 "
                        dangerouslySetInnerHTML={{__html: subItem.htmlBefore}}
                      />
                    )}
                    {subItem.title}
                  </DropdownItem>
                )
              }
            })}
          </Collapse>
        )}
      </NavItem>
    );
  }
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default withRouter(SidebarNavItem);
