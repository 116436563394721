import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
    FormInput
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    get_benefits_class_list,
    get_benefits_coverage_list,
    save_user_profile,
    update_isSave_user_profile,
    sync_pay_items,
    attach_Pay_Item,
    get_pay_item_list,
} from "../../../redux/actions/user";
import { calculateEndDate, setAPIDate, setDateForTable, setInputDate, plusDays } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {compareObjects, fixedDecimalPlaces, monetaryToNumber, numberToMonetary, parsingUserProfileSavingObject, setAllowanceTypeString, setApproverInfoString, setLeavePolicyString, setLeaveTypeString } from '../../../utils/general';
import PayTypeComponentModal from '../PayTypeComponentModal';
import { STANDARD_FORMATS, SUBSCRIBER_BILLING_ADMIN, SUBSCRIBER_SYNC_TYPE } from '../../../utils/constants';
import ConfirmAlert from '../../common/ConfirmAlert';

function BenefitsForm(props) {
    const location = useLocation();
    const [notesCharacters, seNotesCharacters] = useState(100);
    const history = useHistory();
    const formDataVal = useRef({})
    const [disabledSave, setDisabledSave] = useState(location.state.row !== undefined)
    const [disabledEndDate, setDisableEndDate] = useState(location.state.row !== undefined && location.state.formData.benefitsInfos[0] !== undefined && location.state.row.benefitsId !== location.state.formData.benefitsInfos[0].benefitsId );
    //Added by nipul
    const [userNameForModal, setUserNameForModal] = useState('');
    const [openModalForPayItem, setOpenModalForPayItem] = useState(false);
    const [isSelectable, setIsSelectable] = useState(false);
    const [isWorkerMode, setIsWorkerMode] = useState(true);
    const isSubmitted = useRef(true);
    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        benefitsCertificateNo: '',
        benefitsClass: '',
        benefitsCoverage: '',
        benefitsEndDate: '',
        benefitsStartDate: location.state.formData.benefitsInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        clientContribution: '',
        contribution: '',
        //ciCompensationCurrency: location.state.formData.baseCurrency,
        biBenefitsApplicable: 'Yes',
        biBenefitsCurrency: location.state.formData.baseCurrency,
        biBenefitsDeductionFrequency: '',
        biEmployerPayComponentId: '0',
        biEmployer: '',
        biEmployeePayComponentId: '0',
        biWorker: '',
        biLtdAmount: '',
        biLifeAdnDAmount: '',
        biTotalTaxableBenefitsAmount: '',
        biHealthAmount: '',
        biBenefitsMarkUp: location.state.clientMarkups !== undefined ? location.state.clientMarkups.benefitsType : '',
        biTotalMarkupAmount: '',
        biBenefitsMarkupAmount: location.state.clientMarkups !== undefined ?  fixedDecimalPlaces(location.state.clientMarkups.benefitsAmount,  2): '',
        notes: '',
        benefitsId: 1,
        //added by nipul
        syncPayItemClient: location.state.formData.syncPayItemClient,
        payItemRefNumClient: location.state.formData.payItemRefNumClient,
        syncPayItemWorker: location.state.formData.syncPayItemWorker,
        payItemRefNumWorker: location.state.formData.payItemRefNumWorker,

    });
    const [selectedWorker, setSelectedWorker] = useState(formData.payItemRefNumWorker);
    const [selectedClient, setSelectedClient] = useState(formData.payItemRefNumClient);
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null,
    });

    const toggleModal = () => {
        setOpenModalForPayItem(!openModalForPayItem);
    }

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const currentBenefits = (location.state.formData && location.state.formData.benefitsInfos) ? Object.values(location.state.formData.benefitsInfos).flat() : [];

    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        const value = e.target.value;

        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');

        e.target.value = formattedValue;

        onChange(e);
    };

    const removingObject = (refNum)=>{
        location.state.formData.benefitsInfos = (location.state.formData.benefitsInfos || []).filter(benefit=>benefit.benefitsId !== refNum)
    }
    const SavingDataInObject = () => {
        for (const currentBenefit of currentBenefits) {
            let currentDate = new Date(setDateForTable(currentBenefit.benefitsStartDate)).setHours(0, 0, 0, 0);

            let selectedDate = new Date(formData.benefitsStartDate).setHours(0, 0, 0, 0);
            if (currentBenefit.benefitsId !== formData.benefitsId && selectedDate === currentDate) {
                props.setAlert("Details with the same Benefits Start Date exists.", "danger");
                return 0;
            }
        }

        let updatedFormData = { ...formData };
        if ('benefitsEndDate' in updatedFormData) updatedFormData.benefitsEndDate = updatedFormData.benefitsEndDate === '' ? '0' : setInputDate(updatedFormData.benefitsEndDate);
        if ('benefitsStartDate' in updatedFormData) updatedFormData.benefitsStartDate = setInputDate(updatedFormData.benefitsStartDate);
        if ('clientContribution' in updatedFormData) updatedFormData.clientContribution = fixedDecimalPlaces(updatedFormData.clientContribution, 2);
        if ('contribution' in updatedFormData) updatedFormData.contribution = fixedDecimalPlaces(updatedFormData.contribution, 2);
        if ('biEmployer' in updatedFormData) updatedFormData.biEmployer = fixedDecimalPlaces(updatedFormData.biEmployer, 2);
        if ('biWorker' in updatedFormData) updatedFormData.biWorker = fixedDecimalPlaces(updatedFormData.biWorker, 2);
        if ('biLtdAmount' in updatedFormData) updatedFormData.biLtdAmount = fixedDecimalPlaces(updatedFormData.biLtdAmount, 2);
        if ('biLifeAdnDAmount' in updatedFormData) updatedFormData.biLifeAdnDAmount = fixedDecimalPlaces(updatedFormData.biLifeAdnDAmount, 2);
        if ('biTotalTaxableBenefitsAmount' in updatedFormData) updatedFormData.biTotalTaxableBenefitsAmount = fixedDecimalPlaces(updatedFormData.biTotalTaxableBenefitsAmount, 2);
        if ('biHealthAmount' in updatedFormData) updatedFormData.biHealthAmount = fixedDecimalPlaces(updatedFormData.biHealthAmount, 2);
        if ('biTotalMarkupAmount' in updatedFormData) updatedFormData.biTotalMarkupAmount = fixedDecimalPlaces(updatedFormData.biTotalMarkupAmount, 2);
        if ('biBenefitsMarkupAmount' in updatedFormData) updatedFormData.biBenefitsMarkupAmount = fixedDecimalPlaces(updatedFormData.biBenefitsMarkupAmount, 2);
        
        formDataVal.current = {...updatedFormData}

        let isSaved = false;
        if (location.state.row) {

            let currentBenefitForms = location.state.formData.benefitsInfos;
            let updatedBenefits = [];
            if (currentBenefitForms) {
                updatedBenefits = currentBenefitForms.map(benefit => {
                    if (benefit.benefitsId === updatedFormData.benefitsId) {
                        return {
                            userId: updatedFormData.userId,
                            benefitsId: updatedFormData.benefitsId,
                            benefitsCertificateNo: updatedFormData.benefitsCertificateNo,
                            benefitsClass: updatedFormData.benefitsClass,
                            benefitsCoverage: updatedFormData.benefitsCoverage,
                            benefitsEndDate: updatedFormData.benefitsEndDate === "" ? "0" : updatedFormData.benefitsEndDate,
                            benefitsStartDate: updatedFormData.benefitsStartDate,
                            clientContribution: updatedFormData.clientContribution,
                            contribution: updatedFormData.contribution,
                            biBenefitsApplicable: updatedFormData.biBenefitsApplicable,
                            biBenefitsCurrency: updatedFormData.biBenefitsCurrency,
                            biBenefitsDeductionFrequency: updatedFormData.biBenefitsDeductionFrequency,
                            biEmployerPayComponentId: updatedFormData.biEmployerPayComponentId,
                            biEmployer: updatedFormData.biEmployer,
                            biEmployeePayComponentId: updatedFormData.biEmployeePayComponentId,
                            biWorker: updatedFormData.biWorker,
                            biLtdAmount: updatedFormData.biLtdAmount,
                            biLifeAdnDAmount: updatedFormData.biLifeAdnDAmount,
                            biTotalTaxableBenefitsAmount: updatedFormData.biTotalTaxableBenefitsAmount,
                            biHealthAmount: updatedFormData.biHealthAmount,
                            biBenefitsMarkUp: updatedFormData.biBenefitsMarkUp,
                            biTotalMarkupAmount: updatedFormData.biTotalMarkupAmount,
                            biBenefitsMarkupAmount: updatedFormData.biBenefitsMarkupAmount,
                            notes: updatedFormData.notes,

                            syncPayItemClient: updatedFormData.syncPayItemClient,
                            payItemRefNumClient: updatedFormData.payItemRefNumClient,
                            syncPayItemWorker: updatedFormData.syncPayItemWorker,
                            payItemRefNumWorker: updatedFormData.payItemRefNumWorker,
                        };
                    }
                    else {
                        return benefit;
                    }
                })
            }
            location.state.formData.benefitsInfos = updatedBenefits;
            isSaved = true;
        }
        else {
            location.state.formData = {...location.state.formData, benefitsInfos:[{...updatedFormData}, ...location.state.formData.benefitsInfos], benefitsApplicable:'Yes'}
            isSaved = true;
        }

        if (isSaved) {

            if(location.state.formData.benefitsInfos.length > 1){
                
                location.state.formData.benefitsInfos.sort(function(eap1, eap2){return eap2.benefitsStartDate - eap1.benefitsStartDate});
                for(let i = 0; i < location.state.formData.benefitsInfos.length; i++){
                   if(location.state.formData.benefitsInfos[i].benefitsEndDate !== '0'){
                        if((i+1) < location.state.formData.benefitsInfos.length ){
                            
                            location.state.formData.benefitsInfos[i+1].benefitsEndDate = calculateEndDate(location.state.formData.benefitsInfos[i].benefitsStartDate, 1);
                        }
                   }
                   else{
                    if(i === 0 && (i+1) < location.state.formData.benefitsInfos.length && location.state.formData.benefitsInfos[i+1].benefitsEndDate !== '0') continue;
                        location.state.formData.benefitsInfos[i+1].benefitsEndDate = location.state.formData.benefitsInfos[i] ? calculateEndDate(location.state.formData.benefitsInfos[i].benefitsStartDate, 1) : '0';
                   }
                }
            }
            return true; 
        }
        return false;
    }

    const onSave = async (e) => {
        
        e.preventDefault();
        if(isSubmitted.current){
            if(SavingDataInObject()){
                isSubmitted.current = true;
                let userId = formData.userId === undefined ? 0 : formData.userId;
                let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
                history.push({
                    pathname: `/manage-user-profile/${userId}`,
                    search: `${roleStr}&section=benefits`,
                    state: { formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef, reload: props.isSaveUserProfile },
                });
            }
        }
    }

    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
        let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
        props.update_isSave_user_profile(false);
        history.push({
            pathname: `/manage-user-profile/${userId}`,
            search: `${roleStr}&section=benefits`,
            state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef, reload: props.isSaveUserProfile },
        });
    }
    const onDateChange = (name, value) => {

        let benefitsInfos = location.state.formData.benefitsInfos;
        let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
        let selectedDate = new Date(value).setHours(0, 0, 0, 0);
        let checkBackDate = false;
        let benefitsStartDate = new Date(name === 'benefitsEndDate' ? formData.benefitsStartDate : value ).setHours(0, 0, 0, 0);
        let benefitsEndDate = new Date(name === 'benefitsStartDate' ? formData.benefitsEndDate : value).setHours(0, 0, 0, 0);
        let currentDate = new Date().setHours(0, 0, 0, 0);    
        let selectedPreviousEndDate = '';

        if(name === 'benefitsEndDate'){
            if(selectedDate > 0 && selectedDate < benefitsStartDate){
                props.setAlert("Benefits End Date must not be less than Benefits Start Date.", "danger");
                return;
            }

            setFormData({ ...formData, [name]: value});
            return;

        }
        else {  
            if(selectedDate < hireDate){
                props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
                    return 0;
            }
            if(formData.benefitsEndDate !== '' && benefitsStartDate > benefitsEndDate){
                props.setAlert("Benefits Start Date must be greater than Benefits End Date.", "danger");
                return;
            }

            checkBackDate = selectedDate < currentDate

            for (let i = 0; i < benefitsInfos.length ; i++) {
                let currentBenefit = benefitsInfos[i];
                let previousBenefit = benefitsInfos[i+1];

                let currentDate = new Date(setDateForTable(currentBenefit.benefitsStartDate)).setHours(0, 0, 0, 0);
                let prevEndDate = (currentBenefit && currentBenefit.benefitsEndDate !== '0') ? new Date(setDateForTable(currentBenefit.benefitsEndDate)).setHours(0, 0, 0, 0) : '';
                let prevStartDate = (currentBenefit && currentBenefit.benefitsStartDate !== '0') ? new Date(setDateForTable(currentBenefit.benefitsStartDate)).setHours(0, 0, 0, 0) : '';
               
                if(selectedDate < prevEndDate  &&  selectedDate > prevStartDate && currentBenefit){
                    checkBackDate = true;
                    selectedPreviousEndDate = setAPIDate(currentBenefit.benefitsEndDate);
                    //currentBenefit.benefitsEndDate = calculateEndDate(setInputDate(value), 1);
                }
                
                if(prevEndDate !== ''  && selectedDate === prevEndDate){
                    props.setAlert("Benefits can not start from previous benefits End Date.", "danger");
                    return 0;
                }
                if (currentBenefit.benefitsId !== formData.benefitsId && selectedDate === currentDate) {
                    props.setAlert("Details with the same Benefits Start Date exists.", "danger");
                    return 0;
                }
            }
        }
        //setHasError(!hasError)
        setDisableEndDate(checkBackDate)
        setFormData({ ...formData, [name]: value, benefitsEndDate : checkBackDate ? selectedPreviousEndDate : formData.benefitsEndDate });

    };
    var payComponentListOption = props.payComponentList && props.payComponentList.filter(x => x.type === "Recurring").map((cmp) => {
        return <option key={cmp.name + cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });
    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });

    var benefitsCoverageListOption = props.benefitsCoverageList && props.benefitsCoverageList.map((coverage) => {
        return <option key={coverage} value={coverage}>{coverage}</option>;
    });
    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });
    var benefitsClassListOption = props.benefitsClassList && props.benefitsClassList.map((benefitsClass) => {
        return <option key={benefitsClass}
            value={benefitsClass}>{benefitsClass}</option>;
    });

    useEffect(() => {

        if (location.state.userProfile.firstName) {
            props.changePageMeta(`Benefits - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
            setUserNameForModal(location.state.userProfile.personalInfo.firstName + " " + location.state.userProfile.personalInfo.lastName);
        }
        else {
            props.changePageMeta(`Benefits - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
            setUserNameForModal(location.state.formData.firstName + " " + location.state.formData.lastName);
        }

        props.get_worker_types();
        props.sub_get_currency_list();
        props.get_deal_types_types();
        props.get_compensation_types();
        props.get_pay_component_list();
        props.get_benefits_class_list();
        props.get_benefits_coverage_list();

        let benefitsInfos = []
        for (const currentBenefit of currentBenefits) {

            let currentBenefitinfos = {
                userId: currentBenefit.userId,
                benefitsId: currentBenefit.benefitsId,
                benefitsCertificateNo: currentBenefit.benefitsCertificateNo,
                benefitsClass: currentBenefit.benefitsClass,
                benefitsCoverage: currentBenefit.benefitsCoverage,
                benefitsEndDate: setAPIDate(currentBenefit.benefitsEndDate),
                //benefitsStartDate: setAPIDate(currentBenefits.benefitsStartDate),
                benefitsEndDate: setAPIDate(currentBenefit.benefitsEndDate),
                clientContribution:  currentBenefit.clientContribution,
                contribution: currentBenefit.contribution,
                biBenefitsApplicable: currentBenefit.benefitsApplicable,
                biBenefitsCurrency: currentBenefit.benefitsCurrency,
                biBenefitsDeductionFrequency: currentBenefit.benefitsDeductionFrequency,
                biEmployerPayComponentId: currentBenefit.employerPayComponentId,
                biEmployer: currentBenefit.employer,
                biEmployeePayComponentId: currentBenefit.employeePayComponentId,
                biWorker: currentBenefit.worker,
                biLtdAmount: currentBenefit.ltdAmount,
                biLifeAdnDAmount: currentBenefit.lifeAdnDAmount,
                biTotalTaxableBenefitsAmount: currentBenefit.totalTaxableBenefitsAmount,
                biHealthAmount: currentBenefit.healthAmount,
                biBenefitsMarkUp: currentBenefit.benefitsMarkUp,
                biTotalMarkupAmount: currentBenefit.totalMarkupAmount,
                biBenefitsMarkupAmount: currentBenefit.benefitsMarkupAmount,
                notes: currentBenefit.notes,
                //added by nipul
                syncPayItemClient: currentBenefit.syncPayItemClient,
                payItemRefNumClient: currentBenefit.payItemRefNumClient,
                syncPayItemWorker: currentBenefit.syncPayItemWorker,
                payItemRefNumWorker: currentBenefit.payItemRefNumWorker,

            }

            benefitsInfos = [...benefitsInfos, currentBenefitinfos];

        }

        location.state.parentFormData = { ...location.state.parentFormData, benefitsInfos }


        if (location.state && location.state.row) {

            let currentBenefits = { ...location.state.row }

            setFormData({
                ...formData,
                userId: currentBenefits.userId,
                benefitsId: currentBenefits.benefitsId,
                benefitsCertificateNo: currentBenefits.benefitsCertificateNo,
                benefitsClass: currentBenefits.benefitsClass,
                benefitsCoverage: currentBenefits.benefitsCoverage,
                benefitsEndDate: setAPIDate(currentBenefits.benefitsEndDate),
                benefitsStartDate: setAPIDate(currentBenefits.benefitsStartDate),
                clientContribution: currentBenefits.clientContribution,
                contribution: currentBenefits.contribution,
                biBenefitsApplicable: currentBenefits.biBenefitsApplicable,
                biBenefitsCurrency: currentBenefits.biBenefitsCurrency,
                biBenefitsDeductionFrequency: currentBenefits.biBenefitsDeductionFrequency,
                biEmployerPayComponentId: currentBenefits.biEmployerPayComponentId,
                biEmployer: currentBenefits.biEmployer ,
                biEmployeePayComponentId: currentBenefits.biEmployeePayComponentId,
                biWorker:  currentBenefits.biWorker,
                biLtdAmount: currentBenefits.biLtdAmount,
                biLifeAdnDAmount: currentBenefits.biLifeAdnDAmount,
                biTotalTaxableBenefitsAmount: currentBenefits.biTotalTaxableBenefitsAmount ,
                biHealthAmount: currentBenefits.biHealthAmount,
                biBenefitsMarkUp: currentBenefits.biBenefitsMarkUp,
                biTotalMarkupAmount: currentBenefits.biTotalMarkupAmount,
                biBenefitsMarkupAmount: currentBenefits.biBenefitsMarkupAmount,
                notes: currentBenefits.notes,
                //added by nipul
                syncPayItemClient: currentBenefits.syncPayItemClient,
                payItemRefNumClient: currentBenefits.payItemRefNumClient,
                syncPayItemWorker: currentBenefits.syncPayItemWorker,
                payItemRefNumWorker: currentBenefits.payItemRefNumWorker,
            })
        }
        else{
            if(location.state.formData.benefitsInfos[0]){
                //setFormData({...formData, benefitsId : Number(location.state.formData.benefitsInfos[0].benefitsId) + 1})

                    let benefitsIds = location.state.formData.benefitsInfos.map(benefits => Number(benefits.benefitsId));
                    setFormData({...formData,
                        benefitsId: Math.max(...benefitsIds) + 1,
                        benefitsCertificateNo: location.state.formData.benefitsInfos[0].benefitsCertificateNo,
                        benefitsClass: location.state.formData.benefitsInfos[0].benefitsClass,
                        benefitsCoverage: location.state.formData.benefitsInfos[0].benefitsCoverage,
                        clientContribution: location.state.formData.benefitsInfos[0].clientContribution,
                        contribution: location.state.formData.benefitsInfos[0].contribution,
                        biBenefitsApplicable: location.state.formData.benefitsInfos[0].biBenefitsApplicable,
                        biBenefitsCurrency: location.state.formData.benefitsInfos[0].biBenefitsCurrency,
                        biBenefitsDeductionFrequency: location.state.formData.benefitsInfos[0].biBenefitsDeductionFrequency,
                        biEmployerPayComponentId: location.state.formData.benefitsInfos[0].biEmployerPayComponentId,
                        biEmployer: location.state.formData.benefitsInfos[0].biEmployer ,
                        biEmployeePayComponentId: location.state.formData.benefitsInfos[0].biEmployeePayComponentId,
                        biWorker:  location.state.formData.benefitsInfos[0].biWorker,
                        biLtdAmount: location.state.formData.benefitsInfos[0].biLtdAmount,
                        biLifeAdnDAmount: location.state.formData.benefitsInfos[0].biLifeAdnDAmount,
                        biTotalTaxableBenefitsAmount: location.state.formData.benefitsInfos[0].biTotalTaxableBenefitsAmount ,
                        biHealthAmount: location.state.formData.benefitsInfos[0].biHealthAmount,
                        biBenefitsMarkUp: location.state.formData.benefitsInfos[0].biBenefitsMarkUp,
                        biTotalMarkupAmount: location.state.formData.benefitsInfos[0].biTotalMarkupAmount,
                        biBenefitsMarkupAmount: location.state.formData.benefitsInfos[0].biBenefitsMarkupAmount,
                        benefitsStartDate: location.state.formData.benefitsInfos[0].benefitsEndDate !== '0' ? setAPIDate(plusDays(location.state.formData.benefitsInfos[0].benefitsEndDate, 1)) : formData.benefitsStartDate,
                        notes: location.state.formData.benefitsInfos[0].notes,
                        //added by nipul
                        syncPayItemClient: 0,
                        payItemRefNumClient: 0,
                        syncPayItemWorker: 0,
                        payItemRefNumWorker: 0,
                        })
                
            }
        }

    }, [])

    useEffect(()=>{
        // 4 properties need to exclude
        if(location.state.row){
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])
    // useEffect(()=>{
        
    //         let selectedDate = new Date(formData.benefitsStartDate).setHours(0, 0, 0, 0);    
    //         let currentDate = location.state.formData && location.state.formData.BenefitsInfos[1]  && new Date().setHours(0, 0, 0, 0);    
    //         setDisableEndDate(selectedDate < currentDate)
    //         setFormData({...formData, benefitsEndDate : ''})
        
    // },[formData.benefitsStartDate])

    const openPayItemReference = async (editable, mode) => {
        // let isItemExists = location.state.formData.benefitsInfos.filter((x) => { return (x.benefitsId === formData.benefitsId)});
        if(Number(location.state.formData.userId) !== 0) await props.get_pay_item_list(location.state.formData.userId)
        const currentBenefit = location.state.sectionformDataRef && (location.state.sectionformDataRef.benefitsInfos || []).find(benefit=> benefit.benefitsId === formData.benefitsId);
        const identical = currentBenefit ?  compareObjects(currentBenefit, formData) : false;
        if(!identical){
            setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Benefit is not saved. Would you like to save it now?',
                visible: true,
                onConfirm: async () => {
                    setConfirmAlert({
                        ...props.confirmAlert,
                        visible: false
                    })
                    if(!checkCurrentForm()){
                        isSubmitted.current = false;
                        document.getElementById('btnSave').click();
                    }
                    else {
                        isSubmitted.current = true;
                        if(SavingDataInObject()){
                            let profileSaved = await parseModuleObjectForSave();
                            if(profileSaved){
                                formData.syncPayItemClient = 0;
                                formData.syncPayItemWorker = 0;
                                setFormData({...formData})
                                location.state.row = {...formData}
                                //Need to show the modal popup
                                if(mode === 'worker'){
                                    setIsWorkerMode(true);
                                }
                                else
                                    setIsWorkerMode(false);
                                setIsSelectable(editable);
                                setOpenModalForPayItem(true);
                            }else{
                                removingObject(formData.benefitsId)
                            }
                        }
                    }
                }
            }) 
        }
        else {
            if(mode === 'worker'){
                setIsWorkerMode(true);
            }
            else
                setIsWorkerMode(false);
            setIsSelectable(editable);
            setOpenModalForPayItem(true);
        }
    }

    let roleSelected = location.state.formData.workerFlag
        || location.state.formData.clientUserFlag
        || location.state.formData.subscriberUserFlag
        || location.state.formData.subscriberApiIntegrationFlag
        || location.state.formData.clientHRFlag
        || location.state.formData.subscriberHRFlag
        || location.state.formData.clientBillingFlag
        || location.state.formData.subscriberBillingFlag
        || location.state.formData.clientAdminFlag
        || location.state.formData.subscriberAdminFlag
        || location.state.formData.systemAdminFlag

    async function parseModuleObjectForSave() {
        let savingObject = parsingUserProfileSavingObject(location.state.formData, location.state.sectionformDataRef, location.state.formDataRef, roleSelected);
        if(savingObject !== undefined && savingObject.flag){
            let response = await props.save_user_profile(savingObject.finalData);
            return response;
        }
        else {
            props.setAlert(savingObject.errorMessage, "danger");
            return savingObject.flag;
        }
    }

    const checkSaveAndSync = (type)=>{
        const currentBenefit = location.state.sectionformDataRef && (location.state.sectionformDataRef.benefitsInfos || []).find(benefit=> benefit.benefitsId === formData.benefitsId);
        const identical = currentBenefit ?  compareObjects(currentBenefit, formData) : false;
        if(!identical){
            setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Benefit is not saved. Would you like to save it now?',
                visible: true,
                onConfirm: async () => {
                    setConfirmAlert({
                        ...props.confirmAlert,
                        visible: false
                    })
                    if(!checkCurrentForm()){
                        isSubmitted.current = false;
                        document.getElementById('btnSave').click();
                    }
                    else {
                        isSubmitted.current = true;
                        if(SavingDataInObject()){
                            let profileSaved = await parseModuleObjectForSave();
                            if(profileSaved){
                                formData.syncPayItemClient = 0;
                                formData.syncPayItemWorker = 0;
                                setFormData({...formData})
                                location.state.row = {...formData}
                                syncPayItemWithUser(type)
                            }else{
                                removingObject(formData.benefitsId)
                            }
                        }
                    }
                }
            }) 
        }
        else {
            syncPayItemWithUser(type)
        }
    }
    //Type -> Either worker or client
    const syncPayItemWithUser = async (type) => {
        if(type === 'worker'){
            let workerRes = await props.sync_pay_items(formData.userId, SUBSCRIBER_SYNC_TYPE.BENEFITS_WORKER, formData.benefitsId);
            if(workerRes){
                setFormData({ ...formData, ["syncPayItemWorker"]: 1, payItemRefNumWorker: workerRes});
                changeLocationStateFormValues("syncPayItemWorker", 1);
                changeLocationStateFormValues("payItemRefNumWorker", workerRes);
                // location.state.row.syncPayItemWorker = 1;
            }
        }
        else {
            let clientRes = await props.sync_pay_items(formData.userId, SUBSCRIBER_SYNC_TYPE.BENEFITS_CLIENT, formData.benefitsId);
            if(clientRes){
                setFormData({ ...formData, ["syncPayItemClient"]: 1, payItemRefNumClient:clientRes });
                changeLocationStateFormValues("syncPayItemClient", 1);
                changeLocationStateFormValues("payItemRefNumClient", clientRes);
                // location.state.row.syncPayItemClient = 1;
            }
        }
    }

    function checkCurrentForm() {
        let isValid = true;
        let propArray = ['benefitsCoverage', 'benefitsClass', 'benefitsStartDate'
            , 'biBenefitsDeductionFrequency', 'biBenefitsCurrency', 'clientContribution'
            , 'contribution', 'biBenefitsMarkUp', 'biBenefitsMarkupAmount', 'biTotalMarkupAmount']
        
        let currentItems = {...formData};
        propArray.map(item => {
            if(currentItems.hasOwnProperty(item)){
                if(currentItems[item] === '')
                    isValid = false;
            }
        })
        
        return isValid;
    }

    async function attachClient(payItemRefNum) {
            let responseClient = await props.attach_Pay_Item(formData.userId, SUBSCRIBER_SYNC_TYPE.BENEFITS_CLIENT, formData.benefitsId, payItemRefNum);
            if(responseClient){
                setFormData({ ...formData, ["payItemRefNumClient"]: payItemRefNum, syncPayItemClient:  payItemRefNum === selectedClient ? formData.payItemRefNumClient : 0});
                changeLocationStateFormValues("payItemRefNumClient", payItemRefNum);
                changeLocationStateFormValues("syncPayItemClient", payItemRefNum === selectedClient ? formData.payItemRefNumClient : 0);
                location.state.row.payItemRefNumClient = payItemRefNum;
            }
        return responseClient;

    }
    async function attachWorker(payItemRefNum) {
            let responseWorker = await props.attach_Pay_Item(formData.userId, SUBSCRIBER_SYNC_TYPE.BENEFITS_WORKER, formData.benefitsId, payItemRefNum);
            if(responseWorker){
                setFormData({ ...formData, ["payItemRefNumWorker"]: payItemRefNum, syncPayItemWorker: payItemRefNum === selectedWorker ? formData.syncPayItemWorker : 0 });
                changeLocationStateFormValues("payItemRefNumWorker", payItemRefNum);
                changeLocationStateFormValues("syncPayItemWorker", payItemRefNum === selectedWorker ? formData.syncPayItemWorker : 0);
                location.state.row.payItemRefNumWorker = payItemRefNum;
            }
        return responseWorker;

    }
    // useEffect(()=>{
    //     async function attachClient() {
    //         if(selectedClient !== 0){
    //             let responseClient = await props.attach_Pay_Item(formData.userId, SUBSCRIBER_SYNC_TYPE.BENEFITS_CLIENT, formData.benefitsId, selectedClient);
    //             if(responseClient){
    //                 setFormData({ ...formData, ["payItemRefNumClient"]: selectedClient });
    //                 changeLocationStateFormValues("payItemRefNumClient", selectedClient);
    //                 location.state.row.payItemRefNumClient = selectedClient;
    //             }
    //         }
    //     }
    //     attachClient()
    // },[selectedClient])

    // useEffect(()=>{
    //     async function attachWorker() {
    //         if(selectedWorker !== 0) {
    //             let responseWorker = await props.attach_Pay_Item(formData.userId, SUBSCRIBER_SYNC_TYPE.BENEFITS_WORKER, formData.benefitsId, selectedWorker);
    //             if(responseWorker){
    //                 setFormData({ ...formData, ["payItemRefNumWorker"]: selectedWorker });
    //                 changeLocationStateFormValues("payItemRefNumWorker", selectedWorker);
    //                 location.state.row.payItemRefNumWorker = selectedWorker;
    //             }
    //         }
    //     }
    //     attachWorker();
    // },[selectedWorker])

    useEffect(()=>{
        if(props.userProfile.userId !== undefined && props.isSaveUserProfile){
            setFormData({ ...formData, userId: props.userProfile.userId });
            if(location.state.row !== undefined)
                location.state.row.userId = props.userProfile.userId;
            if(location.state.formData !== undefined)
                location.state.formData.userId = props.userProfile.userId;
            if(location.state.userRole !== undefined)
                location.state.userRole.userId = props.userProfile.userId;
        }
    },[props.userProfile])

    function changeLocationStateFormValues(property, value){
        let updatedFormData = { ...formData };
        let currentBenefitForms = location.state.formData.benefitsInfos;
        let updatedBenefits = [];
        if (currentBenefitForms) {
            updatedBenefits = currentBenefitForms.map(benefit => {
                if (benefit.benefitsId === updatedFormData.benefitsId) {
                    return {...benefit, [property] : value }
                }
                else {
                    return benefit;
                }
            })
        }
        location.state.formData.benefitsInfos = updatedBenefits;
        location.state.formDataRef.benefitsInfos = updatedBenefits;
        location.state.sectionformDataRef.benefitsInfos = updatedBenefits;
    }

    return (
        <>
            <Container fluid className="main-content-container p-0">
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert}/>
                <Form id="frmBenefit" onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">
                                            {/*<h4 className="m-0">Placement - First Name Last Name</h4>*/}
                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm' disabled={disabledSave}
                                                theme="accent" id="btnSave">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>

                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.benefitsId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Benefits Coverage</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='benefitsCoverage' value={formData.benefitsCoverage}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Benefits Coverage</option>
                                                {benefitsCoverageListOption}

                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Benefits Class</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='benefitsClass' value={formData.benefitsClass}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Benefits Class</option>
                                                {benefitsClassListOption}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="benefitsStartDate">Benefits Start Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                required={true}
                                                id='benefitsStartDate'
                                                autoComplete='off'
                                                name='benefitsStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText='YYYY-MM-DD'
                                                selected={formData.benefitsStartDate}
                                                onChange={(date) =>
                                                    onDateChange("benefitsStartDate", date)
                                                }
                                                value={formData.benefitsStartDate}
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="benefitsEndDate">Benefits End Date</label>
                                            <DatePicker
                                                disabled={disabledEndDate}
                                                id='benefitsEndDate'
                                                name='benefitsEndDate'
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText='YYYY-MM-DD'
                                                selected={formData.benefitsEndDate}
                                                autoComplete='off'
                                                onChange={(date) =>
                                                    onDateChange("benefitsEndDate", date)
                                                }
                                                value={formData.benefitsEndDate}
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="cirtificateNo">Benefits Certificate No</label>
                                            <FormInput
                                                type="number"
                                                id="cirtificateNo"
                                                placeholder="Enter Benefits Certificate No"
                                                name='benefitsCertificateNo'
                                                onChange={onChange}
                                                value={formData.benefitsCertificateNo}
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Benefits Deduction Frequency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='biBenefitsDeductionFrequency' value={formData.biBenefitsDeductionFrequency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Benefits Deduction Frequency</option>
                                                <option value='Weekly'>Weekly (52 Cycles)</option>
                                                <option value='Bi-weekly'>Bi-weekly (26 Cycles)</option>
                                                <option value='Semi-monthly'>Semi-monthly (24 Cycles)</option>
                                                <option value='Monthly'>Monthly (12 Cycles)</option>
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Benefits Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='biBenefitsCurrency' value={formData.biBenefitsCurrency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Benefits Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component (Employer)</label>
                                            <FormSelect name='biEmployerPayComponentId' value={formData.biEmployerPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Pay Component (Employer)</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                        <InputBox required={true} placeholder="0.00" label="Employer %" id='biEmployer' name='biEmployer'
                                            value={formData.biEmployer}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <Col lg="2" className="form-group m-0 p-2">
                                            <label htmlFor="clientContribution">Employer Contribution</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormInput
                                                required={true}
                                                id="clientContribution"
                                                placeholder="0.00"
                                                name='clientContribution'
                                                className="align-input-amount-right"
                                                onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                                value={formData.clientContribution}
                                            />

                                        </Col>
                                        <Col lg="4" className="form-group m-0 p-2">
                                            <div className="form-group m-0 d-flex flex-row justify-content-left mb-2">
                                                <label className='col-lg-5 pl-0'> Sync Client Status </label>
                                                <label className={ formData.syncPayItemClient === 1 ? 'text-success col-lg-4' : 'text-danger col-lg-4'}> 
                                                    { formData.syncPayItemClient === 1 ? 'Synced' : 'Not Synced'} </label>
                                                <div className='col-lg-4'>
                                                    <Button onClick={() => checkSaveAndSync('client')}>Sync</Button>
                                                </div>
                                            </div>
                                            <div className="form-group m-0 d-flex flex-row justify-content-left mb-2">
                                                <label className='pl-0 col-lg-4 pr-0'> Pay Item Reference </label>
                                                <label className="pl-0 primary-text font-weight-bold ml-1 col-lg-4 text-center" role="button" onClick={() => { 
                                                    if(formData.payItemRefNumClient > 0) history.push({
                                                        pathname: `/sub-add-edit-pay-item/${formData.payItemRefNumClient}`,
                                                        state: {
                                                            refNum: formData.payItemRefNumClient,
                                                            role: location.state.role,
                                                            formData: location.state.formData,
                                                            formDataRef: location.state.formDataRef,
                                                            sectionformDataRef: location.state.sectionformDataRef
                                                        },
                                                    });
                                                 }} >
                                                    { formData.payItemRefNumClient > 0 && formData.payItemRefNumClient }
                                                </label>
                                                <div className='col-lg-5 pl-0 '>
                                                    <Button className="mr-2 mt-1" onClick={() => openPayItemReference(true, 'client')}>
                                                        Find Pay Item
                                                    </Button>
                                                </div>
                                            </div>
                                            <PayTypeComponentModal openModal={openModalForPayItem} 
                                                openModalFunc={toggleModal} userName={userNameForModal} 
                                                attachPayItem={isWorkerMode ? attachWorker : attachClient}
                                                setSelected={isWorkerMode ? setSelectedWorker : setSelectedClient} 
                                                existingSelection={isWorkerMode ? (formData.payItemRefNumWorker) : (formData.payItemRefNumClient)} 
                                                isSelectable={isSelectable}>
                                            </PayTypeComponentModal>
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component (Employee)</label>
                                            <FormSelect name='biEmployeePayComponentId' value={formData.biEmployeePayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Pay Component (Employee)</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                        <InputBox required={true} placeholder="0.00" label="Worker %" id='biWorker' name='biWorker'
                                            value={formData.biWorker}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <Col lg="2" className="form-group m-0 p-2">
                                            <label htmlFor="myContribution">Worker Contribution</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormInput required={true}
                                                id="myContribution"
                                                placeholder="0.00"
                                                name='contribution'
                                                className="align-input-amount-right"
                                                onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                                value={formData.contribution}
                                            />
                                        </Col>
                                        <Col lg="4" className="form-group m-0 p-2">
                                            <div className="form-group m-0 d-flex flex-row justify-content-left mb-2">
                                                <label className='col-lg-5 pl-0'> Sync Worker Status </label>
                                                <label className={ formData.syncPayItemWorker === 1 ? 'text-success col-lg-4' : 'text-danger col-lg-4'}> 
                                                    { formData.syncPayItemWorker === 1 ? 'Synced' : 'Not Synced'} </label>
                                                <div className='col-lg-4'>
                                                    <Button onClick={() => checkSaveAndSync('worker')}>Sync</Button>
                                                </div>
                                            </div>
                                            <div className="form-group m-0 d-flex flex-row justify-content-left mb-2">
                                                <label className='pl-0 col-lg-4 pr-0'> Pay Item Reference </label>
                                                <label className="pl-0 primary-text font-weight-bold ml-1 col-lg-4 text-center" role="button" onClick={() => { 
                                                    if(formData.payItemRefNumWorker > 0) history.push({
                                                        pathname: `/sub-add-edit-pay-item/${formData.payItemRefNumWorker}`,
                                                        state: {
                                                            refNum: formData.payItemRefNumWorker,
                                                            role: location.state.role,
                                                            formData: location.state.formData,
                                                            formDataRef: location.state.formDataRef,
                                                            sectionformDataRef: location.state.sectionformDataRef
                                                        },
                                                    });
                                                 }} >
                                                    { formData.payItemRefNumWorker > 0 && formData.payItemRefNumWorker }
                                                </label>
                                                <div className='col-lg-5 pl-0 '>
                                                    <Button className="mr-2 mt-1" onClick={() => openPayItemReference(true, 'worker')}>Find Pay Item</Button>
                                                </div>
                                            </div>
                                        </Col>

                                        <InputBox placeholder="0.00" label="Long Term Disability (LTD) Amount" id='biLtdAmount' name='biLtdAmount'
                                            value={formData.biLtdAmount}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        {/*<Col lg="3" className="form-group m-0 p-2">
                                            <label>Benefits Applicable</label>
                                            <FormSelect name='biBenefitsApplicable' value={formData.biBenefitsApplicable}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Benefits Applicable</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
                                            </Col>*/}


                                        <InputBox placeholder="0.00" label="Life, AD & D Amount" id='biLifeAdnDAmount' name='biLifeAdnDAmount'
                                            value={formData.biLifeAdnDAmount}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <InputBox placeholder="0.00" label="Total Taxable Benefits Amount" id='biTotalTaxableBenefitsAmount' name='biTotalTaxableBenefitsAmount'
                                            value={formData.biTotalTaxableBenefitsAmount}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <InputBox placeholder="0.00" label="Health Amount" id='biHealthAmount' name='biHealthAmount'
                                            value={formData.biHealthAmount}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />





                                        {/*  <InputBox placeholder="Enter Benefits Markup Type" label="Benefits Markup Type" id='biBenefitsMarkUp' name='biBenefitsMarkUp'
                value={biBenefitsMarkUp}
        onChange={onChange}/>*/}
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="biBenefitsMarkUp">Benefits Markup Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='biBenefitsMarkUp' value={formData.biBenefitsMarkUp}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Benefits Markup Type</option>
                                                <option value='%'>%</option>
                                                <option value='Flat'>Flat</option>
                                            </FormSelect>
                                        </Col>
                                        <InputBox required={true} placeholder="Enter Benefits Markup Amount" label="Benefits Markup Amount" id='biBenefitsMarkupAmount' name='biBenefitsMarkupAmount'
                                            value={formData.biBenefitsMarkupAmount}
                                            align={formData.biBenefitsMarkUp === '%' ? 'left' : 'right'}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <InputBox required={true} placeholder="0.00" label="Benefits Total Markup Amount" id='biTotalMarkupAmount' name='biTotalMarkupAmount'
                                            value={formData.biTotalMarkupAmount}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                            align='right'
                                            toolTipMSG="The Amount a company makes on Benefits Service."
                                        />
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor="notes">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.notes} rows="3" id="notes" name="notes" onChange={(e) => {
                                                if(formData.notes.length >= 99){
                                                   
                                                    props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                                }
                                                onChange(e)
                                            }}>
                                                {formData.notes}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>

                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

BenefitsForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,
    benefitsClassList: PropTypes.array.isRequired,
    benefitsCoverageList: PropTypes.array.isRequired,

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        isSaveUserPlacement: state.isSaveUserPlacement,
        benefitsClassList: state.user.benefitsClassList,
        benefitsCoverageList: state.user.benefitsCoverageList,
    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    get_benefits_class_list,
    get_benefits_coverage_list,
    save_user_profile,
    update_isSave_user_profile,
    sync_pay_items,
    attach_Pay_Item,
    get_pay_item_list
})(BenefitsForm);