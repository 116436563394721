import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  Alert,
  Card,
  Col,
  Container, Form, ListGroup, ListGroupItem, Nav,
  Row
} from "shards-react";
import {
  get_client_view_worker_profile,
  clear_client_view_worker_profile
} from "../../redux/actions/client/clientWorkers";
import {formatPhoneNumber} from "../../utils/general";

function ClientViewWorkerProfile(
  {
    id,
    workerForClientProfile,
    get_client_view_worker_profile,
    clear_client_view_worker_profile,
  }
) {

  useEffect(() => {
    get_client_view_worker_profile(id);
    return () => clear_client_view_worker_profile()
  }, [id]);



  return (
    <div>
      <Container fluid className="main-content-container px-0">
        <div className='profileNav offset-md-3 col-lg-10 offset-lg-2 p-0'>

          <Container fluid className="px-0">
            <Alert theme="white"
                   className="border-bottom text-danger show px-4 mb-0">
              Your profile is view only. For changes, please contact your HR
              representative.
            </Alert>
          </Container>

          <TabBar/>
        </div>

        <Row className='px-4 m-0 view-user-profile main'>

          <Card id='personal' small
                className={"details-card mb-5 w-100 d-flex personalParent"}>
            <img className="avatarImg"
                 src={require("../../images/avatars/img_avatar2.png")}
                 alt="avatar"/>
            <h4 className="m-0 section-title headerStyle">Personal
              Information</h4>
            <h4
              className="m-0 section-title idStyle">Ref# {(workerForClientProfile.personalInfo)?workerForClientProfile.userId:""}</h4>
            <ListGroup flush>
              <ListGroupItem className={"p-3 mt-3"}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Status</label>
                          <span
                            className="inputBoxClr   form-control">{workerForClientProfile.employmentStatus}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Pronoun</label>
                          <span
                            className="inputBoxClr   form-control">{(workerForClientProfile.personalInfo)?workerForClientProfile.personalInfo.pronoun:""}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Legal First Name</label>
                          <span
                            className="inputBoxClr   form-control">{(workerForClientProfile.personalInfo)?workerForClientProfile.personalInfo.firstName:""}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Legal Last Name</label>
                          <span
                            className="inputBoxClr   form-control">{(workerForClientProfile.personalInfo)?workerForClientProfile.personalInfo.lastName:""}</span>
                        </Col>

                        {/* <Col lg="3" className="form-group p-2 mb-0">
                          <label>Nickname</label>
                          <span
                            className="inputBoxClr   form-control">{(workerForClientProfile.personalInfo)?workerForClientProfile.personalInfo.nickName:""}</span>
                        </Col> */}

                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>


            </ListGroup>
          </Card>

          <Card id='employment' small className="details-card mb-5 w-100">
            <span className="iconBox"><i
              className="fas fa-users iconStyle"/></span>
            <h4 className="m-0 section-title headerStyle">Company
              Information</h4>
            <ListGroup flush>
              <ListGroupItem className="p-3 mt-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>


                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Client</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.employmentInfo)?workerForClientProfile.employmentInfo.clientName:""}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Job Title</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.employmentInfo)?workerForClientProfile.employmentInfo.jobTitle:""}</span>
                        </Col>
                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Department</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.employmentInfo)?workerForClientProfile.employmentInfo.department:""}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 mb-0">
                          <label>Address Line 1</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.companyInfo)?workerForClientProfile.companyInfo.addressLine1:""}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 mb-0">
                          <label>Address Line 2</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.companyInfo)?workerForClientProfile.companyInfo.addressLine2:""}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Country</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.companyInfo)?workerForClientProfile.companyInfo.countryName:""}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>State/Province/Region</label>
                          <span
                            className="inputBoxClr  form-control">{(workerForClientProfile.companyInfo)?workerForClientProfile.companyInfo.regionName:""}</span>
                        </Col>


                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>City</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.companyInfo?workerForClientProfile.companyInfo.cityName:""}</span>
                        </Col>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>ZIP/Postal Code</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.companyInfo?workerForClientProfile.companyInfo.zip:""}</span>
                        </Col>


                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>

          <Card id='contact' small className="details-card mb-5 w-100">
            <span className="iconBox"><i className="fas fa-id-badge iconStyle"/></span>
            <h4 className="m-0 section-title headerStyle">Contact</h4>
            <ListGroup flush>
              <ListGroupItem className="p-3 mt-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Phone - Work</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.contactInfo?formatPhoneNumber(workerForClientProfile.contactInfo.phoneWork):""}</span>
                        </Col>

                        {/* <Col lg="3" className="form-group p-2 mb-0">
                          <label>Phone - Work Ext</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.contactInfo?workerForClientProfile.contactInfo.phoneWorkExt:""}</span>
                        </Col> */}


                        {/* <Col lg="3" className="form-group p-2 mb-0">
                          <label>Phone - Mobile</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.contactInfo?formatPhoneNumber(workerForClientProfile.contactInfo.phoneMobile):""}</span>
                        </Col> */}

                        {/* <Col lg="3" className="form-group p-2 mb-0">
                          <label>Linkedin</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.companyInfo?workerForClientProfile.companyInfo.linkedIn:""}</span>
                        </Col> */}

                        {/* <Col lg="3" className="form-group p-2 mb-0">
                          <label>Email - Personal</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.contactInfo?workerForClientProfile.contactInfo.emailPersonal:""}</span>
                        </Col> */}

                        <Col lg="3" className="form-group p-2 mb-0">
                          <label>Email - Work</label>
                          <span
                            className="inputBoxClr  form-control">{workerForClientProfile.contactInfo?workerForClientProfile.contactInfo.emailWork:""}</span>
                        </Col>

                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  id:state.user.user.userId,
  workerForClientProfile: state.clientWorkers.workerForClientProfile,
});
export default connect(mapStateToProps, {
  get_client_view_worker_profile,
  clear_client_view_worker_profile
})(ClientViewWorkerProfile);





function TabBar() {
  const [componentState, setComponentState] = useState({
    activeSection: '',
  })

  useEffect(() => {
    if(!componentState.activeSection)
      setComponentState({
        ...componentState,
        activeSection: 'personal'
      })
  }, [componentState.activeSection]);


  const scrollInto = (id) => {
    document.getElementById(id).getElementsByClassName('section-title')[0].scrollIntoView();
    setComponentState({...componentState, activeSection: id})
  }

  const isSectionVisible = (rect) => {
    return (rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) - 100) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth))
  }

  const setActiveSection = () => {
    for (var i = 0; i < sections.length; i++) {
      let rect = document.querySelector(`#${sections[i].id} .section-title`).getBoundingClientRect()
      if (isSectionVisible(rect)) {
        if (componentState.activeSection !== sections[i].id) {
          setComponentState({...componentState, activeSection: sections[i].id})
        }
        break
      }
    }
  }

  useEffect(() => {
    window.onscroll = () => {
      setActiveSection()
    }
    return () => {
      window.onscroll = null
    }
  }, []);

  const sections=[
    {
      id:"personal",
      title:"Personal",
    },

    {
      id:"employment",
      title:"Company",
    },

    {
      id:"contact",
      title:"Contact",
    },
  ]

  const sectionLinks=sections.map(section=>{
    return <a
      className={'nav-link ' + (componentState.activeSection === section.id ? 'active' : '')}
      onClick={() => scrollInto(section.id )}>{section.title}</a>
  })

  return (
    <Col sm={12} md={8} lg={9} xl={10}
         className='wrapper d-flex mx-md-2 align-items-end order-md-0'>
      <Nav tag="nav" className="flex-nowrap overflow-auto">
        {sectionLinks}
      </Nav>
    </Col>
  );
}
