import {BULK_UPDATE_PAY_RECORD_STATUS, BULK_UPDATE_PAY_RECORD_STATUS_ERROR, CALCULATE_PAY_RECORD_ITEM_DETAILS, CALCULATE_PAY_RECORD_ITEM_DETAILS_ERROR, GET_PAY_RECORD, GET_PAY_RECORD_ERROR, GET_PAY_RECORD_ITEM, GET_PAY_RECORD_ITEM_ERROR, GET_PAY_RECORD_LIST, GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER, GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER_ERROR, GET_PAY_RECORD_LIST_ERROR, GET_PAY_RECORD_STATUS_LIST, GET_PAY_RECORD_STATUS_LIST_ERROR, RESET_BULK_UPDATE_PAY_RECORD_STATUS, RESET_BULK_UPDATE_PAY_RECORD_STATUS_ERROR, RESET_PAY_RECORD, RESET_PAY_RECORD_ITEM, SAVE_PAY_RECORD, SAVE_PAY_RECORD_ERROR } from "../../actions/types";

const initialState = {
    payRecordStatusList:[],
    payRecord:{},
    payRecordFile:{},
    calculatedPayRecordItemDetails:{},
    payRecordItem:{},
    payRecordList:[],
    bulkUpdate:false,
    payRecordListByInvoice: [],
}

const payRecord = (state = initialState, action)=>{

    switch(action.type){
        case GET_PAY_RECORD_STATUS_LIST:{
            return{
                ...state,
                payRecordStatusList:action.payload.data
            }
        }
        case GET_PAY_RECORD_STATUS_LIST_ERROR:{
            return{
                ...state,
                payRecordStatusList:initialState.payRecordStatusList
            }
        }
        case GET_PAY_RECORD:{
            return{
                ...state,
                payRecord:action.payload.data
            }
        }
        case GET_PAY_RECORD_ERROR:{
            return{
                ...state,
                payRecord:initialState.payRecord
            }
        }
        case SAVE_PAY_RECORD:{
            return{
                ...state,
                payRecord:action.payload.data
            }
        }
        case SAVE_PAY_RECORD_ERROR:{
            return{
                ...state,
                payRecord:initialState.payRecord
            }
        }
        case RESET_PAY_RECORD:{
            return{
                ...state,
                payRecord:initialState.payRecord,
                payRecordList:initialState.payRecordList
            }
        }
        case CALCULATE_PAY_RECORD_ITEM_DETAILS :{
            return{
                ...state,
                calculatedPayRecordItemDetails:action.payload.data
            }
        }
        case CALCULATE_PAY_RECORD_ITEM_DETAILS_ERROR :{
            return{
                ...state,
                calculatedPayRecordItemDetails:initialState.calculatedPayRecordItemDetails
            }
        }
        case GET_PAY_RECORD_ITEM:{
            return{
                ...state,
                payRecordItem:action.payload.data
            }
        }
        case GET_PAY_RECORD_ITEM_ERROR:{
            return{
                ...state,
                payRecordItem:initialState.payRecordItem
            }
        }
        case RESET_PAY_RECORD_ITEM:{
            return{
                ...state,
                payRecordItem:initialState.payRecordItem
            }
        }
        case GET_PAY_RECORD_LIST :{
            return{
                ...state,
                payRecordList:action.payload.data
            }
        }
        case GET_PAY_RECORD_LIST_ERROR :{
            return{
                ...state,
                payRecordList:initialState.payRecordList
            }
        }
        case BULK_UPDATE_PAY_RECORD_STATUS:{
            return{
                ...state,
                bulkUpdate:true
            }
        }
        case BULK_UPDATE_PAY_RECORD_STATUS_ERROR:{
            return{
                ...state,
                bulkUpdate:initialState.bulkUpdate
            }
        }
        case BULK_UPDATE_PAY_RECORD_STATUS_ERROR, RESET_BULK_UPDATE_PAY_RECORD_STATUS:{
            return{
                ...state,
                bulkUpdate:false
            }
        }
        case GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER: {
            return {
                ...state,
                payRecordListByInvoice: action.payload.data
            }
        }
        case GET_PAY_RECORD_LIST_BY_INVOICE_NUMBER_ERROR: {
            return {
                ...state,
                payRecordListByInvoice: []
            }
        }

        default:
            return state
    }
}

export default payRecord;