import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Row } from 'shards-react'
import { get_payee_configuration, reset_payee_configuration, save_payee_configuration } from '../../../redux/actions/subscriber/payConfiguration';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InputBox from '../../../components/common/InputBox';

const AddEditPayeeConfig = ({ get_payee_configuration, payeeDetail, save_payee_configuration, reset_payee_configuration }) => {
    const { payeeRefNum } = useParams();
    const history = useHistory();
    const [returnBack, isReturnBack] = useState(false);
    const [formData, setFormData] = useState({
        payeeRefNum: 0,
        name: '',
    });

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        resetForm();
        if (payeeRefNum > 0) {
            get_payee_configuration(payeeRefNum);
        }
    }, [])

    useEffect(() => {
        console.log("payee Detail =>", payeeDetail);
        if (payeeDetail && Object.keys(payeeDetail).length !== 0) {
            setFormData({
                payeeRefNum: payeeDetail.payeeRefNum,
                name: payeeDetail.name,
            });
        }
        else {
            setFormData({
                payeeRefNum: 0,
                name: '',
            });
        }
    }, [payeeDetail])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (formData.name !== '') {
            await save_payee_configuration(JSON.stringify(formData));
            if (e.nativeEvent.submitter.name === "SaveAnother") {
                resetForm();
            }
            else {
                resetForm();
                history.goBack();
            }
        }
    }

    function resetForm() {
        setFormData({
            payeeRefNum: 0,
            name: '',
        });
        reset_payee_configuration();
    }

    function resetAndBack(){
        resetForm();
        history.goBack();
    }

    return (<>
        <Container fluid className="main-content-container p-4">
            <div className="w-100">
                <Form id="frmPayee" onSubmit={onSubmit}>
                    <Card small className="mb-5 w-100 p-1">
                        <h4 className="m-0 section-title idStyle">Ref# {payeeRefNum}</h4>
                        <ListGroup flush>
                            <ListGroupItem className="border-card">
                                <Row form>
                                    <Col lg="6" className="form-group m-0 p-2">
                                        <InputBox id="name" name="name" label="Payee" required={true}
                                            value={formData.name} onChange={(e) => onChange(e)} placeholder="Enter payee"
                                            collg={12} align={'left'} />
                                    </Col>

                                    <Col lg="6" className="form-group p-2 m-0 mt-3 file-manager__filters__search d-flex">
                                        <Button className="mx-2 py-2 my-4 btn btn-accent btn-sm" theme="accent" type="submit" name="SaveAnother">
                                            Save And Add Another
                                        </Button>
                                        <Button className="mx-2 py-2 my-4 btn btn-accent btn-sm" theme="accent" type="submit" name="Save">
                                            Save And Return
                                        </Button>
                                        <Button className="mx-2 py-2 my-4 btn btn-accent btn-sm" theme="accent" type="button" onClick={() => { resetAndBack();}}>
                                            Cancel
                                        </Button>
                                    </Col>

                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Form>
            </div>
        </Container>
    </>)
}

const mapStateToProps = (state) => ({
    payeeDetail: state.payConfiguration.payeeDetail,
});

export default connect(mapStateToProps, { get_payee_configuration, save_payee_configuration, reset_payee_configuration})
    (AddEditPayeeConfig)
