import ReactTable from "react-table";
import CustomModal from "../../../components/common/CustomModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, FormSelect } from "shards-react";
import { connect } from "react-redux";
import { get_payments, reset_payments } from "../../../redux/actions/subscriber/payments";
import { payment } from "../../../redux/reducers/subscriber/payments";
import { setDateForTable } from "../../../utils/date";
import { formatNumberAmount } from "../../../utils/general";
import classNames from "classnames";

 function ViewIncomingAndOutGoingPayments({
    onShowModal,
    toggle,
    openModal,
    paymentType,
    headerTitle,
    module,
    referenceId,
    currency,
    payments,
    get_payments,
    reset_payments }) {
    const initialState = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        from: '',
        to: '',
        payee: '',
        subPayee: '',
        paymentRefNum: '',
        status: '',
        currency: '',
        bulkAction: ''
    }
    const [state, setState] = useState({ ...initialState });
    const { pageSize, pageSizeOptions } = state;
    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const tableColumns = [
        
        {
            Header: "Ref #",
            accessor: "paymentRefNum",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => <Link to={`/new-payment/${row.original.paymentRefNum}`}>{`PMT-${row.original.paymentRefNum}`}</Link>
        },
        {
            Header: "Date",
            accessor: "paymentDate",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => setDateForTable(row.original.paymentDate)
        },
        {
            Header: "Payer",
            accessor: "payer",
            // maxWidth: 140,
            minWidth: 100,
            Cell:row=><span title={row.original.payer}>{row.original.payer}</span>

        },
        {
            Header: "Payee",
            accessor: "payee",
            // maxWidth: 140,
            minWidth: 100,
            Cell:row=><span title={row.original.payee}>{row.original.payee}</span>
        },
        {
            Header: "Payment Status",
            accessor: "paymentStatus",
            maxWidth: 140,
            minWidth: 100,
            Cell:row=><span title={row.original.paymentStatus}>{row.original.paymentStatus}</span>
        },
        {
            Header: "Payment Currency",
            accessor: "paymentCurrency",
            maxWidth: 140,
            minWidth: 100,
        },
        {
            Header: "Payment Amount",
            accessor: "paymentAmount",
            // maxWidth: 140,
            minWidth: 100,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.paymentAmount, 'en-US', 2, 2)}</span>
        },
        {
            Header: "FX Rate",
            accessor: "fxRate",
            maxWidth: 90,
            minWidth: 90,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.fxRate, 'en-US', 5, 5)}</span>
        },
        {
            Header: `Amount (${currency})` ,
            accessor: "allocatedPaymentAmount",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.allocatedPaymentAmount, 'en-US', 2, 2)}</span> ,
            Footer:<span className="w-100 text-right">{formatNumberAmount(payments.totalAllocatedAmount, 'en-US', 2, 2)}</span>,
        },
    ]
        if(paymentType === 'Incoming' && module === "PayRecord")
            tableColumns.splice(2, 0, {
                Header: "Allocation",
                accessor: "allocationRefNum",
                maxWidth: 140,
                minWidth: 100,
                Cell: row => <Link to={`/new-invoice/${row.original.referenceId}`}>{`INV-${row.original.referenceId}`}</Link>
            })
        const pageSizeOption = <span className="file-manager__filters__rows d-flex justify-content-end"><span>Show</span>
        <FormSelect
            size="sm"
            name='pageSize'
            value={state.pageSize}
            className="d-inline"
            onChange={onChange}
        >
            {pageSizeOptions.map((size, idx) => (
                <option key={idx} value={size}>
                    {size} rows
                </option>
            ))}
        </FormSelect>
    </span>
    const closeButton = <span className="d-flex flex-row justify-content-between">
        <Button onClick={toggle} className="px-4 my-2">Cancel</Button>
    </span>

    // useEffect(()=>{
    //     if(openModal && referenceId > 0){
    //         if(module === "PayRecord")
    //             get_payments(paymentType === 'Incoming' ? 'PayRecordIncoming' : paymentType, referenceId);
    //         else 
    //             get_payments(paymentType, referenceId);
    //     }else{
    //         reset_payments();
    //     }
    //     return reset_payments;
    // },[openModal])
    return (
        <CustomModal
            onShowModal={onShowModal}
            toggle={toggle}
            openModal={openModal}
            headerTitle={headerTitle}
            showCloseIcon={false}
            headerComponent={pageSizeOption}
            footerComponent={closeButton}
            className="lg-modal custom-modal"
            footerClasses="d-flex flex-row justify-content-center border-top-0"
        >
            {/* payments?.payments?.length < 10 ? payments?.payments?.length : */}
            <div className="w-100">
                <ReactTable
                    className="no-border-footer"
                    columns={tableColumns}
                    data={payments.payments}
                    pageSize={ Number(pageSize) }
                    showPageSizeOptions={false}
                    resizable={false}
                    noDataText="No results found"
                />
            </div>

        </CustomModal>
    )
}

const mapStateToProps = (state=>(
    {
        payments:state.payment.payments
    }
))
export default connect(mapStateToProps,{
    get_payments,
    reset_payments
})(ViewIncomingAndOutGoingPayments);